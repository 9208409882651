<template>
    <div>
        <h1 class="page-header"><i class="pi pi-star-o"></i> Lista ofert darowizny</h1>

        <div class="search-criteria" @keyup.enter="search">
            <CommissionSearchCriteria ref="commissionSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button v-if="!Role.isOPLCoordinator()"
                                class="button-link" type="button" role="link" icon="pi pi-plus" label="Dodaj zlecenie"
                                @click="$router.push({name: 'commissionCreate'})"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <TabMenu v-if="Role.isBZCoordinator()" class="tabmenu" :model="menuItems"/>

        <CommissionTable ref="commissionTable" :search-criteria="searchCriteria"
                         :display-all="displayAll" :query-type="queryType"
                         @loaded="loadInitialDisplayAll" @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import TabMenu from "primevue/tabmenu";
    import {SystemRole} from "@/utils/SystemRole";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CommissionSearchCriteria from "./components/CommissionSearchCriteria";
    import CommissionTable from "./components/CommissionTable";

    export default {
        name: "CommissionListView",
        components: {
            CommissionTable, CommissionSearchCriteria, Button, Toolbar, TabMenu,
        },

        data() {
            return {
                searchCriteria: {
                    ownerName: "",
                    product: "",
                    initialAmountMin: null,
                    initialAmountMax: null,
                    currentAmountMin: null,
                    currentAmountMax: null,
                    unit: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                displayAll: null,
                queryType: SystemRole.isOPLCoordinator() ? "OTHERS" : "OWN",
                Role: SystemRole,
                menuItems: [
                    {label: "Moje propozycje darowizn", command: () => this.setQueryType("OWN")},
                    {label: "Oferty darowizn FPBŻ/BŻ", command: () => this.setQueryType("OTHERS")},
                ],
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.commissionTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                return {
                    ownerName: "",
                    product: "",
                    initialAmount: null,
                    currentAmount: null,
                    unit: "",
                    queryType: this.searchCriteria.queryType,
                    page: this.searchCriteria.page,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },

            setQueryType(value) {
                this.queryType = value;
            },
        },
    };
</script>

<style scoped>

</style>
