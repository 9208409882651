export const AccessibilityTableMixin = {

    mounted() {
        // this.checkIfExistsPaginationAndAddLabel();
    },

    methods: {
        setAccessibilityPaginatorDropdown() {
            const dropdownPaginatorTable = document.querySelector(".p-paginator .p-dropdown");
            if (dropdownPaginatorTable) {
                const createdLabel = document.createElement("label");
                dropdownPaginatorTable.appendChild(createdLabel);
                createdLabel.innerHTML = "Ilość wyświetlanych pozycji";
                createdLabel.setAttribute("for", "paginator-options");
                createdLabel.classList.add("p-hidden-accessible");
                dropdownPaginatorTable.querySelector("input").id = "paginator-options";
            }
        },

        checkIfExistsPaginationAndAddLabel() {
            const observer = new MutationObserver(() => {
                if (document.querySelectorAll(".p-paginator-first")[0]) {
                    this.addLabelToButtonPaginator();
                    this.setAccessibilityPaginatorDropdown();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                attributes: true,
                childList: true,
                characterData: true,
                subtree: true,
            });
        },
        addLabelToButtonPaginator() {
            const paginatorFirst = document.querySelectorAll(".p-paginator-first");
            for (let i = 0; i < paginatorFirst.length; i += 1) {
                if (!paginatorFirst[i].classList.contains("first-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorFirst[i].classList.add("first-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Pierwsza strona";
                    paginatorFirst[i].appendChild(buttonLabel);
                }
            }
            const paginatorLast = document.querySelectorAll(".p-paginator-last");
            for (let i = 0; i < paginatorLast.length; i += 1) {
                if (!paginatorLast[i].classList.contains("last-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorLast[i].classList.add("last-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Ostanita strona";
                    paginatorLast[i].appendChild(buttonLabel);
                }
            }
            const paginatorPrev = document.querySelectorAll(".p-paginator-prev");
            for (let i = 0; i < paginatorPrev.length; i += 1) {
                if (!paginatorPrev[i].classList.contains("prev-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorPrev[i].classList.add("prev-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Poprzednia strona";
                    paginatorPrev[i].appendChild(buttonLabel);
                }
            }
            const paginatorNext = document.querySelectorAll(".p-paginator-next");
            for (let i = 0; i < paginatorPrev.length; i += 1) {
                if (!paginatorNext[i].classList.contains("next-page")) {
                    const buttonLabel = document.createElement("span");
                    paginatorNext[i].classList.add("next-page");
                    buttonLabel.classList.add("visually-hidden");
                    buttonLabel.innerHTML = "Następna strona";
                    paginatorNext[i].appendChild(buttonLabel);
                }
            }
        },
    },
};

export default {
    AccessibilityTableMixin,
};
