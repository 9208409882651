<template>
    <div>
        <h1 class="page-header"><i class="fas fa-comment"></i> Dane wpisu</h1>
        <div class="container">
            <Form v-if="loaded" @submit="savePost">
                <div class="p-grid post-form">
                    <div class="p-col-12">
                        <h2 class="post-form-section-header">Ogólne</h2>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="name" label="Tytuł" v-model="post.name" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomSelectOnePostCategory name="postCategory"
                                                     v-model="post.postCategory.id"
                                                     label="Kategoria" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomCalendar name="publishDate" label="Data publikacji" v-model="post.publishDate"
                                        :show-time="false"/>
                    </div>

                    <div class="p-col-12">
                        <h2 class="post-form-section-header">Nagłówek</h2>
                        <label for="tinymce-editor-lead" class="visually-hidden">Nagłówek</label>
                        <editor id="tinymce-editor-lead" :init="configLead" :api-key="apiKey" v-model="post.lead"
                                @keydown="onKeyDown" @paste.prevent.stop/>
                        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
                    </div>

                    <div class="p-col-12">
                        <h2 class="post-form-section-header">Treść</h2>
                    </div>
                    <div class="p-col-12">
                        <label for="tinymce-editor" class="visually-hidden">Treść</label>
                        <editor id="tinymce-editor" :init="config" :api-key="apiKey" v-model="post.content"/>
                    </div>

                    <template v-if="Role.isFPBZCoordinator()">
                        <div class="p-col-12">
                            <h2 class="post-form-section-header">Pozostałe</h2>
                        </div>
                        <div class="p-col-12">
                            <CustomCheckbox name="visibleForOpl" label="Widoczny dla OPL" v-model="post.visibleForOpl"/>
                        </div>
                    </template>

                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Editor from "@tinymce/tinymce-vue";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOnePostCategory from "@/components/form/CustomSelectOnePostCategory";
    import {
        createOrUpdatePostUsingPOST as createOrUpdatePost,
        getPostUsingGET as getPost,
    } from "@/swagger/vue-api-client";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import {DateUtils} from "@/utils/DateUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "PostCreateView",
        components: {
            CustomCalendar,
            CustomCheckbox,
            editor: Editor,
            Button,
            Toolbar,
            CustomInputText,
            CustomSelectOnePostCategory,
            Form,
        },

        data() {
            return {
                items: [],
                loaded: false,
                Role: SystemRole,
                post: {
                    id: null,
                    name: null,
                    postCategory: {
                        id: null,
                    },
                    postCategoryId: null,
                    lead: null,
                    content: null,
                    visibleForOpl: false,
                    publishDate: null,
                },
                leadCharacterLimit: 200,
                errorMessage: null,
                apiKey: process.env.VUE_APP_TINYMCE_KEY,
                configLead: {
                    language: "pl",
                    height: 800,
                    width: (window.outerWidth < 1000 ? (window.outerWidth - 50) : ""),
                    menubar: false,
                    plugins: [
                        "advlist anchor autoresize autosave charmap code emoticons fullpage fullscreen",
                        "help image imagetools legacyoutput lists nonbreaking noneditable paste",
                        "preview quickbars searchreplace template visualblocks visualchars wordcount",
                    ],
                    toolbar:
                        "undo redo | formatselect | bold italic backcolor | "
                        + "alignleft aligncenter alignright alignjustify | "
                        + "bullist numlist outdent indent | removeformat | restoredraft | help | charmap emoticons | "
                        + "fullscreen | anchor preview searchreplace "
                        + "code fullpage nonbreaking visualblocks visualchars template",
                },
                config: {
                    language: "pl",
                    height: 800,
                    width: (window.outerWidth < 1000 ? (window.outerWidth - 50) : ""),
                    plugins: [
                        "advlist anchor autolink autoresize autosave charmap code emoticons fullpage fullscreen",
                        "help image imagetools legacyoutput link lists media nonbreaking noneditable paste",
                        "preview quickbars searchreplace table template visualblocks visualchars wordcount",
                    ],
                    toolbar:
                        "undo redo | formatselect | bold italic backcolor | "
                        + "alignleft aligncenter alignright alignjustify | "
                        + "bullist numlist outdent indent | removeformat | restoredraft | help | charmap emoticons | "
                        + "fullscreen | anchor link image media preview searchreplace "
                        + "code fullpage nonbreaking quicktable quicklink visualblocks visualchars template",
                },
            };
        },

        computed: {
            leadContentLength() {
                if (this.post.lead === null) {
                    return 0;
                }

                return this.post.lead.replace(/<[^>]+>/g, "").replace(/&[a-zA-Z]+;/, "x").trim().length;
            },
        },

        async mounted() {
            if (this.$route.params.id) {
                try {
                    const post = await getPost({id: this.$route.params.id});
                    this.post = post.data;
                    this.post.publishDate = new Date(this.post.publishDate);
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        await this.$router.push({name: "forbidden"});
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                }
            } else {
                this.post.publishDate = new Date();
            }

            this.loaded = true;
        },

        methods: {
            onKeyDown() {
                if (this.leadContentLength >= this.leadCharacterLimit) {
                    this.errorMessage = `Limit ${this.leadCharacterLimit} znaków został przekroczony`;
                } else {
                    this.errorMessage = null;
                }
            },

            savePost() {
                if (this.errorMessage !== null) {
                    return;
                }

                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        this.post.publishDate.setHours(12);
                        this.post.postCategoryId = this.post.postCategory.id;

                        this.post.publishDate = DateUtils.convertDateNoLocalTimezoneOffset(this.post.publishDate)
                            .toJSON();

                        createOrUpdatePost({postRequest: this.post}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });

                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },
        },
    };
</script>

<style>
    .post-form-section-header {
        text-align: left;
        font-weight: bold;
    }
</style>
