<template>
    <div>
        <h1 class="page-header"><i class="pi pi-file-o"></i> Dane pliku</h1>
        <div class="container">
            <Form v-if="loaded" ref="form" @submit="saveFile" v-slot="{ isSubmitting }">
                <div class="p-grid file-form">
                    <div class="p-col-12">
                        <CustomInputText name="name" label="Czytelna nazwa" v-model="fileData.name" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomInputTextArea name="description" label="Opis" v-model="fileData.description"/>
                    </div>
                    <div class="p-col-12">
                        <p v-if="Role.isFPBZCoordinator()">
                            Uwaga!
                            Przy usuwaniu dostępu dla BŻ, dostęp zostanie również usunięty wszystkim jego OPL,
                            którym on udostępnił plik.
                        </p>
                        <CustomMultiSelect name="organisationList" label="Udostępnij dla"
                                           item-label="label" item-value="value"
                                           v-model="fileData.organisationIdList" :items="organisations"/>
                    </div>
                    <div class="p-col-4">
                        <CustomSelectOne name="categoryType" label="Typ pliku" v-model="fileData.categoryType"
                                         :items="fileCategoryTypes" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomFileUpload
                            name="file-upload"
                            v-model="file"
                            v-on:change="onFileChange($event)"
                            :rules="{fileRequirements: true, requiredFile: !editing}"/>
                    </div>
                    <div v-if="editing && !edited" class="p-col-12">Aby nie zmieniać pliku nie dodawaj nowego.</div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdateFileUsingPOST as createOrUpdateFile,
        getFileUsingGET as getFile,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        getFpbzUsingGET as getFpbz,
    } from "@/swagger/vue-api-client";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import CustomFileUpload from "@/components/form/CustomFileUpload";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "FileFormView",

        components: {
            CustomMultiSelect,
            CustomSelectOne,
            CustomFileUpload,
            Button,
            Toolbar,
            CustomInputText,
            Form,
            CustomInputTextArea,
        },

        props: {
            value: {},
            editing: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                file: undefined,
                fileData: this.getEmptyFile(),
                fileCategoryTypes: [
                    {value: "DONATION", label: "Plik dot. darowizn"},
                    {value: "COLLECTION", label: "Plik dot. zbiórek"},
                ],
                organisations: [],
                loaded: false,
                edited: false,
                Role: SystemRole,
            };
        },

        async mounted() {
            const fileInfoRequest = this.$route.params.id ? getFile({id: this.$route.params.id}) : null;
            const subordinateOrganisationsRequest = getSubordinateOrganisations();
            const fpbzRequest = this.Role.isBZCoordinator() ? getFpbz() : null;

            let responses;
            try {
                responses = await Promise.all([fileInfoRequest, subordinateOrganisationsRequest, fpbzRequest]);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                }
            }

            if (responses[0] !== null) {
                this.file = responses[0].data.filename;
                this.fileData = responses[0].data;
            }

            this.organisations = responses[1].data;

            if (responses[2] !== null) {
                this.organisations.unshift(responses[2].data);
            }

            this.loaded = true;
        },

        methods: {
            saveFile() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdateFile({fileRequest: this.fileData}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },

            async onFileChange(e) {
                const attachedFiles = e.target.files || e.dataTransfer.files;
                if (attachedFiles.length === 0) {
                    return;
                }

                const [file] = attachedFiles;

                if (this.editing) {
                    this.edited = true;
                }

                this.file = file;

                if (!this.fileData.name) {
                    this.fileData.name = file.name;

                    this.$refs.form.setFieldValue("name", this.fileData.name);
                }
                this.fileData.filename = file.name;
                this.fileData.encodedData = await FileUtils.fileToBase64(file);
            },

            getEmptyFile() {
                return {
                    name: "",
                    filename: "",
                    description: "",
                    encodedData: "",
                    categoryType: null,
                    organisationIdList: null,
                };
            },
        },
    };
</script>

<style lang="less" scoped>
    .file-form {
        max-width: 100%;
    }
    .p-treeselect {
        width:20rem;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.38);
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        padding: 15px;
        border-radius: 3px;
    }
</style>
