/* eslint-disable */
export const messagesPL = {
    login: {
        title: "Logowanie",
        login: "Login",
        password: "Hasło",
        loginError: "Nieprawidłowy login lub hasło",
        action: {
            login: "Zaloguj się",
            remindPassword: "Przypomnij hasło",
        },
    },
    register: {
        title: "Rejestracja",
        firstName: "Imię",
        lastName: "Nazwisko",
        email: "Adres e-mail",
        password: "Hasło",
        confirmPassword: "Potwierdź hasło",
        organisationLevel: "Poziom organizacji",
        organisationName: "Nazwa organizacji",
        action: {
            register: "Zarejestruj się",
            backToLogin: "Wróć do logowania"
        },
        followDonationPostsCheckbox: " Chcę otrzymywać powiadomienia na " +
            "temat opublikowanych postów dotyczących darowizn.",
        followCollectionPostsCheckbox: " Chcę otrzymywać powiadomienia na " +
            "temat opublikowanych postów dotyczących zbiórek.",
    },
    menu: {
        ongoingCollection: "Trwająca zbiórka",
        collections: "Zbiórki",
        collectionEvents: "Akcje zbiórek",
        donations: "Darowizny",
        donationReceivalEvents: "Harmonogram odbioru darowizn",
        management: "Zarządzanie",
        blog: "Blog",
        postCreate: "Dodawanie wpisu",
        users: "Użytkownicy",
        stores: "Sklepy",
        organisations: "Organizacje",
        postCategories: "Kategorie wpisów",
        productCategories: "Kategorie produktów",
        storeChains: "Sieci sklepów",
        files: "Pliki",
        exchange: "Giełda",
        offersFPBZ: "Deklaracja odbioru darowizny",
        offersBZ: "Deklaracja odbioru darowizn",
        offersOPL: "Deklaracja odbioru darowizny",
        commissionsFPBZ: "Propozycja darowizny",
        commissionsBZ: "Propozycje darowizn",
        commissionsOPL: "Propozycja darowizny",
        posts: "Wpisy",
        postsEdit: "Edycja wpisów",
        mailConfiguration: "Konfiguracja maila",
        directories: "Katalogi",
        chat: "Chat",
        authorizations: "Upoważnienia",
    },
    other: {
        emptyTable: "Brak wyników",
        emptyValue: "Wybierz wartość",
    },
    validations: {
        required: "Pole jest wymagane",
        email: "Niepoprawny format adresu e-mail",
        confirmed: "Wartości nie są takie same",
        min: "Wymagana długość: 0:{min}",
        max: "Maksymalna długość: 0:{max}",
        min_value: "Minimalna wartość: 0:{min_value}",
        max_value: "Maksymalna wartość: 0:{max_value}",
        is: "",
        regex: "",
        validPassword: "Hasło musi mieć długość co najmniej 8 znaków, zawierać małą i dużą literę, cyfrę, oraz znak specjalny",
    },
    global: {
        name: "Ładna nazwa pliku",
        filename: "Nazwa pliku",
        description: "Opis",
    },
    components: {
        chooseFile: "Wybierz plik",
    },
    loadCoordinatesFromAddressError: "Aby ustalić geolokalizację na podstawie adresu, musisz najpierw uzupełnić przynajmniej jedno z wymaganych pól (miasto, ulica lub kod pocztowy)",
    loadAddressFromCoordinatesError: "Aby uzupełnić adres na podstawie geolokalizacji, musisz najpierw wskazać punkt na mapie"
};
