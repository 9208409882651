<template>
    <div>
        <div class="change-password-panel-header">
            <img src="@/assets/logo.png" alt="logo">
        </div>

        <div v-if="loaded && !displayInactiveInvitationMessage" class="change-password-panel-content">
            <div>
                <div class="p-col-12">
                    <h1 class="visually-hidden">Odpowiedz na zaproszenie dotyczące zbiórki: '{{collection.name}}'?</h1>
                    <h2>Czy chcesz wziąć udział w zbiórce '{{collection.name}}'?</h2>
                </div>
                <Button style="margin-left: 32px; margin-right: 32px;"
                        type="button" icon="pi pi-times" label="Nie"
                        :disabled="decideToJoin === false" @click="toggleDecideToJoin(false)"/>
                <Button style="margin-left: 32px; margin-right: 32px;"
                        type="button" icon="pi pi-check" label="Tak"
                        :disabled="decideToJoin" @click="toggleDecideToJoin(true)"/>
            </div>

            <div v-if="displayOplsInvitationPrompt">
                <div class="p-col-12">
                    <h2>Czy zaprosić również podległe OPL?</h2>
                </div>
                <Button style="margin-left: 32px; margin-right: 32px;"
                        type="button" icon="pi pi-times" label="Nie"
                        :disabled="collectionInvitationAnswerRequest.inviteOpls === false"
                        @click="toggleInviteOpls(false)"/>
                <Button style="margin-left: 32px; margin-right: 32px;"
                        type="button" icon="pi pi-check" label="Tak"
                        :disabled="collectionInvitationAnswerRequest.inviteOpls"
                        @click="toggleInviteOpls(true)"/>
            </div>

            <div v-if="collectionInvitationAnswerRequest.inviteOpls">
                <div class="p-col-12">
                    <CustomMultiSelect
                        v-model="collectionInvitationAnswerRequest.invitedOpls"
                        :items="organisations"
                        itemLabel="label" itemValue="value" label="Wybierz OPL"
                        :filter="true" emptyMessage="Nie znaleziono OPL"
                        emptyFilterMessage="Nie znaleziono OPL">
                    </CustomMultiSelect>
                    <span v-show="displayNoOplsInvitedErrorMessage" class="p-invalid">
                        {{ noOplsInvitedErrorMessage }}
                    </span>
                </div>
            </div>
            <div v-if="displayReasonMessagePrompt">
                <div class="p-col-12">
                    <h2>Proszę podać powód</h2>
                </div>
                <CustomInputTextarea v-model="collectionInvitationAnswerRequest.message"/>
            </div>

            <div v-if="displaySubmitButton">
                <Toolbar>
                    <template v-slot:end>
                        <Button :style="submitButtonStyle"
                                type="button" icon="pi pi-check" label="Zatwierdź" @click="submit"/>
                    </template>
                </Toolbar>
            </div>
        </div>
        <div v-if="loaded && displayInactiveInvitationMessage" class="change-password-panel-content">
            <h2>Twoja organizacja odpowiedziała już na to zaproszenie</h2>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {
        getCollectionNameUsingGET as getCollectionName,
        checkForActiveInvitationUsingGET as checkForActiveInvitation,
        acceptCollectionInvitationUsingPOST as acceptCollectionInvitation,
        rejectCollectionInvitationUsingPOST as rejectCollectionInvitation,
        getSubordinateOrganisationsUsingGET as getOrganisations,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "AnswerCollectionInvitationView",
        components: {
            Button,
            Toolbar,
            CustomInputTextarea,
            CustomMultiSelect,
        },

        data() {
            return {
                collection: {
                    name: null,
                },
                collectionInvitationAnswerRequest: {
                    collectionId: null,
                    inviteOpls: null,
                    message: null,
                    invitedOpls: null,
                },
                decideToJoin: null,
                displayInactiveInvitationMessage: null,
                displayNoOplsInvitedErrorMessage: false,
                noOplsInvitedErrorMessage: "Pole jest wymagane",
                Role: SystemRole,
                loaded: false,
                organisations: [],
            };
        },

        computed: {

            displayOplsInvitationPrompt() {
                return this.decideToJoin === true && this.Role.isBZCoordinator();
            },

            displayReasonMessagePrompt() {
                return this.decideToJoin === false || this.collectionInvitationAnswerRequest.inviteOpls === false;
            },

            displaySubmitButton() {
                return this.decideToJoin === false
                    || (this.Role.isBZCoordinator() && this.collectionInvitationAnswerRequest.inviteOpls !== null)
                    || (this.Role.isOPLCoordinator() && this.decideToJoin === true);
            },

            submitButtonStyle() {
                let res = "margin-left: 32px; margin-right: 32px;";
                if (this.Role.isOPLCoordinator() && this.decideToJoin === true) {
                    res += " margin-top: 32px;";
                }

                return res;
            },
        },

        async mounted() {
            this.collectionInvitationAnswerRequest.collectionId = this.$route.params.id;

            try {
                let response = await getCollectionName({id: this.$route.params.id});
                this.collection = response.data;

                response = await checkForActiveInvitation({id: this.$route.params.id});
                this.displayInactiveInvitationMessage = !response.data.value;

                response = await getOrganisations();
                this.organisations = response.data;
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    await ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            this.loaded = true;
        },

        methods: {
            toggleDecideToJoin(toggle) {
                const currentValue = this.decideToJoin === null ? !toggle : this.decideToJoin;
                if (!currentValue) {
                    this.decideToJoin = true;
                    return;
                }
                this.decideToJoin = false;
                this.collectionInvitationAnswerRequest = {
                    collectionId: this.collectionInvitationAnswerRequest.collectionId,
                    inviteOpls: null,
                    message: null,
                };
            },

            toggleInviteOpls(toggle) {
                const currentValue = this.collectionInvitationAnswerRequest.inviteOpls === null
                    ? !toggle : this.collectionInvitationAnswerRequest.inviteOpls;
                if (!this.decideToJoin) {
                    return;
                }
                if (currentValue) {
                    this.collectionInvitationAnswerRequest.inviteOpls = false;
                    this.collectionInvitationAnswerRequest.invitedOpls = null;
                    this.collectionInvitationAnswerRequest.message = null;
                } else {
                    this.collectionInvitationAnswerRequest.inviteOpls = true;
                }
            },

            submit() {
                const {collectionInvitationAnswerRequest} = this;

                if (this.decideToJoin) {
                    this.displayNoOplsInvitedErrorMessage = this.collectionInvitationAnswerRequest.inviteOpls
                        && !this.collectionInvitationAnswerRequest.invitedOpls;
                    if (this.displayNoOplsInvitedErrorMessage) {
                        return;
                    }

                    acceptCollectionInvitation({collectionInvitationAnswerRequest}).then(() => {
                        ToastUtils.addToast(this, {
                            severity: "success",
                            summary: "Sukces",
                            detail: "Pomyślnie dołączono do zbiórki",
                        });
                        const {id} = this.$route.params;
                        this.$router.push({name: "collectionHub", params: {id}});
                    }).catch((error) => {
                        if (error.response && error.response.status === 409) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Okres dołączania do tej zbiórki minął",
                            });
                        } else {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        }
                        this.$router.push({name: "map"});
                    });
                } else {
                    rejectCollectionInvitation({collectionInvitationAnswerRequest}).then(() => {
                        ToastUtils.addToast(this, {
                            severity: "success",
                            summary: "Sukces",
                            detail: "Pomyślnie zrezygnowano z udziału w zbiórce",
                        });
                    }).catch((error) => {
                        if (error.response && error.response.status === 409) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Okres dołączania do tej zbiórki minął",
                            });
                        } else {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        }
                    }).finally(() => {
                        this.$router.push({name: "map"});
                    });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/change-password.less";
</style>
