<template>
    <div>
        <Tree v-model:selectionKeys="selectedPostCategories" :value="categories"
              selectionMode="checkbox"
              ref="tree"
              placeholder="Kategoria wpisu"
              scrollHeight="80vh" @node-select="onSelect" @node-unselect="onUnselect"
              class="search-criteria" />
    </div>
</template>

<script>
    import Tree from "primevue/tree";
    import {
        getPostCategoriesUsingGET as getPostCategories,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PostCategoryList",
        components: {Tree},

        mounted() {
            this.emitter.on("postDetailsOpen", async () => {
                Object.entries(this.categories).forEach((item) => {
                    item[1].styleClass = "p-disabled";
                    if (item[1].children !== null) {
                        Object.entries(item[1].children).forEach((children) => {
                            children[1].styleClass = "p-disabled";
                        });
                    }
                });
                this.loaded = true;
            });
            this.emitter.on("postListOpen", async () => {
                this.loadCategories();
                this.emitter.emit("selectedPostCategories", this.selectedCategoriesIds);
                Object.entries(this.categories).forEach((item) => {
                    item[1].styleClass = "";
                });
                this.loaded = true;
            });
        },

        data() {
            return {
                categories: [
                    {
                        key: "donation",
                        label: "Wpisy dot. darowizn",
                        children: null,
                    },
                    {
                        key: "collection",
                        label: "Wpisy dot. zbiórki",
                        children: null,
                    },
                ],
                loaded: false,
                collectionPosts: [
                    {
                        key: null,
                        label: null,
                    },
                ],
                donationPosts: [
                    {
                        key: null,
                        label: null,
                    },
                ],
                postCategories: null,
                selectedPostCategories: [],
                selectedCategoriesIds: [],
            };
        },

        emits: ["change"],

        methods: {
            onSelect(node) {
                let changed = false;
                if (typeof node.key === "number") {
                    changed = true;
                    this.selectedCategoriesIds.push(node.key);
                } else {
                    Object.entries(this.categories[node.key === "collection" ? 1 : 0].children).forEach((item) => {
                        if (this.selectedCategoriesIds.indexOf(item[1].key) === -1) {
                            changed = true;
                            this.selectedCategoriesIds.push(item[1].key);
                        }
                    });
                }
                if (changed) {
                    this.emitter.emit("selectedPostCategories", this.selectedCategoriesIds);
                }
            },

            onUnselect(node) {
                let changed = false;
                let deleted = [];
                if (typeof node.key === "number") {
                    deleted = this.selectedCategoriesIds.splice(this.selectedCategoriesIds.indexOf(node.key), 1);
                    if (deleted.length > 0) {
                        changed = true;
                    }
                } else {
                    Object.entries(this.categories[node.key === "collection" ? 1 : 0].children).forEach((item) => {
                        deleted = this.selectedCategoriesIds.splice(this.selectedCategoriesIds.indexOf(item[1].key), 1);
                        if (deleted.length > 0) {
                            changed = true;
                        }
                    });
                }
                if (changed) {
                    this.emitter.emit("selectedPostCategories", this.selectedCategoriesIds);
                }
            },

            loadCategories() {
                getPostCategories({categoryType: "DONATION"}).then((response) => {
                    this.categories[0].children = response.data.map((item) => ({
                        key: item.value,
                        label: item.label,
                    }));
                    if (this.categories[0].children.length === 0) {
                        this.categories[0].selectable = false;
                    }
                });

                getPostCategories({categoryType: "COLLECTION"}).then((response) => {
                    this.categories[1].children = response.data.map((item) => ({
                        key: item.value,
                        label: item.label,
                    }));
                    if (this.categories[1].children.length === 0) {
                        this.categories[1].selectable = false;
                    }
                });
            },
        },
    };
</script>

<style scoped>

</style>
