<template>
    <div>
        <DataTable ref="messageThreadTable" :value="messageThreads" :lazy="true" :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" v-if="isMounted" :row-class="rowClass"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerStyle="width: 180px">
                <template #body="slotProps">
                    <!--Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                            aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                    <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.threadId"
                                  append-to=".wrapper" v-if="isMounted"
                                  @show="moveActionsMenu(slotProps.data.id)"
                                  @hide="onHideActionsMenu">
                        <router-link :to="{name: 'messages', params: {threadId: slotProps.data.threadId}}">
                            <Button type="button" icon="pi pi-send" label="Otwórz chat"/>
                        </router-link>
                    </OverlayPanel-->
                    <Button class="button-link" type="button" role="link"
                            icon="pi pi-send" label="Otwórz chat"
                            @click="$router.push({name: 'messages', params: {threadId: slotProps.data.threadId}})"/>
                </template>
            </Column>
            <Column field="otherEndName" header="Wątek" :sortable="false"></Column>
            <Column>
                <template #body="slotProps">
                    <div class="new-messages">{{ slotProps.data.unseen ? "Nowe wiadomości" : "" }}
                    </div>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ messageThreads.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    // import OverlayPanel from "primevue/overlaypanel";
    import {
        searchMessageThreadUsingPOST as searchMessageThread,
        searchMessageThreadCountUsingPOST as searchMessageThreadCount,
        deleteMessageThreadUsingDELETE as deleteMessageThread,
    } from "@/swagger/vue-api-client";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "MessageThreadTable",
        components: {
            Button,
            Column,
            DataTable,
            // OverlayPanel,
        },
        mixins: [AccessibilityTableMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                messageThreads: [],
                searchParams: this.searchCriteria,
                isMounted: false,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },
        },

        mounted() {
            this.$emit("loaded");

            this.isMounted = true;
        },

        methods: {
            rowClass(data) {
                return data.unseen ? "row-unseen" : null;
            },

            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchMessageThread({searchCriteria: this.searchCriteria}).then((response) => {
                    this.messageThreads = response.data;
                    this.loading = false;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchMessageThreadCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deleteMessageThread(messageThreadId) {
                deleteMessageThread({id: messageThreadId}).then(() => {
                    this.search();
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.threadId].toggle(event);
            },
        },

    };
</script>

<style lang="less" scoped>
.new-messages {
    font-weight: 700;
    color: white;
}
::v-deep(.row-unseen) {
    td {
        color: white;
    }
    background-color: #008237 !important;
}
</style>
