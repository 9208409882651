<template>
    <div>
        <DataTable ref="offerTable" :value="offers" :lazy="true" :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" v-if="isMounted"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerStyle="width: 160px">
                <template #body="slotProps">
                    <div v-if="slotProps.data.accepted === null" :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button v-if="searchParams.queryType === 'OWN' && slotProps.data.accepted === null"
                                        class="p-m-1 button-link"
                                        type="button" role="link" icon="pi pi-pencil" label="Edytuj ofertę"
                                        @click="$router.push({name: 'offerEdit', params: {'id': slotProps.data.id}})"/>
                                <Button class="p-m-1"
                                        v-if="searchParams.queryType === 'OTHERS' && slotProps.data.accepted === null"
                                        type="button" icon="pi pi-check" label="Zaakceptuj ofertę"
                                        @click="toggleAcceptOfferDialog(slotProps.data)">
                                </Button>
                                <Button class="p-m-1"
                                        v-if="searchParams.queryType === 'OTHERS' && slotProps.data.accepted === null"
                                        type="button" icon="pi pi-times" label="Odrzuć ofertę"
                                        @click="rejectOffer(slotProps.data, $event)">
                                </Button>
                                <Button v-if="searchParams.queryType === 'OWN' && slotProps.data.accepted === null"
                                        class="p-m-1"
                                        label="Usuń ofertę"
                                        type="button" icon="pi pi-times"
                                        @click="deleteOffer(slotProps.data.id, $event)">
                                </Button>
                            </div>
                        </OverlayPanel>
                    </div>
                </template>
            </Column>
            <Column v-if="searchParams.queryType === 'OWN'" field="commission.owner.name"
                    header="Wystawiający" :sortable="true"></Column>
            <Column v-if="searchParams.queryType === 'OTHERS'" field="owner.name"
                    header="Deklarujący" :sortable="true"></Column>
            <Column v-if="searchParams.queryType === 'OTHERS'" field="benefiterCount"
                    header="Liczba beneficjentów" :sortable="true"></Column>
            <Column field="commission.product" header="Produkt" :sortable="true"></Column>
            <Column field="requestedAmount" header="Moja deklaracja" :sortable="true"></Column>
            <Column field="commission.initialAmount" header="Wyjściowa ilość" :sortable="true"></Column>
            <Column field="transferredAmount" header="Przyznana ilość" :sortable="true"></Column>
            <Column field="commission.unit" header="Jednostka" :sortable="true"></Column>
            <Column field="accepted" header="Stan" headerStyle="width: 200px" :sortable="true">
                <template #body="slotProps">
                    <CustomBadge label="Zaakceptowana" color="green"
                                 v-show="slotProps.data.accepted === true"/>
                    <CustomBadge label="Czeka na odpowiedź" color="gray"
                                 v-show="slotProps.data.accepted === null"/>
                    <CustomBadge label="Odrzucona" color="red"
                                 v-show="slotProps.data.accepted === false"/>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>

        <Dialog v-model:visible="display" header="Oferta"
                modal dismissable-mask
                append-to=".wrapper" v-if="isMounted">
            <div class="container form-wrapper">
                <Form class="mt-2" @submit="acceptCurrentOffer" v-slot="{ isSubmitting }">
                    <CustomInputNumber name="transferredAmount" label="Ilość produktu do przyznania"
                                       v-model="currentAgreedAmount" rules="required"/>
                    <p>Zadeklarowana ilość: {{currentOffer.requestedAmount}}</p>
                    <Button label="Zaakceptuj" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                </Form>
            </div>
        </Dialog>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ offers.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import OverlayPanel from "primevue/overlaypanel";
    import Dialog from "primevue/dialog";
    import {Form} from "vee-validate";
    import {
        searchOfferUsingPOST as searchOffer,
        searchOfferCountUsingPOST as searchOfferCount,
        deleteOfferUsingDELETE as deleteOffer,
        acceptOfferUsingPOST as acceptOffer,
        rejectOfferUsingPOST as rejectOffer,
    } from "@/swagger/vue-api-client";
    import CustomBadge from "@/components/CustomBadge";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";

    export default {
        name: "OfferTable",

        components: {
            CustomInputNumber,
            Button,
            Column,
            DataTable,
            Dialog,
            Form,
            CustomBadge,
            OverlayPanel,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            queryType: {
                type: String,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                offers: [],
                searchParams: this.searchCriteria,
                currentOffer: null,
                currentAgreedAmount: null,
                display: false,
                Role: SystemRole,
                isMounted: false,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },

            queryType(value) {
                this.searchParams.queryType = value;
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },
        },

        mounted() {
            this.searchParams.queryType = this.Role.isFPBZCoordinator() ? "OTHERS" : "OWN";
            this.$emit("loaded");

            this.isMounted = true;
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchOffer({searchCriteria: this.searchParams}).then((response) => {
                    this.offers = response.data;
                    this.loading = false;
                    this.isMounted = true;
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchOfferCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deleteOffer(offerId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć ofertę?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteOffer({id: offerId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto ofertę",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            toggleAcceptOfferDialog(offer) {
                this.currentOffer = offer;
                this.currentOffer.commissionId = offer.commission.id;
                this.display = true;
            },

            acceptCurrentOffer() {
                const request = {...this.currentOffer};
                request.transferredAmount = this.currentAgreedAmount;

                acceptOffer({offerRequest: request}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zaakceptowano ofertę",
                    });
                    this.updateTotalRecords();
                    this.search();

                    this.display = false;
                }).catch((error) => {
                    this.display = false;
                    this.currentOffer = null;
                    this.currentAgreedAmount = null;

                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: error.response.data,
                    });
                });
            },

            rejectOffer(offer, event) {
                offer.commissionId = offer.commission.id;
                this.$confirm.require({
                    target: event.currentTarget,
                    message: "Czy na pewno odrzucić ofertę?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    rejectLabel: "Nie",
                    accept: () => {
                        rejectOffer({offerRequest: offer}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Odrzucono ofertę",
                            });
                            this.updateTotalRecords();
                            this.search();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił błąd",
                            });
                        });
                    },
                    reject: () => {
                    },
                });
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },
        },

    };
</script>

<style lang="less" scoped>
.form-wrapper {
    margin-top: 10px;
    min-width: 260px;
}
</style>
