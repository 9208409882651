<template>
    <div>
        <DataTable ref="commissionTable" :value="commissions" :lazy="true" :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" v-if="isMounted"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerStyle="width: 180px">
                <template #body="slotProps">
                    <div v-if="queryType === 'OWN'" :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button v-if="queryType === 'OWN'"
                                        class="p-m-1 button-link" type="button" role="link"
                                        icon="pi pi-pencil" label="Edytuj zlecenie"
                                        @click="$router.push({
                                            name: 'commissionEdit',
                                            params: {'id': slotProps.data.id},
                                        })"/>
                                <Button v-if="queryType === 'OWN'" class="p-m-1"
                                        type="button" icon="pi pi-times" label="Usuń zlecenie"
                                        @click="deleteCommission(slotProps.data.id)">
                                </Button>
                                <!--router-link v-if="queryType === 'OTHERS'"
                                             :to="{name: 'offerCreate',
                                                   params: {'commissionId': slotProps.data.id}}">
                                    <Button type="button" icon="pi pi-plus-circle" label="Złóż ofertę"></Button>
                                </router-link-->
                            </div>
                        </OverlayPanel>
                    </div>
                    <div v-else>
                        <Button v-if="queryType === 'OTHERS'"
                                class="button-link" type="button" role="link"
                                icon="pi pi-plus-circle" label="Złóż ofertę"
                                @click="$router.push({
                                    name: 'offerCreate',
                                    params: {'commissionId': slotProps.data.id},
                                })"/>
                    </div>
                </template>
            </Column>
            <Column field="owner.name" header="Wystawiający" :sortable="true"></Column>
            <Column field="product" header="Produkt" :sortable="true"></Column>
            <Column field="currentAmount" header="Aktualna ilość" :sortable="true"></Column>
            <Column field="initialAmount" header="Wyjściowa ilość" :sortable="true"></Column>
            <Column field="unit" header="Jednostka" :sortable="true"></Column>
            <Column field="comment" header="Komentarz"></Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ commissions.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import OverlayPanel from "primevue/overlaypanel";
    import {
        searchCommissionUsingPOST as searchCommission,
        searchCommissionCountUsingPOST as searchCommissionCount,
        deleteCommissionUsingDELETE as deleteCommission,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";

    export default {
        name: "CommissionTable",

        components: {
            Button, Column, DataTable, OverlayPanel,
        },

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            queryType: {
                type: String,
            },
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                commissions: [],
                searchParams: this.searchCriteria,
                isMounted: false,
            };
        },

        watch: {
            searchCriteria(value) {
                this.searchParams = value;
            },

            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },

            queryType(value) {
                this.searchParams.queryType = value;
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },
        },

        mounted() {
            this.searchParams.queryType = SystemRole.isOPLCoordinator() ? "OTHERS" : "OWN";
            this.$emit("loaded");

            this.isMounted = true;
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchCommission({searchCriteria: this.searchParams}).then((response) => {
                    this.commissions = response.data;
                    this.loading = false;
                    this.isMounted = true;
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchCommissionCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deleteCommission(commissionId) {
                deleteCommission({id: commissionId}).then(() => {
                    this.search();
                }).catch((error) => {
                    if (error.response && error.response.status === 400) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Usunięcie zlecenia nie powiodło się z powodu"
                                + " zależności w bazie danych",
                        });
                    } else if (error.response && error.response.status === 403) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie masz wystarczających uprawnień",
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
