<template>
    <div id="vue-app">
        <router-view/>
    </div>
</template>

<style lang="less">
    @import "~@fortawesome/fontawesome-free/css/all.css";
    @import "assets/theme/global-styles";
    @import "assets/theme/theme";
</style>
