export const NumbersMixin = {

    methods: {
        formatNumber(n) {
            if (n instanceof Number) {
                return n.toLocaleString(undefined, {minimumFractionDigits: 2});
            }
            const num = Number(n);
            if (num) {
                return num.toLocaleString(undefined, {minimumFractionDigits: 2});
            }
            return "0,00";
        },
    },
};

export default {
    NumbersMixin,
};
