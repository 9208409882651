<template>
    <div>
        <h1 class="page-header"><i class="pi pi-file-o"></i> Lista plików</h1>

        <div class="search-criteria" @keyup.enter="search">
            <FileSearchCriteria ref="fileSearchCriteria" v-model="searchCriteria" />

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button v-if="!Role.isOPLCoordinator()"
                                class="button-link" type="button" role="link"
                                icon="pi pi-plus" label="Dodaj plik"
                                @click="$router.push({name: 'fileCreate'})"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <Toolbar class="actions" >
            <template v-slot:end>
            </template>
        </Toolbar>

        <TabMenu v-if="!Role.isOPLCoordinator()" class="tabmenu" :model="menuItems"/>

        <FileTable ref="fileTable" :search-criteria="searchCriteria" :query-type="queryType"
                   :display-all="displayAll" @loaded="loadInitialDisplayAll"
                   @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import TabMenu from "primevue/tabmenu";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import FileSearchCriteria from "./components/FileSearchCriteria";
    import FileTable from "./components/FileTable";

    export default {
        name: "FileListView",
        components: {
            FileTable, FileSearchCriteria, Button, Toolbar, TabMenu,
        },

        data() {
            return {
                searchCriteria: {
                    name: "",
                    filename: "",
                    categoryType: null,
                    description: "",
                    organisationLevel: null,
                    organisationId: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                displayAll: null,
                Role: SystemRole,
                queryType: "OTHERS",
                menuItems: [
                    {label: "Udostępnione mojej organizacji", command: () => this.setQueryType("OTHERS")},
                    {label: "Przesłane przez moją organizację", command: () => this.setQueryType("OWN")},
                ],
            };
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.fileTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    filename: "",
                    categoryType: null,
                    description: "",
                    organisationLevel: null,
                    organisationId: null,
                    queryType: this.searchCriteria.queryType,
                    page: this.searchCriteria.page,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },

            setQueryType(value) {
                this.queryType = value;
            },
        },
    };
</script>

<style scoped>

</style>
