<template>
    <div>
        <h1 class="page-header"><i class="pi pi-users"></i> Dane użytkownika</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveUser" v-slot="{ isSubmitting }">
                <div class="p-grid user-form">
                    <div class="p-col-6">
                        <CustomInputText name="firstName" label="Imię" v-model="user.firstName" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="lastName" label="Nazwisko" v-model="user.lastName" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomInputText name="email" label="Adres e-mail" v-model="user.email" rules="required|email"/>
                    </div>
                    <div v-if="displayRoleChangeSubmenu" class="p-col-12">
                        <CustomSelectOneOrganisationLevel name="organisationLevel"
                                                          label="Poziom organizacji"
                                                          v-model="user.organisationLevel"
                                                          rules="required"
                                                          :disabled="Role.isBZCoordinator()"
                                                          @change="user.organisationId = undefined"/>
                        <CustomAutocompleteOrganisation v-if="user.organisationLevel"
                                                        name="organisationId" label="Nazwa organizacji"
                                                        :complete-function="Role.isBZCoordinator()
                                                            ? getSubordinateOrganisations
                                                            : getOrganisations"
                                                        v-model="user.organisationId"
                                                        :organisation-level="user.organisationLevel"
                                                        rules="required"/>
                    </div>
                    <div v-if="editingOwn" class="p-col-12">
                        <Checkbox id="donationPostsCheckbox" :binary="true"
                                  v-model="user.followingDonationPosts"/>
                        <label for="donationPostsCheckbox">
                            {{$t('message.register.followDonationPostsCheckbox')}}
                        </label>
                    </div>
                    <div v-if="editingOwn" class="p-col-12">
                        <Checkbox id="collectionPostsCheckbox" :binary="true"
                                  v-model="user.followingCollectionPosts"/>
                        <label for="collectionPostsCheckbox">
                            {{$t('message.register.followCollectionPostsCheckbox')}}
                        </label>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:start>
                                <Button v-if="editingOwn"
                                        class="button-link" type="button" role="link"
                                        icon="pi pi-lock" label="Zmień hasło"
                                        :disabled="isSubmitting"
                                        @click="$router.push({name: 'userChangePassword'})"/>
                                <Button v-if="!editingOwn && !user.emailVerified"
                                        type="button" icon="fas fa-envelope"
                                        @click="sendEmailActivationLink()"
                                        label="Wyślij ponownie link aktywacyjny"/>
                                <Button v-if="!editingOwn && user.emailVerified"
                                        type="button" icon="fas fa-envelope"
                                        disabled
                                        label="E-mail zweryfikowany"/>
                            </template>
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Checkbox from "primevue/checkbox";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        updateUserUsingPOST as updateUser,
        getUserUsingGET as getUser,
        getLoggedUserUsingGET as getLoggedUser,
        getOrganisationsUsingGET as getOrganisations,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        sendActivationLinkUsingPOST as sendActivationLink,
    } from "@/swagger/vue-api-client";
    import CustomSelectOneOrganisationLevel
        from "@/components/form/CustomSelectOneOrganisationLevel";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {SystemRole} from "@/utils/SystemRole";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "UserEditView",

        components: {
            Button,
            Toolbar,
            CustomInputText,
            CustomSelectOneOrganisationLevel,
            CustomAutocompleteOrganisation,
            Checkbox,
            Form,
        },

        props: {
            value: {},
            editingOwn: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                user: {
                    id: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    organisationId: "",
                    organisationLevel: "",
                    followingDonationPosts: false,
                    followingCollectionPosts: false,
                },
                disableRoleChange: false,
                Role: SystemRole,
                loaded: false,
            };
        },

        computed: {
            displayRoleChangeSubmenu() {
                return !this.disableRoleChange && !this.editingOwn;
            },
        },

        async mounted() {
            try {
                let response;
                if (this.$route.params.id) {
                    response = await getUser({id: this.$route.params.id});
                    const loggedUser = await getLoggedUser();

                    if (response.data.id === loggedUser.data.id) {
                        this.disableRoleChange = true;
                    }
                } else {
                    response = await getLoggedUser();
                }
                this.user = response.data;
                this.user.organisationLevel = response.data.organisation.organisationLevel;
                this.user.organisationId = {
                    value: response.data.organisation.id,
                    label: response.data.organisation.name,
                };
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            this.loaded = true;
        },

        methods: {
            sendEmailActivationLink() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno chcesz ponownie wysłać link aktywacyjny?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        sendActivationLink({userIdRequest: {id: this.user.id}}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie wysłano link aktywacyjny",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            saveUser() {
                if (!this.user.organisationId) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Należy wskazać nazwę organizacji",
                    });
                    return;
                }

                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const userRequest = {...this.user};
                        if (typeof this.user.organisationId !== "number") {
                            userRequest.organisationId = this.user.organisationId.value;
                        }
                        updateUser({userRequest}).then(() => {
                            if (this.editingOwn) {
                                this.emitter.emit("fullNameChange", this.user.firstName + " " + this.user.lastName);
                            }

                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });

                            if (this.editingOwn) {
                                this.$router.push({name: "map"});
                            } else {
                                this.$router.back();
                            }
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Użytkownik o podanym adresie e-mail już istnieje",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                });
            },

            getOrganisations,

            getSubordinateOrganisations,
        },
    };
</script>

<style lang="less" scoped>
    .user-form {
        max-width: 100%;
    }
</style>
