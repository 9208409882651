<template>
    <div>
        <h1 class="page-header"><i class="pi pi-id-card"></i> Dane upoważnienia</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveOplAuthorization" v-slot="{ isSubmitting }">
                <div class="p-grid oplAuthorization-form">
                    <div class="p-col-12" v-if="existing || oplAuthorization.number">
                        <CustomInputText name="number" label="Numer upoważnienia"
                                         v-model="oplAuthorization.number" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomInputText name="name" label="Imię i nazwisko"
                                         v-model="oplAuthorization.authorizedPersonFullName" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomAutocompleteOrganisation name="assignedOpl" label="OPL"
                                                        v-model="oplAuthorization.authorizedOplId"
                                                        item-label="label"
                                                        :complete-function="getSubordinateOrganisations"
                                                        organisation-level="OPL"
                                                        rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomAutocompleteStoreChain name="storeChain.label" label="Sieć sklepów"
                                                      v-model="oplAuthorization.storeChainId"
                                                      :complete-function="getStoreChains"
                                                      rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomInputTextarea name="address" label="Adres" v-model="oplAuthorization.address"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdateOplAuthorizationUsingPOST as createOrUpdateOplAuthorization,
        getOplAuthorizationUsingGET as getOplAuthorization,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        getStoreChainsUsingGET as getStoreChains,
    } from "@/swagger/vue-api-client";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import CustomAutocompleteStoreChain from "@/components/form/CustomAutocompleteStoreChain";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";
    import CustomInputTextarea from "../../components/form/CustomInputTextArea";

    export default {
        name: "OplAuthorizationFormView",

        components: {
            Button,
            Toolbar,
            CustomInputText,
            Form,
            CustomAutocompleteOrganisation,
            CustomAutocompleteStoreChain,
            CustomInputTextarea,
        },

        props: {
            existing: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                oplAuthorization: {
                    id: null,
                    number: null,
                    authorizedPersonFullName: null,
                    authorizedOplId: null,
                    storeChainId: null,
                    address: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getOplAuthorization({id: this.$route.params.id}).then((response) => {
                    this.oplAuthorization = response.data;
                    this.oplAuthorization.authorizedOplId = {
                        value: this.oplAuthorization.authorizedOpl.id,
                        label: this.oplAuthorization.authorizedOpl.name,
                    };
                    this.oplAuthorization.storeChainId = {
                        value: this.oplAuthorization.storeChain.id,
                        label: this.oplAuthorization.storeChain.name,
                    };
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            saveOplAuthorization() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdateOplAuthorization({oplAuthorizationRequest: this.oplAuthorization}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },

            getSubordinateOrganisations,

            getStoreChains,
        },
    };
</script>

<style lang="less" scoped>
    .oplAuthorization-form {
        max-width: 100%;
    }
</style>
