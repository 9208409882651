<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="firstName" label="Imię" v-model="internalValue.firstName"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="lastName" label="Nazwisko" v-model="internalValue.lastName"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="email" label="Adres e-mail" v-model="internalValue.email"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomSelectOneSystemRole name="systemRole" label="Rola" v-model="internalValue.systemRole"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomSelectOneActiveStatus name="active" label="Status" v-model="internalValue.active"
                                         :empty-value="true"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomAutocompleteOrganisation name="organisationId" label="Organizacja"
                                            :complete-function="getOrganisations"
                                            v-model="internalValue.organisationId"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneActiveStatus from "@/components/form/CustomSelectOneActiveStatus";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {
        getSubordinateOrganisationsWithSelfUsingGET as getOrganisations,
    } from "@/swagger/vue-api-client";
    import CustomInputText from "../../../components/form/CustomInputText";
    import CustomSelectOneSystemRole from "../../../components/form/CustomSelectOneSystemRole";

    export default {
        name: "UserSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {
            CustomAutocompleteOrganisation,
            CustomInputText,
            CustomSelectOneSystemRole,
            CustomSelectOneActiveStatus,
        },

        methods: {
            getOrganisations,
        },
    };
</script>

<style scoped>

</style>
