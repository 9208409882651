<template>
    <div>
        <Toolbar v-if="backButton" style="margin-top: 20px; margin-bottom: 30px;">
            <template v-slot:start>
                <Button class="button-link" type="button" role="link" icon="pi pi-chevron-left" label="Wróć"
                        @click="$router.push({name: 'collectionHub', params: {'id': $route.params.id}})"/>
            </template>
        </Toolbar>

        <OrganisationListView :collection-id="parseInt($route.params.id, 10)" inviting-organisations/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import OrganisationListView from "@/views/organisation/OrganisationListView";

    export default {
        name: "CollectionOrganisationInviteListView",

        components: {
            OrganisationListView,
            Button,
            Toolbar,
        },

        props: {
            backButton: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style scoped>

</style>
