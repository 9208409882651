<template>
    <div>
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue"
                         item-label="label" item-value="value" :label="label"/>
    </div>
</template>

<script>
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneActiveStatus",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: null,
            },
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [
                    {value: true, label: "Aktywny"},
                    {value: false, label: "Nieaktywny"},
                ],
            };
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
