<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomInputText name="name" label="Tytuł" v-model="internalValue.name"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomSelectOnePostCategory name="postCategory"
                                         v-model="internalValue.postCategoryId"
                                         label="Kategoria" rules="required"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOnePostCategory from "@/components/form/CustomSelectOnePostCategory";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "PostSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomSelectOnePostCategory, CustomInputText},
    };
</script>

<style lang="less" scoped>

</style>
