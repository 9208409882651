<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="name" label="Nazwa" v-model="internalValue.name"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomSelectOne name="voivodeship" label="Województwo"
                             item-label="label" item-value="value"
                             v-model="internalValue.voivodeship"
                             :items="voivodeships"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="city" label="Miasto" v-model="internalValue.city"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="street" label="Ulica" v-model="internalValue.street"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0" v-if="Role.isFPBZCoordinator()">
            <CustomSelectOneOrganisationLevel name="organisationLevel"
                                              label="Poziom organizacji"
                                              v-model="internalValue.organisationLevel"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0" v-if="Role.isFPBZCoordinator()">
            <CustomInputText name="supervisorName" label="Organizacja nadrzędna"
                             v-model="internalValue.supervisorName"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneOrganisationLevel from "@/components/form/CustomSelectOneOrganisationLevel";
    import {AddressUtils} from "@/utils/AddressUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "OrganisationSearchCriteria",
        components: {
            CustomInputText,
            CustomSelectOneOrganisationLevel,
            CustomSelectOne,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                voivodeships: AddressUtils.validVoivodeships,
                Role: SystemRole,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },
    };
</script>

<style lang="less" scoped>

</style>
