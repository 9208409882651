<template>
    <div class="content" role="main">
        <PostList/>
    </div>
</template>

<script>
    import PostList from "@/views/components/PostList";

    export default {
        name: "PostListView",
        components: {
            PostList,
        },
    };
</script>

<style scoped>

</style>
