<template>
    <div class="custom-autocomplete">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <AutoComplete :id="name" @complete="innerCompleteFunction" :suggestions="items" :label="label"
                      field="label" v-model="internalValue" :dropdown="true" :multiple="multiple"
                      :delay="delay" :placeholder="internalValue ? '' : label" :rules="rules"
                      @item-select="onChange" :ariaLabelledBy="name" appendTo="self"
                      forceSelection />
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import AutoComplete from "primevue/autocomplete";
    import {useField} from "vee-validate";

    export default {
        name: "CustomAutocompleteStoreChain",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            completeFunction: {
                type: Function,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
            rules: undefined,
            delay: {
                type: Number,
                default: 1000,
            },
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.selectedValue;
                },
                set(value) {
                    this.selectedValue = value;
                    if (value && value.value) {
                        this.$emit("update:modelValue", value.value);
                    } else {
                        this.$emit("update:modelValue", undefined);
                    }
                },
            },
        },

        watch: {
            modelValue: {
                handler(val) {
                    if (val === null) {
                        this.selectedValue = null;
                        this.handleChange(undefined);
                    }
                    this.valueToSet = val;
                    if (this.items.length === 0) {
                        this.innerCompleteFunction({query: ""});
                    }
                },
            },
        },

        data() {
            return {
                items: [],
                selectedValue: null,
                valueToSet: null,
            };
        },

        beforeMount() {
            if (this.modelValue) {
                this.internalValue = this.modelValue;
            }
        },

        mounted() {
            const autocompleteElement = document.getElementById(this.name).closest(".p-autocomplete");
            const dropdownButtonLabel = autocompleteElement.querySelector(".p-button-label");
            dropdownButtonLabel.innerHTML = `rozwiń listę - ${this.label}`;
        },

        components: {AutoComplete},

        methods: {
            onChange(event) {
                if (event && event.value) {
                    if (event.value.value === null) {
                        this.handleChange(undefined);
                    } else {
                        this.handleChange(event.value);
                    }
                }
            },

            innerCompleteFunction(event) {
                this.completeFunction({query: event.query})
                    .then((response) => {
                        this.items = response.data;
                        if (this.emptyValue) {
                            this.items = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
                        }
                        if (this.valueToSet) {
                            this.selectedValue = this.items.find((i) => i.value === this.valueToSet);
                        }
                    });
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style lang="less" scoped>
.custom-autocomplete {
    margin-bottom: 10px;
    position: relative;

    label {
        font-size: 12px;
        color: #898989;
        top: -10px;
        background-color: #ffffff;
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
        position: absolute;
        left: 1rem;
        z-index: 2;
    }

    .p-autocomplete {
        width: 100%;
    }
}
</style>
