<template>
    <div>
        <h2 class="page-header"><i class="fas fa-shopping-basket"></i> Lista sklepów</h2>

        <div class="search-criteria" @keyup.enter="search">
            <CollectionDailyReportSearchCriteria ref="collectionDailyReportSearchCriteria"
                                                 v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button class="button-link" type="button" role="link" icon="pi pi-file-excel"
                                label="Statystyki" v-if="!Role.isOPLCoordinator()"
                                @click="$router.push({
                                    name: 'collectionStatistics',
                                    params: {collectionId: collection.id}
                                })"/>
                        <Button class="button-link" type="button" role="link" icon="pi pi-lock"
                                v-if="canLockReports()"
                                label="Zakończ raport szacunkowy"
                                @click="lockReports($event)"/>
                        <Button class="button-link" type="button" role="link" icon="pi pi-book"
                                v-if="!Role.isFPBZCoordinator()"
                                label="Raport końcowy - szczegółowy"
                                @click="$router.push({
                                    name: 'collectionReport', params: {collectionId: collection.id}
                                })"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <TabMenu v-model:activeIndex="active" class="tabmenu" :model="menuItems"/>

        <CollectionDailyReportTable ref="collectionDailyReportTable"
                                    :is-collection-closed="collection.closedByFpbz"
                                    :search-criteria="searchCriteria"
                                    :display-all="displayAll" @loaded="loadInitialDisplayAll"
                                    @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import TabMenu from "primevue/tabmenu";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {
        lockDailyReportsForOrganisationUsingPOST as lockReports,
    } from "@/swagger/vue-api-client";
    import CollectionDailyReportSearchCriteria from "./components/CollectionDailyReportSearchCriteria";
    import CollectionDailyReportTable from "./components/CollectionDailyReportTable";

    export default {
        name: "CollectionDailyReportListView",
        components: {
            CollectionDailyReportTable, CollectionDailyReportSearchCriteria, Button, Toolbar, TabMenu,
        },
        props: {
            collection: {
                type: Object,
                default: null,
            },
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                displayAll: null,
                menuItems: [],
                Role: SystemRole,
                active: 0,
            };
        },

        beforeMount() {
            const options = {month: "long", day: "numeric"};
            for (let i = 1; i <= this.collection.numberOfDays; i += 1) {
                const date = new Date(this.collection.startDate);
                date.setDate(date.getDate() + i - 1);
                this.menuItems.push({
                    label: `${i} dz, ${date.toLocaleDateString("pl-PL", options)}`,
                    command: () => this.changeToDay(i),
                });
            }
            SearchCriteria.loadCriteria(this);
            if (this.searchCriteria.numberOfDay) {
                this.active = this.searchCriteria.numberOfDay - 1;
            }
        },

        methods: {
            canLockReports() {
                if (this.$refs.collectionDailyReportTable) {
                    return this.$refs.collectionDailyReportTable.canLockReports;
                }
                return false;
            },

            lockReports(event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno chcesz zakończyć raport? "
                        + "Po jego zakończeniu nie będziesz mieć możliwości edytowania danych.",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak, zakończ",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Cofnij",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const body = {
                            collectionId: this.collection.id,
                        };
                        lockReports(body).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Raport został zakończony.",
                            });
                            this.search();
                        });
                    },
                    reject: () => {},
                });
            },

            changeToDay(i) {
                this.searchCriteria.numberOfDay = i;
                this.search();
            },

            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.collectionDailyReportTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                if (this.searchCriteria) {
                    return {
                        collectionId: this.collection.id,
                        numberOfDay: 1,
                        page: this.searchCriteria.page,
                    };
                }

                return {
                    collectionId: this.collection.id,
                    numberOfDay: 1,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
            // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },
        },
    };
</script>

<style scoped>
::v-deep(.tabmenu) {
    padding: 2rem 1rem;
}
</style>
