<template>
    <div class="p-field-checkbox">
        <Checkbox :id="name" v-model="internalValue" :class="computedClasses"
                  @change="change" @blur="handleBlur" :binary="true"/>
        <label :for="name" :class="computedClasses">
            {{ label }}<span class="star" v-if="rules">*</span>
        </label>
    </div>
</template>

<script>
    import Checkbox from "primevue/checkbox";
    import {useField} from "vee-validate";

    export default {
        name: "CustomCheckbox",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue", "change"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                    this.$emit("change", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        methods: {
            change() {
                this.handleChange(!this.internalValue);
            },
        },

        components: {Checkbox},
    };
</script>

<style scoped lang="less">
    @import "../../assets/theme/variable.less";

    .star {
        font-size: 16px;
        color: red;
        font-weight: 700;
    }
</style>
