<template>
    <div class="p-fluid custom-password">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <Password :id="name" v-model="internalValue" :feedback="false" :toggleMask="true"
                  :class="computedClasses" @input="handleChange" @blur="handleBlur"
                  :placeholder="label"
                  v-tooltip.top="title"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Password from "primevue/password";
    import {useField} from "vee-validate";

    export default {
        name: "CustomPassword",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            title: {
                type: String,
                default: null,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Password},
    };
</script>

<style scoped lang="less">
    @import "../../assets/theme/variable";

    .custom-password {
        margin-bottom: 10px;
    }
</style>
