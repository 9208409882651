<template>
    <div class="wrapper">
        <h1 class="visually-hidden">Czat</h1>
        <div v-if="processingTasks > 0" class="progress-spinner">
            <ProgressSpinner/>
        </div>

        <div v-if="loaded && connected" class="chat-wrapper">
            <div class="messages-wrapper">
                <Button v-if="!noMoreMessages" type="button"
                        label="Załaduj starsze wiadomości" @click="loadOlderMessages"/>
                <p v-else>To jest początek konwersacji</p>

                <div v-for="message in messages" :key="message.creationDate">
                    <div v-if="message.senderName === ownName" class="own-chat">
                        <div class="own-entry msg-outer">
                            <p class="own-entry-date msg-date">{{message.creationDate}}</p>
                            <p class="own-entry-msg entry-msg"
                               style="overflow-wrap: break-word;">{{message.content}}</p>
                            <p class="own-name msg-name">{{message.senderName}}</p>
                        </div>
                    </div>
                    <div v-else class="other-chat">
                        <div class="other-entry msg-outer">
                            <p class="other-entry-date msg-date">{{message.creationDate}}</p>
                            <p class="other-entry-msg entry-msg"
                               style="overflow-wrap: break-word;">{{message.content}}</p>
                            <p class="other-name msg-name">{{message.senderName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-grid" @keyup.enter="sendWithEnter">
                <CustomInputTextArea class="p-col-12" style="overflow: auto;" name="msgInput"
                                     :auto-resize="false" :rows="5" v-model="message.content"
                                     label="Wiadomość" :hideLabel="true"/>
                <div class="p-col-12">
                    <div class="p-grid">
                        <CustomCheckbox type="button" class="p-md-4 p-sm-6" name="sendOnEnter"
                                        label="Wysyłaj naciskając Enter" v-model="sendOnEnter"
                                        @change="onSendOnEnterChange"/>
                        <Button style="margin: 10px" class="p-md-4 p-sm-6"
                                type="button" icon="pi pi-angle-right" label="Wyślij" @click="send"/>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="failedToConnect">
            <p>Nie udało się połączyć z chatem</p>
            <Button type="button" label="Spróbuj jeszcze raz" @click="connect"/>
        </div>
    </div>
</template>

<script>
    import SockJS from "sockjs-client";
    import Stomp from "webstomp-client";
    import Button from "primevue/button";
    import ProgressSpinner from "primevue/progressspinner";
    import {
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
        loadOlderMessagesUsingGET as loadMessages,
        loadOlderMessagesCountUsingGET as loadMessagesCount,
        setSeenUsingPOST as setSeen,
    } from "@/swagger/vue-api-client";
    import CustomCheckbox from "@/components/form/CustomCheckbox";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "ChatView",

        components: {
            Button,
            ProgressSpinner,
            CustomCheckbox,
            CustomInputTextArea,
        },

        data() {
            return {
                message: {
                    threadId: this.$route.params.threadId,
                    content: undefined,
                },
                sendOnEnter: false,
                socket: undefined,
                stompClient: undefined,
                messages: [],
                connected: true,
                failedToConnect: false,
                ownName: null,
                noMoreMessages: false,
                processingTasks: 0,
                loaded: false,
                firstLoad: false,
            };
        },

        async mounted() {
            this.processingTasks += 1;

            this.sendOnEnter = this.$cookies.get("sendOnEnter") === "true";

            this.connect();

            const userOrganisationRequest = getLoggedUserOrganisation();
            const olderMessagesCountRequest = loadMessagesCount({threadId: this.$route.params.threadId});

            try {
                const responses = await Promise.all([userOrganisationRequest, olderMessagesCountRequest]);
                this.ownName = responses[0].data.name;
                this.noMoreMessages = responses[1].data === 0;
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd",
                    });

                    return;
                }
            }

            this.processingTasks -= 1;
            await this.loadOlderMessages();
            this.loaded = true;
            if (this.firstLoad) {
                setSeen({threadId: this.message.threadId}).then(() => {
                    this.emitter.emit("enteredChat");
                });
                await this.$nextTick(() => {
                    this.scrollToBottom();
                });
            }
        },

        beforeUnmount() {
            this.stompClient.disconnect();
        },

        methods: {
            scrollToBottom() {
                const div = document.getElementsByClassName("messages-wrapper")[0];
                div.scrollTop = div.scrollHeight;
            },

            async loadOlderMessages() {
                this.processingTasks += 1;

                try {
                    const response = await loadMessages({
                        date: this.messages.length > 0 ? this.messages[0].creationDate : null,
                        threadId: this.$route.params.threadId,
                    });

                    if (this.messages.length === 0) {
                        this.firstLoad = true;
                    }
                    this.messages = [...response.data.reverse(), ...this.messages];
                } catch (error) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd",
                    });
                }

                try {
                    const response = await loadMessagesCount({
                        date: this.messages.length > 0 ? this.messages[0].creationDate : null,
                        threadId: this.$route.params.threadId,
                    });

                    if (response.data === 0) {
                        this.noMoreMessages = true;
                    }
                } catch (error) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd",
                    });
                }

                this.processingTasks -= 1;
            },

            send() {
                if (this.message.content === undefined
                    || this.message.content === null
                    || this.message.content.trim() === "") {
                    return;
                }

                this.stompClient.send("/chat/chat", JSON.stringify(this.message), {});
                this.message.content = undefined;
            },

            sendWithEnter() {
                if (!this.sendOnEnter) {
                    return;
                }

                this.send();
            },

            onSendOnEnterChange() {
                this.$cookies.set("sendOnEnter", this.sendOnEnter, "365d");
            },

            connect() {
                this.processingTasks += 1;
                this.socket = new SockJS(
                    process.env.VUE_APP_API_URL + "/message?access_token=" + localStorage.getItem("token"),
                );

                this.stompClient = Stomp.over(this.socket, {debug: false});
                this.stompClient.connect(
                    {},
                    () => {
                        this.failedToConnect = false;
                        this.connected = true;
                        const url = `/message/${this.$route.params.threadId}/queue/messages`;
                        this.stompClient.subscribe(url, (tick) => {
                            this.messages.push(JSON.parse(tick.body));
                            setSeen({threadId: this.message.threadId}).then(() => {
                                this.emitter.emit("enteredChat");
                            });
                            this.$nextTick(() => { this.scrollToBottom(); });
                        });

                        this.processingTasks -= 1;
                    },
                    () => {
                        this.failedToConnect = true;
                        this.connected = false;
                        this.socket = undefined;
                        this.stompClient = undefined;

                        this.processingTasks -= 1;
                    },
                );
            },
        },
    };
</script>

<style scoped lang="less">
    @import "assets/less/layout-simple.less";
    @import "../assets/theme/variable.less";

    .progress-spinner {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 15px;
    }

    .chat-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
    }

    .messages-wrapper {
        flex: auto;
        max-height: calc(100% - 140px); /* based on .input-wrapper height */
        overflow: auto;
        padding: 20px 30px;
        margin-bottom: 20px;
    }

    .input-wrapper {
        height: 140px; /* used in .messages-wrapper */
        display: flex;
        align-items: center;
    }

    .own-entry-date {
        margin: 10px 0 0 0;
        text-align: right;
        font-size: 12px;
    }

    .own-entry {
        display: flex;
        flex-direction: row-reverse;
    }

    .other-entry-date {
        margin: 10px 0 0 0;
        text-align: left;
        font-size: 12px;
    }

    .other-entry {
        display: flex;
        flex-direction: row;
    }

    .msg-name {
        margin-top: 0;
        padding: 5px;
        font-size: 12px;
    }
    .wrapper {
        display: flex;
        height: calc(100vh - 85px);
    }
    .entry-msg {
        max-width: 70%;
        padding: 5px;
        border: 1px solid gray;
        border-radius: 3px;
        margin-top: 5px;
        margin-bottom: 4px;
    }
    .entry-msg.own-entry {
        margin: 6px 0 3px auto;
    }
    .entry-msg.outher-entry {
        margin: 6px auto 3px 0;
    }
    .own-entry-msg.entry-msg {
        background-color: rgba(0, 255, 255, 0.3);
    }
    .other-entry-msg.entry-msg {
        background-color: rgba(255, 0, 255, 0.3);
    }
    .own-chat {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
    .other-chat {
        display: flex;
        align-items: flex-start;
    }
    .msg-outer {
        display: flex;
        flex-direction: column;
    }
    .msg-outer.own-entry {
        align-items: flex-end;
    }
    .msg-outer.other-entry {
        align-items: flex-start;
    }
    @media only screen and (max-width: @media-breakpoint-down-xs) {
        .entry-msg {
            max-width: 90%;
        }
    }
</style>
