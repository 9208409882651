<template>
    <div>
        <div class="login-panel-header">
            <img src="@/assets/logo.png" alt="logo Rampa">
        </div>
        <div>
            <div class="p-grid p-flex-column login-panel-content">
                <Form @submit="login" class="p-grid p-flex-column" style="align-items:center">
                    <div class="p-col-8">
                        <h1>{{ $t('message.login.title') }}</h1>
                    </div>
                    <div class="p-col-8">
                        <div v-if="loginError" class="p-mb-5">{{ $t('message.login.loginError') }}</div>
                        <CustomInputText name="login" v-model="loginForm.login"
                                         :label="$t('message.login.login')" rules="required"/>
                        <CustomPassword name="password" v-model="loginForm.password"
                                        :label="$t('message.login.password')" rules="required"/>
                    </div>
                    <div class="p-col-8">
                        <Button type="submit" class="login-btn" :label="$t('message.login.action.login')"/>
                    </div>
                </Form>
                <div class="p-col-12">
                    <router-link :to="{name: 'remindPassword'}">
                        {{ $t('message.login.action.remindPassword') }}
                    </router-link>
                </div>
                <div class="p-col-12">
                    <router-link :to="{name: 'register'}">
                        {{ $t('message.register.action.register') }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="login-panel-footer p-grid p-nogutter">
            <div class="p-col-12">
                Pomoc: <a href="mailto:rampa@bankizywnosci.pl">rampa@bankizywnosci.pl</a>
            </div>
            <div class="p-col-12">
                System powstał w ramach projektu:
                "Opracowanie systemu monitorowania marnowanej żywności i efektywnego
                programu racjonalizacji strat i ograniczania marnotrawstwa żywności – PROM",
                <br>realizowanego w ramach strategicznego programu badań naukowych i prac rozwojowych
                GOSPOSTRATEG, finansowanego przez Narodowe Centrum Badań i Rozwoju.
                <br>Gospostrateg 1/385753/1/NCBR/2018
            </div>
            <div class="p-col-4">
                <img src="@/assets/logo_fpbz.png" alt="logo FPBŻ">
            </div>
            <div class="p-col-4">
                <img src="@/assets/logo_prom.png" alt="logo PROM">
            </div>
            <div class="p-col-4">
                <img src="@/assets/logo_ncbr.png" alt="logo NCBR">
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {Form} from "vee-validate";
    import {loginUsingPOST as loginRequest} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomPassword from "@/components/form/CustomPassword";
    import CustomInputText from "@/components/form/CustomInputText";

    export default {
        name: "LoginView",
        components: {
            CustomInputText, CustomPassword, Button, Form,
        },

        data() {
            return {
                loginError: false,
                loginForm: {
                    login: "",
                    password: "",
                },
            };
        },

        methods: {
            login() {
                this.loginError = false;
                loginRequest({authenticationRequest: this.loginForm})
                    .then((response) => {
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem("role", response.data.role);
                        localStorage.setItem("fullName", response.data.fullName);
                        localStorage.setItem("organisationName", response.data.organisationName);
                        if (this.$route.query.next) {
                            this.$router.push({path: this.$route.query.next});
                        } else {
                            this.$router.push({name: "map"});
                        }
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 503) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        } else if (error.response && error.response.status === 403) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Twoje konto jest nieaktywne.\n"
                                    + "Musi zostać zaakceptowane przez organizację nadrzędną",
                            });
                            this.loginError = false;
                        } else {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Błędne dane logowania",
                            });
                            this.loginError = true;
                        }
                    });
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/login.less";
</style>
