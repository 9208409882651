<template>
    <div>
        <DataTable ref="userTable" v-if="isMounted" :value="users" :lazy="true" :paginator="paginator"
                   :auto-layout="true" v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage" @sort="onSort"
                   :rowsPerPageOptions="[5,10,20,50]" :key="displayAll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column v-if="!Role.isOPLCoordinator()" header="Akcje" headerStyle="width: 180px">
                <template #body="slotProps">
                    <div v-if="Role.isFPBZCoordinator()">
                        <div :class="'actions-' + slotProps.data.id">
                            <Button type="button" label="Akcje" @click="toggleActionsMenu($event,slotProps)"
                                    aria-haspopup="true" aria-controls="overlay_menu"
                                    :aria-expanded="actionsMenuVisible"/>
                            <OverlayPanel id="fpbz_actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                          append-to=".wrapper" v-if="isMounted"
                                          @show="moveActionsMenu(slotProps.data.id)"
                                          @hide="onHideActionsMenu">
                                <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                    <Button type="button" icon="pi pi-pencil"
                                            class="p-m-1" label="Organizacja"
                                            v-if="!slotProps.data.organisation"
                                            @click="openAssignOrganisationDialog(slotProps.data,$event)">
                                    </Button>
                                    <Button v-if="slotProps.data.organisation"
                                            class="p-m-1 button-link"
                                            type="button" role="link" icon="pi pi-pencil" label="Edytuj"
                                            @click="$router.push({
                                                name: 'userEdit',
                                                params: {'id': slotProps.data.id},
                                            })"/>
                                    <Button v-if="!slotProps.data.active && slotProps.data.organisation"
                                            class="p-m-1"
                                            type="button" icon="pi pi-check" label="Aktywuj"
                                            @click="activateAccount(slotProps.data,$event)">
                                    </Button>
                                    <Button v-if="slotProps.data.active && slotProps.data.organisation"
                                            class="p-m-1"
                                            type="button" icon="pi pi-times" label="Deaktywuj"
                                            @click="deactivateAccount(slotProps.data,$event)">
                                    </Button>
                                    <Button v-if="slotProps.data.id !== ownId"
                                            class="p-m-1"
                                            type="button" icon="pi pi-ban" label="Usuń"
                                            @click="deleteAccount(slotProps.data,$event)">
                                    </Button>
                                </div>
                            </OverlayPanel>
                        </div>
                    </div>
                    <div v-else-if="Role.isBZCoordinator() && slotProps.data.systemRole === 'OPL_COORDINATOR'">
                        <div :class="'actions-' + slotProps.data.id">
                            <Button type="button" label="Akcje" @click="toggleActionsMenu($event,slotProps)"
                                    aria-haspopup="true" aria-controls="overlay_menu"
                                    :aria-expanded="actionsMenuVisible"/>
                            <OverlayPanel id="bz_actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                          append-to=".wrapper" v-if="isMounted"
                                          @show="moveActionsMenu(slotProps.data.id)"
                                          @hide="onHideActionsMenu">
                                <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                    <template v-if="!slotProps.data.organisation
                                        && slotProps.data.supposedOrganisationName">
                                        <Button type="button" icon="pi pi-pencil" label="Organizacja"
                                                class="p-m-1"
                                                @click="openAssignOrganisationDialog(slotProps.data,$event)">
                                        </Button>
                                    </template>
                                    <template v-else>
                                        <Button v-if="slotProps.data.organisation"
                                                class="p-m-1 button-link"
                                                type="button" role="link" icon="pi pi-pencil" label="Edytuj"
                                                @click="$router.push({
                                                    name: 'userEdit',
                                                    params: {'id': slotProps.data.id},
                                                })"/>
                                        <Button v-if="!slotProps.data.active"
                                                class="p-m-1"
                                                type="button" icon="pi pi-check" label="Aktywuj"
                                                @click="activateAccount(slotProps.data,$event)">
                                        </Button>
                                        <Button v-if="slotProps.data.active"
                                                class="p-m-1"
                                                type="button" icon="pi pi-times" label="Deaktywuj"
                                                @click="deactivateAccount(slotProps.data,$event)">
                                        </Button>
                                    </template>
                                    <Button class="p-m-1"
                                            type="button" icon="pi pi-ban" label="Usuń"
                                            @click="deleteAccount(slotProps.data,$event)">
                                    </Button>
                                </div>
                            </OverlayPanel>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="firstName" header="Imię" :sortable="true"></Column>
            <Column field="lastName" header="Nazwisko" :sortable="true"></Column>
            <Column field="email" header="Adres e-mail" :sortable="true"></Column>
            <Column field="systemRole" header="Rola" headerStyle="width: 200px">
                <template #body="slotProps">
                    <CustomBadge label="Koordynator FPBŻ" :color="fpbzBadgeColor"
                                 v-show="slotProps.data.systemRole === 'FPBZ_COORDINATOR'"/>
                    <CustomBadge label="Koordynator BŻ" :color="bzBadgeColor"
                                 v-show="slotProps.data.systemRole === 'BZ_COORDINATOR'"/>
                    <CustomBadge label="Koordynator OPL" :color="oplBadgeColor"
                                 v-show="slotProps.data.systemRole === 'OPL_COORDINATOR'"/>
                </template>
            </Column>
            <Column field="organisation.name" header="Organizacja" :sortable="true"/>
            <Column field="active" header="Status" headerStyle="width: 160px">
                <template #body="slotProps">
                    <CustomBadge label="Aktywny" :color="activeBadgeColor" v-show="slotProps.data.active"/>
                    <CustomBadge label="Nieaktywny" :color="inactiveBadgeColor" v-show="!slotProps.data.active"/>
                </template>
            </Column>
            <template #empty>
                Nie znaleziono użytkowników.
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ users.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>

    <Dialog v-model:visible="assignOrganisationDialog"
            header="Przypisanie użytkownika do organizacji"
            :modal="true" :dismissable-mask="true"
            append-to=".wrapper" v-if="isMounted">
        <Form @submit="assignOrganisation" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12">
                    <p v-if="editedUser.supposedOrganisationName">
                        Użytkownik zadeklarował przynależność do organizacji: "{{editedUser.supposedOrganisationName}}"
                    </p>
                    <p v-else>
                        Użytkownik nie zadeklarował przynależności do organizacji.
                    </p>
                </div>
                <div class="p-col-12">
                    <CustomAutocompleteOrganisation name="organisationId" label="Nazwa organizacji"
                                                    :complete-function="getOrganisations"
                                                    v-model="editedUser.organisationId"
                                                    :organisation-level="getEditedUserOrganisationLevel"
                                                    :supervisor-id="
                                                        editedUser.supervisorOrganisation ?
                                                            editedUser.supervisorOrganisation.id : null"
                                                    rules="required"/>
                </div>
            </div>

            <div class="button-div">
                <div class="p-d-flex p-flex-wrap">
                    <Button label="Przypisz do organizacji" class="p-mr-2" icon="pi pi-chevron-right"
                            type="submit" :disabled="isSubmitting"/>
                    <Button label="Anuluj" class="p-mr-2" icon="pi pi-times"
                            @click="closeAssignOrganisationDialog()" :disabled="isSubmitting"/>
                </div>
            </div>
        </Form>
    </Dialog>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import OverlayPanel from "primevue/overlaypanel";
    import Dialog from "primevue/dialog";
    import {Form} from "vee-validate";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        getLoggedUserUsingGET as getLoggedUser,
        searchUserUsingPOST as searchUser,
        searchUserCountUsingPOST as searchUserCount,
        activateAccountUsingPOST as activateAccount,
        deactivateAccountUsingPOST as deactivateAccount,
        deleteAccountUsingPOST as deleteAccount,
        getOrganisationsUsingGET as getOrganisations,
        assignOrganisationUsingPOST as assignOrganisation,
    } from "@/swagger/vue-api-client";
    import {ExportUtils} from "@/utils/ExportUtils";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";
    import CustomBadge from "../../../components/CustomBadge";

    export default {
        name: "UserTable",

        components: {
            CustomBadge, Button, Column, DataTable, OverlayPanel, Dialog, CustomAutocompleteOrganisation, Form,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                users: [],
                Role: SystemRole,
                ownId: null,
                searchParams: this.searchCriteria,
                roleNameMapping: {
                    OPL_COORDINATOR: "k-OPL",
                    BZ_COORDINATOR: "k-BZ",
                    FPBZ_COORDINATOR: "k-FPBZ",
                },
                statusNameMapping: {
                    true: "Aktywny",
                    false: "Nieaktywny",
                },
                activeBadgeColor: "green",
                inactiveBadgeColor: "red",
                fpbzBadgeColor: "primary",
                bzBadgeColor: "secondary",
                oplBadgeColor: "tertiary",
                isMounted: false,
                assignOrganisationDialog: false,
                editedUser: null,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },
        },

        async mounted() {
            let response;
            try {
                response = await getLoggedUser();
            } catch (error) {
                this.$toast.add({
                    severity: "error",
                    summary: "Błąd",
                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                });

                return;
            }

            this.ownId = response.data.id;

            this.emitter.on("changedContrast", this.handleContrastType);

            this.$nextTick(() => {
                const currentContrastType = document.getElementsByClassName("wrapper")[0]
                    .getAttribute("data-contrast-type");
                this.handleContrastType(currentContrastType);

                this.$emit("loaded");
            });
        },

        computed: {
            getEditedUserOrganisationLevel() {
                const supervisorLevelMapping = {
                    BZ: "OPL",
                    FPBZ: "BZ",
                };
                const userRoleOrganisationLevelMapping = {
                    BZ_COORDINATOR: "BZ",
                    OPL_COORDINATOR: "OPL",
                };
                if (this.editedUser.supervisorOrganisation) {
                    return supervisorLevelMapping[this.editedUser.supervisorOrganisation.organisationLevel];
                }
                return userRoleOrganisationLevelMapping[this.editedUser.systemRole];
            },
        },

        methods: {
            handleContrastType(color) {
                if (color === "black") {
                    this.activeBadgeColor = "black";
                    this.inactiveBadgeColor = "black";
                    this.fpbzBadgeColor = "black";
                    this.bzBadgeColor = "black";
                    this.oplBadgeColor = "black";
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.activeBadgeColor = "green";
                    this.inactiveBadgeColor = "red";
                    this.fpbzBadgeColor = "primary";
                    this.bzBadgeColor = "secondary";
                    this.oplBadgeColor = "tertiary";
                } else {
                    this.activeBadgeColor = "green";
                    this.inactiveBadgeColor = "red";
                    this.fpbzBadgeColor = "primary";
                    this.bzBadgeColor = "secondary";
                    this.oplBadgeColor = "tertiary";
                }
            },

            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchUser({searchCriteria: this.searchCriteria}).then((response) => {
                    this.users = response.data;
                    this.loading = false;
                    this.isMounted = true;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchUserCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            activateAccount(user, event) {
                if (!user.organisation && user.supposedOrganisationName) {
                    ToastUtils.addToast(this, {
                        severity: "info",
                        summary: "Informacja",
                        detail: "Przypisz użytkownika do konkretnej organizacji zanim go aktywujesz.",
                    });
                    return;
                }
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno aktywować użytkownika?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        activateAccount({userIdRequest: {id: user.id}}).then(() => {
                            user.active = true;
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie aktywowano użytkownika",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            deactivateAccount(user) {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno deaktywować użytkownika?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deactivateAccount({userIdRequest: {id: user.id}}).then(() => {
                            user.active = false;
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie deaktywowano użytkownika",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            deleteAccount(user) {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć użytkownika? Tej akcji nie można odwrócić!",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteAccount({userIdRequest: {id: user.id}}).then(() => {
                            this.users = this.users.filter((u) => u.id !== user.id);
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto użytkownika",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {},
                });
            },

            openAssignOrganisationDialog(user) {
                this.editedUser = {...user};
                this.assignOrganisationDialog = true;
            },

            closeAssignOrganisationDialog() {
                this.editedUser = null;
                this.assignOrganisationDialog = false;
            },

            assignOrganisation() {
                assignOrganisation(
                    {
                        userId: this.editedUser.id,
                        organisationId: this.editedUser.organisationId,
                    },
                ).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie przypisano użytkownika do organizacji",
                    });
                    this.assignOrganisationDialog = false;
                    this.search();
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            exportXLSX(allData) {
                if (allData) {
                    this.loading = true;
                    searchUser({
                        searchCriteria: this.removePagingFromSearchCriteria(),
                    }).then((response) => {
                        ExportUtils.exportXLSX(response.data
                            .map(this.userSearchMapper()), "Użytkownicy", this.getExportFileName());
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    });
                } else {
                    ExportUtils.exportXLSX(this.users
                        .map(this.userSearchMapper()), "Użytkownicy", this.getExportFileName());
                }
            },

            userSearchMapper() {
                /* eslint-disable quote-props */
                return (user) => ({
                    "Imię": user.firstName,
                    "Nazwisko": user.lastName,
                    "Adres e-mail": user.email,
                    "Rola": this.roleNameMapping[user.systemRole],
                    "Organizacja": user.organisation?.name,
                    "Status": this.statusNameMapping[user.active],
                });
            },

            removePagingFromSearchCriteria() {
                const copy = JSON.parse(JSON.stringify(this.searchCriteria));
                copy.page = {
                    limit: 999999,
                    offset: 0,
                    sortField: null,
                    sortOrder: null,
                };
                return copy;
            },

            getExportFileName() {
                return "Dane użytkowników";
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },

            getOrganisations,
        },
    };
</script>

<style scoped>
.form-wrapper {
    margin-top: 10px;
    min-height: 120px;
}

.button-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
</style>
