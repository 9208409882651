<template>
    <div>
        <h1 class="page-header"><i class="pi pi-heart"></i> Dane zbiórki</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveCollection($event)" v-slot="{ isSubmitting }">
                <div class="p-grid collection-form">
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomInputText name="name" label="Nazwa" v-model="collection.name" rules="required"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomCalendar name="invitationsStartDate" label="Data rozpoczęcia okresu zapraszania sklepów"
                                        :show-time="false"
                                        v-model="collection.invitationsStartDate"
                                        rules="required|lessOrEqualDayDate:@startDate,
                                        Data rozpoczęcia okresu zapraszania sklepów nie może być
                                        późniejsza niż data rozpoczęcia"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomCalendar name="startDate" label="Data rozpoczęcia"
                                        :show-time="false"
                                        v-model="collection.startDate"
                                        rules="required|lessOrEqualDayDate:@endDate,
                                        Data rozpoczęcia nie może być późniejsza niż data zakończenia"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomCalendar name="endDate" label="Data zakończenia"
                                        :show-time="false"
                                        v-model="collection.endDate"
                                        rules="required"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomInputNumber name="numberOfdays" label="Liczba dni zbiórki"
                                           :disabled="collection.id"
                                           v-model="collection.numberOfDays"
                                           :min="1"
                                           :rules="collection.id? '': 'required'"/>
                    </div>
                    <div v-if="!collection.id" class="p-lg-12 p-sm-12 p-col-12">
                        <p class="p-lg-12 p-sm-12 p-col-12">Zaproszone BŻ:</p>
                        <div class="p-lg-offset-3 p-lg-6 p-sm-12 p-col-4">
                            <CustomMultiSelect
                                v-model="collection.pendingParticipantsIds"
                                :items="organisations" name="invitedBZ"
                                itemLabel="label" itemValue="value" label="Wybierz BŻ"
                                :filter="true" emptyMessage="Nie znaleziono BŻ"
                                emptyFilterMessage="Nie znaleziono BŻ">
                            </CustomMultiSelect>
                        </div>
                        <div class="p-lg-12 p-sm-12 p-col-12">
                            <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
                        </div>
                    </div>
                    <div class="p-lg-12 p-sm-12 p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdateCollectionUsingPOST as createOrUpdateCollection,
        getSubordinateOrganisationsUsingGET as getOrganisations,
        getCollectionEditInfoUsingGET as getCollectionEditInfo,
    } from "@/swagger/vue-api-client";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {DateUtils} from "@/utils/DateUtils";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "CollectionFormView",

        components: {
            Button, CustomMultiSelect, Toolbar, CustomCalendar, CustomInputText, Form, CustomInputNumber,
        },

        props: {
            value: {},
            editing: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                collection: {
                    id: null,
                    name: null,
                    invitationsStartDate: null,
                    startDate: null,
                    endDate: null,
                    pendingParticipantsIds: [],
                    numberOfDays: null,
                },
                organisations: [],
                errorMessage: null,
                loaded: false,
                confirmMessage: null,
            };
        },

        async mounted() {
            this.confirmMessage = !this.$route.params.id
                ? " Czy na pewno chcesz rozpocząć zbiórkę?"
                : " Czy na pewno chcesz edytować zbiórkę?";

            const organisationsRequest = getOrganisations();
            const editInfoRequest = this.$route.params.id ? getCollectionEditInfo({id: this.$route.params.id}) : null;

            let responses;
            try {
                responses = await Promise.all([organisationsRequest, editInfoRequest]);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                }

                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                });

                return;
            }

            this.organisations = responses[0].data;

            if (responses[1] !== null) {
                this.collection = responses[1].data;
                this.collection.startDate = new Date(this.collection.startDate);
                this.collection.endDate = new Date(this.collection.endDate);
                this.collection.invitationsStartDate = new Date(this.collection.invitationsStartDate);
                this.collection.pendingParticipantsIds = responses[1].data.bzIdList;
            }

            this.loaded = true;
        },

        methods: {
            saveCollection(event) {
                if (this.collection.pendingParticipantsIds.length === 0) {
                    this.errorMessage = "Przynajmniej jeden Bank Żywności musi zostać zaproszony";
                    return;
                }

                const request = {...this.collection};
                request.invitationsStartDate = DateUtils
                    .convertDateNoLocalTimezoneOffset(this.collection.invitationsStartDate)
                    .toJSON();
                request.startDate = DateUtils
                    .convertDateNoLocalTimezoneOffset(this.collection.startDate)
                    .toJSON();
                request.endDate = DateUtils
                    .convertDateNoLocalTimezoneOffset(this.collection.endDate)
                    .toJSON();

                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: this.confirmMessage,
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdateCollection({collectionRequest: request}).then(() => {
                            this.emitter.emit("collectionStart", this.$route.params.id);
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie można utworzyć dwóch zbiórek odbywających się w tym samym czasie",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie udało się zapisać danych",
                                });
                            }
                        });
                    },
                    reject: () => {},
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .collection-form {
        max-width: 100%;
    }
</style>
