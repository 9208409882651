/* eslint-disable */

export const AddressUtils = {

    validVoivodeships: [
        {value: "dolnośląskie", label: "dolnośląskie"},
        {value: "kujawsko-pomorskie", label: "kujawsko-pomorskie"},
        {value: "lubelskie", label: "lubelskie"},
        {value: "lubuskie", label: "lubuskie"},
        {value: "łódzkie", label: "łódzkie"},
        {value: "małopolskie", label: "małopolskie"},
        {value: "mazowieckie", label: "mazowieckie"},
        {value: "opolskie", label: "opolskie"},
        {value: "podkarpackie", label: "podkarpackie"},
        {value: "podlaskie", label: "podlaskie"},
        {value: "pomorskie", label: "pomorskie"},
        {value: "śląskie", label: "śląskie"},
        {value: "świętokrzyskie", label: "świętokrzyskie"},
        {value: "warmińsko-mazurskie", label: "warmińsko-mazurskie"},
        {value: "wielkopolskie", label: "wielkopolskie"},
        {value: "zachodniopomorskie", label: "zachodniopomorskie"},
    ],

    generateAddressString(model) {
        let address = "";

        if (model.voivodeship) {
            address += model.voivodeship + ", ";
        }
        if (model.city) {
            address += model.city + " ";
        }
        if (model.street) {
            address += "ul. " + model.street + " ";
        }
        if (model.houseNumber) {
            address += model.houseNumber;
        }
        if (model.apartmentNumber) {
            address += "/" + model.apartmentNumber;
        }
        if (model.postalCode) {
            address += " (" + model.postalCode + ")";
        }

        return address;
    },

};
