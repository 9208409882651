<template>
    <div>
        <DataTable ref="fileTable" :value="files" :lazy="true" :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" v-if="isMounted"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerStyle="width: 200px">
                <template #body="slotProps">
                    <div v-if="canManageFiles" :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'manage-actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button class="p-m-1"
                                        label="Pobierz plik" type="button"
                                        icon="pi pi-download" @click="downloadFile(slotProps.data)"/>
                                <Button v-if="canManageFiles"
                                        class="button-link p-m-1"
                                        type="button" role="link"
                                        icon="pi pi-pencil" label="Edytuj plik"
                                        @click="$router.push({name: 'fileEdit', params: {'id': slotProps.data.id}})"/>
                                <Button v-if="canManageFiles"
                                        class="p-m-1"
                                        type="button" icon="pi pi-times" label="Usuń plik"
                                        @click="deleteFile(slotProps.data.id, $event)">
                                </Button>
                            </div>
                        </OverlayPanel>
                    </div>
                    <div v-else-if="Role.isBZCoordinator()" :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button class="p-m-1"
                                        label="Pobierz plik" type="button" icon="pi pi-download"
                                        @click="downloadFile(slotProps.data)"/>
                                <Button class="p-m-1"
                                        type="button" icon="pi pi-pencil" label="Edytuj udostępnienia OPL"
                                        @click="editShareOptions(slotProps.data.id, $event)">
                                </Button>
                            </div>
                        </OverlayPanel>
                    </div>
                    <div v-else>
                        <Button class="p-mr-1" label="Pobierz plik" type="button"
                                icon="pi pi-download" @click="downloadFile(slotProps.data)"/>
                    </div>
                </template>
            </Column>
            <Column field="name" header="Nazwa" :sortable="true"/>
            <Column field="description" header="Opis"/>
            <Column field="categoryType" header="Typ pliku" :sortable="true" :style="{'min-width': '150px'}">
                <template #body="slotProps">
                    <CustomBadge label="Plik dot. darowizn" :color="donationBadgeColor"
                                 v-show="slotProps.data.categoryType === 'DONATION'"/>
                    <CustomBadge label="Plik dot. zbiórki" :color="collectionBadgeColor"
                                 v-show="slotProps.data.categoryType === 'COLLECTION'"/>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ files.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>

    <Dialog v-model:visible="shareToOPLDialog"
            header="Udostępnianie pliku OPL"
            :modal="true" :dismissable-mask="true"
            :contentStyle="{overflow: 'visible'}"
            append-to=".wrapper" v-if="isMounted && this.queryType !== 'OWN'">
        <Form @submit="shareToOPL" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12">
                    <CustomMultiSelect name="organisationList" label="Udostępnij dla"
                                       item-label="label" item-value="value"
                                       v-model="sharedToOPLIds" :items="subordinateOrganisations"/>
                </div>
            </div>

            <div class="button-div">
                <div class="p-d-flex p-flex-wrap">
                    <Button label="Udostępnij wybranym OPL" class="p-mr-2" icon="pi pi-chevron-right"
                            type="submit" :disabled="isSubmitting"/>
                    <Button label="Anuluj"  class="p-mr-2" icon="pi pi-times"
                            @click="closeShareToOPLDialog()" :disabled="isSubmitting"/>
                </div>
            </div>
        </Form>
    </Dialog>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import OverlayPanel from "primevue/overlaypanel";
    import {Form} from "vee-validate";
    import Dialog from "primevue/dialog";
    import CustomBadge from "@/components/CustomBadge";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {
        searchFileUsingPOST as searchFile,
        searchFileCountUsingPOST as searchFileCount,
        deleteFileUsingDELETE as deleteFile,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        getFileSharedToOPLIdsUsingGET as getFileSharedToOPLIds,
        editShareOptionsUsingPOST as editShareOptions,
    } from "@/swagger/vue-api-client";
    import {FileUtils} from "@/utils/FileUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";

    export default {
        name: "FileTable",

        components: {
            Button, Column, DataTable, OverlayPanel, CustomBadge, CustomMultiSelect, Dialog, Form,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            queryType: {
                type: String,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                files: [],
                searchParams: this.searchCriteria,
                Role: SystemRole,
                isMounted: false,
                donationBadgeColor: "primary",
                collectionBadgeColor: "secondary",
                subordinateOrganisations: [],
                shareToOPLDialog: false,
                sharedToOPLIds: null,
                currentFileId: null,
            };
        },

        computed: {
            canManageFiles() {
                return !this.Role.isOPLCoordinator() && this.queryType === "OWN";
            },
        },

        watch: {
            searchCriteria(value) {
                this.searchParams = value;
            },

            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },

            queryType(value) {
                this.searchParams.queryType = value;
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },
        },

        async mounted() {
            this.subordinateOrganisations = (await getSubordinateOrganisations()).data;
            this.searchParams.queryType = "OTHERS";

            this.emitter.on("changedContrast", this.handleContrastType);

            await this.$nextTick(() => {
                const currentContrastType = document.getElementsByClassName("wrapper")[0]
                    .getAttribute("data-contrast-type");
                this.handleContrastType(currentContrastType);

                this.$emit("loaded");

                this.isMounted = true;
            });
        },

        methods: {
            closeShareToOPLDialog() {
                this.currentFileId = null;
                this.shareToOPLDialog = false;
            },

            handleContrastType(color) {
                if (color === "black") {
                    this.donationBadgeColor = "black";
                    this.collectionBadgeColor = "black";
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.donationBadgeColor = "primary";
                    this.collectionBadgeColor = "secondary";
                } else {
                    this.donationBadgeColor = "primary";
                    this.collectionBadgeColor = "secondary";
                }
            },

            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchFile({searchCriteria: this.searchParams}).then((response) => {
                    this.files = response.data;
                    this.loading = false;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchFileCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deleteFile(fileId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć plik?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteFile({id: fileId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto plik.",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            editShareOptions(id) {
                getFileSharedToOPLIds({fileId: id}).then((response) => {
                    this.sharedToOPLIds = response.data;
                    this.currentFileId = id;
                    this.shareToOPLDialog = true;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            shareToOPL() {
                editShareOptions({fileId: this.currentFileId, oplIds: this.sharedToOPLIds}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zapisano zmiany",
                    });
                    this.closeShareToOPLDialog();
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            async downloadFile(file) {
                await FileUtils.downloadFileUsingGET(file.filename, "/file/download/" + file.id);
            },

            toggleActionsMenu(event, slotProps) {
                if (this.canManageFiles) {
                    this.$refs["manage-actionsMenu" + slotProps.data.id].toggle(event);
                } else {
                    this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
                }
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
