export const StoreStatusesUtils = {

    collectionStatuses: [
        {label: "Poza zbiórką", value: "IDLE"},
        {label: "Zgłoszony", value: "SIGNED_UP"},
        {label: "Wysłany do sieci", value: "WAITING_FOR_STORE_CHAIN_DECISION"},
        {label: "Aktywowany", value: "ACTIVE"},
        {label: "Odrzucony", value: "REJECTED"},
        {label: "Przywrócony", value: "RESTORED"},
    ],

    donationStatuses: [
        {value: "UNASSIGNED", label: "Wolny"},
        {value: "ASSIGNED", label: "Obsługiwany"},
        {value: "SUSPENDED", label: "Zawieszony"},
        {value: "RESERVED", label: "Zarezerwowany"},
        {value: "ASSIGNED_ELSEWHERE", label: "Zajęty"},
    ],

    storeCollectionStatusOfficialNameMapping: {
        IDLE: "Poza zbiórką",
        SIGNED_UP: "Zgłoszony",
        WAITING_FOR_STORE_CHAIN_DECISION: "Wysłany do sieci",
        ACTIVE: "Aktywowany",
        REJECTED: "Odrzucony",
        RESTORED: "Przywrócony",
    },

    storeDonationStatusOfficialNameMapping: {
        UNASSIGNED: "Wolny",
        ASSIGNED: "Obsługiwany",
        SUSPENDED: "Zawieszony",
        RESERVED: "Zarezerwowany",
        ASSIGNED_ELSEWHERE: "Zajęty",
    },

    nextAvailableCollectionStatuses: {
        IDLE: [],
        SIGNED_UP: [
            {value: "WAITING_FOR_STORE_CHAIN_DECISION", label: "Wysłany do sieci"},
            {value: "ACTIVE", label: "Aktywowany"},
            {value: "REJECTED", label: "Odrzucony"},
        ],
        WAITING_FOR_STORE_CHAIN_DECISION: [
            {value: "ACTIVE", label: "Aktywowany"},
            {value: "IDLE", label: "Poza zbiórką"},
        ],
        ACTIVE: [],
        REJECTED: [],
        RESTORED: [],
    },

    nextAvailableDonationStatuses: {
        UNASSIGNED: [
            {value: "ASSIGNED", label: "Obsługiwany"},
            {value: "SUSPENDED", label: "Zawieszony"},
            {value: "RESERVED", label: "Zarezerwowany"},
            {value: "ASSIGNED_ELSEWHERE", label: "Zajęty"},
        ],
        ASSIGNED: [
            {value: "SUSPENDED", label: "Zawieszony"},
            {value: "ASSIGNED_ELSEWHERE", label: "Zajęty"},
        ],
        SUSPENDED: [
            {value: "UNASSIGNED", label: "Wolny"},
            {value: "ASSIGNED", label: "Obsługiwany"},
            {value: "RESERVED", label: "Zarezerwowany"},
            {value: "ASSIGNED_ELSEWHERE", label: "Zajęty"},
        ],
        RESERVED: [
            {value: "ASSIGNED", label: "Obsługiwany"},
            {value: "SUSPENDED", label: "Zawieszony"},
            {value: "ASSIGNED_ELSEWHERE", label: "Zajęty"},
        ],
        ASSIGNED_ELSEWHERE: [
            {value: "UNASSIGNED", label: "Wolny"},
            {value: "ASSIGNED", label: "Obsługiwany"},
            {value: "SUSPENDED", label: "Zawieszony"},
            {value: "RESERVED", label: "Zarezerwowany"},
        ],
    },

};

export default {
    StoreStatusesUtils,
};
