<template>
    <div class="p-fluid custom-input">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <Calendar :class="computedClasses" :id="name" v-model="internalValue" :showTime="showTime" :timeOnly="timeOnly"
                  @date-select="onChange" dateFormat="yy-mm-dd" :placeholder="label"
                  :minDate="new Date()" :manualInput="true" @show="keyboardAccess"
                  @month-change="keyboardAccess"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Calendar from "primevue/calendar";
    import {useField} from "vee-validate";

    export default {
        name: "CustomCalendar",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            showTime: {
                type: Boolean,
                default: true,
            },
            timeOnly: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
            rules: undefined,
        },
        emits: ["update:modelValue"],

        computed: {
            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },

            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.selectedValue = value;
                    this.handleChange(value);
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            keyboardAccess() {
                const datePickerEl = document.querySelector(".p-datepicker.p-component");
                datePickerEl.querySelectorAll("td span:not(.p-disabled)").forEach((span) => {
                    span.setAttribute("tabindex", "0");
                });
            },
            focusOnInputCalendar() {
                document.querySelector(`#${this.name}`).focus();
            },
            onChange(event) {
                this.handleChange(event);
                this.focusOnInputCalendar();
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Calendar},
    };
</script>

<style scoped>

</style>
