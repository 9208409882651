<template>
    <div>
        <Toolbar style="margin-top: 10px;">
            <template v-slot:start>
                <router-link :to="{name: 'collectionHub', params: {'id': collectionId}}">
                    <Button type="button" icon="pi pi-chevron-left" label="Wróć"></Button>
                </router-link>
                <Button label="Eksportuj pełne statystyki"
                        icon="pi pi-download" @click="exportStatistics"/>
            </template>
        </Toolbar>

        <h2>Raport wstępny-szacunkowy - sieci</h2>
        <DataTable v-if="mounted" :value="datatableDailyData" :lazy="true"
                   :frozenValue="datatableDailyDataFrozen" showGridlines
                   :scrollHeight="scrollHeightString"
                   scrollable class="p-mb-5 p-mt-2" stripedRows responsiveLayout="scroll">
            <Column v-for="(col, index) of dailyColumns"
                    :headerStyle="headerStyleString"
                    :key="col.name"
                    :frozen="isColumnFrozen(index)">
                <template #header>
                    <div v-if="index < 2">{{ col.name }}</div>
                    <div v-else-if="col.dailyReportLocked && !collectionProbablyClosed">
                        <i class="pi pi-check-circle green-tick"></i> {{ col.name }}
                    </div>
                    <div v-else>{{ col.name }}</div>
                </template>
                <template #body="slotProps">
                    <span v-if="slotProps.index === datatableDailyData.length - 1 ||
                              index < 2"
                          v-html="getBoldedValue(slotProps, index)"/>
                    <span v-else>
                        {{ slotProps.data[index] }}
                    </span>
                </template>
            </Column>
        </DataTable>
        <div v-if="!collectionProbablyClosed" class="p-text-left">
            <i class="pi pi-check-circle green-tick"></i>
            obok nazwy organizacji oznacza, że organizacja przesłała raport szacunkowy.
        </div>

        <h2>Raport końcowy-szczegółowy - produkty</h2>
        <DataTable v-if="mounted" :value="datatableFinalData"
                   :scrollHeight="scrollHeightString"
                   :frozenValue="datatableFinalDataFrozen"
                   :lazy="true" showGridlines class="p-mb-5"
                   scrollable stripedRows responsiveLayout="scroll">
            <Column v-for="(col, index) of finalColumns"
                    :headerStyle="headerStyleString"
                    :key="col.name"
                    :frozen="isColumnFrozen(index)">
                <template #header>
                    <div v-if="index < 2">{{ col.name }}</div>
                    <div v-else-if="col.finalReportLocked && !collectionProbablyClosed">
                        <i class="pi pi-check-circle" style="color: green; font-weight: bold"></i>
                        {{ col.name }}
                    </div>
                    <div v-else>{{ col.name }}</div>
                </template>
                <template #body="slotProps">
                    <span v-if="slotProps.index === datatableFinalData.length - 1 ||
                              index < 2"
                          v-html="getBoldedValue(slotProps, index)"/>
                    <span v-else>
                        {{ slotProps.data[index] }}
                    </span>
                </template>
            </Column>
        </DataTable>
        <div v-if="!collectionProbablyClosed" class="p-text-left">
            <i class="pi pi-check-circle green-tick"></i>
            obok nazwy organizacji oznacza, że organizacja przesłała raport końcowy.
        </div>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {
        getStatisticsUsingGET as getStatistics,
        exportStatisticsUsingGET_RAW_URL as exportStatisticsUrl,
    } from "@/swagger/vue-api-client";
    import {NumbersMixin} from "@/mixins/NumbersMixin";
    import {FileUtils} from "@/utils/FileUtils";
    import {DateUtils} from "@/utils/DateUtils";

    export default {
        name: "CollectionStatisticsView",

        components: {
            DataTable, Column, Toolbar, Button,
        },

        mixins: [NumbersMixin],

        data() {
            return {
                mounted: false,
                collectionId: this.$route.params.collectionId,
                dailyColumns: [{name: "SIECI"}],
                finalColumns: [{name: "PRODUKTY"}],
                organisations: [],
                organisationNames: [],
                storeChains: [],
                productCategories: [],
                dailyData: [],
                finalData: [],
                datatableDailyData: [],
                datatableDailyDataFrozen: [],
                datatableFinalData: [],
                datatableFinalDataFrozen: [],
                headerStyleString: "background: #D3D3D3; font-weight: bold;",
                scrollHeightString: "800px",
                collectionProbablyClosed: true,
            };
        },

        mounted() {
            const collectionIdFromStorage = localStorage.getItem("currentCollectionId");
            this.collectionProbablyClosed = collectionIdFromStorage === null
                || this.collectionId !== collectionIdFromStorage;
            getStatistics({collectionId: this.collectionId}).then((res) => {
                this.dailyData = res.data.dailyStatistics;
                this.finalData = res.data.finalStatistics;
                this.organisations = res.data.organisationsNames; // name, dailyReportLocked, finalReportLocked
                this.organisationNames = res.data.organisationsNames.map((org) => org.name);
                this.storeChains = res.data.storeChainsNames;
                this.productCategories = res.data.productCategories.map((pc) => pc.productCategoryName);
                this.calculateColumns();
                this.mounted = true;
            });
        },

        methods: {
            calculateColumns() {
                const organisations = this.organisations.map((o) => {
                    o.name = o.name.replace("Bank Żywności", "BŻ");
                    return o;
                });
                this.dailyColumns.push(...organisations);
                this.finalColumns.push(...organisations);
                this.dailyColumns.splice(1, 0, {name: "Suma końcowa"});
                this.finalColumns.splice(1, 0, {name: "Suma końcowa"});
                this.calculateData(this.organisationNames, this.storeChains,
                                   "storeChainName", this.dailyData,
                                   this.datatableDailyData, this.datatableDailyDataFrozen);
                this.calculateData(this.organisationNames, this.productCategories,
                                   "productCategoryName", this.finalData,
                                   this.datatableFinalData, this.datatableFinalDataFrozen);
            },

            calculateData(organisations, rowNames, rowName, insideData, outputData, frozenData) {
                const organisationLength = organisations.length;
                const sumsOfOrganisations = {
                    0: "Suma końcowa",
                };
                let sumOfAll = 0;
                for (let i = 0; i < rowNames.length; i += 1) {
                    let sum = 0;
                    const rowObject = {};
                    const row = rowNames[i];
                    rowObject[0] = row;
                    // rowObject[1] is sum
                    for (let j = 0; j < organisationLength; j += 1) {
                        const filteredData = insideData
                            .filter((data) => data.organisationName === organisations[j]
                                && data[rowName] === row);
                        // if we found only one organisation with row, and it collected more than 0
                        if (filteredData && filteredData.length === 1
                            && filteredData[0].collectedAmount && filteredData[0].collectedAmount !== 0) {
                            rowObject[j + 2] = this.formatNumber(filteredData[0].collectedAmount);
                            sum += filteredData[0].collectedAmount;
                            if (!sumsOfOrganisations[j + 2]) {
                                sumsOfOrganisations[j + 2] = 0;
                            }
                            sumsOfOrganisations[j + 2] += filteredData[0].collectedAmount;
                        // collected 0 or no row
                        } else {
                            rowObject[j + 2] = "";
                            if (!sumsOfOrganisations[j + 2]) {
                                sumsOfOrganisations[j + 2] = 0;
                            }
                        }
                    }
                    rowObject[1] = this.formatNumber(sum);
                    outputData.push(rowObject);
                }
                Object.keys(sumsOfOrganisations)
                    .forEach((key) => {
                        if (key > 1) {
                            sumOfAll += sumsOfOrganisations[key];
                            sumsOfOrganisations[key] = this.formatNumber(sumsOfOrganisations[key]);
                        }
                    });
                sumsOfOrganisations[1] = this.formatNumber(sumOfAll);
                frozenData.push(sumsOfOrganisations);
            },

            getBoldedValue(slotProps, index) {
                return `<b>${slotProps.data[index]}</b>`;
            },

            async exportStatistics() {
                const url = exportStatisticsUrl().replace("{collectionId}", this.collectionId);
                const currentDate = DateUtils.timestampToReadableDate(new Date(), true);
                await FileUtils.downloadFileUsingGET(`statystyki_${currentDate}.xlsx`, url);
            },

            isColumnFrozen(index) {
                return index === 0 || index === 1;
            },
        },
    };
</script>

<style scoped lang="less">
.green-tick {
    color: green;
    font-weight: bold;
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even)) {
    background: #FFB36849;
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) > td:nth-child(1)) {
    background: rgb(252, 224, 197);
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) > td:nth-child(2)) {
    background: rgb(252, 224, 197);
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd)) {
    background: rgba(255, 179, 104, 0.72);
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) > td:nth-child(1)) {
    background: rgba(255, 179, 104, 1);
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(odd) > td:nth-child(2)) {
    background: rgba(255, 179, 104, 1);
}

//ZABLOKOWANY RZĄD
::v-deep(.p-datatable.p-datatable-striped .p-datatable-frozen-tbody > tr:nth-child(odd)) {
    background: rgb(252, 224, 197);
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-frozen-tbody > tr:nth-child(odd) > td:nth-child(1)) {
    background: rgb(252, 224, 197);
}

::v-deep(.p-datatable.p-datatable-striped .p-datatable-frozen-tbody > tr:nth-child(odd) > td:nth-child(2)) {
    background: rgb(252, 224, 197);
}
</style>
