import {createWebHistory, createRouter} from "vue-router";
import OrganisationListView from "@/views/organisation/OrganisationListView";
import OrganisationFormView from "@/views/organisation/OrganisationFormView";
import FileListView from "@/views/file/FileListView";
import FileFormView from "@/views/file/FileFormView";
import RegisterView from "@/views/RegisterView";
import ProductCategoryListView from "@/views/productCategory/ProductCategoryListView";
import ProductCategoryFormView from "@/views/productCategory/ProductCategoryFormView";
import StoreChainListView from "@/views/storeChain/StoreChainListView";
import StoreChainFormView from "@/views/storeChain/StoreChainFormView";
import PostCategoryListView from "@/views/postCategory/PostCategoryListView";
import PostCategoryFormView from "@/views/postCategory/PostCategoryFormView";
import LandingView from "@/views/LandingView";
import MessageThreadListView from "@/views/messageThread/MessageThreadListView";
import PostListView from "@/views/post/PostListView";
import PostFormView from "@/views/post/PostFormView";
import LayoutPostList from "@/views/layout/LayoutPostList";
import PostView from "@/views/post/PostView";
import PostEditListView from "@/views/post/PostEditListView";
import EmailVerifiedView from "@/views/EmailVerifyView";
import DonationReceivalEventListView
    from "@/views/donationReceivalEvent/DonationReceivalEventListView";
import DonationReceivalEventFormView
    from "@/views/donationReceivalEvent/DonationReceivalEventFormView";
import ChatView from "@/views/ChatView";
import CommissionListView from "@/views/commission/CommissionListView";
import CommissionFormView from "@/views/commission/CommissionFormView";
import OfferListView from "@/views/offer/OfferListView";
import OfferFormView from "@/views/offer/OfferFormView";
import MailConfigurationFormView from "@/views/mailConfiguration/MailConfigurationFormView";
import MapView from "@/views/MapView";
import ChangePasswordView from "@/views/user/ChangePasswordView";
import OplAuthorizationListView from "@/views/oplAuthorization/OplAuthorizationListView";
import OplAuthorizationFormView from "@/views/oplAuthorization/OplAuthorizationFormView";
import CollectionFormView from "@/views/collection/CollectionFormView";
import TransferStoreOwnershipView from "@/views/TransferStoreOwnershipView";
import AnswerCollectionInvitationView from "@/views/AnswerCollectionInvitationView";
import CollectionListView from "@/views/collection/CollectionListView";
import CollectionView from "@/views/collection/CollectionView";
import CollectionReportView from "@/views/collection/components/CollectionReportView";
import StoreStatusHistoryView from "@/views/store/StoreStatusHistoryView";
import MenuTemplate from "@/views/layout/MenuTemplate";
import UserCreateView from "@/views/user/UserCreateView";
import CollectionOrganisationInviteListView
    from "@/views/collection/CollectionOrganisationInviteListView";
import DonationStoreListView from "@/views/store/donation/DonationStoreListView";
import DonationStoreFormView from "@/views/store/donation/DonationStoreFormView";
import CollectionStoreFormView from "@/views/store/collection/CollectionStoreFormView";
import CollectionStoreListView from "@/views/store/collection/CollectionStoreListView";
import CollectionStatisticsView from "@/views/collectionStatistics/CollectionStatisticsView";
import CollectionProductCategoryFormView
    from "@/views/productCategory/CollectionProductCategoryFormView";
import LayoutDashboard from "@/views/layout/LayoutDashboard";
import LoginView from "@/views/LoginView";
import UserListView from "@/views/user/UserListView";
import UserEditView from "@/views/user/UserEditView";
import LayoutSimple from "@/views/layout/LayoutSimple";
import ForbiddenView from "@/views/ForbiddenView";
import NotFoundView from "@/views/NotFoundView";
import ErrorView from "@/views/ErrorView";
import RemindPasswordView from "@/views/RemindPasswordView";
import ResetPassword from "@/views/ResetPasswordView";
import {SystemRole} from "@/utils/SystemRole";

const routes = [
    {
        path: "/home",
        name: "home",
        component: LayoutDashboard,
        meta: {
            requiresAuth: true,
            title: "Strona startowa - Rampa",
        },
        children: [
            {
                path: "/map",
                name: "map",
                component: MapView,
                meta: {title: "Mapa - Rampa"},
            },
            {
                path: "/donation",
                name: "donation",
                component: MenuTemplate,
                children: [
                    {
                        path: "store/status-history/:id",
                        name: "donationStoreStatusHistory",
                        component: StoreStatusHistoryView,
                        meta: {title: "Historia zmian statusów sklepu - Rampa"},
                        props: {
                            collectionStatus: false,
                        },
                    },
                    {
                        path: "store/list",
                        name: "donationStoreList",
                        component: DonationStoreListView,
                        meta: {title: "Darowizny, lista sklepów - Rampa"},
                    },
                    {
                        path: "store/create",
                        name: "donationStoreCreate",
                        component: DonationStoreFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Darowizny - dodawanie sklepu - Rampa",
                        },
                        props: {
                            editing: false,
                        },
                    },
                    {
                        path: "store/edit/:id",
                        name: "donationStoreEdit",
                        component: DonationStoreFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Darowizny - edycja sklepu - Rampa",
                        },
                        props: {
                            editing: true,
                        },
                    },
                    {
                        path: "donation-receival-event/list",
                        name: "donationReceivalEventListView",
                        component: DonationReceivalEventListView,
                        meta: {title: "Harmonogram odbiorów darowizn - Rampa"},
                    },
                    {
                        path: "donation-receival-event/create",
                        name: "donationReceivalEventCreate",
                        component: DonationReceivalEventFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Dane odbiorów darowizn - Rampa",
                        },
                    },
                    {
                        path: "donation-receival-event/edit/:id",
                        name: "donationReceivalEventEdit",
                        component: DonationReceivalEventFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Dane odbiorów darowizn - Rampa",
                        },
                    },
                ],
            },
            {
                path: "/collection",
                name: "collection",
                component: MenuTemplate,
                children: [
                    {
                        path: "statistics/:collectionId",
                        name: "collectionStatistics",
                        component: CollectionStatisticsView,
                        meta: {title: "Statystyki zbiórki - Rampa"},
                    },
                    {
                        path: "store/status-history/:id",
                        name: "collectionStoreStatusHistory",
                        component: StoreStatusHistoryView,
                        meta: {title: "Historia zmian statusów sklepu - Rampa"},
                        props: {
                            collectionStatus: true,
                        },
                    },
                    {
                        path: "store/list",
                        name: "collectionStoreList",
                        component: CollectionStoreListView,
                        meta: {title: "Zbiórki - lista wszystkich sklepów - Rampa"},
                    },
                    {
                        path: "store/create",
                        name: "collectionStoreCreate",
                        component: CollectionStoreFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Zbiórki - dodawanie sklepu - Rampa",
                        },
                        props: {
                            editing: false,
                        },
                    },
                    {
                        path: "store/edit/:id",
                        name: "collectionStoreEdit",
                        component: CollectionStoreFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Zbiórki - edycja sklepu - Rampa",
                        },
                        props: {
                            editing: true,
                        },
                    },
                    {
                        path: "product-category/:collectionId",
                        name: "collectionProductCategories",
                        component: CollectionProductCategoryFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Edycja kategorii produktów w zbiórce - Rampa",
                        },
                    },
                    {
                        path: "product-category/list",
                        name: "productCategoryList",
                        component: ProductCategoryListView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Lista kategorii produktów - Rampa",
                        },
                    },
                    {
                        path: "product-category/create",
                        name: "productCategoryCreate",
                        component: ProductCategoryFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Tworzenie kategorii produktów - Rampa",
                        },
                    },
                    {
                        path: "product-category/edit/:id",
                        name: "productCategoryEdit",
                        component: ProductCategoryFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Edycja kategorii produktów - Rampa",
                        },
                    },
                    {
                        path: "event/list",
                        name: "collectionList",
                        component: CollectionListView,
                        meta: {title: "Lista zbiórek - Rampa"},
                    },
                    {
                        path: "event/create",
                        name: "collectionCreate",
                        component: CollectionFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Dane zbiórki - Rampa",
                        },
                        props: {
                            editing: false,
                        },
                    },
                    {
                        path: "event/edit/:id",
                        name: "collectionEdit",
                        component: CollectionFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Edycja zbiórki - Rampa",
                        },
                        props: {
                            editing: true,
                        },
                    },
                    {
                        path: "event/:id",
                        name: "collectionHub",
                        component: CollectionView,
                        meta: {
                            title: "Zbiórka - Rampa",
                        },
                    },
                    {
                        path: "event/invite-organisations/:id",
                        name: "collectionOrganisationsInviteList",
                        component: CollectionOrganisationInviteListView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Lista organizacji partnerskich w zbiórce - Rampa",
                        },
                    },
                    {
                        path: "event/answer-invite/:id",
                        name: "collectionInvitationAnswer",
                        component: AnswerCollectionInvitationView,
                        meta: {title: "Odpowiedź na zaproszenie do zbiórki - Rampa"},
                    },
                    {
                        path: "event/transfer-store-ownership/:token",
                        name: "collectionStoreTransferOwnership",
                        component: TransferStoreOwnershipView,
                        meta: {
                            title: "Przekazanie sklepu organizacji - Rampa",
                        },
                    },
                    {
                        path: "event/report/:collectionId",
                        name: "collectionReport",
                        component: CollectionReportView,
                        meta: {title: "Raport zbiórki - Rampa"},
                    },
                    {
                        path: "event/report/:collectionId/:organisationId",
                        name: "collectionReportForOrganisation",
                        component: CollectionReportView,
                        meta: {title: "Raport zbiórki - Rampa"},
                    },
                ],
            },
            {
                path: "/management",
                name: "management",
                component: MenuTemplate,
                children: [
                    {
                        path: "user/list",
                        name: "userList",
                        component: UserListView,
                        meta: {title: "Lista użytkowników organizacji partnerskich - Rampa"},
                    },
                    {
                        path: "user/create",
                        name: "userCreate",
                        component: UserCreateView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Tworzenie użytkownika - Rampa",
                        },
                    },
                    {
                        path: "user/edit/:id",
                        name: "userEdit",
                        component: UserEditView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Edycja użytkownika - Rampa",
                        },
                        props: {
                            editingOwn: false,
                        },
                    },
                    {
                        path: "user/edit/own",
                        name: "userEditOwn",
                        component: UserEditView,
                        meta: {title: "Dane użytkownika - Rampa"},
                        props: {
                            editingOwn: true,
                        },
                    },
                    {
                        path: "user/change-password",
                        name: "userChangePassword",
                        component: ChangePasswordView,
                        meta: {title: "Zmiana hasła - Rampa"},
                    },
                    {
                        path: "organisation/list",
                        name: "organisationList",
                        component: OrganisationListView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Lista organizacji partnerskich - Rampa",
                        },
                    },
                    {
                        path: "organisation/create",
                        name: "organisationCreate",
                        component: OrganisationFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Tworzenie organizacji - Rampa",
                        },
                        props: {
                            editing: false,
                            editingOwn: false,
                        },
                    },
                    {
                        path: "organisation/edit/:id",
                        name: "organisationEdit",
                        component: OrganisationFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Edycja organizacji - Rampa",
                        },
                        props: {
                            editing: true,
                            editingOwn: false,
                        },
                    },
                    {
                        path: "organisation/edit/own",
                        name: "organisationEditOwn",
                        component: OrganisationFormView,
                        meta: {
                            title: "Edycja własnej organizacji - Rampa",
                        },
                        props: {
                            editing: true,
                            editingOwn: true,
                        },
                    },
                    {
                        path: "store-chain/list",
                        name: "storeChainList",
                        component: StoreChainListView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Lista sieci sklepów - Rampa",
                        },
                    },
                    {
                        path: "store-chain/create",
                        name: "storeChainCreate",
                        component: StoreChainFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Tworzenie sieci sklepów - Rampa",
                        },
                    },
                    {
                        path: "store-chain/edit/:id",
                        name: "storeChainEdit",
                        component: StoreChainFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Edycja sieci sklepów - Rampa",
                        },
                    },
                    {
                        path: "mail-configuration",
                        name: "mailConfiguration",
                        component: MailConfigurationFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Konfiguracja e-maila - Rampa",
                        },
                    },
                ],
            },
            {
                path: "/blog",
                name: "blog",
                component: MenuTemplate,
                children: [
                    {
                        path: "post-category/list",
                        name: "postCategoryList",
                        component: PostCategoryListView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Lista kategorii wpisów - Rampa",
                        },
                    },
                    {
                        path: "post-category/create",
                        name: "postCategoryCreate",
                        component: PostCategoryFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Tworzenie kategorii wpisów - Rampa",
                        },
                    },
                    {
                        path: "post-category/edit/:id",
                        name: "postCategoryEdit",
                        component: PostCategoryFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR],
                            title: "Edycja kategorii wpisów - Rampa",
                        },
                    },
                    {
                        path: "post/create",
                        name: "postCreate",
                        component: PostFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Blog - nowy wpis - Rampa",
                        },
                    },
                    {
                        path: "post/edit/:id",
                        name: "postEdit",
                        component: PostFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Edycja wpisu - Rampa",
                        },
                    },
                    {
                        path: "posts/edit-list",
                        name: "postEditList",
                        component: PostEditListView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Lista edycji wpisów - Rampa",
                        },
                    },
                ],
            },
            {
                path: "/exchange",
                name: "exchange",
                component: MenuTemplate,
                children: [
                    {
                        path: "commission/list",
                        name: "commissionList",
                        component: CommissionListView,
                        meta: {title: "Lista ofert darowizny - Rampa"},
                    },
                    {
                        path: "commission/create",
                        name: "commissionCreate",
                        component: CommissionFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Dodanie oferty darowizny - Rampa",
                        },
                    },
                    {
                        path: "commission/edit/:id",
                        name: "commissionEdit",
                        component: CommissionFormView,
                        meta: {
                            roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                            title: "Edycja oferty darowizny - Rampa",
                        },
                    },
                    {
                        path: "offer/list",
                        name: "offerList",
                        component: OfferListView,
                        meta: {title: "Lista deklaracji odbioru darowizny - Rampa"},
                    },
                    {
                        path: "offer/create/:commissionId",
                        name: "offerCreate",
                        component: OfferFormView,
                        meta: {title: "Tworzenie deklaracji odbioru darowizny - Rampa"},
                    },
                    {
                        path: "offer/edit/:id",
                        name: "offerEdit",
                        component: OfferFormView,
                        meta: {title: "Edycja deklaracji odbioru darowizny - Rampa"},
                    },
                ],
            },
            {
                path: "/file/list",
                name: "fileList",
                component: FileListView,
                meta: {title: "Lista plików - Rampa"},
            },
            {
                path: "/file/create",
                name: "fileCreate",
                component: FileFormView,
                meta: {
                    roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                    title: "Dodawanie pliku - Rampa",
                },
                props: {
                    editing: false,
                },
            },
            {
                path: "/file/edit/:id",
                name: "fileEdit",
                component: FileFormView,
                meta: {
                    roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                    title: "Edycja pliku - Rampa",
                },
                props: {
                    editing: true,
                },
            },
            {
                path: "/chat",
                name: "chat",
                component: MessageThreadListView,
                meta: {title: "Chat - Rampa"},
            },
            {
                path: "/chat/:threadId",
                name: "messages",
                component: ChatView,
                meta: {title: "Wątek chatu - Rampa"},
            },
            {
                path: "/opl-authorization",
                name: "oplAuthorizationList",
                component: OplAuthorizationListView,
                meta: {
                    roles: [SystemRole.FPBZ_COORDINATOR, SystemRole.BZ_COORDINATOR],
                    title: "Lista upoważnień - Rampa",
                },
            },
            {
                path: "/opl-authorization/create",
                name: "oplAuthorizationCreate",
                component: OplAuthorizationFormView,
                meta: {
                    roles: [SystemRole.BZ_COORDINATOR],
                    title: "Dodaj upoważnienie - Rampa",
                },
            },
            {
                path: "/opl-authorization/create",
                name: "oplAuthorizationExistingCreate",
                component: OplAuthorizationFormView,
                meta: {
                    roles: [SystemRole.BZ_COORDINATOR],
                    title: "Dodaj istniejące upoważnienie - Rampa",
                },
                props: {
                    existing: true,
                },
            },
            {
                path: "/opl-authorization/edit/:id",
                name: "oplAuthorizationEdit",
                component: OplAuthorizationFormView,
                meta: {
                    roles: [SystemRole.BZ_COORDINATOR],
                    title: "Edycja danych upoważnienia - Rampa",
                },
            },
        ],
    },
    {
        name: "layoutSimple",
        component: LayoutSimple,
        path: "/",
        children: [
            {
                path: "/login",
                name: "login",
                component: LoginView,
                meta: {title: "Logowanie - Rampa"},
            },
            {
                path: "/register",
                name: "register",
                component: RegisterView,
                meta: {title: "Rejestracja - Rampa"},
            },
            {
                path: "/remind",
                name: "remindPassword",
                component: RemindPasswordView,
                meta: {title: "Przypomnienie hasła - Rampa"},
            },
            {
                path: "/reset-password",
                name: "resetPassword",
                component: ResetPassword,
                meta: {title: "Zmiana hasła - Rampa"},
            },
            {
                path: "/email-verify",
                name: "emailVerify",
                component: EmailVerifiedView,
                meta: {title: "Weryfikacja e-maila - Rampa"},
            },
            {
                path: "/forbidden",
                name: "forbidden",
                component: ForbiddenView,
                meta: {title: "Dostęp do strony zabroniony - Rampa"},
            },
            {
                path: "/error",
                name: "error",
                component: ErrorView,
                meta: {title: "Wystąpił błąd - Rampa"},
            },
            {
                path: "/:pathMatch(.*)*",
                name: "notFound",
                component: NotFoundView,
                meta: {title: "Wystąpił błąd - Rampa"},
            },
            {
                path: "/",
                name: "landingView",
                component: LandingView,
                redirect: "/login",
                meta: {title: "Logowanie - Rampa"},
            },
        ],
    },
    {
        path: "/posts",
        name: "postListView",
        component: LayoutPostList,
        meta: {requiresAuth: true},
        children: [
            {
                path: "list",
                name: "postList",
                component: PostListView,
                meta: {title: "Lista wpisów - Rampa"},
            },
            {
                path: ":id",
                name: "postDetails",
                component: PostView,
                meta: {title: "Wpis - Rampa"},
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
