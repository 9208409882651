<template>
    <div v-if="fileLoading" class="progress-spinner">
        <ProgressSpinner aria-label="Ładowanie"/>
    </div>
    <div v-if="loaded" class="collection-hub">
        <h1 class="page-header"><i class="pi pi-heart"></i> {{ collection.name }}</h1>
        <div :key="collection.startedByFpbz">
            <div class="page-header">
                <div style="display: flex;">
                    <p v-if="!collection.invitationsStartedByFpbz" class="collection-date-p">
                        Początek okresu zapraszania sklepów:
                        {{ collection.invitationsStartDate }}
                    </p>
                    <p v-if="!collection.startedByFpbz" class="collection-date-p">
                        Początek zbiórki: {{ collection.startDate }}
                    </p>
                    <p class="collection-date-p">
                        Koniec zbiórki: <span
                            class="collection-enddate">{{ collection.endDate }}</span>
                    </p>
                    <p class="collection-date-p">
                        Liczba dni: <span>{{ collection.numberOfDays }}</span>
                    </p>
                </div>

                <Toolbar class="p-mt-0">
                    <template v-if="!Role.isOPLCoordinator() && collection.invitationsStartedByFpbz"
                              v-slot:start>
                        <Button class="button-link" type="button" role="link"
                                icon="fas fa-handshake"
                                :label="organisationsTakingPartInCollectionTableLabel"
                                @click="$router.push({
                                    name: 'collectionOrganisationsInviteList',
                                    params: {id: $route.params.id},
                                })"/>
                    </template>
                    <template v-if="Role.isFPBZCoordinator()" v-slot:end>
                        <Button type="button" icon="pi pi-exclamation-triangle"
                                v-if="!collection.invitationsStartedByFpbz && !isCollectionClosed"
                                label="Rozpocznij okres zapraszania sklepów"
                                @click="onStartInvitations"/>
                        <Button class="button-link" type="button" role="link"
                                :icon="productCategoriesViewIcon"
                                :label="productCategoriesViewLabel"
                                v-if="collection.startedByFpbz"
                                @click="$router.push({
                                    name: 'collectionProductCategories',
                                    params: {
                                        'collectionId': $route.params.id,
                                        'isCollectionClosed': isCollectionClosed
                                    }})"/>
                        <Button v-if="!isCollectionClosed"
                                class="button-link"
                                type="button"
                                role="link"
                                icon="pi pi-pencil"
                                label="Edytuj"
                                @click="$router.push({name: 'collectionEdit', params: {'id': $route.params.id}})"/>
                    </template>
                </Toolbar>
            </div>

            <div v-if="!Role.isFPBZCoordinator()">
                <div v-if="!collection.invitationsStartedByFpbz">
                    <OrganisationListView v-if="Role.isBZCoordinator()"
                                          :collection-id="collection.id" inviting-organisations/>
                </div>
                <div v-else-if="!collection.startedByFpbz">
                    <CollectionStoreListView :collection-id="collection.id" inviting-stores/>
                </div>
                <div v-else>
                    <CollectionDailyReportListView v-if="collection" :collection="collection"/>
                </div>
            </div>

            <div v-if="Role.isFPBZCoordinator()">
                <div v-if="!collection.invitationsStartedByFpbz">
                    <CollectionOrganisationInviteListView :back-button="false"/>
                </div>
                <div v-else-if="!collection.startedByFpbz">
                    <Toolbar class="p-mt-0">
                        <template v-slot:start>
                            <CustomFileUpload label="Zmień statusy sklepów z pliku"
                                              @change="importStoresStatusChanges($event)"/>
                        </template>
                        <template v-slot:end>
                            <Button type="button" icon="pi pi-exclamation-triangle"
                                    label="Rozpocznij zbiórkę"
                                    @click="onStartCollection"/>
                        </template>
                    </Toolbar>
                    <CollectionStoreListView ref="storeList" v-if="collection"
                                             :collection-id="collection.id" :changing-statuses="true"/>
                </div>
                <div v-else>
                    <Toolbar v-if="!isCollectionClosed" class="p-mt-4">
                        <template v-slot:end>
                            <Button type="button" icon="pi pi-exclamation-triangle"
                                    label="Zakończ zbiórkę"
                                    @click="onFinishCollection"/>
                        </template>
                    </Toolbar>
                    <CollectionDailyReportListView v-if="collection" :collection="collection"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {
        finishCollectionUsingPOST as finishCollection,
        getCollectionUsingGET as getCollection,
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
        massChangeCollectionStoreStatusesViaUploadUsingPOST as massChangeStoresStatusesViaUpload,
        startCollectionUsingPOST as startCollection,
        startInvitationsUsingPOST as startInvitations,
    } from "@/swagger/vue-api-client";
    import CustomFileUpload from "@/components/form/CustomFileUpload";
    import {SystemRole} from "@/utils/SystemRole";
    import {ExportUtils} from "@/utils/ExportUtils";
    import {FileUtils} from "@/utils/FileUtils";
    import CollectionStoreListView from "@/views/store/collection/CollectionStoreListView";
    import OrganisationListView from "@/views/organisation/OrganisationListView";
    import CollectionOrganisationInviteListView
        from "@/views/collection/CollectionOrganisationInviteListView";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CollectionDailyReportListView
        from "@/views/collectionDailyReport/CollectionDailyReportListView";
    import ProgressSpinner from "primevue/progressspinner";

    export default {
        name: "CollectionView",

        components: {
            CollectionOrganisationInviteListView,
            OrganisationListView,
            CollectionStoreListView,
            CustomFileUpload,
            CollectionDailyReportListView,
            Button,
            Toolbar,
            ProgressSpinner,
        },

        data() {
            return {
                collection: null,
                loaded: false,
                fileLoading: false,
                statusChangesFile: null,
                userIsFpbz: false,
                activeStores: [],
                Role: SystemRole,
            };
        },

        computed: {
            organisationsTakingPartInCollectionTableLabel() {
                if (!this.collection.startedByFpbz) {
                    return this.Role.isFPBZCoordinator()
                        ? "Lista organizacji zaproszonych do udziału w zbiórce"
                        : "Lista organizacji partnerskich zaproszonych do udziału w zbiórce";
                }

                return this.Role.isFPBZCoordinator()
                    ? "Lista organizacji biorących udział w zbiórce"
                    : "Lista organizacji partnerskich biorących udział w zbiórce";
            },
            isCollectionClosed() {
                return this.collection.closedByFpbz;
            },
            productCategoriesViewLabel() {
                return this.isCollectionClosed ? "Wyświetl kategorie produktów" : "Edytuj kategorie produktów";
            },
            productCategoriesViewIcon() {
                return this.isCollectionClosed ? "fas fa-gifts" : "pi pi-pencil";
            },
        },

        async mounted() {
            try {
                const loggedUserOrganisationRequest = getLoggedUserOrganisation();
                const collectionRequest = getCollection({id: this.$route.params.id});

                const responses = await Promise.all([loggedUserOrganisationRequest, collectionRequest]);

                const userOrganisationId = responses[0].data.id;
                this.userIsFpbz = responses[0].data.organisationLevel === "FPBZ";

                this.collection = responses[1].data;

                if (this.collection.closedByFpbz) {
                    this.loaded = true;
                    return;
                }

                this.activeStores = this.collection.activeStores.filter((store) => this.userIsFpbz
                    || (store.assignedBz && store.assignedBz.id === userOrganisationId));
                this.activeStores.sort(this.sortByName);
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie masz wystarczających uprawnień",
                    });
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                    return;
                }
            }

            this.loaded = true;
        },

        methods: {
            sortByName(objectA, objectB) {
                return objectA.name.toUpperCase() < objectB.name.toUpperCase() ? -1 : 1;
            },

            onStartInvitations() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno rozpocząć okres zapraszania sklepów?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        startInvitations({id: this.$route.params.id}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie rozpoczęto okres zapraszania sklepów",
                            });

                            this.collection.invitationsStartedByFpbz = true;
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Obecnie trwająca zbiórka musi zostać zakończona przed rozpoczęciem innej",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            onStartCollection() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno rozpocząć zbiórkę?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        startCollection({id: this.$route.params.id}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie rozpoczęto zbiórkę",
                            });

                            this.collection.startedByFpbz = true;
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        });
                    },
                    reject: () => {
                    },
                });
            },

            onFinishCollection() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno zakończyć zbiórkę?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        finishCollection({id: this.$route.params.id}).then(() => {
                            this.emitter.emit("collectionStop");

                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie zakończono zbiórkę",
                            });

                            this.$router.push({name: "map"});
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        });
                    },
                    reject: () => {
                    },
                });
            },

            exportXLSX() {
                ExportUtils.exportXLSX(this.getExportData(), "Sklepy", this.getExportFileName());
            },

            getExportData() {
                /* eslint-disable quote-props */
                return this.collection.activeStores.map((store) => ({
                    "Sieć sklepów": store.storeChain.name,
                    "Nazwa": store.name,
                    "Nr sklepu": store.number,
                    "Województwo": store.voivodeship,
                    "Miasto": store.city,
                    "Ulica": store.street,
                    "Nr domu": store.houseNumber,
                    "Nr mieszkania": store.apartmentNumber,
                    "Kod pocztowy": store.postalCode,
                    "Nr telefonu": store.phoneNumber,
                    "Adres e-mail": store.email,
                    "Osoba kontaktowa": store.contactPerson,
                    "Przypisany BŻ": store.assignedBz.name,
                    "Przypisana OPL": store.assignedOpl.name,
                }));
            },

            getExportFileName() {
                return "Dane sklepów biorących udział w zbiórce '" + this.collection.name + "'";
            },

            async importStoresStatusChanges(e) {
                const attachedFiles = e.target.files || e.dataTransfer.files;
                if (!attachedFiles.length) {
                    e.target.value = null;
                    return;
                }

                const [file] = attachedFiles;

                if (file.size > 15728640) { // 15728640 = 15 * 1024 * 1024
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Rozmiar pliku nie może przekraczać 15MB",
                    });
                    e.target.value = null;
                    return;
                }

                if (!/^.*\.xlsx$/.test(file.name)) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Przyjmowane są tylko pliki z rozszerzeniem .xlsx",
                    });
                    e.target.value = null;
                    return;
                }
                this.fileLoading = true;

                const fileData = await FileUtils.fileToBase64(file);
                const encodedFileDataRequest = {
                    encodedData: fileData,
                };

                massChangeStoresStatusesViaUpload({encodedFileDataRequest}).then(() => {
                    this.fileLoading = false;
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zmieniono statusy sklepów",
                    });
                    e.target.value = null;
                    this.$refs.storeList.reloadTable();
                }).catch((error) => {
                    this.fileLoading = false;
                    if (error.response && error.response.status === 409) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: error.response.data,
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                    e.target.value = null;
                });
            },
        },
    };
</script>

<style scoped lang="less">
.progress-spinner {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
}

.collection-date-p {
    margin: 2px 10px 2px 2px;
}

.collection-enddate {
    white-space: nowrap;
}

.collection-hub {

    .page-header {
        margin-bottom: 2px;
    }

}
</style>
