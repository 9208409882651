<template>
    <div>
        <h1 class="page-header"><i class="pi pi-globe"></i> Dane sieci</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveStoreChain" v-slot="{ isSubmitting }">
                <div class="p-grid storeChain-form">
                    <div class="p-col-12">
                        <CustomInputText name="name" label="Nazwa" v-model="storeChain.name" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomInputNumber name="authorizationId" label="Numer sieci"
                                           v-model="storeChain.authorizationId" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdateStoreChainUsingPOST as createOrUpdateStoreChain,
        getStoreChainUsingGET as getStoreChain,
    } from "@/swagger/vue-api-client";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "storeChainFormView",

        components: {
            CustomInputNumber,
            Button,
            Toolbar,
            CustomInputText,
            Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                storeChain: {
                    id: null,
                    name: null,
                    authorizationId: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getStoreChain({id: this.$route.params.id}).then((response) => {
                    this.storeChain = response.data;
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        if (error.response.code === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            saveStoreChain() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdateStoreChain({storeChainRequest: this.storeChain}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .storeChain-form {
        max-width: 100%;
    }
</style>
