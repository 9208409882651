/* eslint-disable */
export const SystemRole = {
    FPBZ_COORDINATOR: "FPBZ_COORDINATOR",
    BZ_COORDINATOR: "BZ_COORDINATOR",
    OPL_COORDINATOR: "OPL_COORDINATOR",

    isFPBZCoordinator() {
        return localStorage.getItem("role") === this.FPBZ_COORDINATOR;
    },

    isBZCoordinator() {
        return localStorage.getItem("role") === this.BZ_COORDINATOR;
    },

    isOPLCoordinator() {
        return localStorage.getItem("role") === this.OPL_COORDINATOR;
    },

    getUserRole() {
        return localStorage.getItem("role");
    },

};
