<template>
    <div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: "MenuTemplate",
    };
</script>

<style lang="less">
</style>
