<template>
    <div>
        <h1 class="page-header"><i class="far fa-calendar-alt"></i> Dane odbioru darowizny</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveDonationReceivalEvent" v-slot="{ isSubmitting }">
                <div class="p-grid donationReceivalEvent-form">
                    <h2 class="p-col-12 form-section-header">Ogólne</h2>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomInputText name="title" label="Nazwa"
                                         v-model="event.title"
                                         rules="required"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomAutocompleteOrganisation name="assignedOpl" label="Przypisana OPL"
                                                        v-model="event.assignedOplId"
                                                        item-label="label"
                                                        :complete-function="getSubordinateOrganisations"
                                                        organisation-level="OPL"
                                                        rules="required"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomAutocomplete name="store" label="Przypisany sklep"
                                            v-model="event.storeId"
                                            item-label="label"
                                            :complete-function="getStores"
                                            rules="required"/>
                    </div>

                    <div class="p-col-12">
                        <h2 class="form-section-header">Ustawienia cykliczności</h2>
                        <Checkbox id="eventRecurrenceCheckbox" :binary="true"
                                  v-model="event.recurring"/>
                        <label for="eventRecurrenceCheckbox"> Wydarzenie cykliczne</label>
                    </div>

                    <div v-if="event.recurring" class="p-col-12">
                        <div class="p-col-12">
                            <Checkbox if="recurringOnWeekdaysCheckbox" :binary="true"
                                      id="recurringOnWeekdaysCheckbox"
                                      v-model="event.recurringOnWeekdays"/>
                            <label for="recurringOnWeekdaysCheckbox"> W wybrane dni tygodnia</label>
                        </div>

                        <div v-if="event.recurringOnWeekdays" class="p-col-12">
                            <div class="container">
                                <div class="weekday-checkbox">
                                    <Checkbox if="mondayCheckbox" :binary="true" id="mondayCheckbox"
                                              v-model="weekdaysState[1]"
                                              @change="onWeekdayToggle(1)"/>
                                    <label for="mondayCheckbox"> Pn</label>
                                </div>
                                <div class="weekday-checkbox">
                                    <Checkbox if="tuesdayCheckbox" :binary="true"
                                              id="tuesdayCheckbox"
                                              v-model="weekdaysState[2]"
                                              @change="onWeekdayToggle(2)"/>
                                    <label for="tuesdayCheckbox"> Wt</label>
                                </div>
                                <div class="weekday-checkbox">
                                    <Checkbox if="wednesdayCheckbox" :binary="true"
                                              id="wednesdayCheckbox"
                                              v-model="weekdaysState[3]"
                                              @change="onWeekdayToggle(3)"/>
                                    <label for="wednesdayCheckbox"> Śr</label>
                                </div>
                                <div class="weekday-checkbox">
                                    <Checkbox if="thursdayCheckbox" :binary="true"
                                              id="thursdayCheckbox"
                                              v-model="weekdaysState[4]"
                                              @change="onWeekdayToggle(4)"/>
                                    <label for="thursdayCheckbox"> Czw</label>
                                </div>
                                <div class="weekday-checkbox">
                                    <Checkbox if="fridayCheckbox" :binary="true" id="fridayCheckbox"
                                              v-model="weekdaysState[5]"
                                              @change="onWeekdayToggle(5)"/>
                                    <label for="fridayCheckbox"> Pt</label>
                                </div>
                                <div class="weekday-checkbox">
                                    <Checkbox if="saturdayCheckbox" :binary="true"
                                              id="saturdayCheckbox"
                                              v-model="weekdaysState[6]"
                                              @change="onWeekdayToggle(6)"/>
                                    <label for="saturdayCheckbox"> Sb</label>
                                </div>
                                <div class="weekday-checkbox">
                                    <Checkbox if="sundayCheckbox" :binary="true" id="sundayCheckbox"
                                              v-model="weekdaysState[0]"
                                              @change="onWeekdayToggle(0)"/>
                                    <label for="sundayCheckbox"> Nd</label>
                                </div>
                            </div>
                            <div class="p-col-12">
                                <CustomInputText name="duration"
                                                 label="Długość trwania wydarzenia (H:mm)"
                                                 v-model="event.duration"
                                                 rules="required|duration"/>
                                <!-- TODO: validate it on backend -->
                            </div>
                            <div class="container interval-container p-col-12">
                                <p>Liczba powtórzeń wydarzenia:</p>
                                <CustomInputNumber name="interval" v-model="event.interval" :min="1"
                                                   rules="required"/>
                            </div>
                        </div>

                        <div v-if="!recurringOnWeekdays" class="interval-container p-col-12">
                            <div class="p-col-12">
                                <CustomSelectOne name="intervalUnitType" label="Interwał"
                                                 v-model="event.intervalUnitType"
                                                 item-value="value" item-label="label"
                                                 :items="intervalUnitTypes"
                                                 rules="required"/>
                            </div>
                            <div v-if="event.intervalUnitType"
                                 class="container interval-container p-col-12">
                                <p>Liczba powtórzeń wydarzenia:</p>
                                <CustomInputNumber name="interval" v-model="event.interval" :min="1"
                                                   rules="required"/>
                            </div>

                            <div class="p-col-12">
                                <CustomInputText name="duration"
                                                 label="Długość trwania wydarzenia (H:mm)"
                                                 v-model="event.duration"
                                                 rules="required|duration"/>
                                <!-- TODO: validate it on backend -->
                            </div>
                        </div>
                    </div>

                    <p class="p-col-12 form-section-header">Dane czasowe</p>

                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomCalendar name="startDate" :label="startDateLabel"
                                        v-model="event.startDate"
                                        rules="required|lessOrEqual:@endDate,
                                        Data rozpoczęcia nie może być późniejsza niż data zakończenia"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12"
                         v-if="!event.recurring">
                        <CustomCalendar name="endDate" :label="endDateLabel"
                                        v-model="event.endDate"
                                        rules="required"/>
                    </div>

                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check"
                                        :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Checkbox from "primevue/checkbox";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdateDonationReceivalEventUsingPOST as createOrUpdateDonationReceivalEvent,
        getDonationReceivalEventUsingGET as getDonationReceivalEvent,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        getSubordinateDonationStoresUsingGET as getStores,
    } from "@/swagger/vue-api-client";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import CustomAutocomplete from "@/components/form/inner/CustomAutocomplete";
    import CustomCalendar from "@/components/form/CustomCalendar";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "DonationReceivalEventFormView",

        components: {
            CustomSelectOne,
            CustomInputNumber,
            CustomInputText,
            CustomCalendar,
            CustomAutocomplete,
            CustomAutocompleteOrganisation,
            Button,
            Checkbox,
            Toolbar,
            Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                event: {
                    id: null,
                    title: null,
                    assignedOplId: null,
                    storeId: null,
                    recurring: false,
                    recurringOnWeekdays: false,
                    weekdays: [],
                    intervalUnitType: null,
                    interval: null,
                    startDate: null,
                    endDate: null,
                },
                weekdays: new Set(),
                intervalUnitTypes: [
                    {value: "DAY", label: "Dzień"},
                    {value: "WEEK", label: "Tydzień"},
                    {value: "MONTH", label: "Miesiąc"},
                ],
                weekdaysState: this.getClearWeekdaysState(), // used to mark checkboxes as checked/unchecked
                oplList: [],
                storeList: [],
                loaded: false,
            };
        },

        computed: {
            recurringOnWeekdays() {
                return this.event.recurringOnWeekdays;
            },

            startDateLabel() {
                if (!this.event.recurring) {
                    return "Data rozpoczęcia";
                }
                if (this.event.recurringOnWeekdays) {
                    return "Od kiedy rozpocząć wydarzenia";
                }
                return "Data pierwszego wydarzenia";
            },

            endDateLabel() {
                return !this.event.recurring ? "Data zakończenia" : "Data ostatniego wydarzenia";
            },
        },

        beforeMount() {
            const organisations = getSubordinateOrganisations({query: "", organisationLevel: "OPL"});
            const stores = getStores({query: ""});
            const event = this.$route.params.id !== undefined
                ? getDonationReceivalEvent({id: this.$route.params.id})
                : undefined;

            Promise.all([organisations, stores, event]).then((responses) => {
                this.oplList = responses[0].data;
                this.storeList = responses[1].data;
                if (this.$route.params.id !== undefined) {
                    const eventData = responses[2].data;

                    this.event = {
                        id: eventData.id,
                        title: eventData.title,
                        assignedOplId: {
                            value: eventData.assignedOpl.id,
                            label: eventData.assignedOpl.name,
                        },
                        storeId: {
                            value: eventData.store.id,
                            label: eventData.store.name,
                        },
                        recurring: eventData.recurring,
                        recurringOnWeekdays: eventData.recurringOnWeekdays,
                        weekdays: eventData.weekdays,
                        intervalUnitType: eventData.intervalUnitType,
                        interval: eventData.interval,
                        duration: eventData.duration,
                        startDate: new Date(eventData.startDate),
                        endDate: new Date(eventData.endDate),
                    };
                    this.event.weekdays.forEach((day) => {
                        this.weekdaysState[day] = true;
                    });
                    this.weekdays = new Set(this.event.weekdays);
                }

                this.loaded = true;
            }).catch((error) => {
                if (error.response && error.response.status === 403) {
                    this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się załadować danych",
                    });
                }
            });
        },

        methods: {
            onWeekdayToggle(dayNumber) {
                if (!this.weekdays.has(dayNumber)) {
                    this.weekdays.add(dayNumber);
                } else {
                    this.weekdays.delete(dayNumber);
                }
            },

            saveDonationReceivalEvent() {
                if (
                    this.event.recurring
                    && this.event.recurringOnWeekdays
                    && this.weekdays.size < 1
                ) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Należy wybrać przynajmniej jeden dzień tygodnia.",
                    });
                    return;
                }

                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        this.event.weekdays = [...this.weekdays];
                        createOrUpdateDonationReceivalEvent({donationReceivalEventRequest: this.event})
                            .then(() => {
                                ToastUtils.addToast(this, {
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Zapisano dane",
                                });
                                this.$router.back();
                            }).catch(() => {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie udało się zapisać danych",
                                });
                            });
                    },
                });
            },

            getClearWeekdaysState() {
                return Array.from({length: 7}).map(() => false);
            },

            getSubordinateOrganisations,

            getStores,
        },
    };
</script>

<style lang="less" scoped>
.form-section-header {
    text-align: left;
    font-weight: bold;
}

.weekday-checkbox {
    margin-left: 4px;
    margin-right: 4px;
}

.interval-container * {
    margin-left: 8px;
    margin-right: 8px;
}

.donationReceivalEvent-form {
    max-width: 100%;
}
</style>
