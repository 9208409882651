<template>
    <div>
        <div class="login-panel-header">
            <img src="@/assets/logo.png" alt="logo Rampa">
        </div>
        <div class="login-panel-content">
            <div class="p-grid">
                <Form @keyup.enter="register" @submit="register">
                    <div class="p-col-8 p-offset-2">
                        <h1>{{ $t('message.register.title') }}</h1>
                    </div>
                    <div class="p-col-8 p-offset-2">
                        <CustomInputText name="firstName" v-model="registerForm.firstName"
                                         :label="$t('message.register.firstName')" rules="required"/>
                        <CustomInputText name="lastName" v-model="registerForm.lastName"
                                         :label="$t('message.register.lastName')" rules="required"/>
                        <CustomInputText name="email" v-model="registerForm.email"
                                         :label="$t('message.register.email')" rules="required|email"/>
                        <CustomPassword name="newPassword" v-model="registerForm.password"
                                        :label="$t('message.register.password')"
                                        rules="required|validPassword"/>
                        <CustomPassword name="confirmPassword" v-model="registerForm.confirmPassword"
                                        :label="$t('message.register.confirmPassword')"
                                        rules="required|equalTo:@newPassword,Hasła nie są takie same"/>
                        <CustomSelectOneOrganisationLevel name="organisationLevel"
                                                          label="Poziom organizacji"
                                                          v-model="registerForm.organisationLevel"
                                                          rules="required"
                                                          :omitting-fpbz="true"
                                                          @change="registerForm.supervisorOrganisationId = undefined"/>
                        <CustomAutocompleteOrganisation v-if="registerForm.organisationLevel"
                                                        name="supervisorOrganisationId" label="Organizacja nadrzędna"
                                                        :complete-function="getOrganisations"
                                                        v-model="registerForm.supervisorOrganisationId"
                                                        :organisation-level="supervisorOrganisationLevel"
                                                        rules="required"/>
                        <CustomInputText name="organisationName"
                                         label="Nazwa organizacji"
                                         v-model="registerForm.organisationName"
                                         rules="required"/>
                        <div class="p-col-12">
                            <Checkbox id="donationPostsCheckbox" :binary="true"
                                      v-model="registerForm.followingDonationPosts"/>
                            <label
                                for="donationPostsCheckbox">{{$t('message.register.followDonationPostsCheckbox')}}
                            </label>
                        </div>
                        <div class="p-col-12">
                            <Checkbox id="collectionPostsCheckbox" :binary="true"
                                      v-model="registerForm.followingCollectionPosts"/>
                            <label
                                for="collectionPostsCheckbox">{{$t('message.register.followCollectionPostsCheckbox')}}
                            </label>
                        </div>
                    </div>
                    <div class="p-col-8 p-offset-2">
                        <Button type="submit" :label="$t('message.register.action.register')"/>
                    </div>
                    <div class="p-col-8 p-offset-2">
                        <router-link :to="{name: 'login'}">
                            {{ $t('message.register.action.backToLogin') }}
                        </router-link>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Checkbox from "primevue/checkbox";
    import {Form} from "vee-validate";
    import {
        registerUsingPOST as registerRequest,
        getOrganisationsUsingGET as getOrganisations,
    } from "@/swagger/vue-api-client";
    import CustomSelectOneOrganisationLevel from "@/components/form/CustomSelectOneOrganisationLevel";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomPassword from "../components/form/CustomPassword";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "RegisterView",
        components: {
            Button,
            Checkbox,
            CustomAutocompleteOrganisation,
            CustomInputText,
            CustomPassword,
            CustomSelectOneOrganisationLevel,
            Form,
        },

        data() {
            return {
                registerForm: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    supervisorOrganisationId: "",
                    organisationName: "",
                    organisationLevel: "",
                    followingDonationPosts: false,
                    followingCollectionPosts: false,
                    createdByFpbz: false,
                },
            };
        },

        computed: {
            supervisorOrganisationLevel() {
                if (this.registerForm.organisationLevel === "FPBZ") {
                    return this.organisationLevel;
                }
                if (this.registerForm.organisationLevel === "BZ") {
                    return "FPBZ";
                }
                return "BZ";
            },
        },

        methods: {
            register() {
                registerRequest({registrationRequest: this.registerForm})
                    .then(() => {
                        ToastUtils.addToast(this, {
                            severity: "success",
                            summary: "Sukces",
                            detail: "Dziękujemy za rejestrację."
                                + " Na twój adres e-mail wysłaliśmy link z potwierdzeniem rejestracji konta."
                                + " Konto musi zostać zaakceptowane i przypisane do konkretnej organizacji.",
                        });
                        this.$router.push({name: "login"});
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 409) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Użytkownik o podanym adresie e-mail już istnieje",
                            });
                        } else {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                            });
                        }
                    });
            },
            getOrganisations,
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/login.less";
</style>
