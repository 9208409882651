<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="owner" label="Wystawiający" v-model="internalValue.ownerName"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="product" label="Produkt" v-model="internalValue.product"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="unit" label="Jednostka" v-model="internalValue.unit"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputNumber name="currentAmountOwner" label="Aktualna ilość od"
                               v-model="internalValue.currentAmountMin"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">

            <CustomInputNumber name="currentAmountProduct" label="Aktualna ilość do"
                               v-model="internalValue.currentAmountMax"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputNumber name="initialAmountOwner" label="Wyjściowa ilość od"
                               v-model="internalValue.initialAmountMin"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputNumber name="initialAmountProduct" label="Wyjściowa ilość do"
                               v-model="internalValue.initialAmountMax"/>
        </div>
    </div>
</template>

<script>
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "CommissionSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputNumber, CustomInputText},
    };
</script>

<style lang="less" scoped>

</style>
