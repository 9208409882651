/* eslint-disable */

import Leaflet from "leaflet";

// https://github.com/pointhi/leaflet-color-markers
export const blueIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-blue.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const goldIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-gold.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const greenIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-green.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const redIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-red.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const orangeIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-orange.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const violetIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-violet.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const greyIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-grey.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const blackIcon = new Leaflet.Icon({
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/"
        + "master/img/marker-icon-2x-black.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export default {
    blueIcon,
    goldIcon,
    greenIcon,
    redIcon,
    orangeIcon,
    violetIcon,
    greyIcon,
    blackIcon,
};
