<template>
    <div class="layout-topbar">
        <div class="layout-topbar-nav">
            <div class="skip-links">
                <a href="#content" class="inner" @click="focusElement" @keyup.enter="focusElement">
                    Przejdź do treści
                </a>
            </div>
            <button id="menu-toggle-button" class="p-link layout-menu-button"
                    @click="onMenuToggle" tabindex="0">
                <span class="pi pi-bars"></span>
                <span class="visually-hidden">Pokaż/schowaj menu</span>
            </button>
            <div class="environment-description">
                {{ envDesc }}
            </div>
        </div>
        <div class="layout-topbar-icons">
            <div class="user-info" v-if="fullName">
                <router-link class="div-button" tabindex="0" :to="{name: 'userEditOwn'}">
                    <i class="pi pi-user"/>
                    <span class="p-ml-1 user-info-text">{{ fullName }}</span>
                </router-link>
                <router-link class="div-button" tabindex="0" :to="{name: 'organisationEditOwn'}">
                    <i class="fas fa-handshake" />
                    <span class="visually-hidden">Dane organizacji:</span>
                    <span class="p-ml-1 user-info-text">{{ organisationName }}</span>
                </router-link>
                <div class="div-button">
                    <HeaderAcc @clickedContrast="propagateContrast" @clickedFontSize="propagateFontSize"/>
                </div>
                <div class="div-button" role="button" tabindex="0" v-on:keyup.enter="logout" @click="logout">
                    <i class="pi pi-sign-out" />
                    <span class="p-ml-1 user-info-text">Wyloguj</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import HeaderAcc from "@/views/public/HeaderAcc";

    export default {
        components: {HeaderAcc},
        emits: ["menuToggle", "clickedContrast", "clickedFontSize"],
        data() {
            return {
                envDesc: process.env.VUE_APP_ENV_DESC,
                fullName: localStorage.getItem("fullName"),
                organisationName: localStorage.getItem("organisationName"),
            };
        },
        mounted() {
            this.emitter.on("fullNameChange", (value) => {
                localStorage.setItem("fullName", value);
                this.fullName = value;
            });

            this.emitter.on("organisationNameChange", (value) => {
                localStorage.setItem("organisationName", value);
                this.organisationName = value;
            });
        },
        methods: {
            focusElement() {
                document.getElementById("content").focus();
            },
            onMenuToggle(event) {
                this.$emit("menuToggle", event);
            },
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("fullName");
                this.$router.push({name: "login"});
            },
            propagateContrast(c) {
                this.$emit("clickedContrast", c);
            },
            propagateFontSize(f) {
                this.$emit("clickedFontSize", f);
            },
        },
    };
</script>

<style lang="less">
@import "assets/less/app-top-bar";
</style>
