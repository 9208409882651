/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * acceptOffer
 * request: acceptOfferUsingPOST
 * url: acceptOfferUsingPOSTURL
 * method: acceptOfferUsingPOST_TYPE
 * raw_url: acceptOfferUsingPOST_RAW_URL
 * @param offerRequest - offerRequest
 */
export const acceptOfferUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/accept'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['offerRequest'] !== undefined) {
    body = parameters['offerRequest']
  }
  if (parameters['offerRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: offerRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptOfferUsingPOST_RAW_URL = function() {
  return '/accept'
}
export const acceptOfferUsingPOST_TYPE = function() {
  return 'post'
}
export const acceptOfferUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/accept'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCategoryTypes
 * request: getCategoryTypesUsingGET
 * url: getCategoryTypesUsingGETURL
 * method: getCategoryTypesUsingGET_TYPE
 * raw_url: getCategoryTypesUsingGET_RAW_URL
 */
export const getCategoryTypesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/categoryTypes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCategoryTypesUsingGET_RAW_URL = function() {
  return '/categoryTypes'
}
export const getCategoryTypesUsingGET_TYPE = function() {
  return 'get'
}
export const getCategoryTypesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/categoryTypes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getChatOrganisations
 * request: getChatOrganisationsUsingGET
 * url: getChatOrganisationsUsingGETURL
 * method: getChatOrganisationsUsingGET_TYPE
 * raw_url: getChatOrganisationsUsingGET_RAW_URL
 * @param query - query
 */
export const getChatOrganisationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/chat-organisations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getChatOrganisationsUsingGET_RAW_URL = function() {
  return '/chat-organisations'
}
export const getChatOrganisationsUsingGET_TYPE = function() {
  return 'get'
}
export const getChatOrganisationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/chat-organisations'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateCollection
 * request: createOrUpdateCollectionUsingPOST
 * url: createOrUpdateCollectionUsingPOSTURL
 * method: createOrUpdateCollectionUsingPOST_TYPE
 * raw_url: createOrUpdateCollectionUsingPOST_RAW_URL
 * @param collectionRequest - collectionRequest
 */
export const createOrUpdateCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionRequest'] !== undefined) {
    body = parameters['collectionRequest']
  }
  if (parameters['collectionRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateCollectionUsingPOST_RAW_URL = function() {
  return '/collection'
}
export const createOrUpdateCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getActiveStoresInCollection
 * request: getActiveStoresInCollectionUsingGET
 * url: getActiveStoresInCollectionUsingGETURL
 * method: getActiveStoresInCollectionUsingGET_TYPE
 * raw_url: getActiveStoresInCollectionUsingGET_RAW_URL
 */
export const getActiveStoresInCollectionUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/active-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getActiveStoresInCollectionUsingGET_RAW_URL = function() {
  return '/collection/active-stores'
}
export const getActiveStoresInCollectionUsingGET_TYPE = function() {
  return 'get'
}
export const getActiveStoresInCollectionUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/active-stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * checkForActiveInvitation
 * request: checkForActiveInvitationUsingGET
 * url: checkForActiveInvitationUsingGETURL
 * method: checkForActiveInvitationUsingGET_TYPE
 * raw_url: checkForActiveInvitationUsingGET_RAW_URL
 * @param id - id
 */
export const checkForActiveInvitationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/check-for-active-invitation/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const checkForActiveInvitationUsingGET_RAW_URL = function() {
  return '/collection/check-for-active-invitation/{id}'
}
export const checkForActiveInvitationUsingGET_TYPE = function() {
  return 'get'
}
export const checkForActiveInvitationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/check-for-active-invitation/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * finishCollection
 * request: finishCollectionUsingPOST
 * url: finishCollectionUsingPOSTURL
 * method: finishCollectionUsingPOST_TYPE
 * raw_url: finishCollectionUsingPOST_RAW_URL
 * @param id - id
 */
export const finishCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/close'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const finishCollectionUsingPOST_RAW_URL = function() {
  return '/collection/close'
}
export const finishCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const finishCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/close'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRejectedStoresRequestedByUser
 * request: getRejectedStoresRequestedByUserUsingGET
 * url: getRejectedStoresRequestedByUserUsingGETURL
 * method: getRejectedStoresRequestedByUserUsingGET_TYPE
 * raw_url: getRejectedStoresRequestedByUserUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const getRejectedStoresRequestedByUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/declined-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionId'] !== undefined) {
    queryParameters['collectionId'] = parameters['collectionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRejectedStoresRequestedByUserUsingGET_RAW_URL = function() {
  return '/collection/declined-stores'
}
export const getRejectedStoresRequestedByUserUsingGET_TYPE = function() {
  return 'get'
}
export const getRejectedStoresRequestedByUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/declined-stores'
  if (parameters['collectionId'] !== undefined) {
    queryParameters['collectionId'] = parameters['collectionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCollectionStores
 * request: deleteCollectionStoresUsingPOST
 * url: deleteCollectionStoresUsingPOSTURL
 * method: deleteCollectionStoresUsingPOST_TYPE
 * raw_url: deleteCollectionStoresUsingPOST_RAW_URL
 * @param storeIdListRequest - storeIdListRequest
 */
export const deleteCollectionStoresUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/delete-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeIdListRequest'] !== undefined) {
    body = parameters['storeIdListRequest']
  }
  if (parameters['storeIdListRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeIdListRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteCollectionStoresUsingPOST_RAW_URL = function() {
  return '/collection/delete-stores'
}
export const deleteCollectionStoresUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteCollectionStoresUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/delete-stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionEditInfo
 * request: getCollectionEditInfoUsingGET
 * url: getCollectionEditInfoUsingGETURL
 * method: getCollectionEditInfoUsingGET_TYPE
 * raw_url: getCollectionEditInfoUsingGET_RAW_URL
 * @param id - id
 */
export const getCollectionEditInfoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/edit-info/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionEditInfoUsingGET_RAW_URL = function() {
  return '/collection/edit-info/{id}'
}
export const getCollectionEditInfoUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionEditInfoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/edit-info/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStoreOwnershipTransferRequest
 * request: getStoreOwnershipTransferRequestUsingGET
 * url: getStoreOwnershipTransferRequestUsingGETURL
 * method: getStoreOwnershipTransferRequestUsingGET_TYPE
 * raw_url: getStoreOwnershipTransferRequestUsingGET_RAW_URL
 * @param token - token
 */
export const getStoreOwnershipTransferRequestUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/event/{token}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{token}', `${parameters['token']}`)
  if (parameters['token'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: token'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoreOwnershipTransferRequestUsingGET_RAW_URL = function() {
  return '/collection/event/{token}'
}
export const getStoreOwnershipTransferRequestUsingGET_TYPE = function() {
  return 'get'
}
export const getStoreOwnershipTransferRequestUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/event/{token}'
  path = path.replace('{token}', `${parameters['token']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addAmountToCollectionReport
 * request: addAmountToCollectionReportUsingPOST
 * url: addAmountToCollectionReportUsingPOSTURL
 * method: addAmountToCollectionReportUsingPOST_TYPE
 * raw_url: addAmountToCollectionReportUsingPOST_RAW_URL
 * @param reportRequest - reportRequest
 */
export const addAmountToCollectionReportUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/final-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['reportRequest'] !== undefined) {
    body = parameters['reportRequest']
  }
  if (parameters['reportRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reportRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addAmountToCollectionReportUsingPOST_RAW_URL = function() {
  return '/collection/final-report'
}
export const addAmountToCollectionReportUsingPOST_TYPE = function() {
  return 'post'
}
export const addAmountToCollectionReportUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/final-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * importCollectionStores
 * request: importCollectionStoresUsingPOST
 * url: importCollectionStoresUsingPOSTURL
 * method: importCollectionStoresUsingPOST_TYPE
 * raw_url: importCollectionStoresUsingPOST_RAW_URL
 * @param importFileRequest - importFileRequest
 */
export const importCollectionStoresUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/import/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['importFileRequest'] !== undefined) {
    body = parameters['importFileRequest']
  }
  if (parameters['importFileRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: importFileRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importCollectionStoresUsingPOST_RAW_URL = function() {
  return '/collection/import/'
}
export const importCollectionStoresUsingPOST_TYPE = function() {
  return 'post'
}
export const importCollectionStoresUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/import/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * addStoresToCollection
 * request: addStoresToCollectionUsingPOST
 * url: addStoresToCollectionUsingPOSTURL
 * method: addStoresToCollectionUsingPOST_TYPE
 * raw_url: addStoresToCollectionUsingPOST_RAW_URL
 * @param collectionInvitationRequest - collectionInvitationRequest
 */
export const addStoresToCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/invite-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionInvitationRequest'] !== undefined) {
    body = parameters['collectionInvitationRequest']
  }
  if (parameters['collectionInvitationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionInvitationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const addStoresToCollectionUsingPOST_RAW_URL = function() {
  return '/collection/invite-stores'
}
export const addStoresToCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const addStoresToCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/invite-stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * acceptCollectionInvitation
 * request: acceptCollectionInvitationUsingPOST
 * url: acceptCollectionInvitationUsingPOSTURL
 * method: acceptCollectionInvitationUsingPOST_TYPE
 * raw_url: acceptCollectionInvitationUsingPOST_RAW_URL
 * @param collectionInvitationAnswerRequest - collectionInvitationAnswerRequest
 */
export const acceptCollectionInvitationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/invite/accept'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionInvitationAnswerRequest'] !== undefined) {
    body = parameters['collectionInvitationAnswerRequest']
  }
  if (parameters['collectionInvitationAnswerRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionInvitationAnswerRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptCollectionInvitationUsingPOST_RAW_URL = function() {
  return '/collection/invite/accept'
}
export const acceptCollectionInvitationUsingPOST_TYPE = function() {
  return 'post'
}
export const acceptCollectionInvitationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/invite/accept'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * rejectCollectionInvitation
 * request: rejectCollectionInvitationUsingPOST
 * url: rejectCollectionInvitationUsingPOSTURL
 * method: rejectCollectionInvitationUsingPOST_TYPE
 * raw_url: rejectCollectionInvitationUsingPOST_RAW_URL
 * @param collectionInvitationAnswerRequest - collectionInvitationAnswerRequest
 */
export const rejectCollectionInvitationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/invite/reject'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionInvitationAnswerRequest'] !== undefined) {
    body = parameters['collectionInvitationAnswerRequest']
  }
  if (parameters['collectionInvitationAnswerRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionInvitationAnswerRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectCollectionInvitationUsingPOST_RAW_URL = function() {
  return '/collection/invite/reject'
}
export const rejectCollectionInvitationUsingPOST_TYPE = function() {
  return 'post'
}
export const rejectCollectionInvitationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/invite/reject'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionName
 * request: getCollectionNameUsingGET
 * url: getCollectionNameUsingGETURL
 * method: getCollectionNameUsingGET_TYPE
 * raw_url: getCollectionNameUsingGET_RAW_URL
 * @param id - id
 */
export const getCollectionNameUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/name/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionNameUsingGET_RAW_URL = function() {
  return '/collection/name/{id}'
}
export const getCollectionNameUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionNameUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/name/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPlannedCollectionId
 * request: getPlannedCollectionIdUsingGET
 * url: getPlannedCollectionIdUsingGETURL
 * method: getPlannedCollectionIdUsingGET_TYPE
 * raw_url: getPlannedCollectionIdUsingGET_RAW_URL
 */
export const getPlannedCollectionIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/planned/id'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPlannedCollectionIdUsingGET_RAW_URL = function() {
  return '/collection/planned/id'
}
export const getPlannedCollectionIdUsingGET_TYPE = function() {
  return 'get'
}
export const getPlannedCollectionIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/planned/id'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * removeStoresFromCollection
 * request: removeStoresFromCollectionUsingPOST
 * url: removeStoresFromCollectionUsingPOSTURL
 * method: removeStoresFromCollectionUsingPOST_TYPE
 * raw_url: removeStoresFromCollectionUsingPOST_RAW_URL
 * @param collectionRemoveStoresRequest - collectionRemoveStoresRequest
 */
export const removeStoresFromCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/remove-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionRemoveStoresRequest'] !== undefined) {
    body = parameters['collectionRemoveStoresRequest']
  }
  if (parameters['collectionRemoveStoresRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionRemoveStoresRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const removeStoresFromCollectionUsingPOST_RAW_URL = function() {
  return '/collection/remove-stores'
}
export const removeStoresFromCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const removeStoresFromCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/remove-stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStoresRequestedByUser
 * request: getStoresRequestedByUserUsingGET
 * url: getStoresRequestedByUserUsingGETURL
 * method: getStoresRequestedByUserUsingGET_TYPE
 * raw_url: getStoresRequestedByUserUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const getStoresRequestedByUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/requested-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionId'] !== undefined) {
    queryParameters['collectionId'] = parameters['collectionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoresRequestedByUserUsingGET_RAW_URL = function() {
  return '/collection/requested-stores'
}
export const getStoresRequestedByUserUsingGET_TYPE = function() {
  return 'get'
}
export const getStoresRequestedByUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/requested-stores'
  if (parameters['collectionId'] !== undefined) {
    queryParameters['collectionId'] = parameters['collectionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCollectionStore
 * request: searchCollectionStoreUsingPOST
 * url: searchCollectionStoreUsingPOSTURL
 * method: searchCollectionStoreUsingPOST_TYPE
 * raw_url: searchCollectionStoreUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCollectionStoreUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/search/store'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCollectionStoreUsingPOST_RAW_URL = function() {
  return '/collection/search/store'
}
export const searchCollectionStoreUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCollectionStoreUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/search/store'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCollectionStoreCount
 * request: searchCollectionStoreCountUsingPOST
 * url: searchCollectionStoreCountUsingPOSTURL
 * method: searchCollectionStoreCountUsingPOST_TYPE
 * raw_url: searchCollectionStoreCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCollectionStoreCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/search/store/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCollectionStoreCountUsingPOST_RAW_URL = function() {
  return '/collection/search/store/count'
}
export const searchCollectionStoreCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCollectionStoreCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/search/store/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSimplifiedCollection
 * request: getSimplifiedCollectionUsingGET
 * url: getSimplifiedCollectionUsingGETURL
 * method: getSimplifiedCollectionUsingGET_TYPE
 * raw_url: getSimplifiedCollectionUsingGET_RAW_URL
 * @param id - id
 */
export const getSimplifiedCollectionUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/simplified/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSimplifiedCollectionUsingGET_RAW_URL = function() {
  return '/collection/simplified/{id}'
}
export const getSimplifiedCollectionUsingGET_TYPE = function() {
  return 'get'
}
export const getSimplifiedCollectionUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/simplified/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * startCollection
 * request: startCollectionUsingPOST
 * url: startCollectionUsingPOSTURL
 * method: startCollectionUsingPOST_TYPE
 * raw_url: startCollectionUsingPOST_RAW_URL
 * @param id - id
 */
export const startCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/start'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const startCollectionUsingPOST_RAW_URL = function() {
  return '/collection/start'
}
export const startCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const startCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/start'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * startInvitations
 * request: startInvitationsUsingPOST
 * url: startInvitationsUsingPOSTURL
 * method: startInvitationsUsingPOST_TYPE
 * raw_url: startInvitationsUsingPOST_RAW_URL
 * @param id - id
 */
export const startInvitationsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/start-invitations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const startInvitationsUsingPOST_RAW_URL = function() {
  return '/collection/start-invitations'
}
export const startInvitationsUsingPOST_TYPE = function() {
  return 'post'
}
export const startInvitationsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/start-invitations'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateCollectionStore
 * request: createOrUpdateCollectionStoreUsingPOST
 * url: createOrUpdateCollectionStoreUsingPOSTURL
 * method: createOrUpdateCollectionStoreUsingPOST_TYPE
 * raw_url: createOrUpdateCollectionStoreUsingPOST_RAW_URL
 * @param storeRequest - storeRequest
 */
export const createOrUpdateCollectionStoreUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeRequest'] !== undefined) {
    body = parameters['storeRequest']
  }
  if (parameters['storeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateCollectionStoreUsingPOST_RAW_URL = function() {
  return '/collection/store'
}
export const createOrUpdateCollectionStoreUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateCollectionStoreUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changeCollectionStatus
 * request: changeCollectionStatusUsingPOST
 * url: changeCollectionStatusUsingPOSTURL
 * method: changeCollectionStatusUsingPOST_TYPE
 * raw_url: changeCollectionStatusUsingPOST_RAW_URL
 * @param storeCollectionStatusChangeRequest - storeCollectionStatusChangeRequest
 */
export const changeCollectionStatusUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store/change-status/collection'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeCollectionStatusChangeRequest'] !== undefined) {
    body = parameters['storeCollectionStatusChangeRequest']
  }
  if (parameters['storeCollectionStatusChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeCollectionStatusChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changeCollectionStatusUsingPOST_RAW_URL = function() {
  return '/collection/store/change-status/collection'
}
export const changeCollectionStatusUsingPOST_TYPE = function() {
  return 'post'
}
export const changeCollectionStatusUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store/change-status/collection'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * massChangeCollectionStoreStatusesViaUpload
 * request: massChangeCollectionStoreStatusesViaUploadUsingPOST
 * url: massChangeCollectionStoreStatusesViaUploadUsingPOSTURL
 * method: massChangeCollectionStoreStatusesViaUploadUsingPOST_TYPE
 * raw_url: massChangeCollectionStoreStatusesViaUploadUsingPOST_RAW_URL
 * @param encodedFileDataRequest - encodedFileDataRequest
 */
export const massChangeCollectionStoreStatusesViaUploadUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store/import-status-changes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['encodedFileDataRequest'] !== undefined) {
    body = parameters['encodedFileDataRequest']
  }
  if (parameters['encodedFileDataRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: encodedFileDataRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const massChangeCollectionStoreStatusesViaUploadUsingPOST_RAW_URL = function() {
  return '/collection/store/import-status-changes'
}
export const massChangeCollectionStoreStatusesViaUploadUsingPOST_TYPE = function() {
  return 'post'
}
export const massChangeCollectionStoreStatusesViaUploadUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store/import-status-changes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * massChangeCollectionStatuses
 * request: massChangeCollectionStatusesUsingPOST
 * url: massChangeCollectionStatusesUsingPOSTURL
 * method: massChangeCollectionStatusesUsingPOST_TYPE
 * raw_url: massChangeCollectionStatusesUsingPOST_RAW_URL
 * @param massCollectionStatusChangeRequest - massCollectionStatusChangeRequest
 */
export const massChangeCollectionStatusesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store/mass-collection-status-change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['massCollectionStatusChangeRequest'] !== undefined) {
    body = parameters['massCollectionStatusChangeRequest']
  }
  if (parameters['massCollectionStatusChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: massCollectionStatusChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const massChangeCollectionStatusesUsingPOST_RAW_URL = function() {
  return '/collection/store/mass-collection-status-change'
}
export const massChangeCollectionStatusesUsingPOST_TYPE = function() {
  return 'post'
}
export const massChangeCollectionStatusesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store/mass-collection-status-change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionStatusChangeHistory
 * request: getCollectionStatusChangeHistoryUsingGET
 * url: getCollectionStatusChangeHistoryUsingGETURL
 * method: getCollectionStatusChangeHistoryUsingGET_TYPE
 * raw_url: getCollectionStatusChangeHistoryUsingGET_RAW_URL
 * @param id - id
 */
export const getCollectionStatusChangeHistoryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store/status-history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionStatusChangeHistoryUsingGET_RAW_URL = function() {
  return '/collection/store/status-history'
}
export const getCollectionStatusChangeHistoryUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionStatusChangeHistoryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store/status-history'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionStore
 * request: getCollectionStoreUsingGET
 * url: getCollectionStoreUsingGETURL
 * method: getCollectionStoreUsingGET_TYPE
 * raw_url: getCollectionStoreUsingGET_RAW_URL
 * @param id - id
 */
export const getCollectionStoreUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionStoreUsingGET_RAW_URL = function() {
  return '/collection/store/{id}'
}
export const getCollectionStoreUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionStoreUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCollectionStore
 * request: deleteCollectionStoreUsingDELETE
 * url: deleteCollectionStoreUsingDELETEURL
 * method: deleteCollectionStoreUsingDELETE_TYPE
 * raw_url: deleteCollectionStoreUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteCollectionStoreUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/store/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteCollectionStoreUsingDELETE_RAW_URL = function() {
  return '/collection/store/{id}'
}
export const deleteCollectionStoreUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteCollectionStoreUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/store/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * acceptStoreOwnershipTransfer
 * request: acceptStoreOwnershipTransferUsingPOST
 * url: acceptStoreOwnershipTransferUsingPOSTURL
 * method: acceptStoreOwnershipTransferUsingPOST_TYPE
 * raw_url: acceptStoreOwnershipTransferUsingPOST_RAW_URL
 * @param storeOwnershipTransferIdRequest - storeOwnershipTransferIdRequest
 */
export const acceptStoreOwnershipTransferUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/transfer-store-ownership/accept'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeOwnershipTransferIdRequest'] !== undefined) {
    body = parameters['storeOwnershipTransferIdRequest']
  }
  if (parameters['storeOwnershipTransferIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeOwnershipTransferIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptStoreOwnershipTransferUsingPOST_RAW_URL = function() {
  return '/collection/transfer-store-ownership/accept'
}
export const acceptStoreOwnershipTransferUsingPOST_TYPE = function() {
  return 'post'
}
export const acceptStoreOwnershipTransferUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/transfer-store-ownership/accept'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * rejectStoreOwnershipTransfer
 * request: rejectStoreOwnershipTransferUsingPOST
 * url: rejectStoreOwnershipTransferUsingPOSTURL
 * method: rejectStoreOwnershipTransferUsingPOST_TYPE
 * raw_url: rejectStoreOwnershipTransferUsingPOST_RAW_URL
 * @param storeOwnershipTransferIdRequest - storeOwnershipTransferIdRequest
 */
export const rejectStoreOwnershipTransferUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/transfer-store-ownership/reject'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeOwnershipTransferIdRequest'] !== undefined) {
    body = parameters['storeOwnershipTransferIdRequest']
  }
  if (parameters['storeOwnershipTransferIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeOwnershipTransferIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectStoreOwnershipTransferUsingPOST_RAW_URL = function() {
  return '/collection/transfer-store-ownership/reject'
}
export const rejectStoreOwnershipTransferUsingPOST_TYPE = function() {
  return 'post'
}
export const rejectStoreOwnershipTransferUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/transfer-store-ownership/reject'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateOrganisationInCollection
 * request: updateOrganisationInCollectionUsingPOST
 * url: updateOrganisationInCollectionUsingPOSTURL
 * method: updateOrganisationInCollectionUsingPOST_TYPE
 * raw_url: updateOrganisationInCollectionUsingPOST_RAW_URL
 * @param collectionOrganisationUpdateRequest - collectionOrganisationUpdateRequest
 */
export const updateOrganisationInCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/update-organisation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionOrganisationUpdateRequest'] !== undefined) {
    body = parameters['collectionOrganisationUpdateRequest']
  }
  if (parameters['collectionOrganisationUpdateRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionOrganisationUpdateRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateOrganisationInCollectionUsingPOST_RAW_URL = function() {
  return '/collection/update-organisation'
}
export const updateOrganisationInCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const updateOrganisationInCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/update-organisation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * lockDailyReportsForOrganisation
 * request: lockDailyReportsForOrganisationUsingPOST
 * url: lockDailyReportsForOrganisationUsingPOSTURL
 * method: lockDailyReportsForOrganisationUsingPOST_TYPE
 * raw_url: lockDailyReportsForOrganisationUsingPOST_RAW_URL
 * @param collectionId - collectionId
 */
export const lockDailyReportsForOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{collectionId}/daily-report/lock/{organisationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const lockDailyReportsForOrganisationUsingPOST_RAW_URL = function() {
  return '/collection/{collectionId}/daily-report/lock/{organisationId}'
}
export const lockDailyReportsForOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const lockDailyReportsForOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{collectionId}/daily-report/lock/{organisationId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * unlockDailyReportsForOrganisation
 * request: unlockDailyReportsForOrganisationUsingPOST
 * url: unlockDailyReportsForOrganisationUsingPOSTURL
 * method: unlockDailyReportsForOrganisationUsingPOST_TYPE
 * raw_url: unlockDailyReportsForOrganisationUsingPOST_RAW_URL
 * @param collectionId - collectionId
 * @param organisationId - organisationId
 */
export const unlockDailyReportsForOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{collectionId}/daily-report/unlock/{organisationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters['organisationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const unlockDailyReportsForOrganisationUsingPOST_RAW_URL = function() {
  return '/collection/{collectionId}/daily-report/unlock/{organisationId}'
}
export const unlockDailyReportsForOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const unlockDailyReportsForOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{collectionId}/daily-report/unlock/{organisationId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionReport
 * request: getCollectionReportUsingGET
 * url: getCollectionReportUsingGETURL
 * method: getCollectionReportUsingGET_TYPE
 * raw_url: getCollectionReportUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const getCollectionReportUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{collectionId}/final-report'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionReportUsingGET_RAW_URL = function() {
  return '/collection/{collectionId}/final-report'
}
export const getCollectionReportUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionReportUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{collectionId}/final-report'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * lockFinalReportsForOrganisation
 * request: lockFinalReportsForOrganisationUsingPOST
 * url: lockFinalReportsForOrganisationUsingPOSTURL
 * method: lockFinalReportsForOrganisationUsingPOST_TYPE
 * raw_url: lockFinalReportsForOrganisationUsingPOST_RAW_URL
 * @param collectionId - collectionId
 */
export const lockFinalReportsForOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{collectionId}/final-report/lock'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const lockFinalReportsForOrganisationUsingPOST_RAW_URL = function() {
  return '/collection/{collectionId}/final-report/lock'
}
export const lockFinalReportsForOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const lockFinalReportsForOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{collectionId}/final-report/lock'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * unlockFinalReportsForOrganisation
 * request: unlockFinalReportsForOrganisationUsingPOST
 * url: unlockFinalReportsForOrganisationUsingPOSTURL
 * method: unlockFinalReportsForOrganisationUsingPOST_TYPE
 * raw_url: unlockFinalReportsForOrganisationUsingPOST_RAW_URL
 * @param collectionId - collectionId
 * @param organisationId - organisationId
 */
export const unlockFinalReportsForOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{collectionId}/final-report/unlock/{organisationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters['organisationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const unlockFinalReportsForOrganisationUsingPOST_RAW_URL = function() {
  return '/collection/{collectionId}/final-report/unlock/{organisationId}'
}
export const unlockFinalReportsForOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const unlockFinalReportsForOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{collectionId}/final-report/unlock/{organisationId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionReportForOrganisation
 * request: getCollectionReportForOrganisationUsingGET
 * url: getCollectionReportForOrganisationUsingGETURL
 * method: getCollectionReportForOrganisationUsingGET_TYPE
 * raw_url: getCollectionReportForOrganisationUsingGET_RAW_URL
 * @param collectionId - collectionId
 * @param organisationId - organisationId
 */
export const getCollectionReportForOrganisationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{collectionId}/final-report/{organisationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters['organisationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionReportForOrganisationUsingGET_RAW_URL = function() {
  return '/collection/{collectionId}/final-report/{organisationId}'
}
export const getCollectionReportForOrganisationUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionReportForOrganisationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{collectionId}/final-report/{organisationId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollection
 * request: getCollectionUsingGET
 * url: getCollectionUsingGETURL
 * method: getCollectionUsingGET_TYPE
 * raw_url: getCollectionUsingGET_RAW_URL
 * @param id - id
 */
export const getCollectionUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collection/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionUsingGET_RAW_URL = function() {
  return '/collection/{id}'
}
export const getCollectionUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collection/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveCollectionDailyReportValue
 * request: saveCollectionDailyReportValueUsingPOST
 * url: saveCollectionDailyReportValueUsingPOSTURL
 * method: saveCollectionDailyReportValueUsingPOST_TYPE
 * raw_url: saveCollectionDailyReportValueUsingPOST_RAW_URL
 * @param collectionDailyReportRequest - collectionDailyReportRequest
 */
export const saveCollectionDailyReportValueUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collectionDailyReport/save'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['collectionDailyReportRequest'] !== undefined) {
    body = parameters['collectionDailyReportRequest']
  }
  if (parameters['collectionDailyReportRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionDailyReportRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveCollectionDailyReportValueUsingPOST_RAW_URL = function() {
  return '/collectionDailyReport/save'
}
export const saveCollectionDailyReportValueUsingPOST_TYPE = function() {
  return 'post'
}
export const saveCollectionDailyReportValueUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collectionDailyReport/save'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionDailyReportSummary
 * request: getCollectionDailyReportSummaryUsingPOST
 * url: getCollectionDailyReportSummaryUsingPOSTURL
 * method: getCollectionDailyReportSummaryUsingPOST_TYPE
 * raw_url: getCollectionDailyReportSummaryUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const getCollectionDailyReportSummaryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/collectionDailyReport/summary'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getCollectionDailyReportSummaryUsingPOST_RAW_URL = function() {
  return '/collectionDailyReport/summary'
}
export const getCollectionDailyReportSummaryUsingPOST_TYPE = function() {
  return 'post'
}
export const getCollectionDailyReportSummaryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/collectionDailyReport/summary'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateCommission
 * request: createOrUpdateCommissionUsingPOST
 * url: createOrUpdateCommissionUsingPOSTURL
 * method: createOrUpdateCommissionUsingPOST_TYPE
 * raw_url: createOrUpdateCommissionUsingPOST_RAW_URL
 * @param commissionRequest - commissionRequest
 */
export const createOrUpdateCommissionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/commission'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commissionRequest'] !== undefined) {
    body = parameters['commissionRequest']
  }
  if (parameters['commissionRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commissionRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateCommissionUsingPOST_RAW_URL = function() {
  return '/commission'
}
export const createOrUpdateCommissionUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateCommissionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/commission'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCommission
 * request: getCommissionUsingGET
 * url: getCommissionUsingGETURL
 * method: getCommissionUsingGET_TYPE
 * raw_url: getCommissionUsingGET_RAW_URL
 * @param id - id
 */
export const getCommissionUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/commission/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCommissionUsingGET_RAW_URL = function() {
  return '/commission/{id}'
}
export const getCommissionUsingGET_TYPE = function() {
  return 'get'
}
export const getCommissionUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/commission/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCommission
 * request: deleteCommissionUsingDELETE
 * url: deleteCommissionUsingDELETEURL
 * method: deleteCommissionUsingDELETE_TYPE
 * raw_url: deleteCommissionUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteCommissionUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/commission/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteCommissionUsingDELETE_RAW_URL = function() {
  return '/commission/{id}'
}
export const deleteCommissionUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteCommissionUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/commission/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteAccount
 * request: deleteAccountUsingPOST
 * url: deleteAccountUsingPOSTURL
 * method: deleteAccountUsingPOST_TYPE
 * raw_url: deleteAccountUsingPOST_RAW_URL
 * @param userIdRequest - userIdRequest
 */
export const deleteAccountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/delete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userIdRequest'] !== undefined) {
    body = parameters['userIdRequest']
  }
  if (parameters['userIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteAccountUsingPOST_RAW_URL = function() {
  return '/delete'
}
export const deleteAccountUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteAccountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/delete'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteDonationStores
 * request: deleteDonationStoresUsingPOST
 * url: deleteDonationStoresUsingPOSTURL
 * method: deleteDonationStoresUsingPOST_TYPE
 * raw_url: deleteDonationStoresUsingPOST_RAW_URL
 * @param storeIdListRequest - storeIdListRequest
 */
export const deleteDonationStoresUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/delete-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeIdListRequest'] !== undefined) {
    body = parameters['storeIdListRequest']
  }
  if (parameters['storeIdListRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeIdListRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteDonationStoresUsingPOST_RAW_URL = function() {
  return '/donation/delete-stores'
}
export const deleteDonationStoresUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteDonationStoresUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/delete-stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * importDonationStores
 * request: importDonationStoresUsingPOST
 * url: importDonationStoresUsingPOSTURL
 * method: importDonationStoresUsingPOST_TYPE
 * raw_url: importDonationStoresUsingPOST_RAW_URL
 * @param importFileRequest - importFileRequest
 */
export const importDonationStoresUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/import/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['importFileRequest'] !== undefined) {
    body = parameters['importFileRequest']
  }
  if (parameters['importFileRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: importFileRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const importDonationStoresUsingPOST_RAW_URL = function() {
  return '/donation/import/'
}
export const importDonationStoresUsingPOST_TYPE = function() {
  return 'post'
}
export const importDonationStoresUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/import/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAssociatedDonationStoresMapObjects
 * request: getAssociatedDonationStoresMapObjectsUsingGET
 * url: getAssociatedDonationStoresMapObjectsUsingGETURL
 * method: getAssociatedDonationStoresMapObjectsUsingGET_TYPE
 * raw_url: getAssociatedDonationStoresMapObjectsUsingGET_RAW_URL
 * @param organisationId - organisationId
 */
export const getAssociatedDonationStoresMapObjectsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/map/associated-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organisationId'] !== undefined) {
    queryParameters['organisationId'] = parameters['organisationId']
  }
  if (parameters['organisationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAssociatedDonationStoresMapObjectsUsingGET_RAW_URL = function() {
  return '/donation/map/associated-stores'
}
export const getAssociatedDonationStoresMapObjectsUsingGET_TYPE = function() {
  return 'get'
}
export const getAssociatedDonationStoresMapObjectsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/map/associated-stores'
  if (parameters['organisationId'] !== undefined) {
    queryParameters['organisationId'] = parameters['organisationId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDonationStoresMapObjects
 * request: getDonationStoresMapObjectsUsingGET
 * url: getDonationStoresMapObjectsUsingGETURL
 * method: getDonationStoresMapObjectsUsingGET_TYPE
 * raw_url: getDonationStoresMapObjectsUsingGET_RAW_URL
 */
export const getDonationStoresMapObjectsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/map/stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDonationStoresMapObjectsUsingGET_RAW_URL = function() {
  return '/donation/map/stores'
}
export const getDonationStoresMapObjectsUsingGET_TYPE = function() {
  return 'get'
}
export const getDonationStoresMapObjectsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/map/stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUnassignedDonationStoresMapObjects
 * request: getUnassignedDonationStoresMapObjectsUsingGET
 * url: getUnassignedDonationStoresMapObjectsUsingGETURL
 * method: getUnassignedDonationStoresMapObjectsUsingGET_TYPE
 * raw_url: getUnassignedDonationStoresMapObjectsUsingGET_RAW_URL
 */
export const getUnassignedDonationStoresMapObjectsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/map/unassigned-stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUnassignedDonationStoresMapObjectsUsingGET_RAW_URL = function() {
  return '/donation/map/unassigned-stores'
}
export const getUnassignedDonationStoresMapObjectsUsingGET_TYPE = function() {
  return 'get'
}
export const getUnassignedDonationStoresMapObjectsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/map/unassigned-stores'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchDonationStore
 * request: searchDonationStoreUsingPOST
 * url: searchDonationStoreUsingPOSTURL
 * method: searchDonationStoreUsingPOST_TYPE
 * raw_url: searchDonationStoreUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchDonationStoreUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/search/store'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDonationStoreUsingPOST_RAW_URL = function() {
  return '/donation/search/store'
}
export const searchDonationStoreUsingPOST_TYPE = function() {
  return 'post'
}
export const searchDonationStoreUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/search/store'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchDonationStoreCount
 * request: searchDonationStoreCountUsingPOST
 * url: searchDonationStoreCountUsingPOSTURL
 * method: searchDonationStoreCountUsingPOST_TYPE
 * raw_url: searchDonationStoreCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchDonationStoreCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/search/store/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDonationStoreCountUsingPOST_RAW_URL = function() {
  return '/donation/search/store/count'
}
export const searchDonationStoreCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchDonationStoreCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/search/store/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateDonationStore
 * request: createOrUpdateDonationStoreUsingPOST
 * url: createOrUpdateDonationStoreUsingPOSTURL
 * method: createOrUpdateDonationStoreUsingPOST_TYPE
 * raw_url: createOrUpdateDonationStoreUsingPOST_RAW_URL
 * @param storeRequest - storeRequest
 */
export const createOrUpdateDonationStoreUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeRequest'] !== undefined) {
    body = parameters['storeRequest']
  }
  if (parameters['storeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateDonationStoreUsingPOST_RAW_URL = function() {
  return '/donation/store'
}
export const createOrUpdateDonationStoreUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateDonationStoreUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changeDonationStatus
 * request: changeDonationStatusUsingPOST
 * url: changeDonationStatusUsingPOSTURL
 * method: changeDonationStatusUsingPOST_TYPE
 * raw_url: changeDonationStatusUsingPOST_RAW_URL
 * @param storeDonationStatusChangeRequest - storeDonationStatusChangeRequest
 */
export const changeDonationStatusUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/change-status/donation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeDonationStatusChangeRequest'] !== undefined) {
    body = parameters['storeDonationStatusChangeRequest']
  }
  if (parameters['storeDonationStatusChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeDonationStatusChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changeDonationStatusUsingPOST_RAW_URL = function() {
  return '/donation/store/change-status/donation'
}
export const changeDonationStatusUsingPOST_TYPE = function() {
  return 'post'
}
export const changeDonationStatusUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/change-status/donation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * massChangeDonationStatuses
 * request: massChangeDonationStatusesUsingPOST
 * url: massChangeDonationStatusesUsingPOSTURL
 * method: massChangeDonationStatusesUsingPOST_TYPE
 * raw_url: massChangeDonationStatusesUsingPOST_RAW_URL
 * @param massDonationStatusChangeRequest - massDonationStatusChangeRequest
 */
export const massChangeDonationStatusesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/mass-donation-status-change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['massDonationStatusChangeRequest'] !== undefined) {
    body = parameters['massDonationStatusChangeRequest']
  }
  if (parameters['massDonationStatusChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: massDonationStatusChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const massChangeDonationStatusesUsingPOST_RAW_URL = function() {
  return '/donation/store/mass-donation-status-change'
}
export const massChangeDonationStatusesUsingPOST_TYPE = function() {
  return 'post'
}
export const massChangeDonationStatusesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/mass-donation-status-change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDonationStatusChangeHistory
 * request: getDonationStatusChangeHistoryUsingGET
 * url: getDonationStatusChangeHistoryUsingGETURL
 * method: getDonationStatusChangeHistoryUsingGET_TYPE
 * raw_url: getDonationStatusChangeHistoryUsingGET_RAW_URL
 * @param id - id
 */
export const getDonationStatusChangeHistoryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/status-history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDonationStatusChangeHistoryUsingGET_RAW_URL = function() {
  return '/donation/store/status-history'
}
export const getDonationStatusChangeHistoryUsingGET_TYPE = function() {
  return 'get'
}
export const getDonationStatusChangeHistoryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/status-history'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSubordinateDonationStores
 * request: getSubordinateDonationStoresUsingGET
 * url: getSubordinateDonationStoresUsingGETURL
 * method: getSubordinateDonationStoresUsingGET_TYPE
 * raw_url: getSubordinateDonationStoresUsingGET_RAW_URL
 * @param query - query
 */
export const getSubordinateDonationStoresUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/subordinate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSubordinateDonationStoresUsingGET_RAW_URL = function() {
  return '/donation/store/subordinate'
}
export const getSubordinateDonationStoresUsingGET_TYPE = function() {
  return 'get'
}
export const getSubordinateDonationStoresUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/subordinate'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDonationStore
 * request: getDonationStoreUsingGET
 * url: getDonationStoreUsingGETURL
 * method: getDonationStoreUsingGET_TYPE
 * raw_url: getDonationStoreUsingGET_RAW_URL
 * @param id - id
 */
export const getDonationStoreUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDonationStoreUsingGET_RAW_URL = function() {
  return '/donation/store/{id}'
}
export const getDonationStoreUsingGET_TYPE = function() {
  return 'get'
}
export const getDonationStoreUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteDonationStore
 * request: deleteDonationStoreUsingDELETE
 * url: deleteDonationStoreUsingDELETEURL
 * method: deleteDonationStoreUsingDELETE_TYPE
 * raw_url: deleteDonationStoreUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteDonationStoreUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDonationStoreUsingDELETE_RAW_URL = function() {
  return '/donation/store/{id}'
}
export const deleteDonationStoreUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteDonationStoreUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignOrganisationToDonationStore
 * request: assignOrganisationToDonationStoreUsingPOST
 * url: assignOrganisationToDonationStoreUsingPOSTURL
 * method: assignOrganisationToDonationStoreUsingPOST_TYPE
 * raw_url: assignOrganisationToDonationStoreUsingPOST_RAW_URL
 * @param organisationId - organisationId
 * @param storeId - storeId
 */
export const assignOrganisationToDonationStoreUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donation/store/{storeId}/assign/{organisationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters['organisationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationId'))
  }
  path = path.replace('{storeId}', `${parameters['storeId']}`)
  if (parameters['storeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const assignOrganisationToDonationStoreUsingPOST_RAW_URL = function() {
  return '/donation/store/{storeId}/assign/{organisationId}'
}
export const assignOrganisationToDonationStoreUsingPOST_TYPE = function() {
  return 'post'
}
export const assignOrganisationToDonationStoreUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donation/store/{storeId}/assign/{organisationId}'
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  path = path.replace('{storeId}', `${parameters['storeId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateDonationReceivalEvent
 * request: createOrUpdateDonationReceivalEventUsingPOST
 * url: createOrUpdateDonationReceivalEventUsingPOSTURL
 * method: createOrUpdateDonationReceivalEventUsingPOST_TYPE
 * raw_url: createOrUpdateDonationReceivalEventUsingPOST_RAW_URL
 * @param donationReceivalEventRequest - donationReceivalEventRequest
 */
export const createOrUpdateDonationReceivalEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donationReceivalEvent'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['donationReceivalEventRequest'] !== undefined) {
    body = parameters['donationReceivalEventRequest']
  }
  if (parameters['donationReceivalEventRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: donationReceivalEventRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateDonationReceivalEventUsingPOST_RAW_URL = function() {
  return '/donationReceivalEvent'
}
export const createOrUpdateDonationReceivalEventUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateDonationReceivalEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donationReceivalEvent'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDonationReceivalEvent
 * request: getDonationReceivalEventUsingGET
 * url: getDonationReceivalEventUsingGETURL
 * method: getDonationReceivalEventUsingGET_TYPE
 * raw_url: getDonationReceivalEventUsingGET_RAW_URL
 * @param id - id
 */
export const getDonationReceivalEventUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donationReceivalEvent/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDonationReceivalEventUsingGET_RAW_URL = function() {
  return '/donationReceivalEvent/{id}'
}
export const getDonationReceivalEventUsingGET_TYPE = function() {
  return 'get'
}
export const getDonationReceivalEventUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donationReceivalEvent/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteDonationReceivalEvent
 * request: deleteDonationReceivalEventUsingDELETE
 * url: deleteDonationReceivalEventUsingDELETEURL
 * method: deleteDonationReceivalEventUsingDELETE_TYPE
 * raw_url: deleteDonationReceivalEventUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteDonationReceivalEventUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/donationReceivalEvent/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDonationReceivalEventUsingDELETE_RAW_URL = function() {
  return '/donationReceivalEvent/{id}'
}
export const deleteDonationReceivalEventUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteDonationReceivalEventUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/donationReceivalEvent/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingGET
 * url: errorHtmlUsingGETURL
 * method: errorHtmlUsingGET_TYPE
 * raw_url: errorHtmlUsingGET_RAW_URL
 */
export const errorHtmlUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingGET_TYPE = function() {
  return 'get'
}
export const errorHtmlUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingHEAD
 * url: errorHtmlUsingHEADURL
 * method: errorHtmlUsingHEAD_TYPE
 * raw_url: errorHtmlUsingHEAD_RAW_URL
 */
export const errorHtmlUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorHtmlUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPOST
 * url: errorHtmlUsingPOSTURL
 * method: errorHtmlUsingPOST_TYPE
 * raw_url: errorHtmlUsingPOST_RAW_URL
 */
export const errorHtmlUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPOST_TYPE = function() {
  return 'post'
}
export const errorHtmlUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPUT
 * url: errorHtmlUsingPUTURL
 * method: errorHtmlUsingPUT_TYPE
 * raw_url: errorHtmlUsingPUT_RAW_URL
 */
export const errorHtmlUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPUT_TYPE = function() {
  return 'put'
}
export const errorHtmlUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingDELETE
 * url: errorHtmlUsingDELETEURL
 * method: errorHtmlUsingDELETE_TYPE
 * raw_url: errorHtmlUsingDELETE_RAW_URL
 */
export const errorHtmlUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorHtmlUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingOPTIONS
 * url: errorHtmlUsingOPTIONSURL
 * method: errorHtmlUsingOPTIONS_TYPE
 * raw_url: errorHtmlUsingOPTIONS_RAW_URL
 */
export const errorHtmlUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorHtmlUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPATCH
 * url: errorHtmlUsingPATCHURL
 * method: errorHtmlUsingPATCH_TYPE
 * raw_url: errorHtmlUsingPATCH_RAW_URL
 */
export const errorHtmlUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorHtmlUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateFile
 * request: createOrUpdateFileUsingPOST
 * url: createOrUpdateFileUsingPOSTURL
 * method: createOrUpdateFileUsingPOST_TYPE
 * raw_url: createOrUpdateFileUsingPOST_RAW_URL
 * @param fileRequest - fileRequest
 */
export const createOrUpdateFileUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['fileRequest'] !== undefined) {
    body = parameters['fileRequest']
  }
  if (parameters['fileRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateFileUsingPOST_RAW_URL = function() {
  return '/file'
}
export const createOrUpdateFileUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateFileUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadFile
 * request: downloadFileUsingGET
 * url: downloadFileUsingGETURL
 * method: downloadFileUsingGET_TYPE
 * raw_url: downloadFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/download/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadFileUsingGET_RAW_URL = function() {
  return '/file/download/{id}'
}
export const downloadFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/download/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editShareOptions
 * request: editShareOptionsUsingPOST
 * url: editShareOptionsUsingPOSTURL
 * method: editShareOptionsUsingPOST_TYPE
 * raw_url: editShareOptionsUsingPOST_RAW_URL
 * @param fileId - fileId
 * @param oplIds - OPLIds
 */
export const editShareOptionsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/{fileId}/share'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{fileId}', `${parameters['fileId']}`)
  if (parameters['fileId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileId'))
  }
  if (parameters['oplIds'] !== undefined) {
    body = parameters['oplIds']
  }
  if (parameters['oplIds'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oplIds'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editShareOptionsUsingPOST_RAW_URL = function() {
  return '/file/{fileId}/share'
}
export const editShareOptionsUsingPOST_TYPE = function() {
  return 'post'
}
export const editShareOptionsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/{fileId}/share'
  path = path.replace('{fileId}', `${parameters['fileId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFileSharedToOPLIds
 * request: getFileSharedToOPLIdsUsingGET
 * url: getFileSharedToOPLIdsUsingGETURL
 * method: getFileSharedToOPLIdsUsingGET_TYPE
 * raw_url: getFileSharedToOPLIdsUsingGET_RAW_URL
 * @param fileId - fileId
 */
export const getFileSharedToOPLIdsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/{fileId}/shared'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{fileId}', `${parameters['fileId']}`)
  if (parameters['fileId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFileSharedToOPLIdsUsingGET_RAW_URL = function() {
  return '/file/{fileId}/shared'
}
export const getFileSharedToOPLIdsUsingGET_TYPE = function() {
  return 'get'
}
export const getFileSharedToOPLIdsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/{fileId}/shared'
  path = path.replace('{fileId}', `${parameters['fileId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFile
 * request: getFileUsingGET
 * url: getFileUsingGETURL
 * method: getFileUsingGET_TYPE
 * raw_url: getFileUsingGET_RAW_URL
 * @param id - id
 */
export const getFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFileUsingGET_RAW_URL = function() {
  return '/file/{id}'
}
export const getFileUsingGET_TYPE = function() {
  return 'get'
}
export const getFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteFile
 * request: deleteFileUsingDELETE
 * url: deleteFileUsingDELETEURL
 * method: deleteFileUsingDELETE_TYPE
 * raw_url: deleteFileUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteFileUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteFileUsingDELETE_RAW_URL = function() {
  return '/file/{id}'
}
export const deleteFileUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteFileUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * checkIfClosedByFpbz
 * request: checkIfClosedByFpbzUsingGET
 * url: checkIfClosedByFpbzUsingGETURL
 * method: checkIfClosedByFpbzUsingGET_TYPE
 * raw_url: checkIfClosedByFpbzUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const checkIfClosedByFpbzUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-closed-by-fpbz/{collectionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const checkIfClosedByFpbzUsingGET_RAW_URL = function() {
  return '/is-closed-by-fpbz/{collectionId}'
}
export const checkIfClosedByFpbzUsingGET_TYPE = function() {
  return 'get'
}
export const checkIfClosedByFpbzUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-closed-by-fpbz/{collectionId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isTokenValid
 * request: isTokenValidUsingGET
 * url: isTokenValidUsingGETURL
 * method: isTokenValidUsingGET_TYPE
 * raw_url: isTokenValidUsingGET_RAW_URL
 */
export const isTokenValidUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValidUsingGET_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValidUsingGET_TYPE = function() {
  return 'get'
}
export const isTokenValidUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isSuperAdmin
 * request: isSuperAdminUsingGET
 * url: isSuperAdminUsingGETURL
 * method: isSuperAdminUsingGET_TYPE
 * raw_url: isSuperAdminUsingGET_RAW_URL
 */
export const isSuperAdminUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/isSuperAdmin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isSuperAdminUsingGET_RAW_URL = function() {
  return '/isSuperAdmin'
}
export const isSuperAdminUsingGET_TYPE = function() {
  return 'get'
}
export const isSuperAdminUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/isSuperAdmin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param authenticationRequest - authenticationRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['authenticationRequest'] !== undefined) {
    body = parameters['authenticationRequest']
  }
  if (parameters['authenticationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: authenticationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMailConfiguration
 * request: getMailConfigurationUsingGET
 * url: getMailConfigurationUsingGETURL
 * method: getMailConfigurationUsingGET_TYPE
 * raw_url: getMailConfigurationUsingGET_RAW_URL
 */
export const getMailConfigurationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/mailConfiguration'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMailConfigurationUsingGET_RAW_URL = function() {
  return '/mailConfiguration'
}
export const getMailConfigurationUsingGET_TYPE = function() {
  return 'get'
}
export const getMailConfigurationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/mailConfiguration'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateMailConfiguration
 * request: updateMailConfigurationUsingPOST
 * url: updateMailConfigurationUsingPOSTURL
 * method: updateMailConfigurationUsingPOST_TYPE
 * raw_url: updateMailConfigurationUsingPOST_RAW_URL
 * @param mailConfigurationRequest - mailConfigurationRequest
 */
export const updateMailConfigurationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/mailConfiguration'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['mailConfigurationRequest'] !== undefined) {
    body = parameters['mailConfigurationRequest']
  }
  if (parameters['mailConfigurationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mailConfigurationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateMailConfigurationUsingPOST_RAW_URL = function() {
  return '/mailConfiguration'
}
export const updateMailConfigurationUsingPOST_TYPE = function() {
  return 'post'
}
export const updateMailConfigurationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/mailConfiguration'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAssociatedOrganisationsMapObjects
 * request: getAssociatedOrganisationsMapObjectsUsingGET
 * url: getAssociatedOrganisationsMapObjectsUsingGETURL
 * method: getAssociatedOrganisationsMapObjectsUsingGET_TYPE
 * raw_url: getAssociatedOrganisationsMapObjectsUsingGET_RAW_URL
 * @param id - id
 */
export const getAssociatedOrganisationsMapObjectsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map/associated-organisations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAssociatedOrganisationsMapObjectsUsingGET_RAW_URL = function() {
  return '/map/associated-organisations'
}
export const getAssociatedOrganisationsMapObjectsUsingGET_TYPE = function() {
  return 'get'
}
export const getAssociatedOrganisationsMapObjectsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map/associated-organisations'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganisationsMapObjects
 * request: getOrganisationsMapObjectsUsingGET
 * url: getOrganisationsMapObjectsUsingGETURL
 * method: getOrganisationsMapObjectsUsingGET_TYPE
 * raw_url: getOrganisationsMapObjectsUsingGET_RAW_URL
 */
export const getOrganisationsMapObjectsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/map/organisations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganisationsMapObjectsUsingGET_RAW_URL = function() {
  return '/map/organisations'
}
export const getOrganisationsMapObjectsUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganisationsMapObjectsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/map/organisations'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createMessageThread
 * request: createMessageThreadUsingPOST
 * url: createMessageThreadUsingPOSTURL
 * method: createMessageThreadUsingPOST_TYPE
 * raw_url: createMessageThreadUsingPOST_RAW_URL
 * @param messageThreadRequest - messageThreadRequest
 */
export const createMessageThreadUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/messageThread'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['messageThreadRequest'] !== undefined) {
    body = parameters['messageThreadRequest']
  }
  if (parameters['messageThreadRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: messageThreadRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createMessageThreadUsingPOST_RAW_URL = function() {
  return '/messageThread'
}
export const createMessageThreadUsingPOST_TYPE = function() {
  return 'post'
}
export const createMessageThreadUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/messageThread'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteMessageThread
 * request: deleteMessageThreadUsingDELETE
 * url: deleteMessageThreadUsingDELETEURL
 * method: deleteMessageThreadUsingDELETE_TYPE
 * raw_url: deleteMessageThreadUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteMessageThreadUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/messageThread/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteMessageThreadUsingDELETE_RAW_URL = function() {
  return '/messageThread/{id}'
}
export const deleteMessageThreadUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteMessageThreadUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/messageThread/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getMessageThread
 * request: getMessageThreadUsingGET
 * url: getMessageThreadUsingGETURL
 * method: getMessageThreadUsingGET_TYPE
 * raw_url: getMessageThreadUsingGET_RAW_URL
 * @param threadId - threadId
 */
export const getMessageThreadUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/messageThread/{threadId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{threadId}', `${parameters['threadId']}`)
  if (parameters['threadId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: threadId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getMessageThreadUsingGET_RAW_URL = function() {
  return '/messageThread/{threadId}'
}
export const getMessageThreadUsingGET_TYPE = function() {
  return 'get'
}
export const getMessageThreadUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/messageThread/{threadId}'
  path = path.replace('{threadId}', `${parameters['threadId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setSeen
 * request: setSeenUsingPOST
 * url: setSeenUsingPOSTURL
 * method: setSeenUsingPOST_TYPE
 * raw_url: setSeenUsingPOST_RAW_URL
 * @param threadId - threadId
 */
export const setSeenUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/messageThread/{threadId}/seen'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{threadId}', `${parameters['threadId']}`)
  if (parameters['threadId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: threadId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setSeenUsingPOST_RAW_URL = function() {
  return '/messageThread/{threadId}/seen'
}
export const setSeenUsingPOST_TYPE = function() {
  return 'post'
}
export const setSeenUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/messageThread/{threadId}/seen'
  path = path.replace('{threadId}', `${parameters['threadId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * loadOlderMessages
 * request: loadOlderMessagesUsingGET
 * url: loadOlderMessagesUsingGETURL
 * method: loadOlderMessagesUsingGET_TYPE
 * raw_url: loadOlderMessagesUsingGET_RAW_URL
 * @param date - date
 * @param threadId - threadId
 */
export const loadOlderMessagesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/messages'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['threadId'] !== undefined) {
    queryParameters['threadId'] = parameters['threadId']
  }
  if (parameters['threadId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: threadId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const loadOlderMessagesUsingGET_RAW_URL = function() {
  return '/messages'
}
export const loadOlderMessagesUsingGET_TYPE = function() {
  return 'get'
}
export const loadOlderMessagesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/messages'
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['threadId'] !== undefined) {
    queryParameters['threadId'] = parameters['threadId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * loadOlderMessagesCount
 * request: loadOlderMessagesCountUsingGET
 * url: loadOlderMessagesCountUsingGETURL
 * method: loadOlderMessagesCountUsingGET_TYPE
 * raw_url: loadOlderMessagesCountUsingGET_RAW_URL
 * @param date - date
 * @param threadId - threadId
 */
export const loadOlderMessagesCountUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/messages/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['threadId'] !== undefined) {
    queryParameters['threadId'] = parameters['threadId']
  }
  if (parameters['threadId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: threadId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const loadOlderMessagesCountUsingGET_RAW_URL = function() {
  return '/messages/count'
}
export const loadOlderMessagesCountUsingGET_TYPE = function() {
  return 'get'
}
export const loadOlderMessagesCountUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/messages/count'
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['threadId'] !== undefined) {
    queryParameters['threadId'] = parameters['threadId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * numberOfUnreadThreads
 * request: numberOfUnreadThreadsUsingGET
 * url: numberOfUnreadThreadsUsingGETURL
 * method: numberOfUnreadThreadsUsingGET_TYPE
 * raw_url: numberOfUnreadThreadsUsingGET_RAW_URL
 */
export const numberOfUnreadThreadsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/new-messages'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const numberOfUnreadThreadsUsingGET_RAW_URL = function() {
  return '/new-messages'
}
export const numberOfUnreadThreadsUsingGET_TYPE = function() {
  return 'get'
}
export const numberOfUnreadThreadsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/new-messages'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateOffer
 * request: createOrUpdateOfferUsingPOST
 * url: createOrUpdateOfferUsingPOSTURL
 * method: createOrUpdateOfferUsingPOST_TYPE
 * raw_url: createOrUpdateOfferUsingPOST_RAW_URL
 * @param offerRequest - offerRequest
 */
export const createOrUpdateOfferUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/offer'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['offerRequest'] !== undefined) {
    body = parameters['offerRequest']
  }
  if (parameters['offerRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: offerRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateOfferUsingPOST_RAW_URL = function() {
  return '/offer'
}
export const createOrUpdateOfferUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateOfferUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/offer'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOffer
 * request: getOfferUsingGET
 * url: getOfferUsingGETURL
 * method: getOfferUsingGET_TYPE
 * raw_url: getOfferUsingGET_RAW_URL
 * @param id - id
 */
export const getOfferUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/offer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOfferUsingGET_RAW_URL = function() {
  return '/offer/{id}'
}
export const getOfferUsingGET_TYPE = function() {
  return 'get'
}
export const getOfferUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/offer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteOffer
 * request: deleteOfferUsingDELETE
 * url: deleteOfferUsingDELETEURL
 * method: deleteOfferUsingDELETE_TYPE
 * raw_url: deleteOfferUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteOfferUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/offer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteOfferUsingDELETE_RAW_URL = function() {
  return '/offer/{id}'
}
export const deleteOfferUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteOfferUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/offer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateOplAuthorization
 * request: createOrUpdateOplAuthorizationUsingPOST
 * url: createOrUpdateOplAuthorizationUsingPOSTURL
 * method: createOrUpdateOplAuthorizationUsingPOST_TYPE
 * raw_url: createOrUpdateOplAuthorizationUsingPOST_RAW_URL
 * @param oplAuthorizationRequest - oplAuthorizationRequest
 */
export const createOrUpdateOplAuthorizationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/oplAuthorization'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['oplAuthorizationRequest'] !== undefined) {
    body = parameters['oplAuthorizationRequest']
  }
  if (parameters['oplAuthorizationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oplAuthorizationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateOplAuthorizationUsingPOST_RAW_URL = function() {
  return '/oplAuthorization'
}
export const createOrUpdateOplAuthorizationUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateOplAuthorizationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/oplAuthorization'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * generateAuthorization
 * request: generateAuthorizationUsingGET
 * url: generateAuthorizationUsingGETURL
 * method: generateAuthorizationUsingGET_TYPE
 * raw_url: generateAuthorizationUsingGET_RAW_URL
 * @param id - id
 */
export const generateAuthorizationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/oplAuthorization/generate/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const generateAuthorizationUsingGET_RAW_URL = function() {
  return '/oplAuthorization/generate/{id}'
}
export const generateAuthorizationUsingGET_TYPE = function() {
  return 'get'
}
export const generateAuthorizationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/oplAuthorization/generate/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOplAuthorization
 * request: getOplAuthorizationUsingGET
 * url: getOplAuthorizationUsingGETURL
 * method: getOplAuthorizationUsingGET_TYPE
 * raw_url: getOplAuthorizationUsingGET_RAW_URL
 * @param id - id
 */
export const getOplAuthorizationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/oplAuthorization/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOplAuthorizationUsingGET_RAW_URL = function() {
  return '/oplAuthorization/{id}'
}
export const getOplAuthorizationUsingGET_TYPE = function() {
  return 'get'
}
export const getOplAuthorizationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/oplAuthorization/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteOplAuthorization
 * request: deleteOplAuthorizationUsingDELETE
 * url: deleteOplAuthorizationUsingDELETEURL
 * method: deleteOplAuthorizationUsingDELETE_TYPE
 * raw_url: deleteOplAuthorizationUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteOplAuthorizationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/oplAuthorization/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteOplAuthorizationUsingDELETE_RAW_URL = function() {
  return '/oplAuthorization/{id}'
}
export const deleteOplAuthorizationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteOplAuthorizationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/oplAuthorization/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateOrganisation
 * request: createOrUpdateOrganisationUsingPOST
 * url: createOrUpdateOrganisationUsingPOSTURL
 * method: createOrUpdateOrganisationUsingPOST_TYPE
 * raw_url: createOrUpdateOrganisationUsingPOST_RAW_URL
 * @param organisationRequest - organisationRequest
 */
export const createOrUpdateOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organisationRequest'] !== undefined) {
    body = parameters['organisationRequest']
  }
  if (parameters['organisationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateOrganisationUsingPOST_RAW_URL = function() {
  return '/organisation'
}
export const createOrUpdateOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFpbz
 * request: getFpbzUsingGET
 * url: getFpbzUsingGETURL
 * method: getFpbzUsingGET_TYPE
 * raw_url: getFpbzUsingGET_RAW_URL
 */
export const getFpbzUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisation/fpbz'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFpbzUsingGET_RAW_URL = function() {
  return '/organisation/fpbz'
}
export const getFpbzUsingGET_TYPE = function() {
  return 'get'
}
export const getFpbzUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisation/fpbz'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganisationLevels
 * request: getOrganisationLevelsUsingGET
 * url: getOrganisationLevelsUsingGETURL
 * method: getOrganisationLevelsUsingGET_TYPE
 * raw_url: getOrganisationLevelsUsingGET_RAW_URL
 */
export const getOrganisationLevelsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisation/levels'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganisationLevelsUsingGET_RAW_URL = function() {
  return '/organisation/levels'
}
export const getOrganisationLevelsUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganisationLevelsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisation/levels'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getLoggedUserOrganisation
 * request: getLoggedUserOrganisationUsingGET
 * url: getLoggedUserOrganisationUsingGETURL
 * method: getLoggedUserOrganisationUsingGET_TYPE
 * raw_url: getLoggedUserOrganisationUsingGET_RAW_URL
 */
export const getLoggedUserOrganisationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisation/logged'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLoggedUserOrganisationUsingGET_RAW_URL = function() {
  return '/organisation/logged'
}
export const getLoggedUserOrganisationUsingGET_TYPE = function() {
  return 'get'
}
export const getLoggedUserOrganisationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisation/logged'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganisation
 * request: getOrganisationUsingGET
 * url: getOrganisationUsingGETURL
 * method: getOrganisationUsingGET_TYPE
 * raw_url: getOrganisationUsingGET_RAW_URL
 * @param id - id
 */
export const getOrganisationUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisation/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganisationUsingGET_RAW_URL = function() {
  return '/organisation/{id}'
}
export const getOrganisationUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganisationUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisation/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteOrganisation
 * request: deleteOrganisationUsingDELETE
 * url: deleteOrganisationUsingDELETEURL
 * method: deleteOrganisationUsingDELETE_TYPE
 * raw_url: deleteOrganisationUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteOrganisationUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisation/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteOrganisationUsingDELETE_RAW_URL = function() {
  return '/organisation/{id}'
}
export const deleteOrganisationUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteOrganisationUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisation/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganisations
 * request: getOrganisationsUsingGET
 * url: getOrganisationsUsingGETURL
 * method: getOrganisationsUsingGET_TYPE
 * raw_url: getOrganisationsUsingGET_RAW_URL
 * @param organisationLevel - organisationLevel
 * @param query - query
 * @param supervisorId - supervisorId
 */
export const getOrganisationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/organisations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organisationLevel'] !== undefined) {
    queryParameters['organisationLevel'] = parameters['organisationLevel']
  }
  if (parameters['organisationLevel'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationLevel'))
  }
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters['supervisorId'] !== undefined) {
    queryParameters['supervisorId'] = parameters['supervisorId']
  }
  if (parameters['supervisorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: supervisorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganisationsUsingGET_RAW_URL = function() {
  return '/organisations'
}
export const getOrganisationsUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganisationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/organisations'
  if (parameters['organisationLevel'] !== undefined) {
    queryParameters['organisationLevel'] = parameters['organisationLevel']
  }
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['supervisorId'] !== undefined) {
    queryParameters['supervisorId'] = parameters['supervisorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOtherOrganisations
 * request: getOtherOrganisationsUsingGET
 * url: getOtherOrganisationsUsingGETURL
 * method: getOtherOrganisationsUsingGET_TYPE
 * raw_url: getOtherOrganisationsUsingGET_RAW_URL
 * @param organisationLevel - organisationLevel
 */
export const getOtherOrganisationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/other-organisations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organisationLevel'] !== undefined) {
    queryParameters['organisationLevel'] = parameters['organisationLevel']
  }
  if (parameters['organisationLevel'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationLevel'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOtherOrganisationsUsingGET_RAW_URL = function() {
  return '/other-organisations'
}
export const getOtherOrganisationsUsingGET_TYPE = function() {
  return 'get'
}
export const getOtherOrganisationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/other-organisations'
  if (parameters['organisationLevel'] !== undefined) {
    queryParameters['organisationLevel'] = parameters['organisationLevel']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdatePost
 * request: createOrUpdatePostUsingPOST
 * url: createOrUpdatePostUsingPOSTURL
 * method: createOrUpdatePostUsingPOST_TYPE
 * raw_url: createOrUpdatePostUsingPOST_RAW_URL
 * @param postRequest - postRequest
 */
export const createOrUpdatePostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postRequest'] !== undefined) {
    body = parameters['postRequest']
  }
  if (parameters['postRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdatePostUsingPOST_RAW_URL = function() {
  return '/post'
}
export const createOrUpdatePostUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdatePostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPostWithAdjacentInfo
 * request: getPostWithAdjacentInfoUsingPOST
 * url: getPostWithAdjacentInfoUsingPOSTURL
 * method: getPostWithAdjacentInfoUsingPOST_TYPE
 * raw_url: getPostWithAdjacentInfoUsingPOST_RAW_URL
 * @param id - id
 * @param searchCriteria - searchCriteria
 */
export const getPostWithAdjacentInfoUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post-with-adjacent-info/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getPostWithAdjacentInfoUsingPOST_RAW_URL = function() {
  return '/post-with-adjacent-info/{id}'
}
export const getPostWithAdjacentInfoUsingPOST_TYPE = function() {
  return 'post'
}
export const getPostWithAdjacentInfoUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post-with-adjacent-info/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPost
 * request: getPostUsingGET
 * url: getPostUsingGETURL
 * method: getPostUsingGET_TYPE
 * raw_url: getPostUsingGET_RAW_URL
 * @param id - id
 */
export const getPostUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPostUsingGET_RAW_URL = function() {
  return '/post/{id}'
}
export const getPostUsingGET_TYPE = function() {
  return 'get'
}
export const getPostUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePost
 * request: deletePostUsingDELETE
 * url: deletePostUsingDELETEURL
 * method: deletePostUsingDELETE_TYPE
 * raw_url: deletePostUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePostUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePostUsingDELETE_RAW_URL = function() {
  return '/post/{id}'
}
export const deletePostUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePostUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPostCategories
 * request: getPostCategoriesUsingGET
 * url: getPostCategoriesUsingGETURL
 * method: getPostCategoriesUsingGET_TYPE
 * raw_url: getPostCategoriesUsingGET_RAW_URL
 * @param categoryType - categoryType
 */
export const getPostCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/postCategories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryType'] !== undefined) {
    queryParameters['categoryType'] = parameters['categoryType']
  }
  if (parameters['categoryType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPostCategoriesUsingGET_RAW_URL = function() {
  return '/postCategories'
}
export const getPostCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getPostCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/postCategories'
  if (parameters['categoryType'] !== undefined) {
    queryParameters['categoryType'] = parameters['categoryType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdatePostCategory
 * request: createOrUpdatePostCategoryUsingPOST
 * url: createOrUpdatePostCategoryUsingPOSTURL
 * method: createOrUpdatePostCategoryUsingPOST_TYPE
 * raw_url: createOrUpdatePostCategoryUsingPOST_RAW_URL
 * @param postCategoryDto - postCategoryDTO
 */
export const createOrUpdatePostCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/postCategory'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postCategoryDto'] !== undefined) {
    body = parameters['postCategoryDto']
  }
  if (parameters['postCategoryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postCategoryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdatePostCategoryUsingPOST_RAW_URL = function() {
  return '/postCategory'
}
export const createOrUpdatePostCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdatePostCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/postCategory'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPostCategory
 * request: getPostCategoryUsingGET
 * url: getPostCategoryUsingGETURL
 * method: getPostCategoryUsingGET_TYPE
 * raw_url: getPostCategoryUsingGET_RAW_URL
 * @param id - id
 */
export const getPostCategoryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/postCategory/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPostCategoryUsingGET_RAW_URL = function() {
  return '/postCategory/{id}'
}
export const getPostCategoryUsingGET_TYPE = function() {
  return 'get'
}
export const getPostCategoryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/postCategory/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePostCategory
 * request: deletePostCategoryUsingDELETE
 * url: deletePostCategoryUsingDELETEURL
 * method: deletePostCategoryUsingDELETE_TYPE
 * raw_url: deletePostCategoryUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePostCategoryUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/postCategory/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePostCategoryUsingDELETE_RAW_URL = function() {
  return '/postCategory/{id}'
}
export const deletePostCategoryUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePostCategoryUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/postCategory/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCollectionProductCategories
 * request: getCollectionProductCategoriesUsingGET
 * url: getCollectionProductCategoriesUsingGETURL
 * method: getCollectionProductCategoriesUsingGET_TYPE
 * raw_url: getCollectionProductCategoriesUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const getCollectionProductCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/product-categories/{collectionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCollectionProductCategoriesUsingGET_RAW_URL = function() {
  return '/product-categories/{collectionId}'
}
export const getCollectionProductCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getCollectionProductCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/product-categories/{collectionId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateCollectionProductCategories
 * request: updateCollectionProductCategoriesUsingPOST
 * url: updateCollectionProductCategoriesUsingPOSTURL
 * method: updateCollectionProductCategoriesUsingPOST_TYPE
 * raw_url: updateCollectionProductCategoriesUsingPOST_RAW_URL
 * @param collectionId - collectionId
 * @param request - request
 */
export const updateCollectionProductCategoriesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/product-categories/{collectionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateCollectionProductCategoriesUsingPOST_RAW_URL = function() {
  return '/product-categories/{collectionId}'
}
export const updateCollectionProductCategoriesUsingPOST_TYPE = function() {
  return 'post'
}
export const updateCollectionProductCategoriesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/product-categories/{collectionId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateProductCategory
 * request: createOrUpdateProductCategoryUsingPOST
 * url: createOrUpdateProductCategoryUsingPOSTURL
 * method: createOrUpdateProductCategoryUsingPOST_TYPE
 * raw_url: createOrUpdateProductCategoryUsingPOST_RAW_URL
 * @param productCategoryDto - productCategoryDTO
 */
export const createOrUpdateProductCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/productCategory'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['productCategoryDto'] !== undefined) {
    body = parameters['productCategoryDto']
  }
  if (parameters['productCategoryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: productCategoryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateProductCategoryUsingPOST_RAW_URL = function() {
  return '/productCategory'
}
export const createOrUpdateProductCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateProductCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/productCategory'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * activateProductCategory
 * request: activateProductCategoryUsingPOST
 * url: activateProductCategoryUsingPOSTURL
 * method: activateProductCategoryUsingPOST_TYPE
 * raw_url: activateProductCategoryUsingPOST_RAW_URL
 * @param id - id
 */
export const activateProductCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/productCategory/activate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const activateProductCategoryUsingPOST_RAW_URL = function() {
  return '/productCategory/activate'
}
export const activateProductCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const activateProductCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/productCategory/activate'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getActiveProductCategories
 * request: getActiveProductCategoriesUsingGET
 * url: getActiveProductCategoriesUsingGETURL
 * method: getActiveProductCategoriesUsingGET_TYPE
 * raw_url: getActiveProductCategoriesUsingGET_RAW_URL
 */
export const getActiveProductCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/productCategory/active'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getActiveProductCategoriesUsingGET_RAW_URL = function() {
  return '/productCategory/active'
}
export const getActiveProductCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getActiveProductCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/productCategory/active'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deactivateProductCategory
 * request: deactivateProductCategoryUsingPOST
 * url: deactivateProductCategoryUsingPOSTURL
 * method: deactivateProductCategoryUsingPOST_TYPE
 * raw_url: deactivateProductCategoryUsingPOST_RAW_URL
 * @param id - id
 */
export const deactivateProductCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/productCategory/deactivate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deactivateProductCategoryUsingPOST_RAW_URL = function() {
  return '/productCategory/deactivate'
}
export const deactivateProductCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const deactivateProductCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/productCategory/deactivate'
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getProductCategory
 * request: getProductCategoryUsingGET
 * url: getProductCategoryUsingGETURL
 * method: getProductCategoryUsingGET_TYPE
 * raw_url: getProductCategoryUsingGET_RAW_URL
 * @param id - id
 */
export const getProductCategoryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/productCategory/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getProductCategoryUsingGET_RAW_URL = function() {
  return '/productCategory/{id}'
}
export const getProductCategoryUsingGET_TYPE = function() {
  return 'get'
}
export const getProductCategoryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/productCategory/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * register
 * request: registerUsingPOST
 * url: registerUsingPOSTURL
 * method: registerUsingPOST_TYPE
 * raw_url: registerUsingPOST_RAW_URL
 * @param registrationRequest - registrationRequest
 */
export const registerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/register'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['registrationRequest'] !== undefined) {
    body = parameters['registrationRequest']
  }
  if (parameters['registrationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: registrationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerUsingPOST_RAW_URL = function() {
  return '/register'
}
export const registerUsingPOST_TYPE = function() {
  return 'post'
}
export const registerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/register'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * rejectOffer
 * request: rejectOfferUsingPOST
 * url: rejectOfferUsingPOSTURL
 * method: rejectOfferUsingPOST_TYPE
 * raw_url: rejectOfferUsingPOST_RAW_URL
 * @param offerRequest - offerRequest
 */
export const rejectOfferUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/reject'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['offerRequest'] !== undefined) {
    body = parameters['offerRequest']
  }
  if (parameters['offerRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: offerRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectOfferUsingPOST_RAW_URL = function() {
  return '/reject'
}
export const rejectOfferUsingPOST_TYPE = function() {
  return 'post'
}
export const rejectOfferUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/reject'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCollection
 * request: searchCollectionUsingPOST
 * url: searchCollectionUsingPOSTURL
 * method: searchCollectionUsingPOST_TYPE
 * raw_url: searchCollectionUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCollectionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/collection'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCollectionUsingPOST_RAW_URL = function() {
  return '/search/collection'
}
export const searchCollectionUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCollectionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/collection'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCollectionCount
 * request: searchCollectionCountUsingPOST
 * url: searchCollectionCountUsingPOSTURL
 * method: searchCollectionCountUsingPOST_TYPE
 * raw_url: searchCollectionCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCollectionCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/collection/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCollectionCountUsingPOST_RAW_URL = function() {
  return '/search/collection/count'
}
export const searchCollectionCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCollectionCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/collection/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCollectionDailyReport
 * request: searchCollectionDailyReportUsingPOST
 * url: searchCollectionDailyReportUsingPOSTURL
 * method: searchCollectionDailyReportUsingPOST_TYPE
 * raw_url: searchCollectionDailyReportUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCollectionDailyReportUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/collectionDailyReport'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCollectionDailyReportUsingPOST_RAW_URL = function() {
  return '/search/collectionDailyReport'
}
export const searchCollectionDailyReportUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCollectionDailyReportUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/collectionDailyReport'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCollectionDailyReportCount
 * request: searchCollectionDailyReportCountUsingPOST
 * url: searchCollectionDailyReportCountUsingPOSTURL
 * method: searchCollectionDailyReportCountUsingPOST_TYPE
 * raw_url: searchCollectionDailyReportCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCollectionDailyReportCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/collectionDailyReport/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCollectionDailyReportCountUsingPOST_RAW_URL = function() {
  return '/search/collectionDailyReport/count'
}
export const searchCollectionDailyReportCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCollectionDailyReportCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/collectionDailyReport/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCommission
 * request: searchCommissionUsingPOST
 * url: searchCommissionUsingPOSTURL
 * method: searchCommissionUsingPOST_TYPE
 * raw_url: searchCommissionUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCommissionUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/commission'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCommissionUsingPOST_RAW_URL = function() {
  return '/search/commission'
}
export const searchCommissionUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCommissionUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/commission'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCommissionCount
 * request: searchCommissionCountUsingPOST
 * url: searchCommissionCountUsingPOSTURL
 * method: searchCommissionCountUsingPOST_TYPE
 * raw_url: searchCommissionCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCommissionCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/commission/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCommissionCountUsingPOST_RAW_URL = function() {
  return '/search/commission/count'
}
export const searchCommissionCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCommissionCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/commission/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchDonationReceivalEvent
 * request: searchDonationReceivalEventUsingPOST
 * url: searchDonationReceivalEventUsingPOSTURL
 * method: searchDonationReceivalEventUsingPOST_TYPE
 * raw_url: searchDonationReceivalEventUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchDonationReceivalEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/donationReceivalEvent'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDonationReceivalEventUsingPOST_RAW_URL = function() {
  return '/search/donationReceivalEvent'
}
export const searchDonationReceivalEventUsingPOST_TYPE = function() {
  return 'post'
}
export const searchDonationReceivalEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/donationReceivalEvent'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchDonationReceivalEventCount
 * request: searchDonationReceivalEventCountUsingPOST
 * url: searchDonationReceivalEventCountUsingPOSTURL
 * method: searchDonationReceivalEventCountUsingPOST_TYPE
 * raw_url: searchDonationReceivalEventCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchDonationReceivalEventCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/donationReceivalEvent/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchDonationReceivalEventCountUsingPOST_RAW_URL = function() {
  return '/search/donationReceivalEvent/count'
}
export const searchDonationReceivalEventCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchDonationReceivalEventCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/donationReceivalEvent/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchFile
 * request: searchFileUsingPOST
 * url: searchFileUsingPOSTURL
 * method: searchFileUsingPOST_TYPE
 * raw_url: searchFileUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchFileUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchFileUsingPOST_RAW_URL = function() {
  return '/search/file'
}
export const searchFileUsingPOST_TYPE = function() {
  return 'post'
}
export const searchFileUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchFileCount
 * request: searchFileCountUsingPOST
 * url: searchFileCountUsingPOSTURL
 * method: searchFileCountUsingPOST_TYPE
 * raw_url: searchFileCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchFileCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/file/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchFileCountUsingPOST_RAW_URL = function() {
  return '/search/file/count'
}
export const searchFileCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchFileCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/file/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMessageThread
 * request: searchMessageThreadUsingPOST
 * url: searchMessageThreadUsingPOSTURL
 * method: searchMessageThreadUsingPOST_TYPE
 * raw_url: searchMessageThreadUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchMessageThreadUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/messageThread'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMessageThreadUsingPOST_RAW_URL = function() {
  return '/search/messageThread'
}
export const searchMessageThreadUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMessageThreadUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/messageThread'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMessageThreadCount
 * request: searchMessageThreadCountUsingPOST
 * url: searchMessageThreadCountUsingPOSTURL
 * method: searchMessageThreadCountUsingPOST_TYPE
 * raw_url: searchMessageThreadCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchMessageThreadCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/messageThread/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMessageThreadCountUsingPOST_RAW_URL = function() {
  return '/search/messageThread/count'
}
export const searchMessageThreadCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMessageThreadCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/messageThread/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOffer
 * request: searchOfferUsingPOST
 * url: searchOfferUsingPOSTURL
 * method: searchOfferUsingPOST_TYPE
 * raw_url: searchOfferUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOfferUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/offer'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOfferUsingPOST_RAW_URL = function() {
  return '/search/offer'
}
export const searchOfferUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOfferUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/offer'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOfferCount
 * request: searchOfferCountUsingPOST
 * url: searchOfferCountUsingPOSTURL
 * method: searchOfferCountUsingPOST_TYPE
 * raw_url: searchOfferCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOfferCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/offer/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOfferCountUsingPOST_RAW_URL = function() {
  return '/search/offer/count'
}
export const searchOfferCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOfferCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/offer/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOplAuthorization
 * request: searchOplAuthorizationUsingPOST
 * url: searchOplAuthorizationUsingPOSTURL
 * method: searchOplAuthorizationUsingPOST_TYPE
 * raw_url: searchOplAuthorizationUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOplAuthorizationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/oplAuthorization'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOplAuthorizationUsingPOST_RAW_URL = function() {
  return '/search/oplAuthorization'
}
export const searchOplAuthorizationUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOplAuthorizationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/oplAuthorization'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOplAuthorizationCount
 * request: searchOplAuthorizationCountUsingPOST
 * url: searchOplAuthorizationCountUsingPOSTURL
 * method: searchOplAuthorizationCountUsingPOST_TYPE
 * raw_url: searchOplAuthorizationCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOplAuthorizationCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/oplAuthorization/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOplAuthorizationCountUsingPOST_RAW_URL = function() {
  return '/search/oplAuthorization/count'
}
export const searchOplAuthorizationCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOplAuthorizationCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/oplAuthorization/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOrganisation
 * request: searchOrganisationUsingPOST
 * url: searchOrganisationUsingPOSTURL
 * method: searchOrganisationUsingPOST_TYPE
 * raw_url: searchOrganisationUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/organisation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOrganisationUsingPOST_RAW_URL = function() {
  return '/search/organisation'
}
export const searchOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/organisation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchOrganisationCount
 * request: searchOrganisationCountUsingPOST
 * url: searchOrganisationCountUsingPOSTURL
 * method: searchOrganisationCountUsingPOST_TYPE
 * raw_url: searchOrganisationCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchOrganisationCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/organisation/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchOrganisationCountUsingPOST_RAW_URL = function() {
  return '/search/organisation/count'
}
export const searchOrganisationCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchOrganisationCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/organisation/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPost
 * request: searchPostUsingPOST
 * url: searchPostUsingPOSTURL
 * method: searchPostUsingPOST_TYPE
 * raw_url: searchPostUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/post'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostUsingPOST_RAW_URL = function() {
  return '/search/post'
}
export const searchPostUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/post'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPostCount
 * request: searchPostCountUsingPOST
 * url: searchPostCountUsingPOSTURL
 * method: searchPostCountUsingPOST_TYPE
 * raw_url: searchPostCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPostCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/post/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostCountUsingPOST_RAW_URL = function() {
  return '/search/post/count'
}
export const searchPostCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/post/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPostCategory
 * request: searchPostCategoryUsingPOST
 * url: searchPostCategoryUsingPOSTURL
 * method: searchPostCategoryUsingPOST_TYPE
 * raw_url: searchPostCategoryUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPostCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/postCategory'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostCategoryUsingPOST_RAW_URL = function() {
  return '/search/postCategory'
}
export const searchPostCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/postCategory'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPostCategoryCount
 * request: searchPostCategoryCountUsingPOST
 * url: searchPostCategoryCountUsingPOSTURL
 * method: searchPostCategoryCountUsingPOST_TYPE
 * raw_url: searchPostCategoryCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchPostCategoryCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/postCategory/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostCategoryCountUsingPOST_RAW_URL = function() {
  return '/search/postCategory/count'
}
export const searchPostCategoryCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostCategoryCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/postCategory/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchProductCategory
 * request: searchProductCategoryUsingPOST
 * url: searchProductCategoryUsingPOSTURL
 * method: searchProductCategoryUsingPOST_TYPE
 * raw_url: searchProductCategoryUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchProductCategoryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/productCategory'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProductCategoryUsingPOST_RAW_URL = function() {
  return '/search/productCategory'
}
export const searchProductCategoryUsingPOST_TYPE = function() {
  return 'post'
}
export const searchProductCategoryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/productCategory'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchProductCategoryCount
 * request: searchProductCategoryCountUsingPOST
 * url: searchProductCategoryCountUsingPOSTURL
 * method: searchProductCategoryCountUsingPOST_TYPE
 * raw_url: searchProductCategoryCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchProductCategoryCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/productCategory/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchProductCategoryCountUsingPOST_RAW_URL = function() {
  return '/search/productCategory/count'
}
export const searchProductCategoryCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchProductCategoryCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/productCategory/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchStoreChain
 * request: searchStoreChainUsingPOST
 * url: searchStoreChainUsingPOSTURL
 * method: searchStoreChainUsingPOST_TYPE
 * raw_url: searchStoreChainUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchStoreChainUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/storeChain'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchStoreChainUsingPOST_RAW_URL = function() {
  return '/search/storeChain'
}
export const searchStoreChainUsingPOST_TYPE = function() {
  return 'post'
}
export const searchStoreChainUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/storeChain'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchStoreChainCount
 * request: searchStoreChainCountUsingPOST
 * url: searchStoreChainCountUsingPOSTURL
 * method: searchStoreChainCountUsingPOST_TYPE
 * raw_url: searchStoreChainCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchStoreChainCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/storeChain/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchStoreChainCountUsingPOST_RAW_URL = function() {
  return '/search/storeChain/count'
}
export const searchStoreChainCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchStoreChainCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/storeChain/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStatistics
 * request: getStatisticsUsingGET
 * url: getStatisticsUsingGETURL
 * method: getStatisticsUsingGET_TYPE
 * raw_url: getStatisticsUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const getStatisticsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/statistics/{collectionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStatisticsUsingGET_RAW_URL = function() {
  return '/statistics/{collectionId}'
}
export const getStatisticsUsingGET_TYPE = function() {
  return 'get'
}
export const getStatisticsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/statistics/{collectionId}'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * exportStatistics
 * request: exportStatisticsUsingGET
 * url: exportStatisticsUsingGETURL
 * method: exportStatisticsUsingGET_TYPE
 * raw_url: exportStatisticsUsingGET_RAW_URL
 * @param collectionId - collectionId
 */
export const exportStatisticsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/statistics/{collectionId}/export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters['collectionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: collectionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const exportStatisticsUsingGET_RAW_URL = function() {
  return '/statistics/{collectionId}/export'
}
export const exportStatisticsUsingGET_TYPE = function() {
  return 'get'
}
export const exportStatisticsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/statistics/{collectionId}/export'
  path = path.replace('{collectionId}', `${parameters['collectionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateStoreChain
 * request: createOrUpdateStoreChainUsingPOST
 * url: createOrUpdateStoreChainUsingPOSTURL
 * method: createOrUpdateStoreChainUsingPOST_TYPE
 * raw_url: createOrUpdateStoreChainUsingPOST_RAW_URL
 * @param storeChainRequest - storeChainRequest
 */
export const createOrUpdateStoreChainUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storeChain'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['storeChainRequest'] !== undefined) {
    body = parameters['storeChainRequest']
  }
  if (parameters['storeChainRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: storeChainRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateStoreChainUsingPOST_RAW_URL = function() {
  return '/storeChain'
}
export const createOrUpdateStoreChainUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateStoreChainUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storeChain'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStoreChain
 * request: getStoreChainUsingGET
 * url: getStoreChainUsingGETURL
 * method: getStoreChainUsingGET_TYPE
 * raw_url: getStoreChainUsingGET_RAW_URL
 * @param id - id
 */
export const getStoreChainUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storeChain/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoreChainUsingGET_RAW_URL = function() {
  return '/storeChain/{id}'
}
export const getStoreChainUsingGET_TYPE = function() {
  return 'get'
}
export const getStoreChainUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storeChain/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteStoreChain
 * request: deleteStoreChainUsingDELETE
 * url: deleteStoreChainUsingDELETEURL
 * method: deleteStoreChainUsingDELETE_TYPE
 * raw_url: deleteStoreChainUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteStoreChainUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storeChain/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteStoreChainUsingDELETE_RAW_URL = function() {
  return '/storeChain/{id}'
}
export const deleteStoreChainUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteStoreChainUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storeChain/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStoreChains
 * request: getStoreChainsUsingGET
 * url: getStoreChainsUsingGETURL
 * method: getStoreChainsUsingGET_TYPE
 * raw_url: getStoreChainsUsingGET_RAW_URL
 * @param query - query
 */
export const getStoreChainsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/storeChains'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoreChainsUsingGET_RAW_URL = function() {
  return '/storeChains'
}
export const getStoreChainsUsingGET_TYPE = function() {
  return 'get'
}
export const getStoreChainsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/storeChains'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSubordinateOrganisations
 * request: getSubordinateOrganisationsUsingGET
 * url: getSubordinateOrganisationsUsingGETURL
 * method: getSubordinateOrganisationsUsingGET_TYPE
 * raw_url: getSubordinateOrganisationsUsingGET_RAW_URL
 * @param organisationLevel - organisationLevel
 */
export const getSubordinateOrganisationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/subordinate-organisations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organisationLevel'] !== undefined) {
    queryParameters['organisationLevel'] = parameters['organisationLevel']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSubordinateOrganisationsUsingGET_RAW_URL = function() {
  return '/subordinate-organisations'
}
export const getSubordinateOrganisationsUsingGET_TYPE = function() {
  return 'get'
}
export const getSubordinateOrganisationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/subordinate-organisations'
  if (parameters['organisationLevel'] !== undefined) {
    queryParameters['organisationLevel'] = parameters['organisationLevel']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSubordinateOrganisationsWithSelf
 * request: getSubordinateOrganisationsWithSelfUsingGET
 * url: getSubordinateOrganisationsWithSelfUsingGETURL
 * method: getSubordinateOrganisationsWithSelfUsingGET_TYPE
 * raw_url: getSubordinateOrganisationsWithSelfUsingGET_RAW_URL
 * @param query - query
 */
export const getSubordinateOrganisationsWithSelfUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/subordinate-organisations-with-self'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSubordinateOrganisationsWithSelfUsingGET_RAW_URL = function() {
  return '/subordinate-organisations-with-self'
}
export const getSubordinateOrganisationsWithSelfUsingGET_TYPE = function() {
  return 'get'
}
export const getSubordinateOrganisationsWithSelfUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/subordinate-organisations-with-self'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateUser
 * request: updateUserUsingPOST
 * url: updateUserUsingPOSTURL
 * method: updateUserUsingPOST_TYPE
 * raw_url: updateUserUsingPOST_RAW_URL
 * @param userRequest - userRequest
 */
export const updateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userRequest'] !== undefined) {
    body = parameters['userRequest']
  }
  if (parameters['userRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const updateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const updateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * activateAccount
 * request: activateAccountUsingPOST
 * url: activateAccountUsingPOSTURL
 * method: activateAccountUsingPOST_TYPE
 * raw_url: activateAccountUsingPOST_RAW_URL
 * @param userIdRequest - userIdRequest
 */
export const activateAccountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/activate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userIdRequest'] !== undefined) {
    body = parameters['userIdRequest']
  }
  if (parameters['userIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const activateAccountUsingPOST_RAW_URL = function() {
  return '/user/activate'
}
export const activateAccountUsingPOST_TYPE = function() {
  return 'post'
}
export const activateAccountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/activate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deactivateAccount
 * request: deactivateAccountUsingPOST
 * url: deactivateAccountUsingPOSTURL
 * method: deactivateAccountUsingPOST_TYPE
 * raw_url: deactivateAccountUsingPOST_RAW_URL
 * @param userIdRequest - userIdRequest
 */
export const deactivateAccountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/deactivate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userIdRequest'] !== undefined) {
    body = parameters['userIdRequest']
  }
  if (parameters['userIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deactivateAccountUsingPOST_RAW_URL = function() {
  return '/user/deactivate'
}
export const deactivateAccountUsingPOST_TYPE = function() {
  return 'post'
}
export const deactivateAccountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/deactivate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * sendActivationLink
 * request: sendActivationLinkUsingPOST
 * url: sendActivationLinkUsingPOSTURL
 * method: sendActivationLinkUsingPOST_TYPE
 * raw_url: sendActivationLinkUsingPOST_RAW_URL
 * @param userIdRequest - userIdRequest
 */
export const sendActivationLinkUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/email/send-activation-link'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userIdRequest'] !== undefined) {
    body = parameters['userIdRequest']
  }
  if (parameters['userIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const sendActivationLinkUsingPOST_RAW_URL = function() {
  return '/user/email/send-activation-link'
}
export const sendActivationLinkUsingPOST_TYPE = function() {
  return 'post'
}
export const sendActivationLinkUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/email/send-activation-link'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * verifyEmail
 * request: verifyEmailUsingPOST
 * url: verifyEmailUsingPOSTURL
 * method: verifyEmailUsingPOST_TYPE
 * raw_url: verifyEmailUsingPOST_RAW_URL
 * @param emailVerifyRequest - emailVerifyRequest
 */
export const verifyEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/email/verify'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['emailVerifyRequest'] !== undefined) {
    body = parameters['emailVerifyRequest']
  }
  if (parameters['emailVerifyRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: emailVerifyRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const verifyEmailUsingPOST_RAW_URL = function() {
  return '/user/email/verify'
}
export const verifyEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const verifyEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/email/verify'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getLoggedUser
 * request: getLoggedUserUsingGET
 * url: getLoggedUserUsingGETURL
 * method: getLoggedUserUsingGET_TYPE
 * raw_url: getLoggedUserUsingGET_RAW_URL
 */
export const getLoggedUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/logged'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getLoggedUserUsingGET_RAW_URL = function() {
  return '/user/logged'
}
export const getLoggedUserUsingGET_TYPE = function() {
  return 'get'
}
export const getLoggedUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/logged'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param changePasswordRequest - changePasswordRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['changePasswordRequest'] !== undefined) {
    body = parameters['changePasswordRequest']
  }
  if (parameters['changePasswordRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: changePasswordRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * resetPassword
 * request: resetPasswordUsingPOST
 * url: resetPasswordUsingPOSTURL
 * method: resetPasswordUsingPOST_TYPE
 * raw_url: resetPasswordUsingPOST_RAW_URL
 * @param resetPasswordRequest - resetPasswordRequest
 */
export const resetPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/reset'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['resetPasswordRequest'] !== undefined) {
    body = parameters['resetPasswordRequest']
  }
  if (parameters['resetPasswordRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: resetPasswordRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const resetPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/reset'
}
export const resetPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const resetPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/reset'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserRoles
 * request: getUserRolesUsingGET
 * url: getUserRolesUsingGETURL
 * method: getUserRolesUsingGET_TYPE
 * raw_url: getUserRolesUsingGET_RAW_URL
 */
export const getUserRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRolesUsingGET_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignOrganisation
 * request: assignOrganisationUsingPOST
 * url: assignOrganisationUsingPOSTURL
 * method: assignOrganisationUsingPOST_TYPE
 * raw_url: assignOrganisationUsingPOST_RAW_URL
 * @param organisationId - organisationId
 * @param userId - userId
 */
export const assignOrganisationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{userId}/assign/{organisationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  if (parameters['organisationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationId'))
  }
  path = path.replace('{userId}', `${parameters['userId']}`)
  if (parameters['userId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const assignOrganisationUsingPOST_RAW_URL = function() {
  return '/user/{userId}/assign/{organisationId}'
}
export const assignOrganisationUsingPOST_TYPE = function() {
  return 'post'
}
export const assignOrganisationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{userId}/assign/{organisationId}'
  path = path.replace('{organisationId}', `${parameters['organisationId']}`)
  path = path.replace('{userId}', `${parameters['userId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}