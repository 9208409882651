<template>
    <div>
        <h1 v-if="!collectionId" class="page-header"><i class="fas fa-handshake"></i>
            Lista organizacji partnerskich
        </h1>
        <h2 v-else class="page-header"><i class="fas fa-handshake"></i>
            Lista organizacji biorących udział w zbiórce
        </h2>

        <div class="search-criteria" @keyup.enter="search">
            <OrganisationSearchCriteria ref="organisationSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button label="Eksportuj dane" icon="pi pi-download" @click="exportXLSX(false)"/>
                        <Button label="Eksportuj wszystkie dane" icon="pi pi-download" @click="exportXLSX(true)"/>
                        <Button v-if="!collectionId && !Role.isOPLCoordinator()"
                                class="button-link" type="button"
                                role="link" icon="pi pi-plus"
                                :label="Role.isBZCoordinator() ? 'Dodaj OPL' : 'Dodaj organizację'"
                                @click="$router.push({name: 'organisationCreate'})"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <OrganisationTable ref="organisationTable"
                           :search-criteria="searchCriteria" :display-all="displayAll"
                           :collection-id="collectionId" :inviting-organisations="invitingOrganisations"
                           @loaded="loadInitialDisplayAll"
                           @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import OrganisationSearchCriteria from "./components/OrganisationSearchCriteria";
    import OrganisationTable from "./components/OrganisationTable";

    export default {
        name: "OrganisationListView",

        components: {
            OrganisationTable, OrganisationSearchCriteria, Button, Toolbar,
        },

        props: {
            collectionId: {
                type: Number,
                default: null,
            },
            invitingOrganisations: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                searchCriteria: {
                    name: "",
                    city: "",
                    voivodeship: "",
                    street: "",
                    organisationLevel: null,
                    supervisorName: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                displayAll: null,
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.organisationTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    city: "",
                    voivodeship: "",
                    street: "",
                    organisationLevel: null,
                    supervisorName: "",
                    page: this.searchCriteria.page,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },

            exportXLSX(allData) {
                this.$refs.organisationTable.exportXLSX(allData);
            },
        },
    };
</script>

<style scoped>

</style>
