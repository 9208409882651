<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomInputText name="name" label="Nazwa kategorii produktów" v-model="internalValue.name"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomSelectOneActiveStatus name="active" label="Status" v-model="internalValue.active"
                                         :empty-value="true"/>
        </div>
    </div>
</template>

<script>
    import CustomSelectOneActiveStatus from "@/components/form/CustomSelectOneActiveStatus";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "ProductCategorySearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomSelectOneActiveStatus, CustomInputText},
    };
</script>

<style lang="less" scoped>

</style>
