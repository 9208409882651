<template>
    <div>
        <h1 class="page-header"><i class="pi pi-star-o"></i> Dane oferty darowizny</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveCommission" v-slot="{ isSubmitting }">
                <div class="p-grid commission-form">
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomInputText name="product" label="Produkt" v-model="commission.product" rules="required"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomInputNumber name="amount" label="Ilość" :min="1"
                                           v-model="commission.initialAmount" rules="required"/>
                    </div>
                    <div class="p-lg-6 p-sm-6 p-col-12">
                        <CustomInputText name="unit" label="Jednostka" v-model="commission.unit" rules="required"/>
                    </div>
                    <div v-if="!Role.isFPBZCoordinator()" class="p-col-6">
                        <CustomSelectOneOrganisationLevel name="organisationLevel"
                                                          label="Poziom organizacji"
                                                          v-model="commission.organisationLevel" rules="required"
                                                          @change="getOrganisations"
                                                          v-bind:omitting-fpbz="true"/>
                    </div>
                    <div class="p-col-12">
                        <CustomMultiSelect v-if="commission.organisationLevel" v-model="commission.organisationIds"
                                           :items="organisationsToSelect" name="organisationsToSelect"
                                           itemLabel="label" itemValue="value" label="Wybierz organizacje"
                                           class="multiselect-custom p-col-12" :required="true"
                                           :error-message="organisationIdsErrorMessage"/>
                    </div>
                    <div class="p-col-12">
                        <CustomInputTextarea v-model="commission.comment" name="comment" label="Komentarz"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        createOrUpdateCommissionUsingPOST as createOrUpdateCommission,
        getCommissionUsingGET as getCommission,
        getOtherOrganisationsUsingGET as getOtherOrganisations,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
    } from "@/swagger/vue-api-client";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import CustomSelectOneOrganisationLevel from "@/components/form/CustomSelectOneOrganisationLevel";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "CommissionFormView",

        components: {
            CustomInputTextarea,
            Button,
            Toolbar,
            Form,
            CustomInputNumber,
            CustomMultiSelect,
            CustomSelectOneOrganisationLevel,
            CustomInputText,
        },

        props: {
            value: {},
        },

        data() {
            return {
                commission: {
                    initialAmount: null,
                    product: null,
                    unit: null,
                    organisations: null,
                    organisationIds: [],
                    organisationLevel: SystemRole.isFPBZCoordinator() ? "BZ" : null,
                    comment: null,
                },
                organisationsToSelect: [],
                organisationIdsErrorMessage: null,
                Role: SystemRole,
                loaded: false,
            };
        },

        mounted() {
            if (this.$route.params.id) {
                Promise.all([getCommission({id: this.$route.params.id}),
                             getOtherOrganisations({organisationLevel: this.commission.organisationLevel})])
                    .then((response) => {
                        this.commission = response[0].data;
                        this.organisationsToSelect = response[1].data;
                        this.commission.organisationIds = this.commission.organisations.map((item) => item.id);

                        this.loaded = true;
                    });
            } else {
                if (this.Role.isFPBZCoordinator()) {
                    this.getOrganisations();
                }

                this.loaded = true;
            }
        },

        methods: {
            saveCommission() {
                // TODO: naprawic, by MultiSelect dzialal z vee-validate
                if (this.commission.organisationIds.length === 0) {
                    this.organisationIdsErrorMessage = "Pole jest wymagane";

                    return;
                }
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdateCommission({commissionRequest: this.commission}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },

            getOrganisations() {
                this.commission.organisationIds = [];
                if (this.commission.organisationLevel === "BZ") {
                    getOtherOrganisations({organisationLevel: this.commission.organisationLevel}).then((response) => {
                        this.organisationsToSelect = response.data;
                    }).catch((error) => {
                        if (error.response && error.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
                } else {
                    getSubordinateOrganisations().then((response) => {
                        this.organisationsToSelect = response.data;
                    }).catch((error) => {
                        if (error.response && error.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        }
                    });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .commission-form {
        max-width: 100%;
    }
</style>
