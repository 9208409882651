<template>
    <div>
        <h1 class="page-header"><i class="pi pi-lock"></i> Zmiana hasła</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveUser">
                <div class="p-grid user-form">
                    <div class="p-col-12">
                        <CustomPassword name="oldPassword"
                                        label="Stare hasło" v-model="oldPassword"
                                        rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <CustomPassword name="newPassword"
                                        label="Nowe hasło" v-model="newPassword"
                                        rules="required|validPassword"/>
                    </div>
                    <div class="p-col-12">
                        <CustomPassword name="confirmedNewPassword"
                                        label="Potwierdź nowe hasło"
                                        v-model="confirmedNewPassword"
                                        rules="required|equalTo:@newPassword,Hasła nie są takie same"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import CustomPassword from "@/components/form/CustomPassword";
    import {changePasswordUsingPOST as changePassword} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "ChangePasswordView",

        components: {
            Button,
            Form,
            Toolbar,
            CustomPassword,
        },

        props: {
            value: {},
            editingOwn: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                oldPassword: null,
                newPassword: null,
                confirmedNewPassword: null,
                loaded: false,
            };
        },

        mounted() {
            this.loaded = true;
        },

        methods: {
            saveUser() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const changePasswordRequest = {
                            oldPassword: this.oldPassword,
                            newPassword: this.newPassword,
                        };

                        changePassword({changePasswordRequest}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zmieniono hasło",
                            });

                            this.$router.push({name: "map"});
                        }).catch((error) => {
                            if (error.response && error.response.status === 400) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: error.response.data,
                                });

                                this.oldPassword = null;
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
.user-form {
    width: 80%;
    max-width: 800px;
}
</style>
