export const ContextMenuMixin = {

    data() {
        return {
            actionsMenuVisible: false,
        };
    },

    methods: {
        moveActionsMenu(id) {
            const containers = document.getElementsByClassName("actions-" + id);

            let fatherNode = this.$refs["actionsMenu" + id];
            if (!fatherNode) {
                fatherNode = this.$refs["manage-actionsMenu" + id]; // fileTable
            }

            containers[0].appendChild(fatherNode.container);
            this.actionsMenuVisible = true;
        },

        onHideActionsMenu() {
            this.actionsMenuVisible = false;
        },
    },

};

export default {
    ContextMenuMixin,
};
