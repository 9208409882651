<template>
    <div class="layout-blog-wrapper wrapper">
        <div class="main">
            <header>
                <div role="navigation">
                    <div class="skip-links">
                        <a href="#content" class="inner">Przejdź do treści</a>
                    </div>
                    <div class="environment-description">
                        {{ envDesc }}
                    </div>
                    <router-link class="back" :to="{name: 'map'}">
                        <i class="pi pi-home"/> Menu główne
                    </router-link>
                    <router-link v-if="$route.params.id"
                                 class="back back-to-post-list" :to="{name: 'postList'}">
                        <i class="pi pi-refresh" /><span>Lista wpisów</span>
                    </router-link>
                    <div class="user-info" v-if="fullName">
                        <router-link v-if="fullName" class="div-button" :to="{name: 'userEditOwn'}">
                            <i class="pi pi-user" />
                            <span class="user-info-text">
                                {{ fullName }}
                            </span>
                        </router-link>
                        <router-link class="div-button" :to="{name: 'organisationEditOwn'}">
                            <i class="fas fa-handshake" />
                            <span class="organisation-name-text">
                                {{ organisationName }}
                            </span>
                        </router-link>
                        <div tabindex="0" class="div-button" role="button" @click="logout">
                            <i class="pi pi-sign-out" />
                            <span class="logout-text">Wyloguj</span>
                        </div>
                    </div>
                </div>
            </header>
            <div class="general-container">
                <div>
                    <div class="content" id="content" role="main">
                        <div name="content">
                            <span class="visually-hidden">Wybierz kategorie wpisów do wyświetlenia</span>
                            <PostCategoryList />
                        </div>
                    </div>
                </div>
                <div :key="$route.fullPath">
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import PostCategoryList from "@/views/components/PostCategoryList";

    export default {
        name: "LayoutBlog",
        components: {PostCategoryList},
        data() {
            return {
                envDesc: process.env.VUE_APP_ENV_DESC,
                fullName: localStorage.getItem("fullName"),
                organisationName: localStorage.getItem("organisationName"),
            };
        },

        methods: {
            logout() {
                localStorage.removeItem("token");
                localStorage.removeItem("role");
                localStorage.removeItem("fullName");
                this.$router.push({name: "login"});
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/layout-blog";
</style>
