<template>
    <div>
        <DataTable ref="collectionTable" v-if="isMounted" :value="collections" :lazy="true"
                   :paginator="paginator" v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerClass="visually-hidden-header">
                <template #body="slotProps">
                    <div v-if="!slotProps.data.closedByFpbz">
                        <Button v-if="slotProps.data.isInvited"
                                class="button-link" type="button" role="link"
                                icon="pi pi-external-link" label="Odpowiedz na zaproszenie"
                                @click="$router.push({
                                    name: 'collectionInvitationAnswer',
                                    params: {'id': slotProps.data.id},
                                })"/>
                        <Button v-else class="button-link" type="button" role="link"
                                icon="pi pi-chevron-right" label="Przejdź do zbiórki"
                                @click="$router.push({name: 'collectionHub', params: {'id': slotProps.data.id}})"/>
                    </div>
                    <div v-else>
                        <Button class="p-button-outlined" type="button" role="link"
                                icon="pi pi-chevron-right" label="Przejdź do historii zbiórki"
                                @click="$router.push({name: 'collectionHub', params: {'id': slotProps.data.id}})"/>
                    </div>
                </template>
            </Column>
            <Column field="name" header="Nazwa" :sortable="true"></Column>
            <Column field="invitationsStartDateReadable" header="Data rozpoczęcia okresu zapraszania sklepów"></Column>
            <Column field="startDateReadable" header="Data rozpoczęcia"></Column>
            <Column field="endDateReadable" header="Data zakończenia"></Column>
            <Column header="Status">
                <template #body="slotProps">
                    <p v-if="slotProps.data.closedByFpbz" class="archival">Archiwalna</p>
                    <p v-else-if="slotProps.data.startedByFpbz" class="ongoing">W trakcie</p>
                    <p v-else-if="slotProps.data.invitationsStartedByFpbz" class="store-invite-phase">
                        Okres zapraszania sklepów
                    </p>
                    <p v-else-if="slotProps.data.isInvited"
                       class="active-invitation">Masz zaproszenie</p>
                    <p v-else class="inactive">Zaplanowana</p>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ collections.length > 1 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import {
        searchCollectionUsingPOST as searchCollection,
        searchCollectionCountUsingPOST as searchCollectionCount,
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
    } from "@/swagger/vue-api-client";
    import {DateUtils} from "@/utils/DateUtils";
    import {SystemRole} from "@/utils/SystemRole";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CollectionTable",
        components: {
            Button, Column, DataTable,
        },
        mixins: [AccessibilityTableMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                collections: [],
                userOrganisationId: null,
                searchParams: this.searchCriteria,
                Role: SystemRole,
                isMounted: false,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },
        },

        async mounted() {
            try {
                const response = await getLoggedUserOrganisation();
                this.userOrganisationId = response.data.id;
            } catch (error) {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                });
                return;
            }

            this.$emit("loaded");
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchCollection({searchCriteria: this.searchCriteria}).then((response) => {
                    this.collections = response.data;
                    this.collections.forEach((collection) => {
                        collection.invitationsStartDateReadable = DateUtils
                            .timestampToReadableDate(collection.invitationsStartDate);
                        collection.startDateReadable = DateUtils.timestampToReadableDate(collection.startDate);
                        collection.endDateReadable = DateUtils.timestampToReadableDate(collection.endDate);
                    });

                    this.loading = false;
                    this.isMounted = true;
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchCollectionCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },
        },
    };
</script>

<style lang="less" scoped>
.archival {
    color: grey;
}

.ongoing {
    color: green;
}

.store-invite-phase {
    color: purple;
}

.active-invitation {
    color: blue;
}

.inactive {
    color: orange;
}

.contrast-black {
    .archival, .ongoing, .store-invite-phase, .active-invitation, .inactive {
        color: black;
    }
}

// TODO: add .contrast-yellow case
</style>
