<template>
    <div>
        <div class="change-password-panel-header">
            <img src="@/assets/logo.png" alt="logo">
        </div>
        <div class="change-password-panel-content">
            <div class="p-grid">
                <div class="p-col-12">
                    <h1>Email zweryfikowany</h1>
                </div>
                <div class="p-col-12">
                    <h2>Dziękujemy za rejestrację!</h2>
                </div>
                <div class="p-col-12">
                    Aby konto zostało w pełni aktywowane musi
                    zostać zaakceptowane przez koordynatora Organizacji nadrzędnej.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {verifyEmailUsingPOST as verifyEmail} from "@/swagger/vue-api-client";

    export default {
        name: "EmailVerifyView",

        data() {
            return {
                emailVerifyRequest: {
                    emailVerificationToken: "",
                },
            };
        },

        mounted() {
            this.emailVerifyRequest.emailVerificationToken = this.$route.query.emailVerificationToken;
            this.verifyEmail();
        },

        methods: {
            verifyEmail() {
                verifyEmail({emailVerifyRequest: this.emailVerifyRequest});
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/change-password.less";
</style>
