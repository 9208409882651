/* eslint-disable */

export const DateUtils = {

    timestampToReadableDate(timestamp, skipTime = true, removeTimezoneOffset = true) {
        let date = new Date(timestamp);
        if (removeTimezoneOffset) {
            date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        }
        const dateString = date.toISOString();

        return skipTime
            ? /^(\d{4}-\d{2}-\d{2}).*$/.exec(dateString)[1]
            : /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}).*$/.exec(dateString).slice(1).join(" ");
    },

    // removes local timezone offset from the date
    convertDateNoLocalTimezoneOffset(date) {
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    },

};
