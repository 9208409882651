<template>
    <div>
        <h1 class="page-header"><i class="far fa-calendar-alt"></i> Harmonogram odbiorów darowizn</h1>

        <div class="search-criteria" @keyup.enter="search">
            <DonationReceivalEventSearchCriteria ref="donationReceivalEventSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button v-if="Role.isBZCoordinator()"
                                class="button-link" type="button" role="link"
                                icon="pi pi-plus" label="Zaplanuj odbiór darowizny"
                                @click="$router.push({name: 'donationReceivalEventCreate'})"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <FullCalendar v-if="loaded" :events="events" :options="options"/>
        <Dialog v-model:visible="display" header="Informacje o odbiorze darowizny"
                :modal="true" :dismissableMask="true" append-to=".wrapper" v-if="isMounted">
            <p>Tytuł: {{selectedEvent.title}}</p>
            <p v-if="!selectedEvent.rrule">Data rozpoczęcia: {{selectedEvent.eventStart}}</p>
            <p v-if="!selectedEvent.rrule">Data zakończenia: {{selectedEvent.eventEnd}}</p>
            <p>Odpowiedzialna OPL: {{selectedEvent !== null ? selectedEvent.assignedOplName : ""}}</p>
            <p>Sklep: {{selectedEvent !== null ? selectedEvent.storeName : ""}}</p>
            <Toolbar>
                <template v-slot:end>
                    <Button v-if="displayButtons"
                            class="p-mr-1 button-link" type="button" role="link"
                            icon="pi pi-pencil" v-tooltip="'Edytuj'"
                            @click="$router.push({
                                name: 'donationReceivalEventEdit',
                                params: {'id': selectedEvent.id},
                            })"/>
                    <Button v-if="displayButtons"
                            type="button" icon="pi pi-times" @click="deleteEvent(selectedEvent)"
                            v-tooltip="'Usuń'"></Button>
                </template>
            </Toolbar>
        </Dialog>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Dialog from "primevue/dialog";
    import Toolbar from "primevue/toolbar";
    import FullCalendar from "primevue/fullcalendar";
    import "rrule";
    import rrulePlugin from "@fullcalendar/rrule";
    import plLocale from "@fullcalendar/core/locales/pl";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import interactionPlugin from "@fullcalendar/interaction";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {
        searchDonationReceivalEventUsingPOST as searchDonationReceivalEvent,
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
        deleteDonationReceivalEventUsingDELETE as deleteDonationReceivalEvent,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {DateUtils} from "@/utils/DateUtils";
    import {ToastUtils} from "@/utils/ToastUtils";
    import DonationReceivalEventSearchCriteria from "./components/DonationReceivalEventSearchCriteria";

    export default {
        name: "DonationReceivalEventListView",
        components: {
            DonationReceivalEventSearchCriteria, Button, Toolbar, FullCalendar, Dialog,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                options: {
                    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin],
                    headerToolbar: {
                        left: "",
                        center: "title",
                        right: "",
                    },
                    footerToolbar: {
                        left: "prev,next",
                        right: "dayGridMonth,timeGridWeek,timeGridDay",
                    },
                    locale: plLocale,
                    // editable: true, // edycja poprzez przeciąganie eventów po kalendarzu
                    eventClick: (event) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const eventId = parseInt(event.event._def.publicId, 10);
                        this.selectedEvent = this.events.find((item) => item.id === eventId);
                        this.display = true;
                    },
                    eventTimeFormat: {
                        hour: "2-digit",
                        minute: "2-digit",
                    },
                },
                events: null,
                selectedEvent: null,
                display: false,
                Role: SystemRole,
                loaded: false,
                organisationId: null,
                eventColor: null,
                eventTextColor: null,
                isMounted: false,
            };
        },

        computed: {
            displayButtons() {
                return this.Role.isFPBZCoordinator()
                    || (this.Role.isBZCoordinator() && this.organisationId === this.selectedEvent.ownerId);
            },
        },

        async mounted() {
            SearchCriteria.loadCriteria(this);

            try {
                await this.fetchEvents();
            } catch (error) {
                await ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Nie udało się pobrać danych",
                });
            }

            const response = await getLoggedUserOrganisation();
            this.organisationId = response.data.id;

            const currentContrastType = document.getElementsByClassName("wrapper")[0]
                .getAttribute("data-contrast-type");
            this.handleContrastType(currentContrastType);

            this.emitter.on("changedContrast", (color) => {
                this.handleContrastType(color);
            });

            this.loaded = true;
            this.isMounted = true;
        },

        methods: {
            async fetchEvents() {
                const response = await searchDonationReceivalEvent({searchCriteria: this.searchCriteria});
                this.events = response.data.map((item) => this.toCalendarEvent(item));
            },

            toCalendarEvent(item) {
                const startDateReadable = DateUtils.timestampToReadableDate(item.startDate, false);
                const endDateReadable = DateUtils.timestampToReadableDate(item.endDate, false);

                // wydarzenie bez cykliczności
                if (!item.recurring) {
                    return {
                        id: item.id,
                        title: item.title,
                        start: startDateReadable,
                        end: endDateReadable,
                        eventStart: startDateReadable,
                        eventEnd: endDateReadable,
                        assignedOplName: item.assignedOpl.name,
                        storeName: item.store.name,
                        ownerId: item.owner.id,
                        color: this.eventColor,
                        textColor: this.eventTextColor,
                    };
                }

                // wydarzenie cykliczne, powtarzane w danym dniu
                if (item.recurringOnWeekdays) {
                    const recurrenceRule = {
                        dtstart: startDateReadable,
                        freq: "daily",
                        count: item.interval,
                    };
                    recurrenceRule.byweekday = item.weekdays.map((weekday) => {
                        switch (weekday) {
                            case 1:
                                return "Mo";
                            case 2:
                                return "Tu";
                            case 3:
                                return "We";
                            case 4:
                                return "Th";
                            case 5:
                                return "Fr";
                            case 6:
                                return "Sa";
                            case 0:
                                return "Su";
                            default:
                                // eslint-disable-next-line no-throw-literal
                                throw {error: "error"}; // TODO: find out a better way
                        }
                    });
                    return {
                        id: item.id,
                        title: item.title,
                        eventStart: startDateReadable,
                        eventEnd: endDateReadable,
                        assignedOplName: item.assignedOpl.name,
                        storeName: item.store.name,
                        ownerId: item.owner.id,
                        rrule: recurrenceRule,
                        duration: item.duration,
                        color: this.eventColor,
                        textColor: this.eventTextColor,
                    };
                }

                // wydarzenie cykliczne powtarzane co interwał
                let frequency;
                switch (item.intervalUnitType) {
                    case "DAY":
                        frequency = "daily";
                        break;
                    case "WEEK":
                        frequency = "weekly";
                        break;
                    case "MONTH":
                        frequency = "monthly";
                        break;
                    default:
                        frequency = "daily";
                        break;
                }
                const recurrenceRule = {
                    dtstart: startDateReadable,
                    freq: frequency,
                    count: item.interval,
                };

                return {
                    id: item.id,
                    title: item.title,
                    eventStart: startDateReadable,
                    eventEnd: endDateReadable,
                    assignedOplName: item.assignedOpl.name,
                    storeName: item.store.name,
                    ownerId: item.owner.id,
                    rrule: recurrenceRule,
                    duration: item.duration,
                    color: this.eventColor,
                    textColor: this.eventTextColor,
                };
            },

            handleContrastType(color) {
                if (color === "black") {
                    this.eventColor = "#000";
                    this.eventTextColor = "#ffdf00";
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.eventColor = null;
                    this.eventTextColor = null;
                } else {
                    this.eventColor = null;
                    this.eventTextColor = null;
                }

                this.fetchEvents();
            },

            search() {
                SearchCriteria.updateUrl(this);

                this.fetchEvents();
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            getClearSearchCriteria() {
                return {
                    assignedOplId: null,
                    storeId: null,
                };
            },

            deleteEvent(event) {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno usunąć akcję odbioru darowizny? "
                        + "Podana akcja jest wydarzeniem cyklicznym. Zostaną usunięte wszystkie jej kopie.",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteDonationReceivalEvent({id: event.id}).then(() => {
                            this.display = false;
                            this.fetchEvents();
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },
        },
    };
</script>

<style scoped>

</style>
