<template>
    <div>
        <CustomSelectOne :name="name" :items="items" :empty-value="emptyValue" v-model="internalValue" :rules="rules"
                         item-label="label" item-value="value" :label="label" :disabled="disabled" v-if="loaded"
                         @change="onChange"/>
    </div>
</template>

<script>
    import {getOrganisationLevelsUsingGET as getOrganisationLevels} from "../../swagger/vue-api-client";
    import CustomSelectOne from "./inner/CustomSelectOne";

    export default {
        name: "CustomSelectOneOrganisationLevel",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            omittingFpbz: {
                type: Boolean,
                default: false,
            },
            omittingOpl: {
                type: Boolean,
                default: false,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue", "change"],

        methods: {
            onChange(val) {
                if (val && val.value) {
                    this.$emit("change", val.value);
                } else {
                    this.$emit("change", undefined);
                }
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        data() {
            return {
                items: [],
                loaded: false,
            };
        },

        beforeMount() {
            getOrganisationLevels().then((response) => {
                this.items = response.data;
                if (this.omittingFpbz) {
                    this.items = this.items.filter((item) => item.value !== "FPBZ");
                }
                if (this.omittingOpl) {
                    this.items = this.items.filter((item) => item.value !== "OPL");
                }
                this.loaded = true;
            });
        },

        components: {CustomSelectOne},
    };
</script>

<style scoped>

</style>
