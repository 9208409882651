<template>
    <div>
        <h1 class="page-header"><i class="fas fa-edit"></i> Lista wpisów</h1>

        <div class="search-criteria" @keyup.enter="search">
            <PostSearchCriteria ref="postSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button class="button-link" type="button" role="link"
                                icon="pi pi-plus" label="Dodaj wpis"
                                @click="$router.push({name: 'postCreate'})"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <PostTable ref="postTable" :search-criteria="searchCriteria"
                   :display-all="displayAll" @loaded="loadInitialDisplayAll"
                   @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import PostSearchCriteria from "./components/PostSearchCriteria";
    import PostTable from "./components/PostTable";

    export default {
        name: "PostEditListView",
        components: {
            PostTable, PostSearchCriteria, Button, Toolbar,
        },

        data() {
            return {
                searchCriteria: {
                    name: "",
                    postCategory: null,
                    publishDate: null,
                    editView: true,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                displayAll: null,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.postTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    postCategory: null,
                    publishDate: null,
                    editView: true,
                    page: this.searchCriteria.page,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },
        },
    };
</script>

<style scoped>

</style>
