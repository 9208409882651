<template>
    <div class="p-col-12 p-m-0" style="text-align:left">
        Sumarycznie {{ searchParams.numberOfDay !== 1 ?
            'do tej pory zebrano ' + collectedToTodaySum + ' kg, z czego'
            : '' }}
        dziś zebrano {{ collectedSums[searchParams.numberOfDay - 1]?.toFixed(2) }} kg.
    </div>
    <div style="overflow: auto;">
        <DataTable ref="collectionDailyReportTable" :value="collectionDailyReports" :lazy="true"
                   :paginator="paginator"
                   dataKey="id" paginatorPosition="both"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}"
                   :key="displayAll">
            <Column field="collectedFoodToToday" header="Do tej pory zebrano"
                    v-if="searchParams.numberOfDay !== 1">
                <template #body="slotProps">
                    {{ formatNumber(slotProps.data.collectedFoodToToday) }}
                </template>
            </Column>
            <Column field="collectedFood" header="Dziś zebrano" :sortable="true">
                <template #body="slotProps">
                    {{ formatNumber(slotProps.data.collectedFood) }}
                </template>
            </Column>
            <Column header="Dodaj/odejmij" v-if="!Role.isFPBZCoordinator() && !isCollectionClosed"
                    style="width: 280px">
                <template #body="slotProps">
                    <CustomInputNumber name="collectedFoodToAdd"
                                       :min-digits="2"
                                       :max-digits="2"
                                       class="collected-food-input"
                                       label="Tu wpisz liczbę kilogramów"
                                       :min="-99999999"
                                       :handle-esc-enter-blur="true"
                                       :disabled="shouldBeLocked(slotProps.data)"
                                       @on-esc="cancelCollectedFood(slotProps.data)"
                                       @on-blur="saveCollectedFood(
                                           $event,
                                           slotProps.data.id)"
                                       v-model="slotProps.data.collectedFoodToAdd"/>
                </template>
            </Column>
            <Column header="Nazwa sklepu" field="store.name" :sortable="true"/>
            <!-- TODO add sortable to storechain and bz/opl when backend implements it -->
            <Column header="Sieć sklepów" field="store.storeChain.name"/>
            <Column header="Adres sklepu" field="store.address"/>
            <Column header="BŻ" field="store.assignedBz.name"/>
            <Column header="OPL" field="store.assignedOpl.name"/>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {
        searchCollectionDailyReportUsingPOST as searchCollectionDailyReport,
        searchCollectionDailyReportCountUsingPOST as searchCollectionDailyReportCount,
        saveCollectionDailyReportValueUsingPOST as saveCollectionDailyReport,
        getCollectionDailyReportSummaryUsingPOST as getSummary,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {AddressUtils} from "@/utils/AddressUtils";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {SystemRole} from "@/utils/SystemRole";
    import {NumbersMixin} from "@/mixins/NumbersMixin";

    export default {
        name: "CollectionDailyReportTable",
        components: {
            Column, DataTable, CustomInputNumber,
        },
        mixins: [NumbersMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            isCollectionClosed: {
                type: Boolean,
                default: true,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                collectionDailyReports: [],
                searchParams: this.searchCriteria,
                Role: SystemRole,
                collectedSums: [],
                organisationLockedReports: false,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },
        },

        mounted() {
            getSummary({searchCriteria: this.searchCriteria}).then((response) => {
                this.collectedSums = response.data;
            });
            this.$emit("loaded");
        },

        computed: {
            collectedToTodaySum() {
                if (this.collectedSums) {
                    return this.collectedSums.slice(0, this.searchParams.numberOfDay)
                        .reduce((acc, r) => +acc + r, 0).toFixed(2);
                }
                return "0.00";
            },

            canLockReports() {
                if (this.collectionDailyReports.length === 0) {
                    return false;
                }
                if (this.Role.isFPBZCoordinator()) {
                    return false;
                }
                if (this.Role.isOPLCoordinator()) {
                    return !this.isCollectionClosed && this.collectionDailyReports.some((report) => !report.locked);
                }
                if (this.Role.isBZCoordinator()) {
                    return !this.isCollectionClosed && this.collectionDailyReports.some((report) => !report.locked
                        && report.store.assignedOpl.organisationLevel === "BZ");
                }
                return false;
            },
        },

        methods: {
            shouldBeLocked(data) {
                if (this.organisationLockedReports) {
                    return true;
                }
                if (!data.locked) {
                    return false;
                }
                if (data.store.assignedOpl?.organisationLevel === "OPL" && this.Role.isBZCoordinator()) {
                    return false;
                }
                return data.locked;
            },

            cancelCollectedFood(report) {
                report.collectedFoodToAdd = null;
            },

            saveCollectedFood(val, id) {
                if (!val) {
                    return;
                }
                const body = {
                    id,
                    collectedFood: val,
                };
                saveCollectionDailyReport({collectionDailyReportRequest: body}).then(() => {
                    const report = this.collectionDailyReports.find((r) => r.id === id);
                    report.collectedFood = +report.collectedFood + val;
                    this.collectedSums[this.searchParams.numberOfDay - 1] += val;
                    // report.collectedFood = report.collectedFood.toFixed(2);
                    report.collectedFoodToAdd = null;
                    ToastUtils.clearToasts(this);
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane",
                    }, false);
                }).catch((e) => {
                    if (e.response && e.response.status === 400) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Raport wstępny zablokowany",
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się zapisać danych",
                        });
                    }
                });
            },

            search() {
                getSummary({searchCriteria: this.searchCriteria}).then((response) => {
                    this.collectedSums = response.data;
                });
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchCollectionDailyReport({searchCriteria: this.searchCriteria}).then((response) => {
                    this.collectionDailyReports = response.data;
                    this.collectionDailyReports.forEach((cdr) => {
                        cdr.store.address = AddressUtils.generateAddressString(cdr.store);
                        // assuming when we see BZ as OPL then 100% it's our user org and user locked reports
                        if (cdr.store.assignedOpl.organisationLevel === "BZ" && cdr.locked) {
                            this.organisationLockedReports = true;
                        }
                    });
                    this.loading = false;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        life: 3000,
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchCollectionDailyReportCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        life: 3000,
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
