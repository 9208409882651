<template>
    <div class="custom-select-one">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <Dropdown :id="name" :options="internalItems" :optionLabel="itemLabel"
                  :optionValue="itemValue" :disabled="disabled"
                  @change="onChange" :rules="rules"
                  v-model="internalValue" :placeholder="internalValue ? '' : label"
                  :ariaLabelledBy="name" appendTo="self"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";

    export default {
        name: "CustomSelectOne",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
                default: "label",
            },
            itemValue: {
                type: String,
                default: "value",
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue", "change"],

        components: {Dropdown},

        data() {
            return {
                internalItems: this.items,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        mounted() {
            if (this.emptyValue) {
                this.internalItems = [...this.items, {value: null, label: this.$t("message.other.emptyValue")}];
            }
        },

        methods: {
            onChange(val) {
                if (val && val.value) {
                    this.handleChange(val.value);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

    };
</script>

<style lang="less" scoped>
    .custom-select-one {
        margin-bottom: 10px;
        position: relative;

        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-dropdown {
            width: 100%;
        }
    }
</style>
