<template>
    <div>
        <PanelMenu v-if="isSuperAdmin !== null" :model="getMenuItems" v-model:expandedKeys="expandedKeys"
                   :key="ongoingCollection"/>
    </div>
</template>

<script>
    import PanelMenu from "primevue/panelmenu";
    import {
        isSuperAdminUsingGET as isSuperAdmin,
        getPlannedCollectionIdUsingGET as getPlannedCollectionId,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "AppMenu",

        components: {PanelMenu},

        data() {
            return {
                isSuperAdmin: null,
                ongoingCollection: false,
                itemsSuperAdmin: [
                    {
                        key: "0_0",
                        label: "Mapa",
                        icon: "pi pi-map",
                        to: "/map",
                    },
                    {
                        key: "0_1",
                        label: this.$t("message.menu.donations"),
                        icon: "fas fa-shipping-fast",
                        items: [
                            {
                                label: this.$t("message.menu.stores"),
                                icon: "fas fa-shopping-basket",
                                to: "/donation/store/list",
                                key: "0_1_0",
                            },
                            {
                                label: this.$t("message.menu.donationReceivalEvents"),
                                icon: "far fa-calendar-alt",
                                to: "/donation/donation-receival-event/list",
                                key: "0_1_1",
                            },
                        ],
                    },
                    {
                        key: "0_2",
                        label: this.$t("message.menu.collections"),
                        icon: "fas fa-shopping-cart",
                        items: [
                            {
                                key: "0_2_0",
                                label: this.$t("message.menu.productCategories"),
                                icon: "fas fa-gifts",
                                to: "/collection/product-category/list",
                            },
                            {
                                key: "0_2_1",
                                label: "Baza wszystkich sklepów",
                                icon: "fas fa-shopping-basket",
                                to: "/collection/store/list",
                            },
                            {
                                key: "0_2_2",
                                label: this.$t("message.menu.collectionEvents"),
                                icon: "pi pi-heart",
                                to: "/collection/event/list",
                            },
                        ],
                    },
                    {
                        key: "0_3",
                        label: this.$t("message.menu.management"),
                        icon: "pi pi-cog",
                        items: [
                            {
                                key: "0_3_0",
                                label: this.$t("message.menu.users"),
                                icon: "pi pi-users",
                                to: "/management/user/list",
                            },
                            {
                                key: "0_3_1",
                                label: this.$t("message.menu.organisations"),
                                icon: "fas fa-handshake",
                                to: "/management/organisation/list",
                            },
                            {
                                key: "0_3_2",
                                label: this.$t("message.menu.storeChains"),
                                icon: "pi pi-globe",
                                to: "/management/store-chain/list",
                            },
                            {
                                key: "0_3_3",
                                label: this.$t("message.menu.mailConfiguration"),
                                icon: "pi pi-envelope",
                                to: "/management/mail-configuration/",
                            },
                        ],
                    },
                    {
                        key: "0_4",
                        label: this.$t("message.menu.exchange"),
                        icon: "exchange-icon",
                        items: [
                            {
                                key: "0_4_0",
                                label: this.$t("message.menu.commissionsFPBZ"),
                                icon: "pi pi-star-fill",
                                to: "/exchange/commission/list",
                            },
                            {
                                key: "0_4_1",
                                label: this.$t("message.menu.offersFPBZ"),
                                icon: "pi pi-star",
                                to: "/exchange/offer/list",
                            },
                        ],
                    },
                    {
                        key: "0_5",
                        label: this.$t("message.menu.blog"),
                        icon: "fas fa-blog",
                        items: [
                            {
                                key: "0_5_0",
                                label: this.$t("message.menu.postCategories"),
                                icon: "pi pi-list",
                                to: "/blog/post-category/list",
                            },
                            {
                                key: "0_5_1",
                                label: this.$t("message.menu.postCreate"),
                                icon: "fas fa-comment",
                                to: "/blog/post/create",
                                meta: {roles: [SystemRole.FPBZ_COORDINATOR]},
                            },
                            {
                                key: "0_5_2",
                                label: this.$t("message.menu.posts"),
                                icon: "fas fa-comments",
                                to: "/posts/list",
                            },
                            {
                                key: "0_5_3",
                                label: this.$t("message.menu.postsEdit"),
                                icon: "fas fa-edit",
                                to: "/blog/posts/edit-list",
                            },
                        ],
                    },
                    {
                        key: "0_6",
                        label: this.$t("message.menu.files"),
                        icon: "pi pi-file-o",
                        to: "/file/list",
                    },
                    {
                        key: "0_7",
                        label: this.getChatLabel,
                        icon: "fas fa-envelope",
                        to: "/chat",
                    },
                    {
                        key: "0_8",
                        label: this.$t("message.menu.authorizations"),
                        icon: "pi pi-id-card",
                        to: "/opl-authorization",
                    },
                ],
                itemsFpbz: [
                    {
                        key: "1_0",
                        label: "Mapa",
                        icon: "pi pi-map",
                        to: "/map",
                    },
                    {
                        key: "1_1",
                        label: this.$t("message.menu.donations"),
                        icon: "fas fa-shipping-fast",
                        items: [
                            {
                                key: "1_1_0",
                                label: this.$t("message.menu.stores"),
                                icon: "fas fa-shopping-basket",
                                to: "/donation/store/list",
                            },
                            {
                                key: "1_1_1",
                                label: this.$t("message.menu.donationReceivalEvents"),
                                icon: "far fa-calendar-alt",
                                to: "/donation/donation-receival-event/list",
                            },
                        ],
                    },
                    {
                        key: "1_2",
                        label: this.$t("message.menu.collections"),
                        icon: "fas fa-shopping-cart",
                        items: [
                            {
                                key: "1_2_0",
                                label: this.$t("message.menu.productCategories"),
                                icon: "fas fa-gifts",
                                to: "/collection/product-category/list",
                            },
                            {
                                key: "1_2_1",
                                label: "Baza wszystkich sklepów",
                                icon: "fas fa-shopping-basket",
                                to: "/collection/store/list",
                            },
                            {
                                key: "1_2_2",
                                label: this.$t("message.menu.collectionEvents"),
                                icon: "pi pi-heart",
                                to: "/collection/event/list",
                            },
                        ],
                    },
                    {
                        key: "1_3",
                        label: this.$t("message.menu.management"),
                        icon: "pi pi-cog",
                        items: [
                            {
                                key: "1_3_0",
                                label: this.$t("message.menu.users"),
                                icon: "pi pi-users",
                                to: "/management/user/list",
                            },
                            {
                                key: "1_3_1",
                                label: this.$t("message.menu.organisations"),
                                icon: "fas fa-handshake",
                                to: "/management/organisation/list",
                            },
                            {
                                key: "1_3_2",
                                label: this.$t("message.menu.storeChains"),
                                icon: "pi pi-globe",
                                to: "/management/store-chain/list",
                            },
                        ],
                    },
                    {
                        key: "1_4",
                        label: this.$t("message.menu.exchange"),
                        icon: "exchange-icon",
                        items: [
                            {
                                key: "1_4_0",
                                label: this.$t("message.menu.commissionsFPBZ"),
                                icon: "pi pi-star-fill",
                                to: "/exchange/commission/list",
                            },
                            {
                                key: "1_4_1",
                                label: this.$t("message.menu.offersFPBZ"),
                                icon: "pi pi-star",
                                to: "/exchange/offer/list",
                            },
                        ],
                    },
                    {
                        key: "1_5",
                        label: this.$t("message.menu.blog"),
                        icon: "fas fa-blog",
                        items: [
                            {
                                key: "1_5_0",
                                label: this.$t("message.menu.postCategories"),
                                icon: "pi pi-list",
                                to: "/blog/post-category/list",
                            },
                            {
                                key: "1_5_1",
                                label: this.$t("message.menu.postCreate"),
                                icon: "fas fa-comment",
                                to: "/blog/post/create",
                                meta: {roles: [SystemRole.FPBZ_COORDINATOR]},
                            },
                            {
                                key: "1_5_2",
                                label: this.$t("message.menu.posts"),
                                icon: "fas fa-comments",
                                to: "/posts/list",
                            },
                            {
                                key: "1_5_3",
                                label: this.$t("message.menu.postsEdit"),
                                icon: "fas fa-edit",
                                to: "/blog/posts/edit-list",
                            },
                        ],
                    },
                    {
                        key: "1_6",
                        label: this.$t("message.menu.files"),
                        icon: "pi pi-file-o",
                        to: "/file/list",
                    },
                    {
                        key: "1_7",
                        label: this.getChatLabel,
                        icon: "fas fa-envelope",
                        to: "/chat",
                    },
                    {
                        key: "1_8",
                        label: this.$t("message.menu.authorizations"),
                        icon: "pi pi-id-card",
                        to: "/opl-authorization",
                    },
                ],
                itemsBz: [
                    {
                        key: "2_0",
                        label: "Mapa",
                        icon: "pi pi-map",
                        to: "/map",
                    },
                    {
                        key: "2_1",
                        label: this.$t("message.menu.donations"),
                        icon: "fas fa-shipping-fast",
                        items: [
                            {
                                key: "2_1_0",
                                label: this.$t("message.menu.stores"),
                                icon: "fas fa-shopping-basket",
                                to: "/donation/store/list",
                            },
                            {
                                key: "2_1_1",
                                label: this.$t("message.menu.donationReceivalEvents"),
                                icon: "far fa-calendar-alt",
                                to: "/donation/donation-receival-event/list",
                            },
                        ],
                    },
                    {
                        key: "2_2",
                        label: this.$t("message.menu.collections"),
                        icon: "fas fa-shopping-cart",
                        items: [
                            {
                                key: "2_2_1",
                                label: "Baza wszystkich sklepów",
                                icon: "fas fa-shopping-basket",
                                to: "/collection/store/list",
                            },
                            {
                                key: "2_2_2",
                                label: this.$t("message.menu.collectionEvents"),
                                icon: "pi pi-heart",
                                to: "/collection/event/list",
                            },
                        ],
                    },
                    {
                        key: "2_3",
                        label: this.$t("message.menu.management"),
                        icon: "pi pi-cog",
                        items: [
                            {
                                key: "2_3_0",
                                label: this.$t("message.menu.users"),
                                icon: "pi pi-users",
                                to: "/management/user/list",
                            },
                            {
                                key: "2_3_1",
                                label: this.$t("message.menu.organisations"),
                                icon: "fas fa-handshake",
                                to: "/management/organisation/list",
                            },
                        ],
                    },
                    {
                        key: "2_4",
                        label: this.$t("message.menu.exchange"),
                        icon: "exchange-icon",
                        items: [
                            {
                                key: "2_4_0",
                                label: this.$t("message.menu.commissionsBZ"),
                                icon: "pi pi-star-fill",
                                to: "/exchange/commission/list",
                            },
                            {
                                key: "2_4_1",
                                label: this.$t("message.menu.offersBZ"),
                                icon: "pi pi-star",
                                to: "/exchange/offer/list",
                            },
                        ],
                    },
                    {
                        key: "2_5",
                        label: this.$t("message.menu.blog"),
                        icon: "fas fa-blog",
                        items: [
                            {
                                key: "2_5_0",
                                label: this.$t("message.menu.postCreate"),
                                icon: "fas fa-comment",
                                to: "/blog/post/create",
                                meta: {roles: [SystemRole.BZ_COORDINATOR]},
                            },
                            {
                                key: "2_5_1",
                                label: this.$t("message.menu.posts"),
                                icon: "fas fa-comments",
                                to: "/posts/list",
                            },
                            {
                                key: "2_5_2",
                                label: this.$t("message.menu.postsEdit"),
                                icon: "fas fa-edit",
                                to: "/blog/posts/edit-list",
                            },
                        ],
                    },
                    {
                        key: "2_6",
                        label: this.$t("message.menu.files"),
                        icon: "pi pi-file-o",
                        to: "/file/list",
                    },
                    {
                        key: "2_7",
                        label: this.getChatLabel,
                        icon: "fas fa-envelope",
                        to: "/chat",
                    },
                    {
                        key: "2_8",
                        label: this.$t("message.menu.authorizations"),
                        icon: "pi pi-id-card",
                        to: "/opl-authorization",
                    },
                ],
                itemsOpl: [
                    {
                        key: "3_0",
                        label: "Mapa",
                        icon: "pi pi-map",
                        to: "/map",
                    },
                    {
                        key: "3_1",
                        label: this.$t("message.menu.donations"),
                        icon: "fas fa-shipping-fast",
                        items: [
                            {
                                key: "3_1_0",
                                label: this.$t("message.menu.stores"),
                                icon: "fas fa-shopping-basket",
                                to: "/donation/store/list",
                            },
                            {
                                key: "3_1_1",
                                label: this.$t("message.menu.donationReceivalEvents"),
                                icon: "far fa-calendar-alt",
                                to: "/donation/donation-receival-event/list",
                            },
                        ],
                    },
                    {
                        key: "3_2",
                        label: this.$t("message.menu.collections"),
                        icon: "fas fa-shopping-cart",
                        to: "/collection/event/list",
                    },
                    {
                        key: "3_3",
                        label: this.$t("message.menu.management"),
                        icon: "pi pi-cog",
                        items: [
                            {
                                key: "3_3_0",
                                label: this.$t("message.menu.users"),
                                icon: "pi pi-users",
                                to: "/management/user/list",
                            },
                        ],
                    },
                    {
                        key: "3_4",
                        label: this.$t("message.menu.exchange"),
                        icon: "exchange-icon",
                        items: [
                            {
                                key: "3_4_0",
                                label: this.$t("message.menu.commissionsOPL"),
                                icon: "pi pi-star-fill",
                                to: "/exchange/commission/list",
                            },
                            {
                                key: "3_4_1",
                                label: this.$t("message.menu.offersOPL"),
                                icon: "pi pi-star",
                                to: "/exchange/offer/list",
                            },
                        ],
                    },
                    {
                        key: "3_5",
                        label: this.$t("message.menu.blog"),
                        icon: "fas fa-blog",
                        to: "/posts/list",
                    },
                    {
                        key: "3_6",
                        label: this.$t("message.menu.files"),
                        icon: "pi pi-file-o",
                        to: "/file/list",
                    },
                    {
                        key: "3_7",
                        label: this.getChatLabel,
                        icon: "fas fa-envelope",
                        to: "/chat",
                    },
                ],
                expandedKeys: [],
            };
        },

        computed: {
            getMenuItems: {
                get() {
                    let items;
                    if (this.isSuperAdmin) {
                        items = this.itemsSuperAdmin;
                    }
                    if (SystemRole.isFPBZCoordinator()) {
                        items = this.itemsFpbz;
                    }
                    if (SystemRole.isBZCoordinator()) {
                        items = this.itemsBz;
                    }
                    if (SystemRole.isOPLCoordinator()) {
                        items = this.itemsOpl;
                    }
                    return this.updateChatItem(items);
                },
            },

            getChatLabel() {
                return this.threadsUnread > 0 ? `Chat (${this.threadsUnread})` : "Chat";
            },
        },

        async mounted() {
            const plannedCollectionIdRequest = getPlannedCollectionId();
            const isSuperAdminRequest = isSuperAdmin();
            let responses;

            try {
                responses = await Promise.all([plannedCollectionIdRequest, isSuperAdminRequest]);
            } catch (error) {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                });
            }

            this.emitter.on("collectionStart", this.addCollectionItem);
            this.emitter.on("collectionStop", this.removeCollectionItem);

            this.$nextTick(() => {
                this.isSuperAdmin = responses[1].data;

                localStorage.setItem("currentCollectionId", responses[0].data);
                if (responses[0].data) {
                    this.addCollectionItem(responses[0].data);
                }
            });
        },

        methods: {
            updateChatItem(items) {
                items.find((i) => i.to === "/chat").label = this.getChatLabel;
                if (this.threadsUnread > 0) {
                    items.find((i) => i.to === "/chat").class = "menu-new-messages";
                    items.find((i) => i.to === "/chat").icon = "fas fa-envelope-open-text";
                } else {
                    items.find((i) => i.to === "/chat").class = null;
                    items.find((i) => i.to === "/chat").icon = "fas fa-envelope";
                }
                return items;
            },

            async addCollectionItem(collectionId) {
                if (!collectionId) {
                    collectionId = (await getPlannedCollectionId()).data;
                }

                const ongoingCollectionItem = {
                    label: this.$t("message.menu.ongoingCollection"),
                    icon: "pi pi-heart",
                    to: `/collection/event/${collectionId}`,
                };

                if (this.isSuperAdmin) {
                    ongoingCollectionItem.key = "0_9";
                    this.getMenuItems.unshift(ongoingCollectionItem);
                } else if (SystemRole.isFPBZCoordinator()) {
                    ongoingCollectionItem.key = "1_9";
                    this.getMenuItems.unshift(ongoingCollectionItem);
                } else if (SystemRole.isBZCoordinator()) {
                    ongoingCollectionItem.key = "2_9";
                    this.getMenuItems.unshift(ongoingCollectionItem);
                } else {
                    ongoingCollectionItem.key = "3_8";
                    this.getMenuItems.unshift(ongoingCollectionItem);
                }

                this.ongoingCollection = true;
            },

            removeCollectionItem() {
                if (this.isSuperAdmin) {
                    this.getMenuItems.shift();
                } else if (SystemRole.isFPBZCoordinator()) {
                    this.getMenuItems.shift();
                } else if (SystemRole.isBZCoordinator()) {
                    this.getMenuItems.shift();
                } else {
                    this.getMenuItems.shift();
                }

                this.ongoingCollection = false;
            },
        },

        props: {
            threadsUnread: {
                type: Number,
                default: 0,
            },
        },
    };
</script>

<style scoped lang="less">
::v-deep(.menu-new-messages) {
    .p-component.p-panelmenu-header {
        .p-panelmenu-header-link {
            .p-menuitem-text {
                font-weight: bold;
            }
        }
    }
}
</style>
