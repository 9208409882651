<template>
    <div class="p-grid">
        <div v-if="!Role.isOPLCoordinator()" class="p-lg-6 p-sm-6 p-col-12">
            <CustomAutocompleteOrganisation name="assignedOpl" label="Przypisana OPL"
                                            v-model="internalValue.assignedOplId"
                                            item-label="label"
                                            :complete-function="getSubordinateOrganisations"
                                            organisation-level="OPL"/>
        </div>
        <div class="p-lg-6 p-sm-6 p-col-12">
            <CustomAutocomplete name="store" label="Przypisany sklep"
                                v-model="internalValue.storeId"
                                item-label="label"
                                :complete-function="getStores"/>
        </div>
    </div>
</template>

<script>
    import {
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        getSubordinateDonationStoresUsingGET as getStores,
    } from "@/swagger/vue-api-client";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import CustomAutocomplete from "@/components/form/inner/CustomAutocomplete";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "DonationReceivalEventSearchCriteria",

        props: {
            modelValue: null,
        },

        data() {
            return {
                Role: SystemRole,
            };
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            getSubordinateOrganisations,
            getStores,
        },

        components: {CustomAutocompleteOrganisation, CustomAutocomplete},
    };
</script>

<style lang="less" scoped>

</style>
