<template>
    <div>
        <div class="remind-password-panel-header">
            <img src="@/assets/logo.png" alt="logo">
        </div>
        <div class="remind-password-panel-content">
            <div class="p-grid">
                <Form @submit="remind">
                    <div class="p-col-12">
                        <h1>Przypomnienie hasła</h1>
                    </div>

                    <div class="p-col-12" v-if="sending">
                        Na wskazany adres e-mail została wysłana wiadomość z linkiem do zmiany hasła.
                    </div>
                    <div class="p-col-12" v-else @keyup.enter="remind">
                        <CustomInputText name="email" v-model="passwordRemindForm.email" label="Adres e-mail"/>
                    </div>
                    <div class="p-col-12" v-if="!sending">
                        <Button label="Przypomnij hasło" @click="remind"/>
                    </div>
                    <div class="p-col-12">
                        <router-link :to="{name: 'login'}">
                            {{ $t('message.register.action.backToLogin') }}
                        </router-link>
                    </div>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {remindPasswordUsingPOST as remindPassword} from "@/swagger/vue-api-client";
    import CustomInputText from "../components/form/CustomInputText";

    export default {
        name: "PasswordRemindView",
        components: {CustomInputText, Button},

        data() {
            return {
                sending: false,
                passwordRemindForm: {
                    email: "",
                },
            };
        },

        methods: {
            remind() {
                this.sending = true;
                remindPassword({passwordRemindRequest: this.passwordRemindForm});
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/remind-password.less";
</style>
