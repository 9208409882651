<template>
    <div class="post-list-outer">
        <div v-if="displayPostList" class="post-list">
            <h1 class="visually-hidden">Lista wpisów</h1>
            <div>
                <ul>
                    <li v-for="item in items" :key="item.value">
                        <div class="post-inner">
                            <div class="post-header">
                                <h2>
                                    {{ item.label }}
                                </h2>
                                <div class="post-category" >
                                    kategoria: {{ item.postCategory.name }}
                                </div>
                            </div>
                            <div class="post-content">
                                <div class="p-mb-3"> <b>Autor:</b> {{ item.createdByName }} </div>
                                <div v-html="item.lead"/>
                                <div class="p-grid">
                                    <div class="p-col-12">
                                        <Button class="button-link" type="button" role="link"
                                                icon="pi pi-angle-right" label="Czytaj dalej"
                                                @click="$router.push({
                                                    name: 'postDetails',
                                                    params: {
                                                        'id': item.value,
                                                        'selectedCategories': searchParams.postCategoryIds,
                                                    },
                                                })"/>
                                    </div>
                                </div>
                            </div>
                            <div class="post-footer">
                                <div v-if="item.editAccess" class="post-footer-edit">
                                    <Button class="button-link" type="button" role="link"
                                            icon="pi pi-pencil" label="Edytuj wpis"
                                            @click="$router.push({
                                                name: 'postEdit',
                                                params: {'id': item.value},
                                            })"/>
                                </div>
                                <div class="post-publish-date">
                                    Data publikacji: {{ item.publishDate }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <Paginator v-if="!displayAll"
                       v-model:first="offset" v-model:rows="searchParams.page.limit"
                       :totalRecords="totalRecords" @page="onPage($event)" :rowsPerPageOptions="[5,10,20,50]"/>
            <Toolbar>
                <template v-slot:end>
                    <Button v-if="!displayAll"
                            label="Włącz wyświetlanie wszystkich wyników"
                            class="p-button-outlined p-button-x-sm"
                            icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    <Button v-if="displayAll"
                            label="Wyłącz wyświetlanie wszystkich wyników"
                            class="p-button-outlined p-button-x-sm"
                            icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                </template>
            </Toolbar>
        </div>
        <div class="p-loading-overlay p-component-overlay" v-if="!loaded">
            <i class="p-loading-icon pi-spin pi pi-spinner"></i>
        </div>
        <div v-if="loaded && items.length === 0">
            <p>Nie ma wpisów do wyświetlenia</p>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Paginator from "primevue/paginator";
    import Toolbar from "primevue/toolbar";
    import {
        searchPostUsingPOST as searchPost,
        searchPostCountUsingPOST as searchPostCount,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "PostList",
        components: {
            Button,
            Paginator,
            Toolbar,
        },
        mixins: [AccessibilityTableMixin],

        data() {
            return {
                offset: 0,
                items: [],
                loaded: false,
                postCategories: null,
                searchParams: {
                    postCategoryIds: [],
                    editView: false,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: "publishDate",
                        sortOrder: -1,
                    },
                },
                totalRecords: 0,
                paginator: true,
                displayAll: null,
                Role: SystemRole,
            };
        },

        computed: {
            displayPostList() {
                return this.loaded && this.items.length > 0;
            },
        },

        mounted() {
            this.displayAll = this.$cookies.get("displayAll") === "true";

            this.updateTotalRecords();

            this.emitter.on("selectedPostCategories", (selectedCategories) => {
                this.searchParams.postCategoryIds = selectedCategories;

                this.fetchPosts(this.displayAll);

                this.updateTotalRecords();
            });

            this.emitter.emit("postListOpen");
        },

        methods: {
            fetchPosts(fakeCall = false) {
                this.loaded = false;

                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                searchPost({searchCriteria: this.searchParams}).then((response) => {
                    this.items = response.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                        postCategory: item.postCategory,
                        lead: item.lead,
                        content: item.content,
                        visibleForOpl: item.visibleForOpl,
                        publishDate: item.publishDate,
                        createdByName: item.createdByName,
                        editAccess: item.editAccess,
                    }));

                    this.loaded = true;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onPage(event, fakeCall = false) {
                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;

                this.fetchPosts(fakeCall);
            },

            updateTotalRecords() {
                searchPostCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                });
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");

                if (this.displayAll) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                    this.offset = 0;
                }

                this.fetchPosts(this.displayAll);
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/post-list";
</style>
