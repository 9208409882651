<template>
    <div class="content" role="main">
        <div class="post">
            <div class="post-header">
                <h1>
                    {{ post.name }}
                </h1>
                <div class="post-header-cat">
                    kategoria: {{ title }}
                </div>
            </div>
            <div class="post-content">
                <div v-html="post.content"/>
            </div>
            <div class="post-publish-date">
                {{ post.createdByName }} <br>
                {{ post.publishDate }}
            </div>
        </div>
        <div class="p-mt-5">
            <Button v-if="loaded && post.nextId"
                    class="p-mx-3 button-link"
                    type="button" role="link" icon="pi pi-angle-double-left" label="Poprzedni wpis"
                    @click="$router.push({
                        name: 'postDetails',
                        params: {
                            'id': post.nextId,
                            'selectedCategories': $route.params.selectedCategories,
                        },
                    })"/>
            <Button v-if="loaded && post.previousId"
                    class="p-mx-3 button-link"
                    type="button" role="link" icon="pi pi-angle-double-right" label="Następny wpis"
                    @click="$router.push({
                        name: 'postDetails',
                        params: {
                            'id': post.previousId,
                            'selectedCategories': $route.params.selectedCategories,
                        },
                    })"/>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {
        getPostWithAdjacentInfoUsingPOST as getPostWithAdjacentInfo,
    } from "@/swagger/vue-api-client";

    export default {
        name: "PostView",

        components: {
            Button,
        },

        props: {
            value: {},
        },

        data() {
            return {
                post: {
                    id: null,
                    name: null,
                    postCategory: null,
                    content: null,
                    previousId: null,
                    nextId: null,
                    publishDate: null,
                },
                title: null,
                searchParams: {
                    postCategoryIds: [],
                    page: {
                        limit: 999999,
                        offset: 0,
                        sortField: "publishDate",
                        sortOrder: 1,
                    },
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.selectedCategories !== undefined
                && this.$route.params.selectedCategories !== "undefined") {
                this.searchParams.postCategoryIds = this.$route.params.selectedCategories;
            }
            if (this.$route.params.id) {
                getPostWithAdjacentInfo({id: this.$route.params.id, searchCriteria: this.searchParams})
                    .then((response) => {
                        this.post = response.data;
                        this.title = this.post.postCategory.name;
                        this.loaded = true;
                    }).catch((error) => {
                        if (error.response && error.response.status === 403) {
                            this.$router.push({name: "forbidden"});
                        } else {
                            this.$router.push({name: "notFound"});
                        }
                    });
            } else {
                this.loaded = true;
            }
        },

        mounted() {
            this.emitter.emit("postDetailsOpen");
        },
    };
</script>

<style lang="less" scoped>
    @import "../assets/less/post";

    .post-form {
        max-width: 100%;
    }
</style>
