<template>
    <div>
        <h1 class="page-header"><i class="pi pi-star"></i>Lista deklaracji odbioru darowizny</h1>

        <div class="search-criteria" @keyup.enter="search">
            <OfferSearchCriteria ref="offerSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <TabMenu v-if="Role.isBZCoordinator()" class="tabmenu" :model="menuItems"/>

        <OfferTable ref="offerTable" :search-criteria="searchCriteria"
                    :display-all="displayAll" :query-type="queryType"
                    @loaded="loadInitialDisplayAll" @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import TabMenu from "primevue/tabmenu";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import OfferSearchCriteria from "./components/OfferSearchCriteria";
    import OfferTable from "./components/OfferTable";

    export default {
        name: "OfferListView",
        components: {
            OfferTable, OfferSearchCriteria, Button, Toolbar, TabMenu,
        },

        data() {
            return {
                searchCriteria: {
                    amountMin: null,
                    amountMax: null,
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                queryType: "OWN",
                displayAll: null,
                Role: SystemRole,
                menuItems: [
                    {label: "Moje deklaracje odbioru darowizn", command: () => this.setQueryType("OWN")},
                    {label: "Deklaracje odbioru BŻ/OPL", command: () => this.setQueryType("OTHERS")},
                ],
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.offerTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                return {
                    amountMin: null,
                    amountMax: null,
                    queryType: this.searchCriteria.queryType,
                    page: this.searchCriteria.page,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },

            setQueryType(value) {
                this.queryType = value;
            },
        },
    };
</script>

<style scoped>

</style>
