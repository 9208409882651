import {createApp, h} from "vue";
import Tooltip from "primevue/tooltip";
import axios from "axios";
import VueAxios from "vue-axios";
import ToastService from "primevue/toastservice";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import {createI18n} from "vue-i18n";
import ConfirmationService from "primevue/confirmationservice";
import VueCookies from "vue3-cookies";
import mitt from "mitt";
import router from "./router";
import App from "./App";
import {setDomain, isTokenValidUsingGET as isTokenValid} from "./swagger/vue-api-client";
import {SystemRole} from "./utils/SystemRole";
import {messagesEN} from "./messages/messages_en";
import {messagesPL} from "./messages/messages_pl";
import "./validators";

setDomain(process.env.VUE_APP_API_URL);

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        isTokenValid().then(() => {
            // token jest ważny, teraz należy sprawdzić role
            const role = SystemRole.getUserRole();

            if (to.matched.some((record) => record.meta.roles && record.meta.roles.indexOf(role) < 0)) {
                // użytkownik nie ma odpowiedniej roli aby wejść na daną stronę
                next({name: "forbidden"});
            } else {
                next();
            }
        }, () => {
            localStorage.removeItem("token");
            next({name: "login", query: {next: to.path}});
        });
    } else {
        next();
    }
    document.title = to.meta.title;
});

const messages = {
    EN: {
        message: messagesEN,
    },
    PL: {
        message: messagesPL,
    },
};

// Create VueI18n instance with options
const i18n = createI18n({
    locale: localStorage.getItem("language") ? localStorage.getItem("language") : "PL",
    messages,
});

const app = createApp({
    i18n,
    render: () => h(App),
    }).use(router)
    .use(VueAxios, axios)
    .use(ToastService)
    .use(ConfirmationService)
    .use(i18n)
    .use(PrimeVue, {
        locale: {
            dayNames: ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"],
            dayNamesShort: ["Nd", "Pon", "Wt", "Śr", "Czw", "Pt", "So"],
            dayNamesMin: ["Nd", "Pon", "Wt", "Śr", "Czw", "Pt", "So"],
            monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec",
                "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
            monthNamesShort: ["STY", "LUT", "MAR", "KWI", "MAJ", "CZE", "LIP", "SIE", "WRZ", "PAŹ", "LIS", "GRU"],
            firstDayOfWeek: 1,
        },
    })
    .use(VueCookies)
    .use(mitt);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;
app.mount("#app");

app.directive("tooltip", Tooltip);

app.axios.interceptors.request.use((config) => {
    if (localStorage.getItem("token") !== null) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    return config;
});
