<template>
    <div>
        <h1 class="page-header"><i class="pi pi-heart"></i> Lista zbiórek</h1>

        <div class="search-criteria" @keyup.enter="search">
            <CollectionSearchCriteria ref="collectionSearchCriteria" v-model="searchCriteria"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button v-if="Role.isFPBZCoordinator()"
                                class="button-link" type="button" role="link"
                                icon="pi pi-plus" label="Zaplanuj zbiórkę"
                                @click="$router.push({name: 'collectionCreate'})"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <CollectionTable ref="collectionTable" :search-criteria="searchCriteria"
                         :display-all="displayAll" @loaded="loadInitialDisplayAll"
                         @sortSettingsChange="onSortSettingsChange"/>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import CollectionSearchCriteria from "./components/CollectionSearchCriteria";
    import CollectionTable from "./components/CollectionTable";

    export default {
        name: "CollectionListView",
        components: {
            CollectionTable, CollectionSearchCriteria, Button, Toolbar,
        },

        data() {
            return {
                searchCriteria: this.getClearSearchCriteria(),
                displayAll: null,
                Role: SystemRole,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            search() {
                SearchCriteria.updateUrl(this);

                this.$nextTick(this.$refs.collectionTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                if (this.searchCriteria) {
                    return {
                        page: this.searchCriteria.page,
                    };
                }

                return {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },
        },
    };
</script>

<style scoped>

</style>
