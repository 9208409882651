<template>
    <Dialog header="Utwórz wątek" v-model:visible="internalVisible"
            :breakpoints="{'1200px': '60vw', '960px': '75vw', '640px': '100vw'}"
            :style="{width: '40vw'}" append-to=".wrapper" v-if="isMounted">
        <Form @submit="createMessageThread" v-slot="{ isSubmitting }">
            <div class="p-grid form-wrapper">
                <div class="p-col-12">
                    <CustomAutocompleteOrganisation name="organisationId" label="Odbiorca"
                                                    :complete-function="getChatOrganisations"
                                                    v-model="receiverId"
                                                    rules="required"
                                                    :organisation-level="Role.isBZCoordinator() ? 'OPL' : 'BZ'"/>
                </div>
            </div>
            <div class="button-div">
                <Button label="Utwórz" icon="pi pi-chevron-right" class="p-mr-2"
                        type="submit" :disabled="isSubmitting"/>
                <Button label="Anuluj" icon="pi pi-times" class="p-mr-2"
                        @click="closeDialog()" :disabled="isSubmitting"/>
            </div>

        </Form>
    </Dialog>
</template>

<script>
    import Dialog from "primevue/dialog";
    import Button from "primevue/button";
    import {Form} from "vee-validate";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {
        getChatOrganisationsUsingGET as getChatOrganisations,
        createMessageThreadUsingPOST as createThread,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CreateMessageThreadDialog",
        components: {
            CustomAutocompleteOrganisation,
            Dialog,
            Button,
            Form,
        },
        emits: ["update:visible", "newThreadAdded"],

        props: {
            visible: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                receiverId: undefined,
                Role: SystemRole,
                isMounted: false,
            };
        },

        computed: {
            internalVisible: {
                get() {
                    return this.visible;
                },
                set(value) {
                    this.$emit("update:visible", value);
                },

            },
        },
        mounted() {
            this.isMounted = true;
        },

        methods: {
            async createMessageThread() {
                createThread({messageThreadRequest: {receiverId: this.receiverId}})
                    .then(() => {
                        this.$emit("newThreadAdded");
                    }).catch((error) => {
                        if (error.response && error.response.status === 400) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Przed utworzeniem nowego wątku upewnij się, że taki wątek już nie istnieje",
                            });
                        } else if (error.response && error.response.status === 403) {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie masz wystarczających uprawnień",
                            });
                        } else {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Wystąpił nieoczekiwany błąd",
                            });
                        }
                    }).finally(() => {
                        this.internalVisible = false;
                    });
            },

            closeDialog() {
                this.internalVisible = false;
            },

            getChatOrganisations,
        },
    };
</script>

<style lang="less" scoped>
.form-wrapper {
    margin-top: 10px;
    min-height: 120px;
}

.button-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
</style>
