<template>
    <div v-if="fetchingData" class="progress-spinner">
        <ProgressSpinner/>
    </div>

    <div>
        <h1 class="visually-hidden">Dodawanie sklepu</h1>
        <h2 class="page-header"><i class="pi shopping-cart"></i> Dane sklepu</h2>
        <div class="container p-grid">
            <div class="p-lg-6 p-col-12 p-pr-lg-5">
                <Form ref="form" v-if="loaded" @submit="saveStore" v-slot="{ isSubmitting }">
                    <div class="p-grid store-form">
                        <div class="p-col-12">
                            <p class="store-form-section-header">Ogólne</p>
                            <CustomAutocompleteStoreChain name="storeChain.label" label="Sieć sklepów"
                                                          v-model="store.storeChainId"
                                                          :complete-function="getStoreChains"
                                                          rules="required"/>
                            <CustomInputText name="name" label="Nazwa" v-model="store.name" rules="required"/>
                            <CustomInputText name="number" label="Nr sklepu" v-model="store.number"/>
                        </div>
                        <div class="p-col-12">
                            <p class="store-form-section-header">Przypisane organizacje</p>
                            <CustomAutocompleteOrganisation name="assignedBzId" label="Przypisany BŻ"
                                                            v-if="Role.isFPBZCoordinator()"
                                                            v-model="store.assignedBzId"
                                                            :complete-function="getOrganisations"
                                                            organisation-level="BZ"
                                                            @change="onBzChange"/>
                            <div v-if="this.store.assignedBzId" style="margin-bottom: 20px;">
                                <Checkbox id="bzAsOplCheckbox" :binary="true" v-model="bzAsOpl"
                                          @change="toggleBzAsOpl"/>
                                <label for="bzAsOplCheckbox">BZ w roli OPL</label>
                            </div>
                            <CustomAutocompleteOrganisation v-if="!bzAsOpl && this.store.assignedBzId"
                                                            name="assignedOplId" label="Przypisana OPL"
                                                            v-model="store.assignedOplId"
                                                            :complete-function="getOrganisations"
                                                            organisation-level="OPL"
                                                            :supervisor-id="store.assignedBzId.value
                                                                ?? store.assignedBzId"/>
                        </div>
                        <div class="p-col-12">
                            <p class="store-form-section-header">Lokalizacja</p>
                            <CustomSelectOne name="voivodeship" label="Województwo"
                                             item-label="label" item-value="value"
                                             v-model="store.voivodeship"
                                             :items="voivodeships"/>
                            <CustomInputText name="city" label="Miasto"
                                             v-model="store.city"/>
                            <CustomInputText name="street" label="Ulica"
                                             v-model="store.street"/>
                            <CustomInputText name="houseNumber" label="Numer domu"
                                             v-model="store.houseNumber"/>
                            <CustomInputText name="apartmentNumber" label="Numer mieszkania"
                                             v-model="store.apartmentNumber"/>
                            <CustomInputText name="postalCode" label="Kod pocztowy"
                                             v-model="store.postalCode"/>
                        </div>
                        <div class="p-col-12">
                            <p class="store-form-section-header">Kontakt</p>
                            <CustomInputText name="phoneNumber" label="Numer telefonu"
                                             v-model="store.phoneNumber"/>
                            <CustomInputText name="email" label="Adres e-mail"
                                             v-model="store.email" rules="email"/>
                            <CustomInputText name="contactPerson" label="Osoba kontaktowa"
                                             v-model="store.contactPerson"/>
                        </div>
                        <div class="p-col-12">
                            <Toolbar class="actions">
                                <template v-slot:start>
                                    <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                                </template>
                                <template v-if="Role.isFPBZCoordinator() && mapLoaded && geocodingApiAvailable"
                                          v-slot:end>
                                    <Button label="Załaduj geolokalizację na podstawie adresu"
                                            icon="pi pi-angle-double-right" @click="loadCoordinatesFromAddress"/>
                                </template>
                            </Toolbar>
                            <div v-if="loadCoordinatesFromAddressError" class="p-mb-5">
                                {{ $t('message.loadCoordinatesFromAddressError') }}</div>
                        </div>
                    </div>
                </Form>
            </div>
            <div class="p-lg-6 p-col-12">
                <div v-if="Role.isFPBZCoordinator()">
                    <div id="map"/>
                    <div v-if="mapLoaded">
                        <p>Wybrana szer. geogr. {{store.latitude ?? "---"}}</p>
                        <p>Wybrana dł. geogr. {{store.longitude ?? "---"}}</p>
                        <Toolbar class="actions">
                            <template v-if="geocodingApiAvailable" v-slot:start>
                                <Button label="Załaduj adres na podstawie geolokalizacji"
                                        icon="pi pi-angle-double-left" @click="loadAddressFromCoordinates"/>
                            </template>
                            <template v-slot:end>
                                <Button label="Reset" icon="pi pi-undo"
                                        @click="resetLocalisation"/>
                            </template>
                        </Toolbar>
                        <div v-if="loadAddressFromCoordinatesError" class="p-mb-5">
                            {{ $t('message.loadAddressFromCoordinatesError') }}</div>
                    </div>
                </div>
            </div>
            <Dialog v-model:visible="display" header="Załadowane dane:" :modal="true" :dismissable-mask="true"
                    append-to=".wrapper" v-if="isMounted">
                <p>Województwo: {{geocodingData.voivodeship ?? "---"}}</p>
                <p>Miasto: {{geocodingData.city ?? "---"}}</p>
                <p>Ulica: {{geocodingData.road ?? "---"}}</p>
                <p>Numer domu: {{geocodingData.house_number ?? "---"}}</p>
                <p>Kod pocztowy: {{geocodingData.postcode ?? "---"}}</p>
                <Toolbar>
                    <template v-slot:start>
                        <p>Czy chcesz zapisać te dane?</p>
                    </template>
                    <template v-slot:end>
                        <Button type="button" class="p-mr-1" icon="pi pi-check" @click="acceptAddressData"/>
                        <Button type="button" icon="pi pi-times" @click="clearAddressData"></Button>
                    </template>
                </Toolbar>
            </Dialog>
        </div>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import Button from "primevue/button";
    import Checkbox from "primevue/checkbox";
    import Dialog from "primevue/dialog";
    import ProgressSpinner from "primevue/progressspinner";
    import Toolbar from "primevue/toolbar";
    import {SystemRole} from "@/utils/SystemRole";
    import {MapMixin} from "@/mixins/MapMixin";
    import {AddressUtils} from "@/utils/AddressUtils";
    import {
        createOrUpdateCollectionStoreUsingPOST as createOrUpdateStore,
        getCollectionStoreUsingGET as getStore,
        getStoreChainsUsingGET as getStoreChains,
        getOrganisationsUsingGET as getOrganisations,
    } from "@/swagger/vue-api-client";
    import CustomAutocompleteStoreChain from "@/components/form/CustomAutocompleteStoreChain";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputText from "@/components/form/CustomInputText";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CollectionStoreFormView",

        components: {
            Form,
            Button,
            Checkbox,
            Dialog,
            ProgressSpinner,
            Toolbar,
            CustomAutocompleteStoreChain,
            CustomAutocompleteOrganisation,
            CustomSelectOne,
            CustomInputText,
        },

        mixins: [MapMixin],

        props: {
            value: {},
            editing: {
                type: Boolean,
            },
        },

        data() {
            return {
                loadCoordinatesFromAddressError: false,
                loadAddressFromCoordinatesError: false,
                store: {
                    id: null,
                    name: null,
                    voivodeship: null,
                    city: null,
                    street: null,
                    houseNumber: null,
                    apartmentNumber: null,
                    postalCode: null,
                    phoneNumber: null,
                    email: null,
                    contactPerson: null,
                    latitude: null,
                    longitude: null,
                    storeChain: null,
                    storeChainId: null,
                    assignedBz: null,
                    assignedBzId: null,
                    assignedOpl: null,
                    assignedOplId: null,
                },
                bzAsOpl: false,
                voivodeships: AddressUtils.validVoivodeships,
                Util: AddressUtils,
                marker: null,
                initialLat: null,
                initialLng: null,
                geocodingData: null,
                display: false,
                Role: SystemRole,
                fetchingData: false,
                loaded: false,
                mapLoaded: false,
                isMounted: false,
            };
        },

        async mounted() {
            try {
                await this.fetchThisStoreData();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            this.loaded = true;

            if (!this.Role.isFPBZCoordinator()) {
                this.store.latitude = null;
                this.store.longitude = null;

                return;
            }

            if (!this.map) {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Załadowanie mapy nie powiodło się",
                });

                return;
            }

            try {
                await this.prepareMap();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            if (!this.geocodingApiAvailable) {
                ToastUtils.addToast(this, {
                    severity: "warn",
                    summary: "Ostrzeżenie",
                    detail: "Nie ma możliwości obliczenia współrzędnych geograficznych na podstawie adresu, "
                        + "bądź adresu na podstawie geolokalizacji, skontaktuj się z administratorem systemu",
                });
            }

            this.mapLoaded = true;
            this.isMounted = true;
        },

        methods: {
            async fetchThisStoreData() {
                if (!this.editing) {
                    return;
                }

                const response = await getStore({
                    id: this.$route.params.id,
                });

                this.store = response.data;

                if (this.store.storeChain) {
                    this.store.storeChainId = {
                        value: this.store.storeChain.id,
                        label: this.store.storeChain.name,
                    };
                }

                if (this.store.assignedBz) {
                    this.store.assignedBzId = {
                        value: this.store.assignedBz.id,
                        label: this.store.assignedBz.name,
                    };
                }

                if (this.store.assignedOpl) {
                    this.store.assignedOplId = {
                        value: this.store.assignedOpl.id,
                        label: this.store.assignedOpl.name,
                    };

                    if (this.store.assignedBzId && this.store.assignedOplId
                        && this.store.assignedBzId.value === this.store.assignedOplId.value) {
                        this.bzAsOpl = true;
                    }
                }
            },

            async prepareMap() {
                if (this.editing) {
                    await this.initialiseMapData("store", parseInt(this.store.id, 10));
                } else {
                    await this.initialiseMapData();
                }

                if (this.editing) {
                    if (this.store.latitude && this.store.longitude) {
                        this.newEditMarker(this.store.latitude, this.store.longitude,
                                           "Sklep zostanie zapisany w tym miejscu<br/>");
                        this.initialLat = this.store.latitude;
                        this.initialLng = this.store.longitude;

                        this.map.setView([this.store.latitude, this.store.longitude], 17);
                    } else {
                        this.focusPoland(false);
                    }
                } else {
                    this.focusPoland(false);
                }

                this.map.on("click", (event) => {
                    this.store.latitude = event.latlng.lat;
                    this.store.longitude = event.latlng.lng;

                    if (!this.marker) {
                        this.newEditMarker(event.latlng.lat, event.latlng.lng,
                                           "Sklep zostanie zapisany w tym miejscu<br/>");
                    } else {
                        this.marker.setLatLng([event.latlng.lat, event.latlng.lng]);
                    }
                });
            },

            onBzChange(store) {
                this.store.assignedOplId = null;

                if (store === null) {
                    this.bzAsOpl = false;
                }
            },

            toggleBzAsOpl() {
                if (this.bzAsOpl) {
                    this.store.assignedOplId = this.store.assignedBzId;
                } else {
                    this.store.assignedOplId = null;
                }
            },

            resetLocalisation() {
                if (this.editing) {
                    this.store.latitude = this.initialLat;
                    this.store.longitude = this.initialLng;
                    this.resetEditMarker(this.initialLat, this.initialLng);
                } else {
                    this.store.latitude = null;
                    this.store.longitude = null;
                    this.resetEditMarker();
                }
            },

            async loadCoordinatesFromAddress() {
                this.loadCoordinatesFromAddressError = false;
                if (!this.store.city
                    && !this.store.street
                    && !this.store.postalCode) {
                    this.loadCoordinatesFromAddressError = true;
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Aby ustalić geolokalizację na podstawie adresu, musisz najpierw uzupełnić "
                            + "przynajmniej jedno z wymaganych pól (miasto, ulica lub kod pocztowy)",
                    });

                    return;
                }

                this.fetchingData = true;

                const data = await this.search({
                    city: this.store.city,
                    street: this.store.street,
                    houseNumber: this.store.houseNumber,
                    county: this.store.voivodeship,
                    postalCode: this.store.postalCode,
                });

                if (!data || !data.lat || !data.lon) {
                    this.loadCoordinatesFromAddressError = true;
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Ustalenie geolokalizacji na podstawie adresu nie powiodło się",
                    });

                    this.fetchingData = false;

                    return;
                }

                this.store.latitude = data.lat;
                this.store.longitude = data.lon;

                if (!this.marker) {
                    this.newEditMarker(data.lat, data.lon,
                                       "Sklep zostanie zapisany w tym miejscu<br/>");
                } else {
                    this.marker.setLatLng([data.lat, data.lon]);
                }

                this.map.setView([this.store.latitude, this.store.longitude], 17);

                this.fetchingData = false;
            },

            loadVoivodeshipFromGeocodingData() {
                if (!this.geocodingData.state || this.geocodingData.state.indexOf(" ") === -1) {
                    return false;
                }

                const voivodeshipObjectArray = this.voivodeships.filter(
                    (item) => item.value === this.geocodingData.state.split(" ")[1],
                );

                if (!voivodeshipObjectArray.length) {
                    return false;
                }

                this.geocodingData.voivodeship = voivodeshipObjectArray[0].value;

                return true;
            },

            async loadAddressFromCoordinates() {
                this.loadAddressFromCoordinatesError = false;
                if (!this.store.latitude || !this.store.longitude) {
                    this.loadAddressFromCoordinatesError = true;
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Aby uzupełnić adres na podstawie geolokalizacji, "
                            + "musisz najpierw wskazać punkt na mapie",
                    });

                    return;
                }

                this.fetchingData = true;

                this.geocodingData = await this.reverseGeocode(this.store.latitude, this.store.longitude);
                if (!this.geocodingData || this.geocodingData.error) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Znalezienie adresu na podstawie współrzędnych nie powiodło się",
                    });

                    this.fetchingData = false;

                    this.clearAddressData();

                    return;
                }

                if (!this.loadVoivodeshipFromGeocodingData()) {
                    this.geocodingData.voivodeship = null;
                }

                this.geocodingData.city = this.geocodingData.city ?? this.geocodingData.village ?? null;

                this.display = true;
                this.fetchingData = false;
            },

            async acceptAddressData() {
                this.store.voivodeship = this.geocodingData.voivodeship;
                this.store.city = this.geocodingData.city;
                this.store.street = this.geocodingData.road;
                this.store.houseNumber = this.geocodingData.house_number;
                this.store.apartmentNumber = null;
                this.store.postalCode = this.geocodingData.postcode;

                this.$refs.form.setFieldValue("voivodeship", this.geocodingData.voivodeship);
                this.$refs.form.setFieldValue("city", this.geocodingData.city);
                this.$refs.form.setFieldValue("street", this.geocodingData.road);
                this.$refs.form.setFieldValue("houseNumber", this.geocodingData.house_number);
                this.$refs.form.setFieldValue("apartmentNumber", null);
                this.$refs.form.setFieldValue("postalCode", this.geocodingData.postcode);

                this.clearAddressData();
            },

            clearAddressData() {
                this.display = false;

                this.geocodingData = null;
            },

            saveStore() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const request = {...this.store};

                        request.storeChainId = this.store.storeChainId
                            ? this.store.storeChainId.value ?? this.store.storeChainId
                            : null;
                        request.assignedBzId = this.store.assignedBzId
                            ? this.store.assignedBzId.value ?? this.store.assignedBzId
                            : null;
                        request.assignedOplId = this.store.assignedOplId
                            ? this.store.assignedOplId.value ?? this.store.assignedOplId
                            : null;

                        if (this.bzAsOpl || !request.assignedOplId) {
                            request.assignedOplId = request.assignedBzId;
                        }

                        createOrUpdateStore({
                            storeRequest: request,
                        })
                            .then(() => {
                                ToastUtils.addToast(this, {
                                    severity: "success",
                                    summary: "Sukces",
                                    detail: "Zapisano dane",
                                });
                                this.$router.back();
                            }).catch((error) => {
                                if (error.response && error.response.status === 409) {
                                    ToastUtils.addToast(this, {
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: error.response.data,
                                    });
                                } else {
                                    ToastUtils.addToast(this, {
                                        severity: "error",
                                        summary: "Błąd",
                                        detail: "Nie udało się zapisać danych",
                                    });
                                }
                            });
                    },
                });
            },
            getStoreChains,
            getOrganisations,
        },
    };
</script>

<style lang="less" scoped>
    .progress-spinner {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 15px;
    }

    #map {
        position: relative;
        z-index: 0;
        height: 600px;
        width: 600px;
        max-width: 100%;
    }

    .store-form-section-header {
        text-align: left;
        font-weight: bold;
    }
</style>
