<template>
    <div class="header-acc">
        <div class="acc-show-icon-outer clickable">
            <div class="acc-show-icon" role="button" :aria-expanded="showAcc" tabindex="0"
                 v-on:keyup.enter="toggleDisplayAccIcons()" @click="toggleDisplayAccIcons()">
                <span class="visually-hidden" v-if="!showAcc">Pokaż opcje zmiany kontrastu i wielkości czcionki</span>
                <span class="visually-hidden" v-else>Ukryj opcje zmiany kontrastu i wielkości czcionki</span>
            </div>
        </div>
        <div class="header-acc-icons" v-if="showAcc">
            <div class="acc-font-size">
                <div class="smaller clickable" role="button" tabindex="0"
                     v-on:keyup.enter="decreaseFontSize" @click="decreaseFontSize">
                    <span class="visually-hidden">Zmniejsz rozmiar czcionki</span>
                    <span class="arrow-down"></span>
                </div>
                <span class="acc-font-aa">Aa</span>
                <div class="greater clickable" role="button" tabindex="0"
                     v-on:keyup.enter="increaseFontSize" @click="increaseFontSize">
                    <span class="visually-hidden">Zwiększ rozmiar czcionki</span>
                    <span class="arrow-up"></span>
                </div>
            </div>
            <div class="acc-contrast-high-black clickable" role="button" tabindex="0"
                 v-on:keyup.enter="setHighContrast('black')" @click="setHighContrast('black')" >
                <span class="visually-hidden">Włącz tryb wysokiego kontrastu</span>
            </div>
            <div class="acc-reset clickable" role="button" tabindex="0"
                 v-on:keyup.enter="resetAcc" @click="resetAcc">
                <span class="visually-hidden">Wyłącz tryb wysokiego kontrastu</span>
            </div>
            <div class="close-button acc-hide-icon" role="button" tabindex="0"
                 @click="showAccIcons(false)" v-on:keyup.enter="showAccIcons(false)" >
                <span class="close-icon"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderAcc",

        components: {},

        emits: ["clickedContrast", "clickedFontSize"],

        data() {
            return {
                showAcc: false,
            };
        },

        methods: {
            increaseFontSize() {
                let currentFontSize = parseInt(document.querySelector("html")
                    .getAttribute("data-font-size"), 10);
                if (currentFontSize < 2) {
                    currentFontSize += 1;
                    this.$emit("clickedFontSize", currentFontSize);
                }
            },
            decreaseFontSize() {
                let currentFontSize = parseInt(document.querySelector("html")
                    .getAttribute("data-font-size"), 10);
                if (currentFontSize > 0) {
                    currentFontSize -= 1;
                    this.$emit("clickedFontSize", currentFontSize);
                }
            },
            resetAcc() {
                this.$emit("clickedFontSize", 0);
                this.$emit("clickedContrast", null);
                this.emitter.emit("changedContrast", null);
            },
            setHighContrast(color) {
                this.$emit("clickedContrast", color);
                this.emitter.emit("changedContrast", color);
            },
            showAccIcons(show) {
                this.showAcc = show;
            },
            toggleDisplayAccIcons() {
                this.showAcc = !this.showAcc;
                this.closeAccIcons();
            },
            closeAccIcons() {
                if (this.showAcc) {
                    document.addEventListener("click", () => {
                        this.showAccIcons(false);
                    });
                    document.getElementsByClassName("header-acc")[0].addEventListener("click", (e) => {
                        e.stopPropagation();
                    });
                }
            },
        },
    };
</script>

<style lang="less">
    @import "../assets/less/header-acc.less";
</style>
