<template>
    <div>
        <div class="top-panel">
            <div class="code">
                404
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-exclamation-triangle"/>
            </div>
            <div class="content" role="main">
                <h1>Nie znalezionio strony</h1>
                <div>Prosimy skontaktować się z administratorem systemu</div>
                <Button class="redirect-button button-link" tag="div"
                        type="button" role="link" icon="pi pi-undo" label="Powrót na stronę główną"
                        @click="$router.push({name: 'map'})"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "NotFoundView",
        components: {Button},
    };
</script>

<style lang="less" scoped>
    @import url("@/views/assets/less/status-page.less");

    .top-panel {
        background-color: deeppink;
        .code {
            color: #ff86d6;
        }
    }

    .bottom-panel {
        .icon {
            background-color: deeppink;
        }
    }
</style>
