<template>
    <div>
        <h1 class="page-header"><i class="fas fa-gifts"></i> Dane kategorii produktu</h1>
        <Form v-if="loaded" @submit="saveProductCategory" v-slot="{ isSubmitting }">
            <div class="p-grid productCategory-form">
                <div class="p-col-6">
                    <CustomInputText name="name" label="Nazwa kategorii produktów" v-model="productCategory.name"
                                     rules="required"/>
                </div>
                <div class="p-col-6">
                    <CustomInputNumber name="estimatedValue" :min-digits="2" :max-digits="2"
                                       label="Szacunkowa wartość kilograma (zł)" :min="0"
                                       v-model="productCategory.estimatedValue"/>
                </div>
                <div class="p-col-6">
                    <CustomInputText name="enovaCode" label="kod Enova"
                                     v-model="productCategory.enovaCode"
                                     rules="required"/>
                </div>
                <div class="p-col-12">
                    <Toolbar class="actions">
                        <template v-slot:end>
                            <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                        </template>
                    </Toolbar>
                </div>
            </div>
        </Form>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdateProductCategoryUsingPOST as createOrUpdateProductCategory,
        getProductCategoryUsingGET as getProductCategory,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "ProductCategoryFormView",

        components: {
            Button, Toolbar, CustomInputText, CustomInputNumber, Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                productCategory: {
                    id: null,
                    name: null,
                    active: true,
                    enovaCode: null,
                    estimatedValue: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getProductCategory({id: this.$route.params.id}).then((response) => {
                    this.productCategory = response.data;
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            saveProductCategory() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdateProductCategory({productCategoryDto: this.productCategory}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
</style>
