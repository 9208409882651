<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="name" label="Imię i nazwisko"
                             v-model="internalValue.authorizedPersonFullName"/>
        </div>
        <div v-if=Role.isFPBZCoordinator() class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomAutocompleteOrganisation name="assignedOplBZ" label="Bank"
                                            v-model="internalValue.authorizingBzId"
                                            item-label="label"
                                            :complete-function="getOtherOrganisations"
                                            organisation-level="BZ"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomAutocompleteOrganisation name="assignedOplOPL" label="OPL"
                                            v-model="internalValue.authorizedOplId"
                                            item-label="label"
                                            :complete-function="getSubordinateOrganisations"
                                            organisation-level="OPL"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomAutocompleteStoreChain name="storeChain.label" label="Sieć sklepów"
                                          v-model="internalValue.storeChainId"
                                          :complete-function="getStoreChains"/>
        </div>
    </div>
</template>

<script>
    import CustomAutocompleteStoreChain from "@/components/form/CustomAutocompleteStoreChain";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        getStoreChainsUsingGET as getStoreChains,
        getOtherOrganisationsUsingGET as getOtherOrganisations,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "OplAuthorizationSearchCriteria",

        props: {
            modelValue: null,
        },

        data() {
            return {
                Role: SystemRole,
            };
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            getSubordinateOrganisations,
            getStoreChains,
            getOtherOrganisations,
        },

        components: {CustomInputText, CustomAutocompleteStoreChain, CustomAutocompleteOrganisation},
    };
</script>

<style lang="less" scoped>

</style>
