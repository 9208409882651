<template>
    <div>
        <h1 class="page-header"><i class="pi pi-book"></i> Historia zmian statusów sklepu</h1>

        <div class="container">
            <div style="margin: 20px" v-if="showCollectionHistory">
                <h3 style="text-align: left;">Historia zmian statusów w zbiórkach</h3>
                <div v-if="!collectionStatusChangeHistory.length">
                    <p>Brak danych</p>
                </div>
                <div v-else style="max-height: 65vh; overflow: scroll;">
                    <div v-for="entry in collectionStatusChangeHistory" :key="entry.date">
                        <div class="entry">
                            <p>Data operacji: {{entry.date}}</p>
                            <p>Użytkownik: {{entry.user.firstName + " " + entry.user.lastName}}</p>
                            <p>Stary status: {{collectionStatusTypeNameMapping[entry.oldStatus]}}</p>
                            <p>Nowy status: {{collectionStatusTypeNameMapping[entry.newStatus]}}</p>
                            <p>Podany powód: {{entry.reason ?? "---"}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin: 20px" v-else>
                <h3 style="text-align: left;">Historia zmian statusów w darowiznach</h3>
                <div v-if="!donationStatusChangeHistory.length">
                    <p>Brak danych</p>
                </div>
                <div v-else style="max-height: 65vh; overflow: scroll;">
                    <div v-for="entry in donationStatusChangeHistory" :key="entry.date">
                        <div class="entry">
                            <p>Data operacji: {{entry.date}}</p>
                            <p>Użytkownik: {{entry.user.firstName + " " + entry.user.lastName}}</p>
                            <p>Stary status: {{donationStatusTypeNameMapping[entry.oldStatus]}}</p>
                            <p>Nowy status: {{donationStatusTypeNameMapping[entry.newStatus]}}</p>
                            <p>Podany powód: {{entry.reason ?? "---"}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getCollectionStatusChangeHistoryUsingGET as getCollectionStatusChangeHistory,
        getDonationStatusChangeHistoryUsingGET as getDonationStatusChangeHistory,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {SystemRole} from "@/utils/SystemRole";

    export default {
        name: "StoreStatusHistoryView",

        data() {
            return {
                collectionStatusChangeHistory: [],
                donationStatusChangeHistory: [],
                collectionStatusTypeNameMapping: {
                    IDLE: "Poza zbiórką",
                    SIGNED_UP: "Zgłoszony",
                    WAITING_FOR_STORE_CHAIN_DECISION: "Wysłany do sieci",
                    ACTIVE: "Aktywowany",
                    REJECTED: "Odrzucony",
                    RESTORED: "Przywrócony",
                },
                donationStatusTypeNameMapping: {
                    UNASSIGNED: "Wolny",
                    ASSIGNED: "Obsługiwany",
                    SUSPENDED: "Zawieszony",
                    RESERVED: "Zarezerwowany",
                    ASSIGNED_ELSEWHERE: "Zajęty",
                },
                showCollectionHistory: true,
                Role: SystemRole,
            };
        },

        props: {
            collectionStatus: {
                type: Boolean,
                default: false,
            },
        },

        async mounted() {
            try {
                this.showCollectionHistory = this.collectionStatus;

                const collectionHistoryRequest = this.showCollectionHistory
                    ? getCollectionStatusChangeHistory({id: this.$route.params.id}) : null;

                const donationHistoryRequest = this.showCollectionHistory
                    ? null : getDonationStatusChangeHistory({id: this.$route.params.id});

                const responses = await Promise.all([collectionHistoryRequest, donationHistoryRequest]);

                if (this.showCollectionHistory) {
                    this.collectionStatusChangeHistory = responses[0].data.reverse().map((entry) => {
                        const day = entry.creationDate.slice(0, 3).join("-");
                        const hour = entry.creationDate.slice(3, 6).map((num) => ("0" + num).slice(-2)).join(":");
                        return {
                            user: entry.user,
                            date: day + " " + hour,
                            oldStatus: entry.oldStatus,
                            newStatus: entry.newStatus,
                            reason: entry.reason,
                        };
                    });
                } else {
                    this.donationStatusChangeHistory = responses[1].data.reverse().map((entry) => {
                        const day = entry.creationDate.slice(0, 3).join("-");
                        const hour = entry.creationDate.slice(3, 6).map((num) => ("0" + num).slice(-2)).join(":");
                        return {
                            user: entry.user,
                            date: day + " " + hour,
                            oldStatus: entry.oldStatus,
                            newStatus: entry.newStatus,
                            reason: entry.reason,
                        };
                    });
                }
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                }
            }
        },
    };
</script>

<style scoped>
    .entry {
        text-align: left;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 5px 15px 5px 15px;
        margin: 10px 0 10px 0;
    }
</style>
