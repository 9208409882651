/* eslint-disable */
import {defineRule, configure} from "vee-validate";
import {
    required, min, max, confirmed, min_value, max_value, is, regex,
} from "@vee-validate/rules";
import {localize} from "@vee-validate/i18n";
import {messagesPL} from "./messages/messages_pl";

defineRule("required", required);
defineRule("min", min);
defineRule("max", max);
defineRule("min_value", min_value);
defineRule("max_value", max_value);
defineRule("confirmed", confirmed);
defineRule("is", is);
defineRule("regex", regex);

/*
 * Sprawdza czy hasło spełnia warunki:
 * Minimum jedna duża litera
 * Minimum jedna mała litera
 * Minimum jedna cyfra
 * Minimum jeden znak specjalny
 * Minimum 8 znaków
 */
defineRule("validPassword", (value) => {
    if (!value || !value.length) {
        return false;
    }

    let regexExp = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\[\\]{};:+=|,<.>\`~£§?/'\\-\_\"])(?=.*[a-zA-Z]).{8,}$");

    return regexExp.test(value);
});

defineRule("equalTo", (value, [other, errorMessage]) => {
    if (value !== other) {
        return errorMessage;
    }

    return true;
});

defineRule("lessOrEqual", (value, [other, errorMessage]) => {
    if (!other) {
        return true;
    }

    if (value > other) {
        return errorMessage;
    }

    return true;
});

defineRule("lessOrEqualDayDate", (value, [other, errorMessage]) => {
    if (!other) {
        return true;
    }

    if (value > other) {
        return errorMessage;
    }

    return true;
});

defineRule("duration", value => {
    if (!value) {
        return true;
    }

    if (!/^\d+:\d{2}$/.test(value)) {
        return "Dopuszczalny format to H:mm, gdzie H - liczba godzin, m - liczba minut";
    }

    return true;
});

defineRule("email", value => {
    // Field is empty, should pass
    if (!value || !value.length) {
        return true;
    }

    // Check if email
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
});

defineRule("city", value => {
    if (!/^[A-ZŻŹĆŃÓŁĘĄŚ][a-zżźćńółęąś]+(?: [A-ZŻŹĆŃÓŁĘĄŚ][a-zżźćńółęąś]+){0,4}$/.test(value)) {
        return "Proszę podać poprawną nazwę miasta";
    }

    return true;
})

defineRule("street", value => {
    if (!/^[- .0-9a-zżźćńółęąśA-ZŻŹĆŃÓŁĘĄŚ]*$/.test(value)) {
        return "Proszę podać poprawną nazwę ulicy";
    }

    return true;
})

defineRule("houseNumber", value => {
    if (!/^[1-9]\d*[a-zA-Z]?$/.test(value)) {
        return "Proszę podać poprawny numer domu";
    }

    return true;
})

defineRule("apartmentNumber", value => {
    if (!value) {
        return true;
    }

    if (!/^[1-9]\d*[a-z]?$/.test(value)) {
        return "Proszę podać poprawny numer mieszkania";
    }

    return true;
})

defineRule("postalCode", value => {
    if (!/^\d{2}-\d{3}$/.test(value)) {
        return "Poprawny format kodu pocztowego to: xx-xxx";
    }

    return true;
})

defineRule("phoneNumber", value => {
    if (!value || !value.length) {
        return true;
    }

    if (!/^(?:\+\d{2} ?)?(?:\d ?){8}\d$/.test(value)) {
        return "Proszę podać poprawny numer telefonu";
    }

    return true;
})

defineRule("requiredFile", value => {
    if (!value) {
        return "Plik jest wymagany";
    }

    return true;
});

defineRule("fileRequirements", value => {
   if (!value || !value.size) {
       return true;
   }

    if (value.size > 15728640) { // 15728640 = 15 * 1024 * 1024
        return "Rozmiar pliku nie może przekraczać 15MB";
    }

    if (!/\.(pdf|jpg|bmp|png|doc|docx|xls|xlsx|zip)$/.test(value.name)) {
        return "Dopuszczalne rozszerzenia plików to: pdf, jpg, bmp, png, doc, docx, xls, xlsx, zip";
    }

    return true;
});

defineRule("importFileRequirements", value => {
    if (!value || !value.size) {
        return true;
    }

    if (value.size > 15728640) { // 15728640 = 15 * 1024 * 1024
        return "Rozmiar pliku nie może przekraczać 15MB";
    }

    if (!/\.(xls|xlsx|csv)$/.test(value.name)) {
        return "Dopuszczalne rozszerzenia plików to: xls, xlsx, csv";
    }

    return true;
});


configure({
    generateMessage: localize("pl", {
        messages: messagesPL.validations,
    }),
});
