<template>
    <div>
        <h1 class="page-header"><i class="pi pi-envelope"></i> Konfiguracja maila</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveMailConfiguration" v-slot="{ isSubmitting }">
                <div class="p-grid mailConfiguration-form">
                    <div class="p-col-6">
                        <CustomInputText name="host" label="Host" v-model="mailConfiguration.host" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="port" label="Port" v-model="mailConfiguration.port" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="username" label="Login"
                                         v-model="mailConfiguration.username" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomPassword name="password" label="Hasło"
                                        v-model="mailConfiguration.password" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="replyTo" label="Odpowiedz do"
                                         v-model="mailConfiguration.replyTo" rules="required"/>
                    </div>
                    <div class="p-col-6">
                        <CustomInputText name="emailFrom" label="Od"
                                         v-model="mailConfiguration.emailFrom" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        updateMailConfigurationUsingPOST as updateMailConfiguration,
        getMailConfigurationUsingGET as getMailConfiguration,
    } from "@/swagger/vue-api-client";
    import CustomPassword from "@/components/form/CustomPassword";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "MailConfigurationFormView",

        components: {
            CustomPassword,
            Button,
            Toolbar,
            CustomInputText,
            Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                mailConfiguration: {
                    id: 1,
                    host: "",
                    port: "",
                    username: "",
                    password: "",
                    emailFrom: "",
                    replyTo: "",
                },
                loaded: false,
            };
        },

        beforeMount() {
            getMailConfiguration({id: 1}).then((response) => {
                this.mailConfiguration = response.data;
                this.loaded = true;
            });
        },

        methods: {
            saveMailConfiguration() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno zmienić konfigurację maila?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        updateMailConfiguration({mailConfigurationRequest: this.mailConfiguration}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.push({name: "map"});
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                    reject: () => {
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .mailConfiguration-form {
        max-width: 100%;
    }
</style>
