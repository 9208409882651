<template>
    <div class="custom-select-one">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <Dropdown v-if="!loading" :id="name" :options="internalItems" optionLabel="label" optionValue="value"
                  @change="onChange"
                  v-model="internalValue" :placeholder="internalValue ? '' : label"
                  optionGroupLabel="label" optionGroupChildren="children"
                  :ariaLabelledBy="name" appendTo="self">
            <template #optiongroup="slotProps">
                <div class="p-text-bold p-text-center p-text" style="padding: 15px;">
                    <div>{{slotProps.option.label}}</div>
                </div>
            </template>
        </Dropdown>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import Dropdown from "primevue/dropdown";
    import {useField} from "vee-validate";
    import {getPostCategoriesUsingGET as getPostCategories} from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CustomSelectOnePostCategory",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
            },
            itemValue: {
                type: String,
            },
            items: {
                type: Array,
            },
            rules: undefined,
            modelValue: null,
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                donationPosts: {
                    value: "donation",
                    label: "Wpisy dotyczące darowizn",
                    children: null,
                },
                collectionPosts: {
                    value: "collection",
                    label: "Wpisy dotyczące zbiórki",
                    children: null,
                },
                posts: [],
                internalItems: this.posts,
                loading: true,
            };
        },

        async mounted() {
            const donationPostCategories = getPostCategories({categoryType: "DONATION"});
            const collectionPostCategories = getPostCategories({categoryType: "COLLECTION"});

            try {
                const responses = await Promise.all([donationPostCategories, collectionPostCategories]);
                this.donationPosts.children = responses[0].data;
                this.collectionPosts.children = responses[1].data;
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            this.posts = [this.donationPosts, this.collectionPosts];
            this.internalItems = this.posts;
            this.loading = false;
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(val) {
                if (val.value) {
                    this.handleChange(val.value);
                    this.$emit("change", val.value);
                } else {
                    this.handleChange(undefined);
                    this.$emit("change", undefined);
                }
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Dropdown},
    };
</script>

<style lang="less" scoped>
.custom-select-one {
    margin-bottom: 20px;
    position: relative;

    label {
        font-size: 12px;
        color: #898989;
        top: -10px;
        background-color: #ffffff;
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
        position: absolute;
        left: 1rem;
        z-index: 2;
    }

    .p-dropdown {
        width: 100%;
    }
}
</style>
