<template>
    <div>
        <h1 class="page-header"><i class="fas fa-shopping-basket"></i> Lista sklepów</h1>

        <div class="search-criteria" @keyup.enter="search">
            <StoreSearchCriteria ref="storeSearchCriteria" v-model="searchCriteria"
                                 :donation-status-filters="$route.path.includes('donation')"/>

            <Toolbar>
                <template v-slot:start>
                    <Button label="Szukaj" icon="pi pi-search" @click="search"/>
                    <Button label="Wyczyść" icon="pi pi-times" @click="clear"/>
                </template>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <div v-if="Role.isFPBZCoordinator()">
                            <Button label="Eksportuj dane" icon="pi pi-download" @click="exportXLSX(false)"
                                    :disabled="!selectedStoresNumber"/>
                            <Button label="Eksportuj wszystkie dane" icon="pi pi-download" @click="exportXLSX(true)"/>
                        </div>
                        <template v-if="Role.isFPBZCoordinator()">
                            <Button label="Usuń zaznaczone" icon="pi pi-trash" @click="deleteStores"
                                    :disabled="!selectedStoresNumber"/>
                            <Button label="Importuj dane" icon="pi pi-upload" @click="showDialog"/>
                            <Button class="button-link" type="button" role="link" icon="pi pi-plus" label="Dodaj sklep"
                                    @click="$router.push({
                                        name: 'donationStoreCreate',
                                    })"/>
                        </template>
                        <template v-if="$route.path.includes('donation') && Role.isFPBZCoordinator()">
                            <Button label="Masowa zmiana statusów" icon="pi pi-flag"
                                    :disabled="disableMassChangeDonationStatusesButton"
                                    @click="toggleDonationStatusChangeDialog"/>
                        </template>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                class="p-button-outlined p-button-x-sm"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <TabMenu v-if="displayTabMenu" class="tabmenu" :model="menuItems"/>

        <DonationStoreTable ref="storeTable" :search-criteria="searchCriteria"
                            :display-all="displayAll" :query-type="queryType"
                            @selectionChange="onSelectionChange"
                            @loaded="loadInitialDisplayAll" @sortSettingsChange="onSortSettingsChange"/>

        <Dialog v-model:visible="display" header="Importowanie sklepów"
                :modal="true" :dismissableMask="true" append-to=".wrapper" v-if="isMounted">
            <div>
                <div class="container">
                    <Form ref="form" @submit="importStores">
                        <div class="p-grid">
                            <div class="p-col-12">
                                <CustomAutocompleteStoreChain name="storeChain" label="Sieć sklepów"
                                                              v-model="fileRequest.storeChainId"
                                                              :complete-function="getStoreChains"
                                                              rules="required"/>
                            </div>
                            <div class="p-col-12">
                                <CustomFileUpload
                                    name="file-upload"
                                    label="Importuj dane"
                                    v-model="file"
                                    v-on:change="onFileChange($event)"
                                    :rules="{importFileRequirements: true, requiredFile: true}"/>
                            </div>
                            <div class="p-col-12">
                                <Toolbar class="actions">
                                    <template v-slot:end>
                                        <Button label="Zapisz" type="submit" icon="pi pi-check"/>
                                    </template>
                                </Toolbar>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </Dialog>

        <Dialog v-model:visible="displayDonationStatusChangeDialog" header="Zmiana statusów sklepów"
                :modal="true" :dismissableMask="true" append-to=".wrapper" v-if="isMounted">
            <CustomSelectOne item-value="value" item-label="label" label="Nowy status"
                             v-model="statusChangeData.newStatus"
                             :items="nextAvailableDonationStatuses[searchCriteria.donationStatus]"/>

            <p>Powód zmiany statusu:</p>
            <CustomInputTextArea v-model="statusChangeData.reason"/>
            <Toolbar>
                <template v-slot:end>
                    <Button type="button" icon="pi pi-times" label="Anuluj"
                            @click="toggleDonationStatusChangeDialog"></Button>
                    <Button type="button" icon="pi pi-check" label="Zatwierdź"
                            @click="onMassDonationStatusChange"></Button>
                </template>
            </Toolbar>
        </Dialog>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import TabMenu from "primevue/tabmenu";
    import Dialog from "primevue/dialog";
    import {Form} from "vee-validate";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import {SystemRole} from "@/utils/SystemRole";
    import {
        importDonationStoresUsingPOST as importStores,
        getStoreChainsUsingGET as getStoreChains,
    } from "@/swagger/vue-api-client";
    import CustomFileUpload from "@/components/form/CustomFileUpload";
    import CustomInputTextArea from "@/components/form/CustomInputTextArea";
    import {FileUtils} from "@/utils/FileUtils";
    import CustomAutocompleteStoreChain from "@/components/form/CustomAutocompleteStoreChain";
    import {StoreStatusesUtils} from "@/utils/StoreStatusesUtils";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import {ToastUtils} from "@/utils/ToastUtils";
    import StoreSearchCriteria from "../components/StoreSearchCriteria";
    import DonationStoreTable from "../components/DonationStoreTable";

    export default {
        name: "DonationStoreListView",
        components: {
            DonationStoreTable,
            StoreSearchCriteria,
            Button,
            Toolbar,
            TabMenu,
            CustomFileUpload,
            CustomInputTextArea,
            CustomSelectOne,
            Dialog,
            CustomAutocompleteStoreChain,
            Form,
        },

        data() {
            return {
                searchCriteria: {
                    name: "",
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                loadedSearchCriteria: {},
                queryType: "OWN",
                displayAll: null,
                Role: SystemRole,
                menuItems: [],
                allMenuItems: [
                    {
                        visibleForOpl: true,
                        visibleForBZ: true,
                        value: {
                            label: "Przypisane do mojej organizacji",
                            command: () => this.setQueryType("OWN"),
                        },
                    },
                    {
                        visibleForOpl: true,
                        visibleForBZ: true,
                        value: {label: "Nieprzypisane", command: () => this.setQueryType("UNASSIGNED")},
                    },
                ],
                selectedStoresNumber: 0,
                file: undefined,
                fileRequest: {
                    name: "",
                    data: "",
                    storeChainId: null,
                },
                statusChangeData: this.getClearStatusChangeData(),
                nextAvailableDonationStatuses: StoreStatusesUtils.nextAvailableDonationStatuses,
                displayDonationStatusChangeDialog: false,
                display: false,
                isMounted: false,
            };
        },

        computed: {
            displayTabMenu() {
                return !this.Role.isFPBZCoordinator();
            },

            disableMassChangeDonationStatusesButton() {
                return this.loadedSearchCriteria.donationStatus === undefined
                    || this.loadedSearchCriteria.donationStatus === null
                    || this.loadedSearchCriteria.donationStatus === ""
                    || !this.selectedStoresNumber;
            },
        },

        beforeMount() {
            this.allMenuItems.forEach((item) => {
                if ((this.Role.isBZCoordinator() && item.visibleForBZ)
                    || (this.Role.isOPLCoordinator() && item.visibleForOpl)) {
                    this.menuItems.push(item.value);
                }
            });
        },

        mounted() {
            SearchCriteria.loadCriteria(this);
            this.loadedSearchCriteria = {...this.searchCriteria};

            this.isMounted = true;
        },

        methods: {
            onSelectionChange(value) {
                this.selectedStoresNumber = value;
            },

            search() {
                SearchCriteria.updateUrl(this);
                this.loadedSearchCriteria = {...this.searchCriteria};

                this.$nextTick(this.$refs.storeTable.search);
            },

            clear() {
                this.searchCriteria = this.getClearSearchCriteria();
                this.search();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            getClearSearchCriteria() {
                return {
                    name: "",
                    filterOutIdle: this.searchCriteria.filterOutIdle,
                    filterOutInactive: this.searchCriteria.filterOutInactive,
                    queryType: this.searchCriteria.queryType,
                    page: this.searchCriteria.page,
                    storeChainId: null,
                    assignedBzId: null,
                    assignedOplId: null,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
            // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {
                SearchCriteria.updateUrl(this);
            },

            setQueryType(value) {
                this.queryType = value;
            },

            async onFileChange(e) {
                const attachedFiles = e.target.files || e.dataTransfer.files;
                if (attachedFiles.length === 0) {
                    return;
                }

                const [file] = attachedFiles;

                this.fileRequest.data = await FileUtils.fileToBase64(file);
                this.fileRequest.name = file.name;
            },

            importStores() {
                importStores({importFileRequest: this.fileRequest}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane",
                    });
                    this.$refs.storeTable.search();
                }).catch((err) => {
                    if (err && err.response.code !== 500) {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: err.response.data,
                        });
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Nie udało się zapisać danych",
                        });
                    }
                });
                this.display = false;
            },

            exportXLSX(allData) {
                this.$refs.storeTable.exportXLSX(allData);
            },

            deleteStores() {
                this.$refs.storeTable.deleteStores();
            },

            toggleDonationStatusChangeDialog() {
                this.statusChangeData = this.getClearStatusChangeData();
                this.displayDonationStatusChangeDialog = !this.displayDonationStatusChangeDialog;
            },

            onMassDonationStatusChange() {
                this.$refs.storeTable.callMassDonationStatusChange(this.statusChangeData);
                this.toggleDonationStatusChangeDialog();
            },

            getClearStatusChangeData() {
                return {
                    storeIds: null,
                    newStatus: null,
                    reason: null,
                };
            },

            showDialog() {
                this.display = true;
            },

            reloadTable() {
                this.$refs.storeTable.search();
            },

            getStoreChains,
        },
    };
</script>

<style scoped lang="css">
::v-deep(.tabmenu) {
    padding: 2rem 1rem;
}
.p-grid {
    text-align: center;
    margin-top: 10px;
}
</style>
