<template>
    <div class="custom-autocomplete">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <AutoComplete :id="name" @complete="innerCompleteFunction" :suggestions="items"
                      field="label" v-model="internalValue" :dropdown="true" :multiple="multiple"
                      :placeholder="internalValue ? '' : label" :rules="rules" :delay="1000"
                      forceSelection @item-select="onChange" :ariaLabelledBy="name" appendTo="self"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import AutoComplete from "primevue/autocomplete";
    import {useField} from "vee-validate";

    export default {
        name: "CustomAutocomplete",
        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            completeFunction: {
                type: Function,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            extraArgumentName: {
                type: String,
                default: null,
            },
            extraArgumentValue: null,
            modelValue: null,
            rules: undefined,
        },

        emits: ["update:modelValue", "change"],

        computed: {
            internalValue: {
                get() {
                    return this.selectedValue;
                },
                set(value) {
                    this.selectedValue = value;
                    if (value && value.value) {
                        this.$emit("update:modelValue", value.value);
                    } else {
                        this.$emit("update:modelValue", value);
                    }
                },
            },
        },

        watch: {
            modelValue: {
                handler(val) {
                    if (val === undefined || val === null) {
                        this.selectedValue = null;
                        this.handleChange(undefined);
                    }
                },
            },
        },

        data() {
            return {
                items: [],
                selectedValue: null,
            };
        },

        beforeMount() {
            if (this.modelValue) {
                this.internalValue = this.modelValue;
            }
        },

        mounted() {
            const autocompleteElement = document.getElementById(this.name).closest(".p-autocomplete");
            const dropdownButtonLabel = autocompleteElement.querySelector(".p-button-label");
            dropdownButtonLabel.innerHTML = `rozwiń listę - ${this.label}`;
        },

        components: {AutoComplete},

        methods: {
            onChange(event) {
                if (event && event.value) {
                    if (event.value.value === null) {
                        this.handleChange(null);
                        this.$emit("change", null);
                    } else {
                        this.handleChange(event.value);
                        this.$emit("change", event.value.value);
                    }
                }
            },

            innerCompleteFunction(event) {
                const args = {
                    query: event.query,
                };

                if (this.extraArgumentName !== null && this.extraArgumentValue !== null) {
                    args[this.extraArgumentName] = this.extraArgumentValue;
                }

                this.completeFunction(args).then((response) => {
                    this.items = response.data;
                    if (this.emptyValue) {
                        this.items = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
                    }
                });
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style lang="less" scoped>
    .custom-autocomplete {
        margin-bottom: 20px;
        position: relative;

        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-autocomplete {
            width: 100%;
        }
    }
</style>
