<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="owner" label="Wystawiający" v-model="internalValue.ownerName"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="product" label="Produkt" v-model="internalValue.product"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="unit" label="Jednostka" v-model="internalValue.unit"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputNumber name="amountOwner" label="Wyjściowa ilość od" v-model="internalValue.amountMin"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputNumber name="amountProduct" label="Wyjściowa ilość do" v-model="internalValue.amountMax"/>
        </div>
    </div>
</template>

<script>
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import CustomInputText from "../../../components/form/CustomInputText";

    export default {
        name: "OfferSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        components: {CustomInputText, CustomInputNumber},
    };
</script>

<style lang="less" scoped>

</style>
