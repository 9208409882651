<template>
    <div>
        <Toolbar class="actions page-header">
            <template v-slot:start>
                <h1><i class="pi pi-inbox"></i> Lista Twoich wątków</h1>
            </template>
        </Toolbar>

        <div style="margin: 20px">
            <Toolbar>
                <template v-slot:end>
                    <div class="p-d-flex p-flex-wrap">
                        <Button label="Nowy wątek" icon="pi pi-plus" @click="showCreateMessageThreadDialog = true"/>
                        <Button v-if="!displayAll"
                                label="Włącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                        <Button v-if="displayAll"
                                label="Wyłącz wyświetlanie wszystkich wyników"
                                class="p-button-outlined p-button-x-sm"
                                icon="pi pi-ellipsis-h" @click="toggleDisplayAll"/>
                    </div>
                </template>
            </Toolbar>
        </div>

        <MessageThreadTable ref="messageThreadTable" :search-criteria="searchCriteria"
                            :display-all="displayAll" @loaded="loadInitialDisplayAll"
                            @sortSettingsChange="onSortSettingsChange"/>
        <CreateMessageThreadDialog v-model:visible="showCreateMessageThreadDialog"
                                   @newThreadAdded="handleNewThread"/>

    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {SearchCriteria} from "@/utils/SearchCriteria";
    import CreateMessageThreadDialog from "@/views/messageThread/components/CreateMessageThreadDialog";
    import MessageThreadTable from "./components/MessageThreadTable";

    export default {
        name: "MessageThreadListView",
        components: {
            CreateMessageThreadDialog,
            MessageThreadTable,
            Button,
            Toolbar,
        },

        data() {
            return {
                showCreateMessageThreadDialog: false,
                searchCriteria: {
                    page: {
                        limit: 10,
                        offset: 0,
                        sortField: null,
                        sortOrder: null,
                    },
                },
                displayAll: null,
            };
        },

        beforeMount() {
            SearchCriteria.loadCriteria(this);
        },

        methods: {
            handleNewThread() {
                this.search();
            },

            search() {
                this.$refs.messageThreadTable.search();
            },

            clear() {
                this.searchCriteria = this.clearSearchCriteria();
            },

            toggleDisplayAll() {
                this.displayAll = !this.displayAll;
                this.$cookies.set("displayAll", this.displayAll, "365d");
            },

            clearSearchCriteria() {
                return {
                    page: this.searchCriteria.page,
                };
            },

            loadInitialDisplayAll() {
                this.displayAll = false;
                // this.displayAll = this.$cookies.get("displayAll") === "true";
            },

            onSortSettingsChange() {},
        },
    };
</script>

<style scoped>

</style>
