<template>
    <div class="p-lg-4 p-sm-6 p-col-12" style="text-align:left">
        <CustomMultiSelect v-model="selectedColumns" :items="columns"
                           item-label="header"
                           label="Widoczne kolumny" name="store-list"/>
    </div>
    <div class="p-lg-4 p-sm-6 p-col-12 p-m-0" style="text-align:left">
        Zaznaczonych {{ selectedStores.length }} z {{ totalRecords }} wierszy
    </div>
    <div style="overflow: auto;">
        <DataTable ref="storeTable" v-if="isMounted" :value="stores" :lazy="true"
                   :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   dataKey="id"
                   :auto-layout="true" paginatorPosition="both"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" scroll-direction="horizontal"
                   v-model:selection="selectedStores" @row-select-all="onSelectAll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column v-if="Role.isFPBZCoordinator()" selectionMode="multiple"
                    header="Zaznacz wiersze" headerClass="visually-hidden-header"/>
            <Column v-if="(Role.isFPBZCoordinator() ||
                (Role.isBZCoordinator() && queryType === 'OWN'))" header="Akcje">
                <template #body="slotProps">
                    <div v-if="canSeeOverlayPanel(slotProps.data)"
                         :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje"
                                @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu"
                                :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <!-- HISTORIA ZMIAN STATUSÓW -->
                                <div
                                    v-if="Role.isBZCoordinator() && queryType === 'OWN'"
                                    class="p-d-flex p-flex-column p-flex-md-row p-m-1">
                                    <Button type="button" icon="pi pi-flag" label="Zmień status"
                                            class="p-m-1"
                                            @click="onSetStoreDonationStatus(slotProps.data)"/>
                                    <Button type="button" icon="pi pi-pencil" label="Przypisz OPL"
                                            class="p-m-1"
                                            @click="onSetDonationOPL(slotProps.data)"/>
                                    <Button class="button-link" type="button" role="link"
                                            icon="pi pi-book" label="Historia statusów"
                                            @click="$router.push({
                                                name: 'donationStoreStatusHistory',
                                                params: {'id': slotProps.data.id},
                                            })"/>
                                </div>
                                <div
                                    v-if="Role.isFPBZCoordinator() && $route.path.includes('donation')"
                                    class="p-m-1">
                                    <Button type="button" icon="pi pi-flag" label="Zmień status"
                                            @click="onSetStoreDonationStatus(slotProps.data)"/>
                                </div>

                                <div v-if="Role.isFPBZCoordinator()"
                                     class="p-m-1">
                                    <Button class="button-link" type="button" role="link"
                                            icon="pi pi-book" label="Historia statusów"
                                            @click="$router.push({
                                                name: 'donationStoreStatusHistory',
                                                params: {'id': slotProps.data.id},
                                            })"/>
                                </div>

                                <!-- ZWYKŁY WIDOK -->
                                <div class="p-d-flex p-flex-column p-flex-md-row p-m-1">
                                    <Button
                                        v-if="queryType !== 'OTHERS' && Role.isFPBZCoordinator()"
                                        class="p-m-1 button-link"
                                        type="button" role="link" icon="pi pi-pencil"
                                        label="Edycja sklepu"
                                        @click="$router.push({
                                            name: 'donationStoreEdit',
                                            params: {'id': slotProps.data.id},
                                        })"/>
                                    <Button v-if="Role.isFPBZCoordinator()" class="p-m-1"
                                            type="button" label="Usuń sklep" icon="pi pi-times"
                                            @click="deleteStore(slotProps.data.id, $event)">
                                    </Button>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                </template>
            </Column>

            <Column v-if="!Role.isOPLCoordinator()"
                    field="donationStatus" header="Status" :sortable="true">
                <template #body="slotProps">
                    <div class="container">
                        <CustomBadge v-show="slotProps.data.donationStatus === 'UNASSIGNED'"
                                     label="Wolny" :color="donationStatusBadgeColor.UNASSIGNED"/>
                        <CustomBadge v-show="slotProps.data.donationStatus === 'ASSIGNED'"
                                     label="Obsługiwany"
                                     :color="donationStatusBadgeColor.ASSIGNED"/>
                        <CustomBadge v-show="slotProps.data.donationStatus === 'SUSPENDED'"
                                     label="Zawieszony"
                                     :color="donationStatusBadgeColor.SUSPENDED"/>
                        <CustomBadge v-show="slotProps.data.donationStatus === 'RESERVED'"
                                     label="Zarezerwowany"
                                     :color="donationStatusBadgeColor.RESERVED"/>
                        <CustomBadge v-show="slotProps.data.donationStatus === 'ASSIGNED_ELSEWHERE'"
                                     label="Zajęty"
                                     :color="donationStatusBadgeColor.ASSIGNED_ELSEWHERE"/>
                    </div>
                </template>
            </Column>

            <Column v-for="(col, index) of selectedColumns" :field="col.field"
                    :header="col.header" :key="col.field + '_' + index"
                    :sortable="col.sortable" :style="col.style"></Column>

            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>

        <Dialog v-model:visible="displayDonationStatusChangeDialog" header="Zmiana statusu sklepu"
                :modal="true" :dismissableMask="true" append-to=".wrapper" v-if="isMounted">
            <CustomSelectOne item-value="value" item-label="label" label="Nowy status"
                             v-model="statusChangeData.status"
                             :items="nextAvailableDonationStatuses[currentStoreDonationStatus]"/>

            <p>Powód zmiany statusu:</p>
            <CustomInputTextarea v-model="statusChangeData.reason"/>
            <Toolbar>
                <template v-slot:end>
                    <Button type="button" icon="pi pi-times" label="Anuluj"
                            @click="closeStatusChangeDialog"></Button>
                    <Button type="button" icon="pi pi-check" label="Zatwierdź"
                            @click="onDonationStatusChange"></Button>
                </template>
            </Toolbar>
        </Dialog>

        <Dialog v-model:visible="displayOPLAssignDialog" header="Przypisanie OPL"
                :modal="true" :dismissableMask="true" append-to=".wrapper" v-if="isMounted">
            <CustomAutocompleteOrganisation name="assignedOpl" label="OPL"
                                            v-model="oplAssignData.organisationId"
                                            item-label="label"
                                            :complete-function="getSubordinateOrganisations"
                                            organisation-level="OPL"
                                            rules="required"/>
            <Toolbar>
                <template v-slot:end>
                    <Button type="button" icon="pi pi-times" label="Anuluj"
                            @click="closeOPLAssignDialog"></Button>
                    <Button type="button" icon="pi pi-check" label="Zatwierdź"
                            @click="saveOPLAssign"></Button>
                </template>
            </Toolbar>
        </Dialog>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ stores.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Column from "primevue/column";
    import DataTable from "primevue/datatable";
    import Dialog from "primevue/dialog";
    import Toolbar from "primevue/toolbar";
    import OverlayPanel from "primevue/overlaypanel";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomInputTextarea from "@/components/form/CustomInputTextArea";
    import {
        changeDonationStatusUsingPOST as changeDonationStatus,
        deleteDonationStoreUsingDELETE as deleteStore,
        massChangeDonationStatusesUsingPOST as massChangeDonationStatuses,
        searchDonationStoreCountUsingPOST as searchStoreCount,
        searchDonationStoreUsingPOST as searchStore,
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
        assignOrganisationToDonationStoreUsingPOST as assignOPL,
        deleteDonationStoresUsingPOST as deleteDonationStores,
    } from "@/swagger/vue-api-client";
    import {ExportUtils} from "@/utils/ExportUtils";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import {AddressUtils} from "@/utils/AddressUtils";
    import CustomBadge from "@/components/CustomBadge";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {StoreStatusesUtils} from "@/utils/StoreStatusesUtils";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";

    export default {
        name: "DonationStoreTable",

        components: {
            CustomMultiSelect,
            CustomBadge,
            CustomSelectOne,
            CustomInputTextarea,
            CustomAutocompleteOrganisation,
            Button,
            Column,
            DataTable,
            Dialog,
            Toolbar,
            OverlayPanel,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            queryType: {
                type: String,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange", "selectionChange"],

        data() {
            return {
                loaded: false,
                loading: false,
                paginator: true,
                totalRecords: 0,
                stores: [],
                selectedStores: [],
                allStoresSelected: false,
                storeDonationStatusOfficialNameMapping: StoreStatusesUtils.storeDonationStatusOfficialNameMapping,
                nextAvailableDonationStatuses: StoreStatusesUtils.nextAvailableDonationStatuses,
                currentStoreDonationStatus: null,
                statusChangeData: this.getClearStatusChangeData(),
                displayDonationStatusChangeDialog: false,
                displayOPLAssignDialog: false,
                searchParams: this.searchCriteria,
                Role: SystemRole,
                donationStatusBadgeColor: {
                    UNASSIGNED: "green",
                    ASSIGNED: "blue",
                    SUSPENDED: "orange",
                    RESERVED: "#e0d847",
                    ASSIGNED_ELSEWHERE: "red",
                },
                columns: [
                    {
                        field: "storeChain.name",
                        header: "Sieć sklepów",
                        sortable: true,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "name",
                        header: "Nazwa",
                        sortable: true,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "number", header: "Numer sklepu", sortable: true, style: "",
                    },
                    {
                        field: "address",
                        header: "Adres",
                        sortable: false,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "phoneNumber", header: "Nr telefonu", sortable: false, style: "",
                    },
                    {
                        field: "email", header: "Adres e-mail", sortable: true, style: "",
                    },
                    {
                        field: "assignedBz.name", header: "Przypisany BŻ", sortable: true, style: "",
                    },
                    {
                        field: "assignedOpl.name", header: "Przypisana OPL", sortable: true, style: "",
                    },
                ],
                selectedColumns: null,
                actionsMenuVisible: false,
                isMounted: false,
                userOrganisationId: null,
                oplAssignData: {
                    storeId: null,
                    organisationId: null,
                },
            };
        },

        watch: {
            selectedColumns(value) {
                this.$cookies.set("donationSelectedColumns", JSON.stringify(value));
            },

            searchCriteria(value) {
                this.searchParams = value;
            },

            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },

            queryType(value, oldValue) {
                if (value === oldValue) {
                    return;
                }

                this.searchParams.queryType = value;
                this.selectedStores = [];
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            selectedStores(value) {
                this.allStoresSelected = value.length === this.stores.length;

                this.$emit("selectionChange", value.length);
            },
        },

        created() {
            this.columns.push({
                field: "contactPerson",
                header: "Osoba kontaktowa",
                sortable: false,
                style: "",
            });
            this.selectedColumns = JSON.parse(this.$cookies.get("donationSelectedColumns")) || this.columns;
        },

        async mounted() {
            this.loading = true;

            const response = await getLoggedUserOrganisation();
            this.userOrganisationId = response.data.id;

            this.searchParams.queryType = "OWN";

            this.emitter.on("changedContrast", this.handleContrastType);

            await this.$nextTick(() => {
                const currentContrastType = document.getElementsByClassName("wrapper")[0]
                    .getAttribute("data-contrast-type");
                this.handleContrastType(currentContrastType);

                this.$emit("loaded");
                this.loaded = true;
                this.loading = false;
            });
            this.isMounted = true;
        },

        methods: {

            canSeeOverlayPanel(store) {
                return this.Role.isFPBZCoordinator()
                    || (this.Role.isBZCoordinator()
                        && store.assignedBz
                        && store.assignedBz.id === this.userOrganisationId
                    );
            },

            handleContrastType(color) {
                if (color === "black") {
                    this.donationStatusBadgeColor = {
                        UNASSIGNED: "black",
                        ASSIGNED: "black",
                        SUSPENDED: "black",
                        RESERVED: "black",
                        ASSIGNED_ELSEWHERE: "black",
                    };
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.donationStatusBadgeColor = {
                        UNASSIGNED: "green",
                        ASSIGNED: "blue",
                        SUSPENDED: "orange",
                        RESERVED: "#e0d847",
                        ASSIGNED_ELSEWHERE: "red",
                    };
                } else {
                    this.donationStatusBadgeColor = {
                        UNASSIGNED: "green",
                        ASSIGNED: "blue",
                        SUSPENDED: "orange",
                        RESERVED: "#e0d847",
                        ASSIGNED_ELSEWHERE: "red",
                    };
                }
            },

            /**
             * FIXME: remove once DataTable:row-unselect-all starts to be emitted reliably
             * when a paginator with more than 1 page is present
             */
            onSelectAll() {
                if (this.allStoresSelected) {
                    this.selectedStores = [];
                }
            },

            async search() {
                this.selectedStores = [];
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                const searchFunction = searchStore;

                searchFunction({searchCriteria: this.searchParams}).then((response) => {
                    this.stores = response.data;

                    this.stores.forEach((store) => {
                        store.address = AddressUtils.generateAddressString(store);
                    });

                    this.loading = false;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchStoreCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deleteStore(storeId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć sklep?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteStore({id: storeId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto sklep",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: error.response.data,
                                });
                            } else if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            deleteStores() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć wybrane sklepy?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteDonationStores({
                            storeIdListRequest: {
                                idList: this.selectedStores.map((store) => store.id),
                            },
                        }).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto sklepy",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: error.response.data,
                                });
                            } else if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            onSetStoreDonationStatus(store) {
                this.statusChangeData.storeId = store.id;

                this.currentStoreDonationStatus = store.donationStatus;
                this.displayDonationStatusChangeDialog = true;
            },

            onSetDonationOPL(store) {
                this.oplAssignData.storeId = store.id;
                this.displayOPLAssignDialog = true;
            },

            closeOPLAssignDialog() {
                this.oplAssignData = {
                    storeId: null,
                    organisationId: null,
                };
                this.displayOPLAssignDialog = false;
            },

            saveOPLAssign() {
                assignOPL(this.oplAssignData).then(() => {
                    this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie przypisano OPL",
                    });
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                }).finally(() => {
                    this.closeOPLAssignDialog();
                });
            },

            onDonationStatusChange() {
                if (this.statusChangeData.reason === "") {
                    this.statusChangeData.reason = null;
                }

                changeDonationStatus({storeDonationStatusChangeRequest: this.statusChangeData}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zmieniono status sklepu",
                    });
                    this.stores[
                        this.stores.findIndex((store) => store.id === this.statusChangeData.storeId)
                    ].donationStatus = this.statusChangeData.status;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                }).finally(() => {
                    this.closeStatusChangeDialog();
                });
            },

            callMassDonationStatusChange(statusChangeData) {
                statusChangeData.storeIds = this.selectedStores.map((store) => store.id);

                massChangeDonationStatuses({massDonationStatusChangeRequest: statusChangeData}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Pomyślnie zmieniono statusy sklepów",
                    });

                    this.searchParams.donationStatus = statusChangeData.newStatus;
                    this.search();
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            closeStatusChangeDialog() {
                this.statusChangeData = this.getClearStatusChangeData();
                this.displayDonationStatusChangeDialog = false;
                this.currentStoreDonationStatus = null;
            },

            getClearStatusChangeData() {
                return {
                    storeId: null,
                    status: null,
                    reason: null,
                };
            },

            exportXLSX(allData) {
                if (allData) {
                    this.loading = true;
                    searchStore({
                        searchCriteria: this.removePagingFromSearchCriteria(),
                    }).then((response) => {
                        const responseData = response.data;
                        responseData.forEach((store) => {
                            store.address = AddressUtils.generateAddressString(store);
                        });
                        ExportUtils.exportXLSX(responseData
                            .map(this.donationStoreSearchMapper()), "Sklepy", this.getExportFileName());
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    });
                } else {
                    ExportUtils.exportXLSX(this.selectedStores
                        .map(this.donationStoreSearchMapper()), "Sklepy", this.getExportFileName());
                }
            },

            donationStoreSearchMapper() {
                /* eslint-disable quote-props */
                /* eslint-disable dot-notation */
                return (store) => {
                    const storeData = {};

                    // always present fields
                    storeData["Status"] = this.translateStoreStatus(store.donationStatus);

                    // extra fields selectable by user
                    this.selectedColumns.forEach((column) => {
                        if (column.field === "storeChain.name") {
                            storeData["Sieć sklepów"] = store.storeChain.name;
                            return;
                        }
                        if (column.field === "assignedBz.name") {
                            storeData["Przypisany BŻ"] = store.assignedBz?.name;
                            return;
                        }
                        if (column.field === "assignedOpl.name") {
                            storeData["Przypisana OPL"] = store.assignedOpl?.name;
                            return;
                        }

                        // if (column.field.includes("assigned")) {
                        //     // assigned BZ/OPL - extract the object's name
                        //     const fieldName = "assigned"
                        //         + "Donation"
                        //         + column.field.slice(8).split(".")[0]; // "Bz" or "Opl"
                        //     storeData[column.header] = store[fieldName] ? store[fieldName].name : "-";
                        //
                        //     return;
                        // }

                        if (column.field === "address") {
                            storeData["Województwo"] = store.voivodeship;
                            storeData["Miasto"] = store.city;
                            storeData["Ulica"] = store.street;
                            storeData["Numer domu"] = store.houseNumber;
                            storeData["Numer mieszkania"] = store.apartmentNumber;
                            storeData["Kod pocztowy"] = store.postalCode;

                            return;
                        }

                        // text fields
                        storeData[column.header] = store[column.field];
                    });

                    return storeData;
                };
            },

            removePagingFromSearchCriteria() {
                const copy = JSON.parse(JSON.stringify(this.searchCriteria));
                copy.queryType = "OWN";
                copy.page = {
                    limit: 999999,
                    offset: 0,
                    sortField: null,
                    sortOrder: null,
                };
                return copy;
            },

            translateStoreStatus(storeStatus) {
                switch (storeStatus) {
                    // donationStatus
                    case "UNASSIGNED":
                        return "WOLNY";
                    case "ASSIGNED":
                        return "OBSŁUGIWANY";
                    case "SUSPENDED":
                        return "ZAWIESZONY";
                    case "RESERVED":
                        return "ZAREZERWOWANY";
                    case "ASSIGNED_ELSWHERE":
                        return "ZAJĘTY";
                    // unspecified
                    default:
                        return "";
                }
            },

            getExportFileName() {
                return "Dane sklepów";
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },

            getSubordinateOrganisations,
        },
    };
</script>

<style lang="less" scoped>
</style>
