<template>
    <div v-if="isMounted && Role.isFPBZCoordinator()" class="search-criteria" style="margin-top: 20px">
        <h1 class="visually-hidden">Mapa</h1>
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomSelectOne name="organisation" label="Banki żywności"
                             item-label="label" item-value="value"
                             v-model="chosenOrganisationId" :items="organisations"/>
        </div>

    </div>
    <div :style="Role.isFPBZCoordinator() ? 'height: calc(100vh - 290px)' : 'height: calc(100vh - 125px)'" id="map"/>
</template>

<script>
    import "leaflet/dist/leaflet.css";
    import {MapMixin} from "@/mixins/MapMixin";
    import {SystemRole} from "@/utils/SystemRole";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import {
        getSubordinateOrganisationsUsingGET as getSubordinateOrganisations,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "MapView",
        components: {CustomSelectOne},
        mixins: [MapMixin],

        data() {
            return {
                longitude: null,
                latitude: null,
                Role: SystemRole,
                chosenOrganisationId: null,
                organisations: [],
                isMounted: false,
            };
        },

        watch: {
            chosenOrganisationId(value) {
                this.loadMapObjects(value);
            },
        },

        async mounted() {
            if (this.Role.isFPBZCoordinator()) {
                const organisations = await getSubordinateOrganisations();
                this.organisations = organisations.data;
            }

            if (!this.map) {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Załadowanie mapy nie powiodło się",
                });

                return;
            }

            try {
                await this.initialiseMapData();
            } catch (error) {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Załadowanie mapy nie powiodło się",
                });

                return;
            }

            this.focusPoland(true);

            this.isMounted = true;
        },
    };
</script>

<style scoped>
#map {margin-top: 10px;}
</style>
