<template>
    <Form @submit="updateProductCategories" v-slot="{ isSubmitting }">
        <Toolbar class="actions">
            <template v-slot:start>
                <router-link :to="{name: 'collectionHub', params: {'id': collectionId}}">
                    <Button type="button" icon="pi pi-chevron-left" label="Wróć"></Button>
                </router-link>
            </template>
            <template v-slot:end>
                <Button v-if="!isCollectionClosed"
                        label="Zapisz"
                        type="submit"
                        icon="pi pi-check"
                        :disabled="isSubmitting"/>
            </template>
        </Toolbar>
        <DataTable ref="productCategoryTable" v-if="isMounted" :value="productCategories" :lazy="true"
                   :loading="loading">
            <Column field="name" header="Nazwa">
                <template #body="slotProps">
                    <CustomInputText v-if="!isCollectionClosed"
                                     name="name"
                                     label="Nazwa kategorii produktów"
                                     v-model="slotProps.data.name"/>
                    <span v-else >{{ slotProps.data.name }}</span>
                </template>
            </Column>
            <Column field="enovaCode" header="Kod enova" headerStyle="width: 200px">
                <template #body="slotProps">
                    <CustomInputText v-if="!isCollectionClosed"
                                     name="enovaCode"
                                     label="Kod Enova"
                                     v-model="slotProps.data.enovaCode"/>
                    <span v-else >{{ slotProps.data.enovaCode }}</span>
                </template>
            </Column>
            <Column field="estimatedValue" header="Szacunkowa wartość" headerStyle="width: 200px">
                <template #body="slotProps">
                    <CustomInputNumber v-if="!isCollectionClosed"
                                       name="estimatedValue"
                                       :min-digits="2"
                                       :max-digits="2"
                                       label="Szacunkowa wartość kilograma (zł)"
                                       :min="-99999999"
                                       v-model="slotProps.data.estimatedValue"/>
                    <span v-else >{{ slotProps.data.estimatedValue }}</span>
                </template>
            </Column>
        </DataTable>
        <Toolbar class="actions p-mt-5">
            <template v-slot:start>
                <router-link :to="{name: 'collectionHub', params: {'id': collectionId}}">
                    <Button type="button" icon="pi pi-chevron-left" label="Wróć"></Button>
                </router-link>
            </template>
            <template v-slot:end>
                <Button v-if="!isCollectionClosed"
                        label="Zapisz"
                        type="submit"
                        icon="pi pi-check"
                        :disabled="isSubmitting"/>
            </template>
        </Toolbar>
    </Form>
</template>

<script>
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import Button from "primevue/button";
    import {Form} from "vee-validate";
    import Toolbar from "primevue/toolbar";
    import {
        getCollectionProductCategoriesUsingGET as getProductCategories,
        updateCollectionProductCategoriesUsingPOST as updateProductCategories,
        checkIfClosedByFpbzUsingGET as checkIfCollectionClosed,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomInputNumber from "@/components/form/CustomInputNumber";

    export default {
        name: "CollectionProductCategoryFormView",

        components: {
            DataTable,
            Column,
            CustomInputNumber,
            CustomInputText,
            Form,
            Toolbar,
            Button,
        },

        data() {
            return {
                collectionId: this.$route.params.collectionId,
                isCollectionClosed: true,
                isMounted: false,
                productCategories: [],
                loading: true,
            };
        },

        mounted() {
            checkIfCollectionClosed({collectionId: this.collectionId}).then((res) => {
                this.isCollectionClosed = res.data;
            }).catch(() => {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    life: 3000,
                });
            });
            getProductCategories({collectionId: this.collectionId}).then((res) => {
                this.productCategories = res.data;
                this.isMounted = true;
                this.loading = false;
            }).catch(() => {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    life: 3000,
                });
            });
        },

        methods: {
            updateProductCategories() {
                const request = {
                    productCategories: this.productCategories,
                };
                updateProductCategories({collectionId: this.collectionId, request}).then(() => {
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane",
                    });
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się zapisać danych",
                    });
                });
            },
        },
    };
</script>

<style scoped>

</style>
