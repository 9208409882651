<template>
    <div>
        <div class="top-panel">
            <div class="code">
                403
            </div>
        </div>
        <div class="bottom-panel">
            <div class="icon">
                <i class="pi pi-lock"/>
            </div>
            <div class="content" role="main">
                <h1>Dostęp zabroniony</h1>
                <div>Prosimy skontaktować się z administratorem systemu</div>
                <Button class="redirect-button button-link" tag="div"
                        type="button" role="link" icon="pi pi-undo" label="Powrót na stronę główną"
                        @click="$router.push({name: 'map'})"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";

    export default {
        name: "ForbiddenView",
        components: {Button},
    };
</script>

<style lang="less" scoped>
    @import "assets/less/status-page.less";
</style>
