<template>
    <div>
        <DataTable ref="postTable" :value="posts" :lazy="true" :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" v-if="isMounted"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerStyle="width: 160px">
                <template #body="slotProps">
                    <div :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button class="p-m-1 button-link"
                                        type="button" role="link" icon="pi pi-pencil" label="Edytuj wpis"
                                        @click="$router.push({name: 'postEdit', params: {'id': slotProps.data.id}})"/>
                                <Button type="button" icon="pi pi-times" label="Usuń wpis"
                                        @click="deletePost(slotProps.data.id, $event)">
                                </Button>
                            </div>
                        </OverlayPanel>
                    </div>
                </template>
            </Column>
            <Column field="name" header="Tytuł" :sortable="true"></Column>
            <Column field="postCategory.name" header="Kategoria" :sortable="true"></Column>
            <Column field="publishDate" header="Data publikacji" :sortable="true"></Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ posts.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import OverlayPanel from "primevue/overlaypanel";
    import {
        searchPostUsingPOST as searchPost,
        searchPostCountUsingPOST as searchPostCount,
        deletePostUsingDELETE as deletePost,
    } from "@/swagger/vue-api-client";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";

    export default {
        name: "PostTable",

        components: {
            Button, Column, DataTable, OverlayPanel,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                posts: [],
                searchParams: this.searchCriteria,
                isMounted: false,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },
        },

        mounted() {
            this.$emit("loaded");

            this.isMounted = true;
        },

        methods: {
            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchPost({searchCriteria: this.searchCriteria}).then((response) => {
                    this.posts = response.data;

                    this.loading = false;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchPostCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deletePost(postId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć wpis?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deletePost({id: postId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto wpis",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
