<template>
    <div class="p-fluid custom-input-text">
        <label :for="name" :class="!showLabel ? 'visually-hidden': ''">{{ label }}</label>
        <InputNumber :id="name" v-model="internalValue" :class="computedClasses"
                     :min="min" :max="max" mode="decimal" :show-buttons="false"
                     :disabled="disabled" @blur="onBlur" @input="handleInput"
                     @keydown="onEsc" @change="handleChange"
                     :minFractionDigits="minDigits" :maxFractionDigits="maxDigits"
                     :placeholder="label"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import InputNumber from "primevue/inputnumber";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputNumber",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            min: {
                type: Number,
                default: 0,
            },
            max: {
                type: Number,
                default: null,
            },
            rules: undefined,
            modelValue: null,
            showLabel: {
                type: Boolean,
                default: false,
            },
            minDigits: {
                type: Number,
                default: null,
            },
            maxDigits: {
                type: Number,
                default: null,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            handleEscEnterBlur: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["update:modelValue", "onBlur", "onEsc"],

        watch: {
            modelValue: {
                handler() {
                    this.inputValue = this.modelValue;
                },
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleChange,
                handleInput,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                errorMessage,
                inputValue,
                handleChange,
                handleInput,
                meta,
            };
        },

        methods: {
            onBlur() {
                if (this.handleEscEnterBlur) {
                    this.$nextTick(() => {
                        this.$emit("onBlur", this.internalValue);
                    });
                }
            },

            onEsc(event) {
                if (!this.handleEscEnterBlur) {
                    return;
                }
                // esc
                if (event.keyCode === 27) {
                    event.target.blur();
                    this.$emit("onEsc");
                }
                // enter - calls onBlur through native events
                if (event.keyCode === 13) {
                    event.target.blur();
                }
            },
        },

        components: {InputNumber},
    };
</script>

<style scoped>
    .custom-input-text {
        margin-bottom: 10px;
    }
</style>
