<template>
    <span class="custom-badge" v-tooltip.top="tooltip" :style="'background-color:' + internalColor">{{ label }}</span>
</template>

<script>

    export default {
        name: "CustomBadge",
        props: {
            color: {
                type: String,
            },
            label: {
                type: String,
            },
            tooltip: {
                type: String,
            },
        },

        data() {
            return {
                internalColor: "primary",
            };
        },

        watch: {
            color() {
                this.updateColor();
            },
        },

        mounted() {
            this.updateColor();
        },

        methods: {
            updateColor() {
                switch (this.color) {
                    case "primary":
                        this.internalColor = "#ff8000";
                        break;
                    case "secondary":
                        this.internalColor = "#49688e";
                        break;
                    case "tertiary":
                        this.internalColor = "#116fbf";
                        break;
                    default:
                        this.internalColor = this.color;
                }
            },
        },
    };
</script>

<style scoped lang="less">
    @import "../assets/theme/variable";

    html[data-font-size="1"] {
        .custom-badge {
            font-size: 20px;
        }
    }
    html[data-font-size="2"] {
        .custom-badge {
            font-size: 28px;
        }
    }
    .custom-badge {
        border-radius: 4px;
        color: @color-white;
        padding: 4px 8px;
        font-size: 12px;
    }
</style>
