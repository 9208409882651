<template>
    <div>
        <div class="change-password-panel-header">
            <img src="@/assets/logo.png" alt="logo">
        </div>
        <div v-if="loaded && dataOk" class="change-password-panel-content">
            <div class="p-col-12">
                <h2>Czy chcesz przekazać sklep '{{getStoreLabel(storeOwnershipTransferRequest.store)}}'
                    organizacji '{{storeOwnershipTransferRequest.newOrganisation.name}}'?</h2>
            </div>
            <Button style="margin: 32px;" type="button" icon="pi pi-times" label="Nie" @click="reject($event)"/>
            <Button style="margin: 32px;" type="button" icon="pi pi-check" label="Tak" @click="accept($event)"/>
        </div>
        <div v-if="loaded && !dataOk" class="change-password-panel-content">
            <div class="p-col-12">
                <h2>Wygląda na to, że podany link jest niepoprawny
                    lub prośba o przekazanie sklepu została cofnięta.</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {
        getStoreOwnershipTransferRequestUsingGET as getStoreOwnershipTransferRequest,
        acceptStoreOwnershipTransferUsingPOST as acceptStoreOwnershipTransfer,
        rejectStoreOwnershipTransferUsingPOST as rejectStoreOwnershipTransfer,
    } from "@/swagger/vue-api-client";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "TransferStoreOwnershipView",
        components: {
            Button,
        },

        data() {
            return {
                storeOwnershipTransferRequest: {
                    id: null,
                    store: null,
                    oldOrganisation: null,
                    newOrganisation: null,
                    accepted: null,
                },
                storeOwnershipTransferIdRequest: {
                    id: null,
                },
                loaded: false,
                dataOk: false,
            };
        },

        async mounted() {
            try {
                const response = await getStoreOwnershipTransferRequest({token: this.$route.params.token});

                this.storeOwnershipTransferRequest = response.data;
                this.storeOwnershipTransferIdRequest = {
                    id: this.storeOwnershipTransferRequest.id,
                };
            } catch (error) {
                this.loaded = true;
                this.dataOk = false;
                return;
            }

            this.loaded = true;
            this.dataOk = true;
        },

        methods: {
            getStoreLabel(store) {
                let label = store.name;

                if (!store.city) {
                    return label;
                }
                label += " - " + store.city;

                if (!store.street) {
                    return label;
                }
                label += ", " + store.street;

                if (!store.houseNumber) {
                    return label;
                }
                label += " " + store.houseNumber;

                if (!store.apartmentNumber) {
                    return label;
                }
                label += "/" + store.apartmentNumber;

                return label;
            },

            accept(event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno przekazać sklep?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        acceptStoreOwnershipTransfer(
                            {storeOwnershipTransferIdRequest: this.storeOwnershipTransferIdRequest},
                        ).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie przekazano sklep",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Okres zapraszania sklepów do tej zbiórki minął",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        }).finally(() => {
                            this.$router.push({name: "map"});
                        });
                    },
                    reject: () => {},
                });
            },

            reject(event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno odrzucić prośbę o przekazanie sklepu?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        rejectStoreOwnershipTransfer(
                            {storeOwnershipTransferIdRequest: this.storeOwnershipTransferIdRequest},
                        ).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie odrzucono prośbę o przekazanie sklepu",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Okres zapraszania sklepów do tej zbiórki minął",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        }).finally(() => {
                            this.$router.push({name: "map"});
                        });
                    },
                    reject: () => {},
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "assets/less/change-password.less";
</style>
