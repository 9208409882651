<template>
    <div :class="containerClass" role="navigation" :data-contrast-type="contrastType">
        <AppTopBar @menuToggle="onMenuToggle" @clickedContrast="setHighContrast" @clickedFontSize="setFontSize"/>

        <div class="sidebar">
            <transition name="layout-sidebar">
                <div class="layout-sidebar" @click="onSidebarClick" v-show="isSidebarVisible()">
                    <div class="logo">
                        <router-link :to="{name: 'map'}" tag="div">
                            <img src="../../assets/logo.png" alt="Rampa" />
                        </router-link>
                    </div>
                    <div class="menu">
                        <AppMenu :threads-unread="threadsUnread"/>
                    </div>
                </div>
            </transition>
        </div>

        <div class="layout-main">

            <div class="content" id="content" role="main" tabindex="-1">
                <Toast/>
                <ConfirmDialog :dismissable-mask="true"/>
                <router-view :key="$route.name"/>
            </div>

            <footer>

            </footer>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";
    import ConfirmDialog from "primevue/confirmdialog";
    import AppTopBar from "@/components/AppTopBar";
    import {AccessibilityMixin} from "@/mixins/AccessibilityMixin";
    import {numberOfUnreadThreadsUsingGET as getNumberOfUnreadThreads} from "@/swagger/vue-api-client";
    import AppMenu from "../../components/AppMenu";

    export default {
        name: "LayoutDashboard",
        components: {
            AppTopBar, AppMenu, Toast, ConfirmDialog,
        },

        mixins: [AccessibilityMixin],

        data() {
            return {
                layoutMode: "static",
                staticMenuInactive: false,
                overlayMenuActive: false,
                mobileMenuActive: false,
                threadsUnread: 0,
            };
        },

        mounted() {
            this.emitter.on("enteredChat", this.getNumberOfThreads);
            this.getNumberOfThreads();
        },

        computed: {
            containerClass() {
                return ["layout-wrapper", "wrapper", this.fontSize > 0 ? `font-size-${this.fontSize}` : "", {
                    "layout-overlay": this.layoutMode === "overlay",
                    "layout-static": this.layoutMode === "static",
                    "layout-static-sidebar-inactive": this.staticMenuInactive && this.layoutMode === "static",
                    "layout-overlay-sidebar-active": this.overlayMenuActive && this.layoutMode === "overlay",
                    "layout-mobile-sidebar-active": this.mobileMenuActive,
                    "contrast-black": this.contrastBlack,
                    "contrast-yellow": this.contrastYellow,
                }];
            },

            contrastType() {
                if (this.contrastBlack) {
                    return "black";
                }
                if (this.contrastYellow) {
                    return "yellow";
                }
                return "none";
            },
        },

        watch: {
            $route() {
                this.mobileMenuActive = false;
                this.getNumberOfThreads();
            },
        },

        methods: {

            getNumberOfThreads() {
                getNumberOfUnreadThreads().then((res) => {
                    if (res.data >= 0) {
                        this.threadsUnread = res.data;
                    }
                });
            },

            onWrapperClick() {
                if (!this.menuClick) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }

                this.menuClick = false;
            },
            onMenuToggle() {
                this.menuClick = true;

                if (this.isDesktop()) {
                    if (this.layoutMode === "overlay") {
                        if (this.mobileMenuActive === true) {
                            this.overlayMenuActive = true;
                        }

                        this.overlayMenuActive = !this.overlayMenuActive;
                        this.mobileMenuActive = false;
                    } else if (this.layoutMode === "static") {
                        this.staticMenuInactive = !this.staticMenuInactive;
                    }
                } else {
                    this.mobileMenuActive = !this.mobileMenuActive;
                }
            },
            onSidebarClick() {
                this.menuClick = true;
            },
            onMenuItemClick(event) {
                if (event.item && !event.item.items) {
                    this.overlayMenuActive = false;
                    this.mobileMenuActive = false;
                }
            },
            onLayoutChange(layoutMode) {
                this.layoutMode = layoutMode;
            },
            removeClass(element, className) {
                if (element.classList) {
                    element.classList.remove(className);
                } else {
                    element.className = element.className.replace(
                        new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ",
                    );
                }
            },
            isDesktop() {
                return window.innerWidth > 1024;
            },
            isSidebarVisible() {
                if (this.isDesktop()) {
                    if (this.layoutMode === "static") return !this.staticMenuInactive;
                    if (this.layoutMode === "overlay") return this.overlayMenuActive;
                    return true;
                }

                return true;
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/layout-dasboard";
@import "../assets/less/responsive";
@import "../../views/assets/less/sidebar";

// TODO: define font-size-* classes
</style>
