<template>
    <div v-if="fetchingData" class="progress-spinner">
        <ProgressSpinner/>
    </div>

    <div>
        <!-- FIXME: invalid header when editing (in all views) -->
        <h1 class="visually-hidden">Tworzenie organizacji</h1>
        <h2 v-if="Role.isFPBZCoordinator()" class="page-header"><i class="fas fa-handshake"></i> Dane organizacji</h2>
        <h2 v-else class="page-header"><i class="fas fa-handshake"></i> Dane organizacji pomocy lokalnej</h2>
        <div class="container p-grid">
            <div v-if="loaded" class="p-lg-6 p-col-12 p-pr-lg-5">
                <Form ref="form" @submit="saveOrganisation" v-slot="{ isSubmitting }">
                    <div class="p-grid organisation-form">
                        <div class="p-col-12">
                            <h3 class="organisation-form-section-header">Ogólne</h3>
                            <CustomInputText name="name" label="Nazwa" v-model="organisation.name"/>
                        </div>
                        <div class="p-col-12">
                            <CustomSelectOneOrganisationLevel name="organisationLevel"
                                                              label="Poziom organizacji"
                                                              :disabled="editing || Role.isBZCoordinator()"
                                                              v-model="organisation.organisationLevel" rules="required"
                                                              @change="handleOrganisationLevelChange"
                                                              :omitting-fpbz=
                                                                  "!(Role.isFPBZCoordinator() && editingOwn)"/>
                        </div>
                        <div class="p-col-12">
                            <CustomAutocompleteOrganisation v-if="fpbzCreatesOpl"
                                                            name="organisationId" label="Organizacja nadrzędna"
                                                            :complete-function="getOrganisations"
                                                            v-model="organisation.supervisor"
                                                            :organisation-level="supervisorLevel"
                                                            :own-id="organisation.id"/>
                            <CustomInputMask name="nip" label="NIP" mask="999-999-99-99"
                                             v-model="organisation.nip"/>
                            <CustomInputNumber name="authorizationId" label="Id w Enova"
                                               v-model="organisation.authorizationId"/>
                            <CustomInputNumber v-if="organisation.organisationLevel === 'OPL'"
                                               name="benefiterCount" label="Liczba osób korzystających z darowizn"
                                               v-model="organisation.benefiterCount"/>
                        </div>
                        <div class="p-col-12">
                            <h3 class="organisation-form-section-header">Lokalizacja</h3>
                            <CustomSelectOne name="voivodeship" label="Województwo"
                                             item-label="label" item-value="value"
                                             v-model="organisation.voivodeship"
                                             :items="voivodeships"/>
                            <CustomInputText name="city" label="Miasto"
                                             v-model="organisation.city"/>
                            <CustomInputText name="street" label="Ulica"
                                             v-model="organisation.street"/>
                            <CustomInputText name="houseNumber" label="Numer domu"
                                             v-model="organisation.houseNumber"/>
                            <CustomInputText name="apartmentNumber" label="Numer mieszkania"
                                             v-model="organisation.apartmentNumber" />
                            <CustomInputText name="postalCode" label="Kod pocztowy"
                                             v-model="organisation.postalCode"/>
                        </div>
                        <div class="p-col-12">
                            <h3 class="organisation-form-section-header">Kontakt</h3>
                            <CustomInputText name="phoneNumber" label="Numer telefonu"
                                             v-model="organisation.phoneNumber"/>
                            <CustomInputText name="email" label="Adres e-mail"
                                             v-model="organisation.email" rules="email"/>
                            <CustomInputText name="collectionContactPerson" label="Osoba kontaktowa (zbiórki)"
                                             v-model="organisation.collectionContactPerson"/>
                            <CustomInputText name="donationContactPerson" label="Osoba kontaktowa (darowizny)"
                                             v-model="organisation.donationContactPerson"/>
                        </div>
                        <div class="p-col-12">
                            <Toolbar class="actions">
                                <template v-slot:start>
                                    <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                                </template>
                                <template v-if="Role.isFPBZCoordinator() && mapLoaded && geocodingApiAvailable"
                                          v-slot:end>
                                    <Button label="Załaduj geolokalizację na podstawie adresu"
                                            icon="pi pi-angle-double-right" @click="loadCoordinatesFromAddress"/>
                                </template>
                            </Toolbar>
                        </div>
                    </div>
                </Form>
                <div v-if="editing" class="p-col-12" style="text-align: left;">
                    <h3 class="organisation-form-section-header">Koordynatorzy</h3>
                    <div v-for="coordinator in organisation.coordinators" :key="coordinator.id">
                        <p>{{getCoordinatorParagraph(coordinator)}}</p>
                    </div>
                    <div v-if="!organisation.coordinators.length" style="text-align: left;">
                        <p>Brak koordynatorów</p>
                    </div>
                </div>
            </div>
            <div class="p-lg-6 p-col-12">
                <div v-if="Role.isFPBZCoordinator()">
                    <div id="map"/>
                    <div v-if="mapLoaded">
                        <p>Wybrana szer. geogr. {{organisation.latitude ?? "---"}}</p>
                        <p>Wybrana dł. geogr. {{organisation.longitude ?? "---"}}</p>
                        <Toolbar class="actions">
                            <template v-if="geocodingApiAvailable" v-slot:start>
                                <Button label="Załaduj adres na podstawie geolokalizacji"
                                        icon="pi pi-angle-double-left" @click="loadAddressFromCoordinates"/>
                            </template>
                            <template v-slot:end>
                                <Button label="Reset" icon="pi pi-undo"
                                        @click="resetLocalisation"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </div>
            <Dialog v-model:visible="display" header="Załadowane dane:" :modal="true" :dismissable-mask="true"
                    append-to=".wrapper" v-if="isMounted">
                <p>Województwo: {{geocodingData.voivodeship ?? "---"}}</p>
                <p>Miasto: {{geocodingData.city ?? "---"}}</p>
                <p>Ulica: {{geocodingData.road ?? "---"}}</p>
                <p>Numer domu: {{geocodingData.house_number ?? "---"}}</p>
                <p>Kod pocztowy: {{geocodingData.postcode ?? "---"}}</p>
                <Toolbar>
                    <template v-slot:start>
                        <p>Czy chcesz zapisać te dane?</p>
                    </template>
                    <template v-slot:end>
                        <Button type="button" icon="pi pi-check" @click="acceptAddressData"/>
                        <Button type="button" icon="pi pi-times" @click="clearAddressData"></Button>
                    </template>
                </Toolbar>
            </Dialog>
        </div>
    </div>
</template>

<script>
    import {Form} from "vee-validate";
    import Button from "primevue/button";
    import Dialog from "primevue/dialog";
    import ProgressSpinner from "primevue/progressspinner";
    import Toolbar from "primevue/toolbar";
    import {MapMixin} from "@/mixins/MapMixin";
    import {SystemRole} from "@/utils/SystemRole";
    import {AddressUtils} from "@/utils/AddressUtils";
    import {
        createOrUpdateOrganisationUsingPOST as createOrUpdateOrganisation,
        getOrganisationUsingGET as getOrganisation,
        getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
        getOrganisationsUsingGET as getOrganisations,
    } from "@/swagger/vue-api-client";
    import CustomSelectOneOrganisationLevel from "@/components/form/CustomSelectOneOrganisationLevel";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputMask from "@/components/form/CustomInputMask";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "OrganisationFormView",

        components: {
            CustomInputMask,
            Form,
            Button,
            Dialog,
            ProgressSpinner,
            Toolbar,
            CustomSelectOneOrganisationLevel,
            CustomAutocompleteOrganisation,
            CustomSelectOne,
            CustomInputText,
            CustomInputNumber,
        },

        mixins: [MapMixin],

        props: {
            value: {},
            editing: {
                type: Boolean,
                required: true,
            },
            editingOwn: {
                type: Boolean,
                required: true,
            },
        },

        data() {
            return {
                organisation: {
                    id: null,
                    name: "",
                    voivodeship: null,
                    city: null,
                    street: null,
                    houseNumber: null,
                    apartmentNumber: null,
                    postalCode: null,
                    phoneNumber: null,
                    email: null,
                    contactPerson: null,
                    latitude: null,
                    longitude: null,
                    organisationLevel: SystemRole.isBZCoordinator() ? "OPL" : null,
                    supervisor: SystemRole.isBZCoordinator() ? {
                        value: null,
                        label: localStorage.getItem("organisationName"),
                    } : null,
                    nip: null,
                    authorizationId: null,
                    benefiterCount: null,
                },
                supervisorLevelMapping: {
                    OPL: "BZ",
                    BZ: "FPBZ",
                },
                fpbz: null,
                voivodeships: AddressUtils.validVoivodeships,
                Util: AddressUtils,
                marker: null,
                initialLat: null,
                initialLng: null,
                geocodingData: null,
                display: false,
                Role: SystemRole,
                fetchingData: false,
                loaded: false,
                mapLoaded: false,
                isMounted: false,
            };
        },

        computed: {
            supervisorLevel() {
                return this.supervisorLevelMapping[this.organisation.organisationLevel];
            },

            fpbzCreatesBz() {
                return !this.editing && this.Role.isFPBZCoordinator() && this.organisation.organisationLevel === "BZ";
            },

            fpbzCreatesOpl() {
                return this.Role.isFPBZCoordinator() && this.organisation.organisationLevel === "OPL";
            },

            bzCreatesOpl() {
                return !this.editing && this.Role.isBZCoordinator();
            },
        },

        async mounted() {
            try {
                await this.fetchThisOrganisationData();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            this.loaded = true;

            if (!this.Role.isFPBZCoordinator()) {
                return;
            }

            if (!this.map) {
                ToastUtils.addToast(this, {
                    severity: "error",
                    summary: "Błąd",
                    detail: "Załadowanie mapy nie powiodło się",
                });

                return;
            }

            try {
                await this.prepareMap();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    await this.$router.push({name: "forbidden"});
                } else {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });

                    return;
                }
            }

            if (!this.geocodingApiAvailable) {
                ToastUtils.addToast(this, {
                    severity: "warn",
                    summary: "Ostrzeżenie",
                    detail: "Nie ma możliwości obliczenia współrzędnych geograficznych na podstawie adresu, "
                        + "bądź adresu na podstawie geolokalizacji, skontaktuj się z administratorem systemu",
                });
            }

            this.mapLoaded = true;
            this.isMounted = true;
        },

        methods: {
            async fetchThisOrganisationData() {
                let response;

                if (this.editing) {
                    if (this.$route.params.id) {
                        response = await getOrganisation({id: this.$route.params.id});
                    } else {
                        response = await getLoggedUserOrganisation();
                    }

                    this.organisation = response.data;

                    if (response.data.supervisor !== null) {
                        this.organisation.supervisor = {
                            value: response.data.supervisor.id,
                            label: response.data.supervisor.name,
                        };
                    }
                } else if (!this.Role.isOPLCoordinator()) {
                    response = await getLoggedUserOrganisation();

                    if (response.data.organisationLevel === "BZ") {
                        this.organisation.supervisor.value = response.data.id;
                    } else {
                        this.fpbz = {
                            value: response.data.id,
                            label: response.data.name,
                        };
                    }
                }
            },

            async prepareMap() {
                if (this.editing) {
                    await this.initialiseMapData("organisation", parseInt(this.organisation.id, 10));
                } else {
                    await this.initialiseMapData();
                }

                if (this.editing) {
                    if (this.organisation.latitude && this.organisation.longitude) {
                        this.newEditMarker(this.organisation.latitude, this.organisation.longitude,
                                           "Organizacja zostanie zapisana w tym miejscu");
                        this.initialLat = this.organisation.latitude;
                        this.initialLng = this.organisation.longitude;

                        this.map.setView([this.organisation.latitude, this.organisation.longitude], 17);
                    } else {
                        this.focusPoland(false);
                    }
                } else {
                    this.focusPoland(false);
                }

                this.map.on("click", (event) => {
                    this.organisation.latitude = event.latlng.lat;
                    this.organisation.longitude = event.latlng.lng;

                    if (!this.marker) {
                        this.newEditMarker(event.latlng.lat, event.latlng.lng,
                                           "Organizacja zostanie zapisana w tym miejscu<br/>");
                    } else {
                        this.marker.setLatLng([event.latlng.lat, event.latlng.lng]);
                    }
                });
            },

            handleOrganisationLevelChange() {
                this.organisation.supervisor = this.organisation.organisationLevel === "BZ" ? this.fpbz : null;
            },

            resetLocalisation() {
                if (this.editing) {
                    this.organisation.latitude = this.initialLat;
                    this.organisation.longitude = this.initialLng;
                    this.resetEditMarker(this.initialLat, this.initialLng);
                } else {
                    this.organisation.latitude = null;
                    this.organisation.longitude = null;
                    this.resetEditMarker();
                }
            },

            async loadCoordinatesFromAddress() {
                if (!this.organisation.city
                    && !this.organisation.street
                    && !this.organisation.postalCode) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Aby ustalić geolokalizację na podstawie adresu, musisz najpierw uzupełnić "
                            + "przynajmniej jedno z wymaganych pól (miasto, ulica lub kod pocztowy)",
                    });

                    return;
                }

                this.fetchingData = true;

                const data = await this.search({
                    city: this.organisation.city,
                    county: this.organisation.voivodeship,
                    street: this.organisation.street,
                    houseNumber: this.organisation.houseNumber,
                    postalCode: this.organisation.postalCode,
                });

                if (!data || !data.lat || !data.lon) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Ustalenie geolokalizacji na podstawie adresu nie powiodło się",
                    });

                    this.fetchingData = false;

                    return;
                }

                this.organisation.latitude = data.lat;
                this.organisation.longitude = data.lon;

                if (!this.marker) {
                    this.newEditMarker(data.lat, data.lon,
                                       "Organizacja zostanie zapisana w tym miejscu<br/>");
                } else {
                    this.marker.setLatLng([data.lat, data.lon]);
                }

                this.map.setView([this.organisation.latitude, this.organisation.longitude], 17);

                this.fetchingData = false;
            },

            loadVoivodeshipFromGeocodingData() {
                if (!this.geocodingData.state || this.geocodingData.state.indexOf(" ") === -1) {
                    return false;
                }

                const voivodeshipObjectArray = this.voivodeships.filter(
                    (item) => item.value === this.geocodingData.state.split(" ")[1],
                );

                if (!voivodeshipObjectArray.length) {
                    return false;
                }

                this.geocodingData.voivodeship = voivodeshipObjectArray[0].value;

                return true;
            },

            async loadAddressFromCoordinates() {
                if (!this.organisation.latitude || !this.organisation.longitude) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Aby uzupełnić adres na podstawie geolokalizacji, "
                            + "musisz najpierw wskazać punkt na mapie",
                    });

                    return;
                }

                this.fetchingData = true;

                this.geocodingData = await this.reverseGeocode(this.organisation.latitude, this.organisation.longitude);
                if (!this.geocodingData || this.geocodingData.error) {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Znalezienie adresu na podstawie współrzędnych nie powiodło się",
                    });

                    this.fetchingData = false;

                    this.clearAddressData();

                    return;
                }

                if (!this.loadVoivodeshipFromGeocodingData()) {
                    this.geocodingData.voivodeship = null;
                }

                this.geocodingData.city = this.geocodingData.city ?? this.geocodingData.village ?? null;

                this.display = true;
                this.fetchingData = false;
            },

            acceptAddressData() {
                this.organisation.voivodeship = this.geocodingData.voivodeship;
                this.organisation.city = this.geocodingData.city;
                this.organisation.street = this.geocodingData.road;
                this.organisation.houseNumber = this.geocodingData.house_number;
                this.organisation.apartmentNumber = null;
                this.organisation.postalCode = this.geocodingData.postcode;

                this.$refs.form.setFieldValue("voivodeship", this.geocodingData.voivodeship);
                this.$refs.form.setFieldValue("city", this.geocodingData.city);
                this.$refs.form.setFieldValue("street", this.geocodingData.road);
                this.$refs.form.setFieldValue("houseNumber", this.geocodingData.house_number);
                this.$refs.form.setFieldValue("apartmentNumber", null);
                this.$refs.form.setFieldValue("postalCode", this.geocodingData.postcode);

                this.clearAddressData();
            },

            clearAddressData() {
                this.display = false;

                this.geocodingData = null;
            },

            getCoordinatorParagraph(coordinator) {
                return coordinator.name + (coordinator.email ? " " + coordinator.email : "");
            },

            saveOrganisation() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        if (this.organisation.supervisor) {
                            this.organisation.supervisorId = this.organisation.supervisor.value
                                ?? this.organisation.supervisor;
                        }

                        if (!this.Role.isFPBZCoordinator()) {
                            this.organisation.latitude = null;
                            this.organisation.longitude = null;
                        }

                        createOrUpdateOrganisation({organisationRequest: this.organisation}).then(() => {
                            if (this.editingOwn) {
                                this.emitter.emit("organisationNameChange", this.organisation.name);
                            }

                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });

                            if (this.editingOwn) {
                                this.$router.push({name: "map"});
                            } else {
                                this.$router.back();
                            }
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },

            getOrganisations,
        },
    };
</script>

<style lang="less" scoped>
    .progress-spinner {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 15px;
    }

    #map {
        position: relative;
        z-index: 0;
        height: 600px;
        width: 600px;
        max-width: 100%;
    }

    .organisation-form {
        max-width: 100%;
    }

    .organisation-form-section-header {
        text-align: left;
        font-weight: bold;
    }
</style>
