<template>
    <div>
        <h1 class="page-header"><i class="pi pi-star"></i> Dane deklaracji odbioru darowizny</h1>
        <div class="container">
            <Form v-if="loaded" @submit="saveOffer" v-slot="{ isSubmitting }">
                <div class="p-grid offer-form">
                    <div class="p-col-12">
                        <CustomInputNumber name="requestedAmount" label="Proponowana ilość"
                                           v-model="offer.requestedAmount" :min="1" :max="999999"
                                           :rules="'required|lessOrEqual:' + offer.commission.currentAmount + ', ' +
                                               'Proponowana ilość nie może być większa niż dostępna ilość produktu'"/>
                        <CustomInputNumber name="benefiterCount" label="Liczba beneficjentów"
                                           v-model="offer.benefiterCount" :min="1" :max="999999" rules="required"/>

                        <p>Oferta darowizny od: {{ offer.commission.owner.name }}</p>
                        <p>Produkt: {{ offer.commission.product }}</p>
                        <p>Jednostka: {{ offer.commission.unit }}</p>
                        <p>Dostępne: {{ offer.commission.currentAmount }}</p>
                        <p v-if="offer.commission.comment">Komentarz: {{ offer.commission.comment }}</p>

                    </div>
                    <div class="p-col-12">
                        <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import {Form} from "vee-validate";
    import {
        createOrUpdateOfferUsingPOST as createOrUpdateOffer,
        getOfferUsingGET as getOffer,
        getCommissionUsingGET as getCommission,
    } from "@/swagger/vue-api-client";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "OfferFormView",

        components: {
            CustomInputNumber,
            Button,
            Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                offer: {
                    commission: null,
                    commissionId: null,
                    requestedAmount: null,
                    benefiterCount: null,
                    comment: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getOffer({id: this.$route.params.id}).then((response) => {
                    this.offer = response.data;
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
            } else {
                getCommission({id: this.$route.params.commissionId}).then((response) => {
                    this.offer.commission = response.data;
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    }
                });
            }
        },

        methods: {
            saveOffer() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        this.offer.commissionId = this.offer.commission.id;
                        createOrUpdateOffer({offerRequest: this.offer}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .offer-form {
        max-width: 100%;
    }
</style>
