export const AccessibilityMixin = {
    data() {
        return {
            contrastBlack: false,
            contrastYellow: false,
            fontSize: "",
        };
    },

    mounted() {
        this.setContrastAndFontSize();
    },

    methods: {
        setContrastAndFontSize() {
            const contrast = this.getCookie("contrast");
            this.setHighContrast(contrast);
            const size = this.getCookie("font-size");
            this.setFontSize(size);
        },

        getCookie(key) {
            const keyValue = document.cookie.match("(?:^|;) ?" + key + "=([^;]*)(?:;|$)");
            return keyValue ? keyValue[1] : null;
        },

        setHighContrast(c) {
            this.setCookie("contrast", c);
            if (c === "black") {
                this.contrastYellow = false;
                this.contrastBlack = true;
            } else if (c === "yellow") {
                this.contrastBlack = false;
                this.contrastYellow = true;
            } else if (c === null) {
                this.contrastBlack = false;
                this.contrastYellow = false;
            }
        },

        setFontSize(f) {
            this.setCookie("font-size", f);
            this.fontSize = f;
            document.querySelector("html").setAttribute("data-font-size", "" + f);
        },

        setCookie(key, value) {
            const expires = new Date();
            expires.setTime(expires.getTime() + 31536000000); // 1 year
            document.cookie = key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
        },
    },
};

export default {
    AccessibilityMixin,
};
