<template>
    <div>
        <h1 class="page-header"><i class="pi pi-list"></i> Dane kategorii</h1>
        <div class="container">
            <Form v-if="loaded" @submit="savePostCategory" v-slot="{ isSubmitting }">
                <div class="p-grid postCategory-form">
                    <div class="p-lg-6 p-col-12">
                        <CustomInputText name="name" label="Nazwa" v-model="postCategory.name" rules="required"/>
                    </div>
                    <div class="p-lg-6 p-col-12">
                        <CustomSelectOneCategoryType name="type"
                                                     label="Typ kategorii"
                                                     v-model="postCategory.type" rules="required"/>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Zapisz" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        createOrUpdatePostCategoryUsingPOST as createOrUpdatePostCategory,
        getPostCategoryUsingGET as getPostCategory,
    } from "@/swagger/vue-api-client";
    import CustomSelectOneCategoryType from "@/components/form/CustomSelectOneCategoryType";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "PostCategoryFormView",

        components: {
            CustomSelectOneCategoryType,
            Button,
            Toolbar,
            CustomInputText,
            Form,
        },

        props: {
            value: {},
        },

        data() {
            return {
                postCategory: {
                    id: null,
                    name: null,
                    type: null,
                },
                loaded: false,
            };
        },

        beforeMount() {
            if (this.$route.params.id) {
                getPostCategory({id: this.$route.params.id}).then((response) => {
                    this.postCategory = response.data;
                    this.loaded = true;
                }).catch((error) => {
                    if (error.response && error.response.status === 403) {
                        this.$router.push({name: "forbidden"});
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    }
                });
            } else {
                this.loaded = true;
            }
        },

        methods: {
            savePostCategory() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        createOrUpdatePostCategory({postCategoryDto: this.postCategory}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Zapisano dane",
                            });
                            this.$router.back();
                        }).catch(() => {
                            ToastUtils.addToast(this, {
                                severity: "error",
                                summary: "Błąd",
                                detail: "Nie udało się zapisać danych",
                            });
                        });
                    },
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .postCategory-form {
        max-width: 100%;
    }
</style>
