<template>
    <div>
        <h1 class="page-header"><i class="pi pi-users"></i> Dane użytkownika</h1>
        <div class="container">
            <Form @submit="saveUser" v-slot="{ isSubmitting }">
                <div class="p-grid user-form">
                    <div class="p-col-8 p-offset-2">
                        <CustomInputText name="firstName" v-model="user.firstName"
                                         :label="$t('message.register.firstName')" rules="required"/>
                        <CustomInputText name="lastName" v-model="user.lastName"
                                         :label="$t('message.register.lastName')" rules="required"/>
                        <CustomInputText name="email" v-model="user.email"
                                         :label="$t('message.register.email')" rules="required|email"/>
                        <CustomPassword name="newPassword" v-model="user.password"
                                        :label="$t('message.register.password')"
                                        rules="required|validPassword"/>
                        <CustomPassword name="confirmPassword" v-model="user.confirmPassword"
                                        :label="$t('message.register.confirmPassword')"
                                        rules="required|equalTo:@newPassword,Hasła nie są takie same"/>
                        <CustomSelectOneOrganisationLevel name="organisationLevel"
                                                          label="Poziom organizacji"
                                                          v-model="user.organisationLevel"
                                                          rules="required"
                                                          @change="user.organisationId = undefined"/>
                        <CustomAutocompleteOrganisation v-if="user.organisationLevel"
                                                        name="organisationId" label="Nazwa organizacji"
                                                        :complete-function="getOrganisations"
                                                        v-model="user.organisationId"
                                                        :organisation-level="user.organisationLevel"
                                                        rules="required"/>
                        <div class="p-col-12">
                            <Checkbox id="donationPostsCheckbox" :binary="true"
                                      v-model="user.followingDonationPosts"/>
                            <label
                                for="donationPostsCheckbox">{{$t('message.register.followDonationPostsCheckbox')}}
                            </label>
                        </div>
                        <div class="p-col-12">
                            <Checkbox id="collectionPostsCheckbox" :binary="true"
                                      v-model="user.followingCollectionPosts"/>
                            <label
                                for="collectionPostsCheckbox">{{$t('message.register.followCollectionPostsCheckbox')}}
                            </label>
                        </div>
                    </div>
                    <div class="p-col-12">
                        <Toolbar class="actions">
                            <template v-slot:end>
                                <Button label="Stwórz konto" type="submit" icon="pi pi-check" :disabled="isSubmitting"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import Checkbox from "primevue/checkbox";
    import Toolbar from "primevue/toolbar";
    import {Form} from "vee-validate";
    import {
        getOrganisationsUsingGET as getOrganisations,
        registerUsingPOST as registerRequest,
    } from "@/swagger/vue-api-client";
    import CustomPassword from "@/components/form/CustomPassword";
    import CustomSelectOneOrganisationLevel
        from "@/components/form/CustomSelectOneOrganisationLevel";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";
    import {ToastUtils} from "@/utils/ToastUtils";
    import CustomInputText from "../../components/form/CustomInputText";

    export default {
        name: "UserCreateView",

        components: {
            Button,
            Toolbar,
            CustomInputText,
            CustomPassword,
            CustomSelectOneOrganisationLevel,
            CustomAutocompleteOrganisation,
            Checkbox,
            Form,
        },

        data() {
            return {
                user: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    organisationId: "",
                    organisationLevel: "",
                    followingDonationPosts: false,
                    followingCollectionPosts: false,
                    createdByFpbz: true,
                },
            };
        },

        methods: {
            saveUser() {
                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: "Czy na pewno wysłać formularz?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        registerRequest({registrationRequest: this.user}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Konto zostało założone."
                                    + " Użytkownik musi potwierdzić adres e-mail.",
                            });
                            this.$router.back();
                        }).catch((error) => {
                            if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Użytkownik o podanym adresie e-mail już istnieje",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                });
            },

            getOrganisations,
        },
    };
</script>

<style lang="less" scoped>
.user-form {
    width: 80%;
    max-width: 800px;
}
</style>
