<template>
    <div class="p-fluid custom-input-text">
        <label :for="name" :class="!showLabel ? 'visually-hidden': ''">{{ label }}</label>
        <InputMask :id="name" v-model="internalValue" :class="computedClasses"
                   :mask="mask" mode="decimal" :unmask="true"
                   :placeholder="label"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import InputMask from "primevue/inputmask";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputMask",

        props: {
            mask: {
                type: String,
            },
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            rules: undefined,
            modelValue: null,
            showLabel: {
                type: Boolean,
                default: false,
            },
        },
        emits: ["update:modelValue"],

        watch: {
            modelValue: {
                handler() {
                    this.inputValue = this.modelValue;
                },
            },
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleChange,
                handleInput,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                errorMessage,
                inputValue,
                handleChange,
                handleInput,
                meta,
            };
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        components: {InputMask},
    };
</script>

<style scoped>
    .custom-input-text {
        margin-bottom: 10px;
    }
</style>
