<template>
    <div>
        <DataTable ref="productCategoryTable" v-if="isMounted" :value="productCategories" :lazy="true"
                   :paginator="paginator" v-model:rows="searchParams.page.limit" removableSort
                   :totalRecords="totalRecords" :loading="loaded" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column header="Akcje" headerStyle="width: 160px">
                <template #body="slotProps">
                    <!--Button type="button" label="Akcje" @click="toggleActionsMenu($event, slotProps)"
                            aria-haspopup="true" aria-controls="overlay_menu" :aria-expanded="actionsMenuVisible"/>
                    <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                  append-to=".wrapper" v-if="isMounted"
                                  @show="moveActionsMenu(slotProps.data.id)"
                                  @hide="onHideActionsMenu">
                        <Button type="button" icon="pi pi-times" v-if="slotProps.data.active"
                                label="Dezaktywuj kategorię"
                                @click="deactivateProductCategory(slotProps.data.id, $event)">
                        </Button>
                        <Button type="button" icon="pi pi-undo" v-if="!slotProps.data.active"
                                label="Aktywuj kategorię"
                                @click="activateProductCategory(slotProps.data.id, $event)">
                        </Button>
                    </OverlayPanel-->
                    <Button type="button" icon="pi pi-times" v-if="slotProps.data.active"
                            label="Dezaktywuj"
                            @click="deactivateProductCategory(slotProps.data.id, $event)">
                    </Button>
                    <Button type="button" icon="pi pi-undo" v-if="!slotProps.data.active"
                            label="Aktywuj"
                            @click="activateProductCategory(slotProps.data.id, $event)">
                    </Button>
                </template>
            </Column>
            <Column field="name" header="Nazwa" :sortable="true"></Column>
            <Column field="enovaCode" header="Kod enova" :sortable="true"></Column>
            <Column field="estimatedValue" header="Szacunkowa wartość" :sortable="true"></Column>
            <Column field="active" header="Status" headerStyle="width: 160px">
                <template #body="slotProps">
                    <CustomBadge label="Aktywna" :color="activeBadgeColor" v-show="slotProps.data.active"/>
                    <CustomBadge label="Nieaktywna" :color="inactiveBadgeColor" v-show="!slotProps.data.active"/>
                </template>
            </Column>
            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ productCategories.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{ totalRecords }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    // import OverlayPanel from "primevue/overlaypanel";
    import {
        searchProductCategoryUsingPOST as searchProductCategory,
        searchProductCategoryCountUsingPOST as searchProductCategoryCount,
        deactivateProductCategoryUsingPOST as deactivateProductCategory,
        activateProductCategoryUsingPOST as activateProductCategory,
    } from "@/swagger/vue-api-client";
    import CustomBadge from "@/components/CustomBadge";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "ProductCategoryTable",
        components: {
            Button,
            Column,
            DataTable,
            CustomBadge,
            // OverlayPanel,
        },
        mixins: [AccessibilityTableMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loaded: false,
                paginator: true,
                totalRecords: 0,
                productCategories: [],
                searchParams: this.searchCriteria,
                activeBadgeColor: "green",
                inactiveBadgeColor: "red",
                isMounted: false,
            };
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },
        },

        mounted() {
            this.emitter.on("changedContrast", this.handleContrastType);

            this.$nextTick(() => {
                const currentContrastType = document.getElementsByClassName("wrapper")[0]
                    .getAttribute("data-contrast-type");
                this.handleContrastType(currentContrastType);

                this.$emit("loaded");
            });
        },

        methods: {
            handleContrastType(color) {
                if (color === "black") {
                    this.activeBadgeColor = "black";
                    this.inactiveBadgeColor = "black";
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.activeBadgeColor = "green";
                    this.inactiveBadgeColor = "red";
                } else {
                    this.activeBadgeColor = "green";
                    this.inactiveBadgeColor = "red";
                }
            },

            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                this.updateTotalRecords();
                this.loaded = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchProductCategory({searchCriteria: this.searchCriteria}).then((response) => {
                    this.productCategories = response.data;
                    this.loaded = false;
                    this.isMounted = true;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchProductCategoryCount({searchCriteria: this.searchCriteria}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            deactivateProductCategory(productCategoryId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno deaktywować kategorię produktu?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deactivateProductCategory({id: productCategoryId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie zdezaktywowano kategorię produktu",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "info",
                                    summary: "Informacja",
                                    detail: "Nie można deaktywować kategorii produktu, gdy trwa zbiórka",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            activateProductCategory(productCategoryId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno reaktywować kategorię produktu?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        activateProductCategory({id: productCategoryId}).then(() => {
                            this.search();
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie reaktywowano kategorię produktu",
                            });
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
