<template>
    <div class="p-fluid custom-input-textarea">
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
        <Textarea :id="name" :rows="rows" :auto-resize="autoResize" cols="30" v-model="internalValue"
                  :class="computedClasses" @input="handleChange" @blur="handleBlur"/>
        <label :for="name" :class="{'visually-hidden' : hideLabel }">{{ label }}</label>
    </div>
</template>

<script>
    import Textarea from "primevue/textarea";
    import {useField} from "vee-validate";

    export default {
        name: "CustomInputTextarea",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            width: {
                type: String,
            },
            rows: {
                type: Number,
                default: 5,
            },
            autoResize: {
                type: Boolean,
                default: true,
            },
            hideLabel: {
                type: Boolean,
                default: false,
            },
            rules: undefined,
            modelValue: null,
        },
        emits: ["update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },

            computedClasses() {
                return this.errorMessage ? "p-invalid" : "";
            },
        },

        methods: {
            inputListener(event) {
                this.$emit("update:modelValue", event.target.value);
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },

        components: {Textarea},
    };
</script>

<style scoped>
    .custom-input-textarea {
        margin-bottom: 10px;
    }
</style>
