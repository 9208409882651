<template>
    <div class="simple-layout-wrapper wrapper">
        <div class="main p-fluid">
            <router-view />
            <Toast/>
        </div>
    </div>
</template>

<script>
    import Toast from "primevue/toast";

    export default {
        name: "LayoutSimple",
        components: {Toast},
    };
</script>

<style lang="less">
    @import "../assets/less/layout-simple.less";
</style>
