import Leaflet from "leaflet";
import {markRaw} from "vue";
import axios from "axios";
import {
    getAssociatedOrganisationsMapObjectsUsingGET as getAssociatedOrganisationsMapObjects,
    getAssociatedDonationStoresMapObjectsUsingGET as getAssociatedStoresMapObjects,
    getLoggedUserOrganisationUsingGET as getLoggedUserOrganisation,
    getUnassignedDonationStoresMapObjectsUsingGET as getUnassignedStoresMapObjects,
} from "@/swagger/vue-api-client";
import {
    blueIcon,
    goldIcon,
    greenIcon,
    redIcon,
    orangeIcon,
    violetIcon,
    greyIcon,
    blackIcon,
} from "@/utils/MapUtils";
import {SystemRole} from "@/utils/SystemRole";
import "@/assets/theme/map-styles.less";

export const MapMixin = {
    data() {
        return {
            map: null,
            marker: null,
            layerGroups: {
                fpbz: null,
                bz: null,
                opl: null,
                store: null,
            },
            entityMapping: {},
            currentOrganisationId: null,
            Role: SystemRole,
        };
    },

    computed: {
        geocodingApiAvailable() {
            return process.env.VUE_APP_GEOCODING_API_URL !== null
                && process.env.VUE_APP_GEOCODING_API_URL !== undefined;
        },
    },

    async mounted() {
        if (!process.env.VUE_APP_TILES_PROVIDER_URI) {
            return;
        }

        let map;
        try {
            map = Leaflet.map("map", {
                center: [52.23, 21],
                zoom: 13,
            });
        } catch (error) {
            return;
        }

        const legend = Leaflet.control({position: "bottomright"});

        legend.onAdd = () => {
            const div = Leaflet.DomUtil.create("div", "legend");

            div.innerHTML += "<div class='legend-title'>Legenda</div>";
            div.innerHTML += "<i style=\"background: #a05ecb\"></i><span>FPBŻ</span><br>";
            div.innerHTML += "<i style=\"background: #7b7b7b\"></i><span>BŻ</span><br>";
            div.innerHTML += "<i style=\"background: #3d3d3d\"></i><span>OPL</span><br>";

            div.innerHTML += "<div class='legend-title'>Statusy sklepów</div>";
            div.innerHTML += "<i style=\"background: #2aad27\"></i><span>Wolny</span><br>";
            div.innerHTML += "<i style=\"background: #2a81cb\"></i><span>Obsługiwany</span><br>";
            div.innerHTML += "<i style=\"background: #cb8427\"></i><span>Zawieszony</span><br>";
            div.innerHTML += "<i style=\"background: #ffd326\"></i><span>Zarezerwowany</span><br>";
            div.innerHTML += "<i style=\"background: #cb2b3e\"></i><span>Zajęty</span><br>";

            return div;
        };

        legend.addTo(map);

        Leaflet.tileLayer(process.env.VUE_APP_TILES_PROVIDER_URI, {
            attribution: "Map data &copy; <a href=\"https://www.openstreetmap.org/copyright\">"
                + "OpenStreetMap</a> contributors",
        }).addTo(map);

        this.map = markRaw(map);
    },

    methods: {
        async initialiseMapData() {
            const markerLayerGroup = new Leaflet.LayerGroup();
            markerLayerGroup.addTo(this.map);

            this.currentOrganisationId = (await getLoggedUserOrganisation()).data.id;

            this.layerGroups = {
                markerLayerGroup: markRaw(markerLayerGroup),
            };

            await this.loadMapObjects(this.currentOrganisationId);
        },

        async loadMapObjects(organisationId = null) {
            if (organisationId === null) {
                organisationId = (await getLoggedUserOrganisation()).data.id;
            }
            const organisationsRequest = getAssociatedOrganisationsMapObjects({id: organisationId});
            const storesRequest = getAssociatedStoresMapObjects({organisationId});
            const unassignedStoresRequest = getUnassignedStoresMapObjects();

            const responses = await Promise.all([organisationsRequest, storesRequest, unassignedStoresRequest]);
            const organisations = responses[0].data;
            const stores = responses[1].data;
            const unassignedStores = responses[2].data;

            const organisation = organisations.find((item) => item.id === organisationId);

            this.layerGroups.markerLayerGroup.clearLayers();

            organisations.forEach((item) => {
                switch (item.organisationLevel) {
                    case "FPBZ":
                        if (item.latitude && item.longitude) {
                            Leaflet.marker([item.latitude, item.longitude], {icon: violetIcon})
                                .bindTooltip(item.name).addTo(this.layerGroups.markerLayerGroup);
                        }
                        break;
                    case "BZ":
                        if (
                            organisation !== undefined
                            && organisation.organisationLevel === "BZ"
                            && item.id !== organisation.id
                        ) {
                            return;
                        }

                        if (item.latitude && item.longitude) {
                            Leaflet.marker([item.latitude, item.longitude], {icon: greyIcon})
                                .bindTooltip(item.name).addTo(this.layerGroups.markerLayerGroup);
                        }
                        break;
                    case "OPL":
                        if (
                            organisation !== undefined
                            && organisation.organisationLevel === "BZ"
                            && item.supervisor.id !== organisation.id
                        ) {
                            return;
                        }
                        if (item.latitude && item.longitude) {
                            Leaflet.marker([item.latitude, item.longitude], {icon: blackIcon})
                                .bindTooltip(item.name).addTo(this.layerGroups.markerLayerGroup);
                        }
                        break;
                    default:
                        throw new Error("OrganisationLevel is null");
                }
            });

            stores.forEach((store) => this.addStoreMarker(store));
            if (organisation.organisationLevel !== "FPBZ") {
                unassignedStores.forEach((store) => this.addStoreMarker(store));
            }
        },

        addStoreMarker(store) {
            if (!store.latitude || !store.longitude) {
                return;
            }

            let icon;
            switch (store.donationStatus) {
                case "UNASSIGNED":
                    icon = greenIcon;
                    break;
                case "ASSIGNED":
                    icon = blueIcon;
                    break;
                case "SUSPENDED":
                    icon = orangeIcon;
                    break;
                case "RESERVED":
                    icon = goldIcon;
                    break;
                case "ASSIGNED_ELSEWHERE":
                    icon = redIcon;
                    break;
                default:
                    throw Error("Donation status not defined");
            }

            Leaflet.marker([store.latitude, store.longitude], {icon})
                .bindTooltip(store.name).addTo(this.layerGroups.markerLayerGroup);
        },

        newEditMarker(latitude, longitude, message) {
            const marker = Leaflet.marker([latitude, longitude], {icon: redIcon})
                .bindPopup(message)
                .addTo(this.map);

            this.marker = markRaw(marker);
        },

        resetEditMarker(latitude = null, longitude = null) {
            if (latitude && longitude) {
                this.marker.setLatLng([latitude, longitude]);

                this.map.setView([latitude, longitude], 17);
            } else {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                    this.marker = null;
                }

                this.focusPoland(false);
            }
        },

        focusPoland(fullscreen) {
            // eslint-disable-next-line no-loss-of-precision
            this.map.setView([52.034528294213646, 19.226074218750004], fullscreen ? 7 : 6); // widok na cala Polske
        },

        async search(data) {
            let uri = process.env.VUE_APP_GEOCODING_API_URL + "/search?country=Poland";

            if (data.county) {
                uri += `&county=${data.county}`;
            }
            if (data.city) {
                uri += `&city=${data.city}`;
            }
            if (data.street) {
                uri += `&street=${data.street}`;

                if (data.houseNumber) {
                    uri += `+${data.houseNumber}`;
                }
            } else if (data.houseNumber) {
                uri += `&street=${data.houseNumber}`;
            }
            if (data.postalCode) {
                uri += `&postal-code=${data.postalCode}`;
            }

            uri += "&format=jsonv2&limit=1";

            const response = await axios(encodeURI(uri));

            return response.error ? response : response.data[0];
        },

        async reverseGeocode(latitude, longitude) {
            const response = await axios(`${process.env.VUE_APP_GEOCODING_API_URL}/reverse`
                + `?lat=${latitude}&lon=${longitude}&zoom=18&format=jsonv2&accept-language=pl`);

            return response.error ? response : response.data.address;
        },
    },
};

export default {
    MapMixin,
};
