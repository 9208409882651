<template>
    <div class="custom-autocomplete">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <AutoComplete :id="name" @complete="innerCompleteFunction" :suggestions="items"
                      field="label" v-model="internalValue" :dropdown="true" :multiple="multiple"
                      :placeholder="internalValue ? '' : label" :rules="rules" :delay="1000"
                      @item-select="onChange" :ariaLabelledBy="name" appendTo="self"
                      forceSelection />
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import AutoComplete from "primevue/autocomplete";
    import {useField} from "vee-validate";

    // fixme nadal działa dziwnie...
    export default {
        name: "CustomAutocompleteOrganisation",

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            emptyValue: {
                type: Boolean,
                default: true,
            },
            organisationLevel: {
                type: String,
                default: null,
            },
            supervisorId: {
                type: Number,
                default: null,
            },
            completeFunction: {
                type: Function,
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            ownId: {
                type: Number,
                default: null,
            },
            modelValue: null,
            rules: undefined,
        },

        emits: ["change", "update:modelValue"],

        computed: {
            internalValue: {
                get() {
                    return this.selectedValue;
                },
                set(value) {
                    if (this.selectedValue === value) {
                        return;
                    }

                    this.selectedValue = value;
                    if (value && value.value) {
                        this.$emit("update:modelValue", value.value);
                        this.$emit("change", value.value);
                    } else {
                        this.$emit("update:modelValue", undefined);
                        this.$emit("change", null);
                    }
                },
            },
        },

        watch: {
            modelValue: {
                handler(val) {
                    if (val === undefined || val === null) {
                        this.selectedValue = null;
                        return;
                    }
                    this.valueToSet = val;
                    if (this.items.length === 0) {
                        this.innerCompleteFunction({query: ""});
                    }
                },
            },
        },

        data() {
            return {
                items: [],
                selectedValue: null,
                valueToSet: null,
            };
        },

        mounted() {
            const autocompleteElement = document.getElementById(this.name).closest(".p-autocomplete");
            const dropdownButtonLabel = autocompleteElement.querySelector(".p-button-label");
            dropdownButtonLabel.innerHTML = `rozwiń listę - ${this.label}`;
        },

        beforeMount() {
            if (this.modelValue) {
                this.internalValue = this.modelValue;
            }
        },

        components: {AutoComplete},

        methods: {
            onChange(event) {
                if (event && event.value) {
                    this.handleChange(event.value);
                } else {
                    this.handleChange(undefined);
                }
            },

            innerCompleteFunction(event) {
                this.completeFunction({
                    query: event.query,
                    organisationLevel: this.organisationLevel,
                    supervisorId: this.supervisorId,
                }).then((response) => {
                    this.items = response.data.filter((item) => item.value !== this.ownId);
                    if (this.emptyValue) {
                        this.items = [{value: null, label: this.$t("message.other.emptyValue")}, ...this.items];
                    }
                    if (this.valueToSet) {
                        this.selectedValue = this.items.find((i) => i.value === this.valueToSet);
                    }
                });
            },
        },

        setup(props) {
            const {
                value: inputValue,
                errorMessage,
                handleBlur,
                handleChange,
                meta,
            } = useField(props.name, props.rules, {
                initialValue: props.modelValue,
            });

            return {
                handleChange,
                handleBlur,
                errorMessage,
                inputValue,
                meta,
            };
        },
    };
</script>

<style lang="less" scoped>
.custom-autocomplete {
    margin-bottom: 20px;
    position: relative;

    label {
        font-size: 12px;
        color: #898989;
        top: -10px;
        background-color: #ffffff;
        padding: 2px 4px;
        margin-left: -4px;
        margin-top: 0;
        position: absolute;
        left: 1rem;
        z-index: 2;
    }

    .p-autocomplete {
        width: 100%;
    }
}
</style>
