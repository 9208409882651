<template>
    <div v-if="isMounted">
        <h2 class="page-header"><i class="fas fa-shopping-basket"></i> Raport końcowy - szczegółowy</h2>
        <Toolbar style="margin-bottom: 30px;">
            <template v-slot:start>
                <router-link :to="{name: 'collectionHub', params: {'id': $route.params.collectionId}}">
                    <Button type="button" icon="pi pi-chevron-left" label="Wróć"></Button>
                </router-link>
            </template>
            <template v-slot:end>
                <Button type="button" icon="pi pi-unlock"
                        label="Odblokuj raport"
                        v-if="!isCollectionClosed && report.locked && report.canUnlock"
                        @click="unlockReport($event)"/>
                <Button type="button" icon="pi pi-lock"
                        v-if="!isCollectionClosed && !report.locked && !this.organisationId"
                        label="Zakończ raport szczegółowy"
                        @click="lockReport($event)"/>
            </template>
        </Toolbar>
        <div class="p-grid">
            <div class="p-md-5 p-col-12 p-text-left">
                <span>
                    <b>Wg. raportów wstępnych - szacunkowych zebrano: {{ report.collectedFromDaily }} kg.</b>
                </span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-md-5 p-col-12 p-text-left">
                <span>
                    <b>Sumarycznie w raporcie końcowym - szczegółowym wykazano: {{ sumOfCollectedAmount }} kg.</b>
                </span>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <DataTable :value="report.collectedAmounts"
                           v-if="isMounted">
                    <Column header="Kod Enova" field="enovaCode"/>
                    <Column header="Kategoria produktów" field="name" style="width: 320px"/>
                    <Column header="Wartość kilograma" field="estimatedValue">
                        <template #body="slotProps">
                            {{ formatNumber(slotProps.data.estimatedValue) + 'zł' }}
                        </template>
                    </Column>
                    <Column header="Do tej pory zebrano" field="collectedAmount" style="width: 80px">
                        <template #body="slotProps">
                            {{ formatNumber(slotProps.data.collectedAmount) }}
                        </template>
                    </Column>
                    <Column header="Dodaj/odejmij" v-if="!Role.isFPBZCoordinator()
                                && !isCollectionClosed && !report.locked"
                            style="width: 240px">
                        <template #body="slotProps">
                            <CustomInputNumber name="collectedFoodToAdd"
                                               :min-digits="2"
                                               :max-digits="2"
                                               class="collected-food-input"
                                               label="Tu wpisz liczbę kilogramów"
                                               :min="-99999999"
                                               :handle-esc-enter-blur="true"
                                               @on-esc="cancelCollectedFood(slotProps.data)"
                                               @on-blur="saveCollectedFood(
                                                   $event,
                                                   slotProps.data)"
                                               v-model="slotProps.data.collectedAmountToAdd"/>
                        </template>
                    </Column>
                </DataTable>
            </div>

        </div>
    </div>
</template>

<script>
    import Toolbar from "primevue/toolbar";
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import {SystemRole} from "@/utils/SystemRole";
    import {NumbersMixin} from "@/mixins/NumbersMixin";
    import {
        getCollectionReportForOrganisationUsingGET as getCollectionReportForOrganisation,
        getCollectionReportUsingGET as getCollectionReport,
        addAmountToCollectionReportUsingPOST as saveCollectionReport,
        checkIfClosedByFpbzUsingGET as checkIfCollectionClosed,
        lockFinalReportsForOrganisationUsingPOST as lockReports,
        unlockFinalReportsForOrganisationUsingPOST as unlockReports,
    } from "@/swagger/vue-api-client";
    import CustomInputNumber from "@/components/form/CustomInputNumber";
    import {ToastUtils} from "@/utils/ToastUtils";

    export default {
        name: "CollectionReportView",

        mixins: [NumbersMixin],

        components: {
            Toolbar, Button, DataTable, Column, CustomInputNumber,
        },

        data() {
            return {
                collectionId: this.$route.params.collectionId,
                organisationId: this.$route.params.organisationId,
                Role: SystemRole,
                report: null,
                isMounted: false,
                isCollectionClosed: true,
            };
        },

        computed: {
            sumOfCollectedAmount() {
                return this.formatNumber(
                    this.report.collectedAmounts.reduce((acc, r) => +acc + r.collectedAmount, 0).toFixed(2),
                );
            },
        },

        mounted() {
            this.checkIfCollectionClosed();
            this.getReport();
        },

        methods: {
            cancelCollectedFood(collectedAmount) {
                collectedAmount.collectedAmountToAdd = null;
            },

            saveCollectedFood(val, category) {
                if (!val) {
                    return;
                }
                const body = {
                    organisationId: this.report.organisationId,
                    collectionId: this.report.collectionId,
                    collectionProductCategoryId: category.id,
                    collectedAmountToAdd: val,
                };
                saveCollectionReport({reportRequest: body}).then(() => {
                    category.collectedAmount = +category.collectedAmount + val;
                    category.collectedAmountToAdd = null;
                    ToastUtils.clearToasts(this);
                    ToastUtils.addToast(this, {
                        severity: "success",
                        summary: "Sukces",
                        detail: "Zapisano dane",
                    }, false);
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Nie udało się zapisać danych",
                    });
                });
            },

            getReport() {
                let searchFunction;
                let body;
                if (this.organisationId) {
                    body = {
                        collectionId: this.collectionId,
                        organisationId: this.organisationId,
                    };
                    searchFunction = getCollectionReportForOrganisation;
                } else {
                    body = {
                        collectionId: this.collectionId,
                    };
                    searchFunction = getCollectionReport;
                }
                searchFunction(body).then((r) => {
                    this.report = r.data;
                    this.report.collectedAmounts.forEach((amount) => {
                        amount.collectedAmountToAdd = null;
                    });
                    this.isMounted = true;
                });
            },

            checkIfCollectionClosed() {
                checkIfCollectionClosed({collectionId: this.collectionId}).then((res) => {
                    this.isCollectionClosed = res.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        life: 3000,
                    });
                });
            },

            lockReport(event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno chcesz zakończyć raport? "
                        + "Po jego zakończeniu nie będziesz mieć możliwości edytowania danych.",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak, zakończ",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Cofnij",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const body = {
                            collectionId: this.report.collectionId,
                        };
                        lockReports(body).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Raport został zakończony.",
                            });
                            this.getReport();
                        });
                    },
                    reject: () => {},
                });
            },

            unlockReport(event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno chcesz odblokować raport? "
                        + "Po jego odblokowaniu organizacja z powrotem będzie mogła wprowadzać dane.",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak, odblokuj",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Cofnij",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const body = {
                            collectionId: this.report.collectionId,
                            organisationId: this.report.organisationId,
                        };
                        unlockReports(body).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Odblokowano raport.",
                            });
                            this.report.locked = false;
                        });
                    },
                    reject: () => {
                    },
                });
            },
        },
    };
</script>

<style scoped lang="less">
::v-deep(.p-datatable-wrapper) {
    .p-datatable-table {
        .p-datatable-thead {
            th {
                padding: 0;
            }
        }
        .p-datatable-tbody {
            td {
                padding: 0;
            }
        }
    }
}
</style>
