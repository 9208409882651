<template>
    <div class="custom-multi-select">
        <label :for="name" class="visually-hidden">{{ label }}</label>
        <MultiSelect :id="name" :options="internalItems" :optionLabel="itemLabel" :optionValue="itemValue"
                     :option-group-label="optionGroupLabel" :option-group-children="optionGroupChildren"
                     :filter="filter" :empty-filter-message="emptyFilterMessage"
                     :maxSelectedLabels="3"
                     :selectedItemsLabel="'Wybrano {0} wartości'"
                     v-model="internalValue" :placeholder="internalValue ? 'Wybierz wartości' : label"
                     appendTo="self" @change="onChange" :required="required" :errorMessage="errorMessage"
                     @show="focusEl" ref="multiselect"/>
        <span v-show="errorMessage" class="p-invalid">{{ errorMessage }}</span>
    </div>
</template>

<script>
    import MultiSelect from "primevue/multiselect";

    export default {
        name: "CustomMultiSelect",

        components: {MultiSelect},

        props: {
            name: {
                type: String,
            },
            label: {
                type: String,
            },
            itemLabel: {
                type: String,
                default: "label",
            },
            itemValue: {
                type: String,
                default: null,
            },
            optionGroupLabel: {
                type: String,
            },
            optionGroupChildren: {
                type: String,
            },
            items: {
                type: Array,
            },
            modelValue: null,
            required: {
                type: Boolean,
            },
            errorMessage: {
                type: String,
            },
            filter: {
                type: Boolean,
                default: false,
            },
            emptyFilterMessage: {
                type: String,
            },
        },

        emits: ["update:modelValue", "change"],

        data() {
            return {
                internalItems: this.items,
            };
        },

        watch: {
            items(value) {
                this.internalItems = value;
            },
        },

        mounted() {
            this.internalItems = this.items;

            if (document.getElementById(this.name)) {
                const multiselectELement = document.getElementById(this.name);
                const multiselectInputELement = multiselectELement.querySelector("input");
                multiselectInputELement.id = this.name;
            }
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            onChange(val) {
                this.$emit("change", val.value);
            },
            focusEl() {
                const multiselectELement = document.getElementById(this.name);
                if (multiselectELement) {
                    const multiselectInputELement = multiselectELement.querySelector("input[type='checkbox']");
                    multiselectInputELement.focus();
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .custom-multi-select {
        margin-bottom: 20px;
        position: relative;
        max-width: 100%;

        label {
            font-size: 12px;
            color: #898989;
            top: -10px;
            background-color: #ffffff;
            padding: 2px 4px;
            margin-left: -4px;
            margin-top: 0;
            position: absolute;
            left: 1rem;
            z-index: 2;
        }

        .p-multiselect {
            width: 100%;
        }
    }
</style>
