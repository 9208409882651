<template>
    <div class="p-lg-4 p-sm-6 p-col-12" style="text-align:left">
        <CustomMultiSelect v-model="selectedColumns" :items="columns"
                           item-label="header"
                           label="Widoczne kolumny" name="organisation-list"/>
    </div>
    <div style="overflow: auto">
        <DataTable ref="organisationTable" v-if="isMounted" :value="organisations" :lazy="true"
                   :paginator="paginator"
                   v-model:rows="searchParams.page.limit" removableSort :auto-layout="true"
                   :totalRecords="totalRecords" :loading="loading" @page="onPage"
                   @sort="onSort" :rowsPerPageOptions="[5,10,20,50]"
                   :key="displayAll" scroll-direction="horizontal"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink
                   CurrentPageReport RowsPerPageDropdown"
                   currentPageReportTemplate="Wyniki {first} do {last} z {totalRecords}">
            <Column v-if="displayActionsColumn" header="Akcje" :style="{'min-width': '150px'}">
                <template #body="slotProps">
                    <!-- WIDOK ZAPRASZANIA ORGANIZACJI W ZBIÓRCE -->
                    <div v-if="!!collectionId && !collectionStartedByFpbz">
                        <Button v-if="displayInviteButton(slotProps.data)" type="button"
                                icon="pi pi-plus" label="Zaproś"
                                @click="updateOrganisationParticipationStatus(slotProps.data, false)"/>
                        <Button v-if="displayDismissButton(slotProps.data)" type="button"
                                icon="pi pi-minus" label="Usuń"
                                @click="updateOrganisationParticipationStatus(slotProps.data, true)"/>
                    </div>

                    <!-- WIDOK ORGANIZACJI W ZBIÓRCE - RAPORTY -->
                    <div
                        v-else-if="!!collectionId && collectionStartedByFpbz &&
                            (!canUnlockDailyReports(slotProps.data) || collectionClosed)">
                        <Button class="button-link" type="button" role="link"
                                icon="pi pi-book" label="Raport"
                                v-if="acceptedParticipants.has(slotProps.data.id)"
                                @click="$router.push({name: 'collectionReportForOrganisation', params: {
                                    collectionId: collectionId,
                                    organisationId: slotProps.data.id
                                }})"/>
                    </div>

                    <div :class="'actions-' + slotProps.data.id"
                         v-else-if="!!collectionId && !collectionClosed &&
                             collectionStartedByFpbz && canUnlockDailyReports(slotProps.data)">
                        <Button type="button" label="Akcje"
                                @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu"
                                :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button class="button-link" type="button" role="link"
                                        icon="pi pi-book" label="Zobacz raport"
                                        v-if="acceptedParticipants.has(slotProps.data.id)"
                                        @click="$router.push({name: 'collectionReportForOrganisation', params: {
                                            collectionId: collectionId,
                                            organisationId: slotProps.data.id
                                        }})"/>
                                <Button type="button" icon="pi pi-unlock" class="p-m-1"
                                        label="Odblokuj raport szacunkowy"
                                        @click="unlockDailyReportsForOrganisation($event, slotProps.data)"/>
                            </div>
                        </OverlayPanel>
                    </div>

                    <!-- ZWYKŁY WIDOK -->
                    <div v-else :class="'actions-' + slotProps.data.id">
                        <Button type="button" label="Akcje"
                                @click="toggleActionsMenu($event, slotProps)"
                                aria-haspopup="true" aria-controls="overlay_menu"
                                :aria-expanded="actionsMenuVisible"/>
                        <OverlayPanel id="actions_menu" :ref="'actionsMenu' + slotProps.data.id"
                                      append-to=".wrapper" v-if="isMounted"
                                      @show="moveActionsMenu(slotProps.data.id)"
                                      @hide="onHideActionsMenu">
                            <div class="actionsMenu p-d-flex p-flex-column p-flex-md-row">
                                <Button class="button-link p-m-1" type="button" role="link"
                                        icon="pi pi-pencil" label="Edytuj organizację"
                                        @click="$router.push({
                                            name: 'organisationEdit',
                                            params: {'id': slotProps.data.id},
                                        })"/>
                                <Button
                                    v-if="slotProps.data.organisationLevel === 'OPL' && !Role.isOPLCoordinator()"
                                    class="p-m-1"
                                    label="Usuń organizację"
                                    type="button" icon="pi pi-times"
                                    @click="deleteOrganisation(slotProps.data.id, $event)">
                                </Button>
                            </div>
                        </OverlayPanel>
                    </div>
                </template>
            </Column>
            <Column v-if="invitingOrganisations" header="Aktywność"
                    :style="{'min-width': '120px'}">
                <template #body="slotProps">
                    <CustomBadge label="Bierze udział" :color="badgeColor.ACCEPTED"
                                 v-show="acceptedParticipants.has(slotProps.data.id)"/>
                    <CustomBadge label="Zaproszona" :color="badgeColor.INVITED"
                                 v-show="invitedParticipants.has(slotProps.data.id)"/>
                    <CustomBadge label="Odrzucona" :color="badgeColor.DECLINED"
                                 v-show="declinedParticipants.has(slotProps.data.id)"/>
                </template>
            </Column>
            <Column field="name" header="Nazwa" :sortable="true"
                    :style="{'min-width': '200px'}"></Column>
            <Column v-if="Role.isFPBZCoordinator()" field="organisationLevel"
                    header="Poziom organizacji"
                    :style="{'min-width': '200px'}">
                <template #body="slotProps">
                    <CustomBadge label="FPBŻ" :color="badgeColor.FPBZ"
                                 v-show="slotProps.data.organisationLevel === 'FPBZ'"/>
                    <CustomBadge label="BŻ" :color="badgeColor.BZ"
                                 v-show="slotProps.data.organisationLevel === 'BZ'"/>
                    <CustomBadge label="OPL" :color="badgeColor.OPL"
                                 v-show="slotProps.data.organisationLevel === 'OPL'"/>
                </template>
            </Column>
            <Column v-if="Role.isFPBZCoordinator()" field="supervisor.name"
                    :style="{'min-width': '200px'}" header="Organizacja nadrzędna"/>

            <Column v-for="(col, index) of selectedColumns" :field="col.field"
                    :header="col.header" :key="col.field + '_' + index"
                    :sortable="col.sortable" :style="col.style"></Column>

            <template #empty>
                {{ $t('message.other.emptyTable') }}
            </template>
        </DataTable>
    </div>
    <div v-if="displayAll" style="display: flex; justify-content: right;">
        <p>Wyniki {{ organisations.length > 0 ? 1 : 0 }} do {{ totalRecords }} z {{
            totalRecords
        }}</p>
    </div>
</template>

<script>
    import Button from "primevue/button";
    import DataTable from "primevue/datatable";
    import Column from "primevue/column";
    import OverlayPanel from "primevue/overlaypanel";
    import {
        searchOrganisationUsingPOST as searchOrganisation,
        searchOrganisationCountUsingPOST as searchOrganisationCount,
        deleteOrganisationUsingDELETE as deleteOrganisation,
        getSimplifiedCollectionUsingGET as getSimplifiedCollection,
        updateOrganisationInCollectionUsingPOST as updateOrganisationInCollection,
        unlockDailyReportsForOrganisationUsingPOST as unlockDailyReportsForOrganisation,
    } from "@/swagger/vue-api-client";
    import CustomBadge from "@/components/CustomBadge";
    import {SystemRole} from "@/utils/SystemRole";
    import {ExportUtils} from "@/utils/ExportUtils";
    import {AddressUtils} from "@/utils/AddressUtils";
    import CustomMultiSelect from "@/components/form/inner/CustomMultiSelect";
    import {AccessibilityTableMixin} from "@/mixins/AccessibilityTableMixin";
    import {ToastUtils} from "@/utils/ToastUtils";
    import {ContextMenuMixin} from "@/mixins/ContextMenuMixin";

    export default {
        name: "OrganisationTable",

        components: {
            Button, Column, DataTable, CustomBadge, OverlayPanel, CustomMultiSelect,
        },

        mixins: [AccessibilityTableMixin, ContextMenuMixin],

        props: {
            searchCriteria: {
                type: Object,
            },
            displayAll: {
                type: Boolean,
            },
            collectionId: {
                type: Number,
                default: null,
            },
            invitingOrganisations: {
                type: Boolean,
                default: false,
            },
        },

        emits: ["update:searchCriteria", "loaded", "sortSettingsChange"],

        data() {
            return {
                loading: false,
                paginator: true,
                totalRecords: 0,
                organisations: [],
                collectionStartedByFpbz: false,
                collectionClosed: false,
                acceptedParticipants: null,
                invitedParticipants: null,
                declinedParticipants: null,
                engagedParticipants: null,
                searchParams: this.searchCriteria,
                badgeColor: {
                    FPBZ: "primary",
                    BZ: "secondary",
                    OPL: "tertiary",
                    ACCEPTED: "green",
                    INVITED: "#ffdf00",
                    DECLINED: "red",
                },
                Role: SystemRole,
                columns: [
                    {
                        field: "benefiterCount", header: "Liczba osób", sortable: true, style: "",
                    },
                    {
                        field: "address",
                        header: "Adres",
                        sortable: false,
                        style: "{'min-width': '200px'}",
                    },
                    {
                        field: "phoneNumber", header: "Nr telefonu", sortable: false, style: "",
                    },
                    {
                        field: "email", header: "Adres e-mail", sortable: true, style: "",
                    },
                    {
                        field: "collectionContactPerson",
                        header: "Osoba kontaktowa (zbiórki)",
                        sortable: false,
                        style: "",
                    },
                    {
                        field: "donationContactPerson",
                        header: "Osoba kontaktowa (darowizny)",
                        sortable: false,
                        style: "",
                    },
                ],
                selectedColumns: null,
                isMounted: false,
            };
        },

        computed: {
            displayActionsColumn() {
                return !this.collectionId || this.invitingOrganisations;
            },
        },

        watch: {
            displayAll(value) {
                this.paginator = !value;
                if (value) {
                    this.searchParams.page.limit = 999999;
                    this.searchParams.page.offset = 0;
                }
                this.onPage(this.getFirstPage(value), value);
            },

            searchCriteria(value) {
                this.searchParams = value;
            },

            selectedColumns(value) {
                this.$cookies.set("organisationSelectedColumns", JSON.stringify(value));
            },
        },

        created() {
            this.selectedColumns = JSON.parse(this.$cookies.get("organisationSelectedColumns")) || this.columns;
        },

        async mounted() {
            this.loading = true;

            if (this.collectionId) {
                let collectionData;
                try {
                    collectionData = await getSimplifiedCollection({id: this.collectionId});
                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        await this.$router.push({name: "forbidden"});
                    } else {
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });

                        return;
                    }
                }

                this.collectionStartedByFpbz = collectionData.data.startedByFpbz;
                this.collectionClosed = collectionData.data.closedByFpbz;

                this.acceptedParticipants = new Set(collectionData.data.acceptedParticipantsIds);
                this.invitedParticipants = new Set(collectionData.data.invitedParticipantsIds);
                this.declinedParticipants = new Set(collectionData.data.declinedParticipantsIds);

                this.engagedParticipants = new Set([
                    ...collectionData.data.acceptedParticipantsIds,
                    ...collectionData.data.invitedParticipantsIds,
                    ...collectionData.data.declinedParticipantsIds,
                ]);

                this.loading = false;
            }

            this.emitter.on("changedContrast", this.handleContrastType);

            await this.$nextTick(() => {
                const currentContrastType = document.getElementsByClassName("wrapper")[0]
                    .getAttribute("data-contrast-type");
                this.handleContrastType(currentContrastType);

                this.$emit("loaded");
            });

            this.isMounted = true;
        },

        methods: {
            unlockDailyReportsForOrganisation(event, organisation) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno chcesz odblokować raport? "
                        + "Po jego odblokowaniu organizacja z powrotem będzie mogła wprowadzać dane.",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak, odblokuj",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Cofnij",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const body = {
                            collectionId: this.collectionId,
                            organisationId: organisation.id,
                        };
                        unlockDailyReportsForOrganisation(body).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Odblokowano raport.",
                            });
                            organisation.dailyReportLocked = false;
                        });
                    },
                    reject: () => {
                    },
                });
            },

            canUnlockDailyReports(organisation) {
                if (this.Role.isFPBZCoordinator()) {
                    return organisation.dailyReportLocked && organisation.organisationLevel === "BZ";
                }
                return organisation.dailyReportLocked;
            },

            displayInviteButton(organisation) {
                return (!this.engagedParticipants.has(organisation.id)
                    || this.declinedParticipants.has(organisation.id))
                    && (this.Role.isBZCoordinator() || organisation.organisationLevel === "BZ");
            },

            displayDismissButton(organisation) {
                return (this.acceptedParticipants.has(organisation.id) || this.invitedParticipants.has(organisation.id))
                    && (this.Role.isBZCoordinator() || organisation.organisationLevel === "BZ");
            },

            handleContrastType(color) {
                if (color === "black") {
                    this.badgeColor.FPBZ = "black";
                    this.badgeColor.BZ = "black";
                    this.badgeColor.OPL = "black";
                    this.badgeColor.ACCEPTED = "black";
                    this.badgeColor.INVITED = "black";
                    this.badgeColor.DECLINED = "black";
                } else if (color === "yellow") {
                    // TODO: handle this case
                    this.badgeColor.FPBZ = "primary";
                    this.badgeColor.BZ = "secondary";
                    this.badgeColor.OPL = "tertiary";
                    this.badgeColor.ACCEPTED = "green";
                    this.badgeColor.INVITED = "#ffdf00";
                    this.badgeColor.DECLINED = "red";
                } else {
                    this.badgeColor.FPBZ = "primary";
                    this.badgeColor.BZ = "secondary";
                    this.badgeColor.OPL = "tertiary";
                    this.badgeColor.ACCEPTED = "green";
                    this.badgeColor.INVITED = "#ffdf00";
                    this.badgeColor.DECLINED = "red";
                }
            },

            search() {
                this.onPage(this.getFirstPage(this.displayAll), this.displayAll);
            },

            onPage(event, fakeCall = false) {
                if (this.collectionStartedByFpbz) {
                    this.searchParams.startedCollectionId = this.collectionId;
                }
                this.updateTotalRecords();
                this.loading = true;

                if (!fakeCall) {
                    this.$cookies.set("pageLimit", event.rows, "365d");
                }

                this.searchParams.page.offset = event.first;
                this.searchParams.page.limit = event.rows;
                if (!event.fakeEvent || (event.fakeEvent && event.sortField)) {
                    this.searchParams.page.sortField = event.sortField;
                }
                if (!event.fakeEvent || (event.fakeEvent && event.sortOrder)) {
                    this.searchParams.page.sortOrder = event.sortOrder;
                }

                searchOrganisation({searchCriteria: this.searchParams}).then((response) => {
                    this.organisations = response.data;

                    this.organisations.forEach((organisation) => {
                        organisation.address = AddressUtils.generateAddressString(organisation);
                    });

                    this.loading = false;
                    this.isMounted = true;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            onSort(event) {
                this.onPage(event, this.displayAll);
                this.$emit("sortSettingsChange");
            },

            updateTotalRecords() {
                searchOrganisationCount({searchCriteria: this.searchParams}).then((response) => {
                    this.totalRecords = response.data;
                }).catch(() => {
                    ToastUtils.addToast(this, {
                        severity: "error",
                        summary: "Błąd",
                        detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                    });
                });
            },

            getFirstPage(fakeCall = false) {
                const pageLimit = parseInt(this.$cookies.get("pageLimit"), 10);
                if (!fakeCall && pageLimit) {
                    this.searchParams.page.limit = pageLimit;
                }

                return {
                    first: this.searchCriteria.page.offset,
                    rows: this.searchCriteria.page.limit,
                    fakeEvent: true,
                };
            },

            removeOrganisationFromCollectionLocally(organisation) {
                this.acceptedParticipants.delete(organisation.id);
                this.invitedParticipants.delete(organisation.id);
                this.engagedParticipants.delete(organisation.id);
            },

            updateOrganisationParticipationStatus(organisation, removal) {
                const confirmMessage = removal
                    ? " Czy na pewno usunąć organizację ze zbiórki?"
                        + (
                            this.Role.isFPBZCoordinator()
                                ? " Wraz z nią zostaną usunięte wszystkie podległe jej OPL."
                                : ""
                        )
                    : " Czy na pewno dodać organizację do zbiórki?";

                this.$confirm.require({
                    header: "Potwierdzenie",
                    message: confirmMessage,
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        const collectionOrganisationUpdateRequest = {
                            collectionId: this.collectionId,
                            organisationId: organisation.id,
                            removal,
                        };

                        updateOrganisationInCollection({collectionOrganisationUpdateRequest}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: removal
                                    ? "Pomyślnie usunięto organizację ze zbiórki"
                                    : "Pomyślnie zaproszono organizację do zbiórki",
                            });

                            if (removal) {
                                this.removeOrganisationFromCollectionLocally(organisation);

                                this.organisations.forEach((o) => {
                                    if (o.supervisor.id === organisation.id) {
                                        this.removeOrganisationFromCollectionLocally(o);
                                    }
                                });
                            } else {
                                this.invitedParticipants.add(organisation.id);
                                this.engagedParticipants.add(organisation.id);
                                this.declinedParticipants.delete(organisation.id);
                            }

                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else if (error.response && error.response.status === 409) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: error.response.data,
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            deleteOrganisation(organisationId, event) {
                this.$confirm.require({
                    target: event.currentTarget,
                    header: "Potwierdzenie",
                    message: " Czy na pewno usunąć organizację?",
                    icon: "pi pi-exclamation-triangle",
                    acceptLabel: "Tak",
                    acceptIcon: "pi pi-check",
                    rejectLabel: "Nie",
                    rejectIcon: "pi pi-times",
                    accept: () => {
                        deleteOrganisation({id: organisationId}).then(() => {
                            ToastUtils.addToast(this, {
                                severity: "success",
                                summary: "Sukces",
                                detail: "Pomyślnie usunięto organizację",
                            });
                            this.search();
                        }).catch((error) => {
                            if (error.response && error.response.status === 400) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Usunięcie organizacji nie powiodło się z powodu"
                                        + " zależności w bazie danych",
                                });
                            } else if (error.response && error.response.status === 403) {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Nie masz wystarczających uprawnień",
                                });
                            } else {
                                ToastUtils.addToast(this, {
                                    severity: "error",
                                    summary: "Błąd",
                                    detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                                });
                            }
                        });
                    },
                    reject: () => {
                    },
                });
            },

            exportXLSX(allData) {
                if (allData) {
                    this.loading = true;
                    searchOrganisation({
                        searchCriteria: this.removePagingFromSearchCriteria(),
                    }).then((response) => {
                        const responseData = response.data;
                        responseData.forEach((organisation) => {
                            organisation.address = AddressUtils.generateAddressString(organisation);
                        });
                        ExportUtils.exportXLSX(responseData
                            .map(this.organisationSearchMapper()), "Organizacje", this.getExportFileName());
                        this.loading = false;
                    }).catch(() => {
                        this.loading = false;
                        ToastUtils.addToast(this, {
                            severity: "error",
                            summary: "Błąd",
                            detail: "Wystąpił nieoczekiwany błąd, skontaktuj się z administratorem systemu",
                        });
                    });
                } else {
                    ExportUtils.exportXLSX(this.organisations
                        .map(this.organisationSearchMapper()), "Organizacje", this.getExportFileName());
                }
            },

            organisationSearchMapper() {
                /* eslint-disable quote-props */
                /* eslint-disable dot-notation */
                return (organisation) => {
                    const organisationData = {};
                    if (this.invitingOrganisations) {
                        organisationData["Aktywność"] = this.getOrganisationActivityLabel(organisation.id);
                    }
                    organisationData["Nazwa"] = organisation.name;
                    organisationData["Poziom organizacji"] = organisation.organisationLevel;
                    organisationData["Organizacja nadrzędna"] = organisation.supervisor
                        ? organisation.supervisor.name : null;

                    this.selectedColumns.forEach((column) => {
                        if (column.field === "address") {
                            organisationData["Województwo"] = organisation.voivodeship;
                            // eslint-disable-next-line dot-notation
                            organisationData["Miasto"] = organisation.city;
                            // eslint-disable-next-line dot-notation
                            organisationData["Ulica"] = organisation.street;
                            organisationData["Numer domu"] = organisation.houseNumber;
                            organisationData["Numer mieszkania"] = organisation.apartmentNumber;
                            organisationData["Kod pocztowy"] = organisation.postalCode;

                            return;
                        }

                        // text fields
                        organisationData[column.header] = organisation[column.field];
                    });

                    return organisationData;
                };
            },

            removePagingFromSearchCriteria() {
                const copy = JSON.parse(JSON.stringify(this.searchCriteria));
                if (this.collectionStartedByFpbz) {
                    copy.startedCollectionId = this.collectionId;
                }
                copy.page = {
                    limit: 999999,
                    offset: 0,
                    sortField: null,
                    sortOrder: null,
                };
                return copy;
            },

            getExportFileName() {
                return "Dane organizacji";
            },

            toggleActionsMenu(event, slotProps) {
                this.$refs["actionsMenu" + slotProps.data.id].toggle(event);
            },

            getOrganisationActivityLabel(organisationId) {
                if (this.acceptedParticipants.has(organisationId)) {
                    return "Bierze udział";
                }
                if (this.invitedParticipants.has(organisationId)) {
                    return "Zaproszona";
                }
                if (this.declinedParticipants.has(organisationId)) {
                    return "Odrzucona";
                }
                return "Nie bierze udziału";
            },
        },

    };
</script>

<style lang="less" scoped>

</style>
