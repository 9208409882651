<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomAutocompleteStoreChain name="storeChain" label="Sieć sklepów"
                                          v-model="internalValue.storeChainId"
                                          :complete-function="getStoreChains"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="name" label="Nazwa" v-model="internalValue.name"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomSelectOne name="voivodeship" label="Województwo"
                             v-model="internalValue.voivodeship"
                             :items="voivodeships"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="city" label="Miasto" v-model="internalValue.city"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0">
            <CustomInputText name="address" label="Adres" v-model="internalValue.address"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0" v-if="Role.isFPBZCoordinator()">
            <CustomAutocompleteOrganisation name="assignedBzId" label="Przypisany BŻ"
                                            v-model="internalValue.assignedBzId"
                                            :complete-function="getOrganisations"
                                            organisation-level="BZ"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12 p-py-0" v-if="!Role.isOPLCoordinator()">
            <CustomAutocompleteOrganisation name="assignedOplId" label="Przypisana OPL"
                                            v-model="internalValue.assignedOplId"
                                            :complete-function="Role.isBZCoordinator ?
                                                getSubWithSelfOrganisations : getOrganisations"/>
        </div>
    </div>
</template>

<script>
    import {
        getStoreChainsUsingGET as getStoreChains,
        getOrganisationsUsingGET as getOrganisations,
        getSubordinateOrganisationsWithSelfUsingGET as getSubWithSelfOrganisations,
    } from "@/swagger/vue-api-client";
    import {SystemRole} from "@/utils/SystemRole";
    import {AddressUtils} from "@/utils/AddressUtils";
    import CustomAutocompleteStoreChain from "@/components/form/CustomAutocompleteStoreChain";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomInputText from "@/components/form/CustomInputText";
    import {StoreStatusesUtils} from "@/utils/StoreStatusesUtils";
    import CustomAutocompleteOrganisation from "@/components/form/CustomAutocompleteOrganisation";

    export default {
        name: "CollectionDailyReportSearchCriteria",
        components: {
            CustomAutocompleteOrganisation,
            CustomAutocompleteStoreChain,
            CustomSelectOne,
            CustomInputText,
        },

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                voivodeships: AddressUtils.validVoivodeships,
                collectionStatuses: StoreStatusesUtils.collectionStatuses,
                Role: SystemRole,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            getStoreChains,
            getOrganisations,
            getSubWithSelfOrganisations,
        },
    };
</script>

<style lang="less" scoped>

</style>
