<template>
    <div class="p-grid">
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomInputText name="name" label="Nazwa pliku" v-model="internalValue.name"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12">
            <CustomSelectOne name="categoryType" label="Typ pliku" v-model="internalValue.categoryType"
                             :items="fileCategoryTypes"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12" v-if="internalValue.queryType === 'OTHERS'">
            <CustomSelectOneOrganisationLevel name="organisationLevel"
                                              label="Poziom organizacji przesyłającej"
                                              omitting-opl
                                              v-model="internalValue.organisationLevel"
                                              @change="onOrganisationLevelChange"/>
        </div>
        <div class="p-lg-4 p-sm-6 p-col-12" v-if="internalValue.queryType === 'OTHERS'">
            <CustomAutocompleteOrganisation  v-if="internalValue.organisationLevel" :key="organisationIdKey"
                                             name="organisationId" label="Nazwa organizacji przesyłającej"
                                             :complete-function="getOrganisations"
                                             v-model="internalValue.organisationId"
                                             :organisation-level="internalValue.organisationLevel"/>
        </div>
    </div>
</template>

<script>
    import CustomInputText from "@/components/form/CustomInputText";
    import CustomSelectOne from "@/components/form/inner/CustomSelectOne";
    import CustomSelectOneOrganisationLevel
        from "@/components/form/CustomSelectOneOrganisationLevel.vue";
    import CustomAutocompleteOrganisation
        from "@/components/form/CustomAutocompleteOrganisation.vue";
    import {getOrganisationsUsingGET as getOrganisations} from "@/swagger/vue-api-client";

    export default {
        name: "FileSearchCriteria",

        props: {
            modelValue: null,
        },

        emits: ["update:modelValue"],

        data() {
            return {
                fileCategoryTypes: [
                    {value: "DONATION", label: "Plik dot. darowizn"},
                    {value: "COLLECTION", label: "Plik dot. zbiórek"},
                ],
                organisationIdKey: 0,
            };
        },

        computed: {
            internalValue: {
                get() {
                    return this.modelValue;
                },
                set(value) {
                    this.$emit("update:modelValue", value);
                },
            },
        },

        methods: {
            getOrganisations,
            onOrganisationLevelChange() {
                if (this.organisationIdKey > 0) {
                    this.internalValue.organisationId = undefined;
                }
                this.organisationIdKey += 1;
            },
        },

        components: {
            CustomAutocompleteOrganisation,
            CustomSelectOneOrganisationLevel,
            CustomSelectOne,
            CustomInputText,
        },
    };
</script>

<style lang="less" scoped>

</style>
